import Image from 'next/image';
import React, { ReactNode } from 'react';
import { useRecoilState } from 'recoil';

import { ShouldRender } from '../shared/basic/ShouldRender';
import ForgotPasswordModal from '../V2/ForgotPasswordModal';
import { authModalControl } from '../../atoms/authModalControl';
import { LoginBG } from '../../../assets';

interface Props {
  children?: ReactNode;
  isModal?: boolean;
}

const AuthLayout: React.FC<Props> = ({ children, isModal = false }) => {
  const [visible, setVisible] = useRecoilState(authModalControl);

  const onCancelCloseForgotPasswordModal = () => {
    setVisible((prevState) => ({
      ...prevState,
      forgotPassword: false,
    }));
  };
  return (
    <div className={`${!isModal && ''}  z-10 overflow-y-hidden relative`}>
      <ShouldRender check={!isModal}>
        <div className=' hidden md:block w-full bottom-0 absolute -z-20'>
          <div className=' h-1/2 xl:translate-y-20'>
            <div className='w-full flex justify-center items-center opacity-30'>
              <Image src={LoginBG} alt='LoginBG ' className=' w-full' />
            </div>
          </div>
        </div>
      </ShouldRender>

      <span className='z-50'>{children}</span>
      <ForgotPasswordModal
        onCancel={onCancelCloseForgotPasswordModal}
        visible={visible.forgotPassword}
      />
    </div>
  );
};
export default AuthLayout;
