import { schoolApiClient } from '../apiClients';
import { getLoginToken } from '../../components/shared/getToken';
import { Endpoints } from '../../network';

export interface IUpdateReadingHistory {
  school_id: number;
  post_id: number;
  ecom_user_id: number;
}

export interface IReadingHistory {
  categoriesId?: string | null;
  keyword?: string;
  id: string;
}

export const getAllReadingHistory = async ({
  categoriesId = null,
  keyword,
  id,
}: IReadingHistory) => {
  const token = await getLoginToken();
  const queryParams = new URLSearchParams({
    ecom_user_id: id,
    ...(categoriesId !== null && { category: categoriesId }),
    ...(keyword && { qry: keyword }),
  });

  if (token) {
    const response = await schoolApiClient.get(
      `${Endpoints.readingHistory}?${queryParams}`,
    );
    return response?.data;
  }
};

export const updateReadingHistory = async ({
  school_id,
  post_id,
  ecom_user_id,
}: IUpdateReadingHistory) => {
  const token = await getLoginToken();
  if (token && school_id && ecom_user_id) {
    const response = await schoolApiClient.post(
      `${Endpoints.readingHistoryUpdate}`,
      {
        school_id,
        post_id,
        ecom_user_id,
      },
    );
    return response?.data;
  }
};

export const updateLike = async ({
  likeable_type,
  likeable_id,
  liked,
}: {
  likeable_type: 'post' | 'school';
  likeable_id: string;
  liked: boolean;
}) => {
  const response = await schoolApiClient.post(`${Endpoints.likesSchool}`, {
    likeable_id,
    likeable_type,
    liked,
  });
  return response?.data;
};

export const updateFollow = async ({
  author_id,
  is_follower,
}: {
  author_id: string;
  is_follower: boolean;
}) => {
  const response = await schoolApiClient.post(`${Endpoints.followAuthor}`, {
    author_id,
    is_follower,
  });
  return response?.data;
};

export const getAuthorProfileById = async ({ zl_uid }: { zl_uid: string }) => {
  const response = await schoolApiClient.get(
    `${Endpoints.getAuthorByZl_uid}/${zl_uid}`,
  );
  return response?.data;
};
