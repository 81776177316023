import { Modal } from 'antd';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import mixpanel from 'mixpanel-browser';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { usePayment } from '../RazorPay';
import ButtonDefault, { ButtonVariants } from '../../shared/basic/button';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { formateErrorObject } from '../../shared/formateErrorObject';
import { notify } from '../../shared/notify';
import ExclamationMarkCart from '../../../../assets/Icons/ExclamationMarkCart';
import { user } from '../../../atoms/user';
import { EventDetails, logEvent } from '../../../hooks/useClickEventTracking';
import { getOrderDetails, handleConvertToCod } from '../../../services/Cart';
import {
  FormattedFinalOrder,
  RawOrderData,
} from '../../../utils/interfaces/Orders';
import { User } from '../../../utils/interfaces/User';

interface Data {
  amount: {
    subTotal: number;
    discount: string;
    total: number;
    tax: number;
    couponCode: { coupon_code: string }[];
  };
  razorpayOrderId: string;
  orderId: string;
  userData: User;
  setVisible: (visible: boolean) => void;
  paymentType: string;
}

interface Props {
  visible: boolean;
  closeModal: () => void;
  data: Data;
}

const DotLoader = () => {
  return (
    <motion.span
      className='ml-1'
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      transition={{
        repeat: Infinity,
        repeatType: 'mirror',
        duration: 1,
      }}
    >
      ...
    </motion.span>
  );
};

const CloseModal: React.FC<Props> = ({ closeModal, visible, data }) => {
  const [order, setOrder] = useState<FormattedFinalOrder>();
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [convertCodLoading, setConvertCodLoading] = useState(false);
  const userData: User | undefined = useRecoilValue(user);
  const environment = process.env.NEXT_PUBLIC_APP_ENV;
  const { initializeRazorpay } = usePayment();
  const router = useRouter();
  const cod_limit =
    Number(process.env.NEXT_PUBLIC_ALLOWABLE_COD_AMOUNT) || 12000;

  const { od, cartId } = router.query;

  useEffect(() => {
    if (od) {
      handleFinalCartDetails(od as string);
    }
  }, [od]);

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      if (visible) {
        // Prevent the default action
        event.preventDefault();
        // Push the current state to prevent going back
        window.history.pushState(null, '', window.location.href);
      }
    };

    if (visible) {
      const eventParams: EventDetails = {
        event_name: 'screen_view',
        source: 'global',
        source_type: 'retry_popup',
        source_id: order?.orderId as string,
        sub_source: null,
        sub_source_id: null,
        unit: 'boolean',
        value: 1,
        meta: {
          click_source: 'retry_popup',
        },
      };

      logEvent({
        eventDetails: eventParams,
        userData: userData,
        pathname: router.pathname,
      });
      // Push the initial state
      window.history.pushState(null, '', window.location.href);

      // Listen for popstate events
      window.addEventListener('popstate', handleBackButton);

      // Push state at intervals to ensure it's always at the top of the history stack
      const intervalId = setInterval(() => {
        window.history.pushState(null, '', window.location.href);
      }, 100);

      return () => {
        // Clear the interval when the component unmounts or visibility changes
        clearInterval(intervalId);
        window.removeEventListener('popstate', handleBackButton);
      };
    }
    return;
  }, [visible]);

  const productIds = (order?.orderDetails || [])
    .map((detail: any) => detail?.id)
    .join(',');

  const convertToCod = async () => {
    try {
      setConvertCodLoading(true);
      await handleConvertToCod(
        {
          order_id: order?.orderId as string,
        },
        Number(cartId),
      );

      const eventParams: EventDetails = {
        event_name: 'convert_cod',
        source: 'global',
        source_type: 'orders',
        source_id: order?.orderId as string,
        sub_source: null,
        sub_source_id: null,
        unit: 'INR',
        value: order?.grand_total,
        meta: {
          click_source: 'retry_popup',
          payment_mode: order?.payment_type === 'razorpay' ? 'prepaid' : 'cod',
        },
      };

      logEvent({
        eventDetails: eventParams,
        userData: userData,
        pathname: router.pathname,
      });

      if (typeof mixpanel !== 'undefined' && environment === 'production') {
        mixpanel.track(eventParams.event_name as string, eventParams);
      }

      router.push({
        pathname: '/order-successful',
        query: {
          orderId: od,
          product_ids: productIds,
          callPurchased: 'purchased',
        },
      });
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setConvertCodLoading(false);
    }
  };

  const formatOrderDetails = (
    data: RawOrderData | null,
  ): FormattedFinalOrder | null => {
    if (!data) return null;

    const formattedOrder: FormattedFinalOrder = {
      orderId: data.id.toString(),
      transactionId: data.payment_details?.rzp_order_id || '',
      grand_total: data.grand_total || 0,
      payment_type: data.payment_type,
      currency: data?.currency_symbol || '',

      orderDetails: data.order_details.map((item) => ({
        id: item.id.toString(),
        name: item.product.name,
        quantity: item.quantity,
        product_id: item.product_id,
        price: item.product.main_price,
        image: item.product.thumbnail_image,
        currency_symbol: item?.product?.currency_symbol,
        book_type: item?.product?.book_type,
      })),
    };

    return formattedOrder;
  };

  const handleFinalCartDetails = async (id: string) => {
    try {
      setLoadingOrder(true);
      if (id) {
        const data = await getOrderDetails(id);
        const formattedData = formatOrderDetails(data);
        setOrder(formattedData as FormattedFinalOrder);
      }
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    } finally {
      setLoadingOrder(false);
    }
  };

  const handlePayment = async () => {
    closeModal();
    await initializeRazorpay({
      totalAmount: order?.grand_total || 0,
      razorpayOrderId: order?.transactionId || '',
      orderId: order?.orderId || '',

      userData: {
        ...userData,
        name: data?.userData?.name ?? userData?.name ?? '',
        email: data?.userData?.email ?? userData?.email ?? '',
      } as User,
      setVisible: data.setVisible,
      paymentType: 'razor-pay',
    });

    const eventParams: EventDetails = {
      event_name: 'retry_payment',
      source: 'global',
      source_type: 'orders',
      source_id: order?.orderId as string,
      sub_source: null,
      sub_source_id: null,
      unit: 'INR',
      value: order?.grand_total,
      meta: {
        click_source: 'retry_popup',
        payment_mode: order?.payment_type === 'razorpay' ? 'prepaid' : 'cod',
      },
    };

    logEvent({
      eventDetails: eventParams,
      userData: userData,
      pathname: router.pathname,
    });

    if (typeof mixpanel !== 'undefined' && environment === 'production') {
      mixpanel.track(eventParams.event_name as string, eventParams);
    }
  };

  const hasDigitalBook = order?.orderDetails.some(
    (detail) => detail.book_type === 'digital',
  );

  return (
    <Modal
      open={visible}
      maskClosable={false}
      closable={false}
      centered={true}
      footer={null}
      styles={{
        mask: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          backdropFilter: 'blur(2px)',
        },
      }}
      className='md:max-w-[505px] customModalNoPadding customMaskModal'
      onCancel={closeModal}
    >
      <div className='flex flex-col gap-y-3 bg-[#000000E5] border rounded-2xl border-[#303030] px-6 pb-12'>
        <span className='text-center flex justify-center mt-16 xs:mt-20 md:mt-24'>
          <ExclamationMarkCart className='text-[#DC9646] w-16 h-16' />
        </span>
        <p className='text-[#A0A0A0] text-center font-semibold text-base mt-2'>
          Your Order Failed
        </p>
        <p className='text-lg font-semibold text-white text-center px-5 md:px-16 mt-8'>
          Don’t worry, all your filled information is safe with us.
        </p>
        <p className='md:px-24 text-center mt-0.5 text-[#A7A7A7]'>
          <ShouldRender check={!hasDigitalBook}>
            Choose Cash on Delivery (COD) or
          </ShouldRender>{' '}
          Retry to complete your order.
        </p>
        <div className='flex flex-col-reverse md:flex-row gap-y-2 xs:gap-x-3 md:gap-y-0 md:gap-x-3.5 mt-8 xs:mt-8 md:mt-10 relative'>
          <ShouldRender check={hasDigitalBook}>
            <p
              onClick={() => {
                closeModal();
                const eventParams: EventDetails = {
                  event_name: 'cancel_order',
                  source: 'global',
                  source_type: 'orders',
                  source_id: order?.orderId as string,
                  sub_source: null,
                  sub_source_id: null,
                  unit: 'INR',
                  value: order?.grand_total,
                  meta: {
                    click_source: 'retry_popup',
                    payment_mode:
                      order?.payment_type === 'razorpay' ? 'prepaid' : 'cod',
                  },
                };

                logEvent({
                  eventDetails: eventParams,
                  userData: userData,
                  pathname: router.pathname,
                });
                if (
                  typeof mixpanel !== 'undefined' &&
                  environment === 'production'
                ) {
                  mixpanel.track(eventParams.event_name as string, eventParams);
                }

                router.push('/my-orders');
              }}
              className={clsx(
                hasDigitalBook ? 'md:w-1/2' : '',
                'text-sm md:text-base hover:text-white cursor-pointer underline font-medium text-[#737373] text-center mt-4 underline-offset-2',
              )}
            >
              Don’t place my Order
            </p>
          </ShouldRender>
          <ButtonDefault
            size={2}
            roundedSm={true}
            disabled={loadingOrder}
            className='md:w-5/12 border border-[#2C2C2C]'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handlePayment();
            }}
            variant={
              hasDigitalBook ? ButtonVariants.WHITE : ButtonVariants.GRAY
            }
          >
            <span
              className={clsx(
                hasDigitalBook ? 'text-black' : 'text-white',
                'flex text-base gap-x-2 md:px-4 md:py-0.5 whitespace-nowrap',
              )}
            >
              Retry Order
            </span>
          </ButtonDefault>
          <ShouldRender check={!hasDigitalBook}>
            <ButtonDefault
              size={2}
              roundedSm={true}
              disabled={
                convertCodLoading || (order?.grand_total || 0) > cod_limit
              }
              onClick={() => {
                convertToCod();
              }}
              spinColor='black'
              enableScaling={false}
              className={clsx(
                (order?.grand_total || 0) > cod_limit && 'mb-5',
                'md:w-7/12',
              )}
              loading={convertCodLoading}
              variant={ButtonVariants.WHITE}
            >
              <span className='flex text-sm md:text-base text-black font-bold gap-x-2 md:px-4 py-0.5 whitespace-nowrap'>
                {!convertCodLoading ? 'Complete with COD' : 'Completing'}
                {convertCodLoading && <DotLoader />}
              </span>
            </ButtonDefault>
          </ShouldRender>
          <ShouldRender check={(order?.grand_total || 0) > cod_limit}>
            <p className='text-xs md:w-7/12 right-0 text-textGrayLight top-[52px] pl-5 absolute'>
              COD unavailable for amount {cod_limit}
            </p>
          </ShouldRender>
        </div>

        <ShouldRender check={!hasDigitalBook}>
          <p
            onClick={() => {
              closeModal();
              const eventParams: EventDetails = {
                event_name: 'cancel_order',
                source: 'global',
                source_type: 'orders',
                source_id: order?.orderId as string,
                sub_source: null,
                sub_source_id: null,
                unit: 'INR',
                value: order?.grand_total,
                meta: {
                  click_source: 'retry_popup',
                  payment_mode:
                    order?.payment_type === 'razorpay' ? 'prepaid' : 'cod',
                },
              };

              logEvent({
                eventDetails: eventParams,
                userData: userData,
                pathname: router.pathname,
              });
              if (
                typeof mixpanel !== 'undefined' &&
                environment === 'production'
              ) {
                mixpanel.track(eventParams.event_name as string, eventParams);
              }

              router.push('/my-orders');
            }}
            className='text-sm md:text-base hover:text-white cursor-pointer underline font-medium text-[#737373] text-center mt-7 underline-offset-2'
          >
            Don’t place my Order
          </p>
        </ShouldRender>
      </div>
    </Modal>
  );
};

export default CloseModal;
