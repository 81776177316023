export const bannerPagesStaging = [
  { id: '6683a35e44cf067cfe0460ea', name: 'live' },
  { id: '668b83fa77de0a1e6e254b67', name: 'home' },
  { id: '66a0aa73655df5c99a0ded13', name: 'books' },
  { id: '66a0aa80655df5c99a0ded19', name: 'school' },
  { id: '66a0f3d4655df5c99a0df095', name: 'courses' },
];

export const bannerPagesLive = [
  { id: '6682b46d97875dcc23a36ca6', name: 'live' },
  { id: '66838f9e97875dcc23a59dd8', name: 'home' },
  { id: '66b09dd4168e3f1576ae1c38', name: 'courses' },
  { id: '670d17156dac25f050532cd6', name: 'books' },
  { id: '670d172f6dac25f050532e85', name: 'school' },
];
