import Cookies from 'js-cookie';

const SESSION_EXISTS_COOKIE_NAME = 'sessionExists';
const SESSION_EXISTS_COOKIE_EXPIRATION = 1 / 12;

export const getSessionExistsFromCookie = (): boolean | undefined => {
  const value = Cookies.get(SESSION_EXISTS_COOKIE_NAME);
  return value ? value === 'true' : undefined;
};

export const setSessionExistsInCookie = (exists: boolean): void => {
  Cookies.set(SESSION_EXISTS_COOKIE_NAME, exists.toString(), {
    expires: SESSION_EXISTS_COOKIE_EXPIRATION,
  });
};

export const resetSessionExistsCookie = (): void => {
  Cookies.remove(SESSION_EXISTS_COOKIE_NAME);
};
