import { RcFile } from 'antd/es/upload';

import { apiClient, baseForPdf } from '../apiClients';
import { Endpoints } from '../../network';

interface IReview {
  limit: number;
  page: number;
  product_id: string;
  sortByDate?: string;
  zl_uid?: string;
  sortByRating?: string;
}

interface IReviewBody {
  rating: number;
  title: string;
  text: string;
  images: (string | RcFile | undefined)[];
  userName: string;
  userId: string;
  userImage?: string;
}

export const isValidObjectId = (id: string): boolean =>
  /^[a-fA-F0-9]{24}$/.test(id);

const fetchReviewData = async (
  endpoint: string,
  { limit, page, product_id, sortByDate, zl_uid, sortByRating }: IReview,
) => {
  const queryParams = new URLSearchParams({
    limit: String(limit),
    page: String(page),
    ...(sortByDate && { sortByDate }),
    ...(sortByRating && { sortByRating }),

    ...(zl_uid && { zl_uid: zl_uid as string }),
  });
  if (!isValidObjectId(product_id)) {
    throw '';
  }
  const response = await baseForPdf.get(
    `${endpoint}/${product_id}?${queryParams}`,
  );
  return response?.data?.data;
};

export const getAllReviews = async (params: IReview) => {
  return fetchReviewData(`${Endpoints.getAllProductReviews}`, params);
};

export const getAverageRating = async (productId: string) => {
  if (!isValidObjectId(productId)) {
    throw '';
  }
  const response = await baseForPdf.get(
    `${Endpoints.getAllProductReviews}/${productId}/average-rating`,
  );
  return response?.data?.data;
};

export const checkIsPurchased = async (productId: string) => {
  const response = await apiClient.get(
    `${Endpoints.checkIsPurchased}?product_id=${productId}`,
  );
  return response?.data?.data;
};

export const getAllReviewsImages = async (params: IReview) => {
  return fetchReviewData(`${Endpoints.getAllProductReviews}/images`, params);
};

export const createReview = async (data: IReviewBody, product_id: string) => {
  const formData = new FormData();

  formData.append('rating', data.rating.toString());
  formData.append('title', data.title);
  formData.append('text', data.text);

  if (data.images) {
    data.images.forEach((image) => {
      if (image) {
        formData.append('images', image);
      }
    });
  }

  formData.append('userName', data.userName);
  formData.append('userId', data.userId);

  if (data.userImage) {
    formData.append('userImage', data.userImage);
  }
  if (!isValidObjectId(product_id)) {
    throw '';
  }
  const response = await baseForPdf.post(
    `${Endpoints.getAllProductReviews}/${product_id}`,
    formData,
  );

  return response?.data?.data;
};

export const updateReview = async (
  data: Partial<IReviewBody>,
  reviewId: string,
  imageIdForDelete: string[],
) => {
  const formData = new FormData();

  // Append only defined values
  const appendField = (key: string, value: any) => {
    if (value != null) formData.append(key, value);
  };

  appendField('rating', data.rating?.toString());
  appendField('title', data.title);
  appendField('text', data.text);
  appendField('userName', data.userName);
  appendField('userId', data.userId);
  appendField('userImage', data.userImage);
  if (imageIdForDelete?.length) {
    imageIdForDelete.forEach((id) => {
      formData.append('imageIdForDelete[]', id);
    });
  }

  const convertToFiles = async (images: (string | File | undefined)[]) => {
    return images
      ?.map((image) => {
        return image instanceof File ? image : undefined;
      })
      .filter((image): image is File => image !== undefined);
  };

  if (data?.images) {
    const files = await convertToFiles(data.images);
    files.forEach((file) => {
      if (file) formData.append('images', file);
    });
  }

  const response = await baseForPdf.put(
    `${Endpoints.getAllProductReviews}/${reviewId}`,
    formData,
  );

  return response?.data?.data;
};

export const fetchUserProductReview = async ({
  zl_uid,
  productId,
}: {
  zl_uid: string;
  productId: string;
}) => {
  if (!isValidObjectId(productId)) {
    throw '';
  }
  const response = await baseForPdf.get(
    `${Endpoints.getUserProductReview}/${zl_uid}/product/${productId}/reviews`,
  );
  return response?.data?.data;
};
