import { XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';

import ButtonDefault, { ButtonVariants } from '../../shared/basic/button';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { QrCode } from '../../../../assets';
import AppleLoginSVG from '../../../../assets/Icons/AppleLoginSVG';
import PlayStoreIcon from '../../../../assets/Icons/PlayStoreIcon';
import { user } from '../../../atoms/user';
import { EventDetails, logEvent } from '../../../hooks/useClickEventTracking';
import { User } from '../../../utils/interfaces/User';

interface Props {
  isClosable?: boolean;
  onCancel?: () => void;
}

const GetAppModalContent: React.FC<Props> = ({ isClosable, onCancel }) => {
  const router = useRouter();
  const userData: User | undefined = useRecoilValue(user);
  const environment = process.env.NEXT_PUBLIC_APP_ENV;
  return (
    <div className='z-30 flex justify-center items-center py-10 h-screen bg-none'>
      <div className='bg-bgColor bg-opacity-75 relative flex gap-y-7 md:gap-y-10 py-10 flex-col px-10 md:px-20 max-w-[550px] text-white  rounded-2xl md:border-2 border-borderDarkGray backdrop-filter backdrop-blur-sm'>
        <ShouldRender check={isClosable}>
          <span
            onClick={() => {
              onCancel && onCancel();
            }}
            className='absolute right-[6%] top-[7%] xs:top-[3%] md:top-[5%]  cursor-pointer  group'
          >
            <div className='w-6 h-6 xs:w-9 xs:h-9 rounded-full flex justify-center items-center bg-[#212121] p-1'>
              <XIcon className='w-4 h-4 md:w-6 md:h-6 group-hover:text-[#A8A8A8] text-[#787878]' />
            </div>
          </span>
        </ShouldRender>
        <div className='flex justify-center'>
          <div className='flex flex-col relative'>
            <span className='text-xl md:text-2xl font-semibold'>
              Download our App
            </span>
            <span className='text-[#818181] text-sm md:text-base font-medium'>
              For Best Reading Experience
            </span>
          </div>
        </div>

        <div className='px-6'>
          <Image
            src={QrCode}
            width={260}
            height={260}
            alt='QR Code'
            className='rounded-xl'
          />
        </div>
        <div className='flex gap-y-4 flex-col'>
          <ButtonDefault
            size={2}
            rounded={true}
            onClick={() => {
              const eventParams: EventDetails = {
                event_name: 'play_store',
                source: 'global',
                source_type: 'app_download',
                source_id: null,
                sub_source: null,
                sub_source_id: null,
                unit: 'boolean',
                value: 1,
              };

              logEvent({
                eventDetails: eventParams,
                userData: userData,
                pathname: router.pathname,
              });
              if (
                typeof mixpanel !== 'undefined' &&
                environment === 'production'
              ) {
                mixpanel.track(eventParams.event_name as string, eventParams);
              }
              router.push(
                'https://play.google.com/store/apps/details?id=com.zebralearn.reader&utm_source=zebralearn_website',
              );
            }}
            className='border border-[#2C2C2C]'
            variant={ButtonVariants.GRAY}
          >
            <span className='flex items-center'>
              <PlayStoreIcon />
              <span
                className={clsx(
                  'text-white',
                  'flex text-base flex-col justify-start gap-x-2 px-4 xs:py-1 whitespace-nowrap',
                )}
              >
                <p className='text-xs text-start'>GET IT ON</p>
                <p className='text-lg font-medium'>Google Play</p>
              </span>
            </span>
          </ButtonDefault>
          <ButtonDefault
            size={2}
            rounded={true}
            onClick={() => {
              const eventParams: EventDetails = {
                event_name: 'app_store',
                source: 'global',
                source_type: 'app_download',
                source_id: null,
                sub_source: null,
                sub_source_id: null,
                unit: 'boolean',
                value: 1,
              };

              logEvent({
                eventDetails: eventParams,
                userData: userData,
                pathname: router.pathname,
              });
              if (
                typeof mixpanel !== 'undefined' &&
                environment === 'production'
              ) {
                mixpanel.track(eventParams.event_name as string, eventParams);
              }
              router.push(
                'https://apps.apple.com/in/app/zebralearn/id6477746268?utm_source=zebralearn_website',
              );
            }}
            className='border border-[#2C2C2C]'
            variant={ButtonVariants.GRAY}
          >
            <span className='flex items-center'>
              <AppleLoginSVG />
              <span
                className={clsx(
                  'text-white',
                  'flex text-base flex-col gap-x-2 px-4 xs:py-1 whitespace-nowrap',
                )}
              >
                <p className='text-xs'>Download on the</p>
                <p className='text-lg font-medium'>Apple Store</p>
              </span>
            </span>
          </ButtonDefault>
        </div>
      </div>
    </div>
  );
};

export default GetAppModalContent;
