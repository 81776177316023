import { XIcon } from '@heroicons/react/outline';
import { Modal } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Image from 'next/image';
import React, { useState } from 'react';

import Headings from '../landingPage/heading';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import YoutubeIcon from '../../../../assets/Icons/YoutubeIcon';
import { IIframeLandingPage } from '../../../utils/interfaces/landingPage';

interface IframeLandingPageProps {
  data: IIframeLandingPage;
}

const extractYoutubeId = (url: string): string | null => {
  const longUrlPattern =
    /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|embed|shorts|watch)[/?])?v=|youtube\.com\/.*[?&]v=)([a-zA-Z0-9_-]{11})/;
  const shortUrlPattern = /(?:youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const embedUrlPattern = /(?:youtube\.com\/embed\/)([a-zA-Z0-9_-]{11})/;
  const mobileUrlPattern = /(?:m\.youtube\.com\/watch\?v=)([a-zA-Z0-9_-]{11})/;
  const attributionUrlPattern =
    /(?:youtube\.com\/attribution_link\?.*v=)([a-zA-Z0-9_-]{11})/;

  const longUrlMatch = url.match(longUrlPattern);
  const shortUrlMatch = url.match(shortUrlPattern);
  const embedUrlMatch = url.match(embedUrlPattern);
  const mobileUrlMatch = url.match(mobileUrlPattern);
  const attributionUrlMatch = url.match(attributionUrlPattern);

  if (longUrlMatch) return longUrlMatch[1] as string;
  if (shortUrlMatch) return shortUrlMatch[1] as string;
  if (embedUrlMatch) return embedUrlMatch[1] as string;
  if (mobileUrlMatch) return mobileUrlMatch[1] as string;
  if (attributionUrlMatch) return attributionUrlMatch[1] as string;

  return null;
};

const IframeLandingPage: React.FC<IframeLandingPageProps> = ({ data }) => {
  const screens = useBreakpoint();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className=''>
      <ShouldRender check={data.iframeUrl}>
        <Headings heading='Discover what’s inside' />

        {/* Image triggering the modal */}
        <div className=''>
          <div className='p-4 md:p-8 bg-[#131313] rounded-lg'>
            <div
              onClick={showModal}
              className='relative rounded-lg flex justify-center items-center w-full cursor-pointer group'
            >
              <Image
                src={data?.thumbnailUrl}
                alt=''
                width={766}
                height={400}
                className='cursor-pointer w-full h-auto'
              />
              {/* YouTube Icon */}
              <div className='absolute transform scale-100 group-hover:scale-110 transition-transform duration-300 inset-0 flex justify-center items-center'>
                <YoutubeIcon />
              </div>
            </div>
          </div>
        </div>
      </ShouldRender>

      {/* Ant Design Modal */}
      <Modal
        title=''
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={screens.lg ? '70%' : '100%'}
        className='custom-modal-bg customLoginModal'
        closable={false}
        style={{ top: 0, backgroundColor: 'transparent' }}
        centered={true}
        // bodyStyle={{ height: '80vh', padding: 0 }} // Make modal body take full screen height
        destroyOnClose={true} // Destroy the modal when closed
      >
        <div className='h-full  '>
          <div className='flex justify-end pb-2'>
            <span
              onClick={() => handleCancel()}
              className=' rounded-full p-1.5 cursor-pointer bg-[#212121]  flex justify-center items-center'
            >
              <XIcon className='w-5 h-5  text-[#787878] cursor-pointer' />
            </span>
          </div>

          <div
            className='no-select custom-modal-bg rounded-lg'
            id='trackingModal'
            style={{ height: screens.lg ? '30vw' : '70vw' }}
          >
            <span className='h-2/3'>
              <iframe
                width='100%'
                height='100%'
                className='rounded-lg'
                src={`https://www.youtube.com/embed/${extractYoutubeId(
                  data?.iframeUrl || '',
                )}?rel=0&autoplay=1&mute=1`}
                allowFullScreen
              />
            </span>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default IframeLandingPage;
