import Cookies from 'js-cookie';

const EXPIRATION_DAYS = 60;

export const getCookieItem = (key: string): string | undefined => {
  return Cookies.get(key);
};

export const setCookieItem = (key: string, value: string): void => {
  Cookies.set(key, value, { expires: EXPIRATION_DAYS });
};

export const removeCookieItem = (key: string): void => {
  Cookies.remove(key);
};

export const isTokenExpired = (): boolean => {
  const tokenTimestamp = parseInt(getCookieItem('tokenTimestamp') || '0', 10);
  if (tokenTimestamp) {
    return Date.now() - tokenTimestamp > EXPIRATION_DAYS * 24 * 60 * 60 * 1000;
  }
  return true;
};

export const initializeCache = (): void => {
  if (isTokenExpired()) {
    removeCookieItem('cachedToken');
    removeCookieItem('sessionChecked');
    removeCookieItem('sessionValid');
    removeCookieItem('tokenTimestamp');
  }
};

export const setSessionChecked = (value: boolean): void => {
  setCookieItem('sessionChecked', value.toString());
};

export const setSessionValid = (value: boolean): void => {
  setCookieItem('sessionChecked', value.toString());
};

export const isSessionChecked = (): boolean => {
  return getCookieItem('sessionChecked') === 'true';
};

export const isSessionCheckedIsFalse = (): boolean => {
  return getCookieItem('sessionChecked') === 'false';
};

export const isSessionValid = (): boolean => {
  return getCookieItem('sessionValid') === 'true';
};

export const clearSession = (): void => {
  removeCookieItem('cachedToken');
  removeCookieItem('sessionChecked');
  removeCookieItem('sessionValid');
  removeCookieItem('tokenTimestamp');
};
