import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { Button, InputNumber } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ShouldRender } from '../shared/basic/ShouldRender';
import { formateErrorObject } from '../shared/formateErrorObject';
import { trackAddToCartEvent, trackRemoveToCartEvent } from '../shared/Gtags';
import { notify } from '../shared/notify';
import { localCart } from '../../atoms/LocalCart';
import { user } from '../../atoms/user';
import {
  deleteCartItem,
  getCartCount,
  getCartSummary,
  updateCartProductQuantity,
} from '../../services/Cart';
import { CartItem } from '../../utils/interfaces/cart';
import { User } from '../../utils/interfaces/User';
import { BookDummy } from '../../../assets';

interface Props {
  data: CartItem;
  handleRemoveProduct: (value: string) => void;
  handleQuantityChange: (id: string, increment: number) => void;
  amount: { subTotal: number; discount: string; total: number };
  cartDataLoading: boolean;
  setAmount: any;
  handleCartDetails?: () => void;
}

const CartCard: React.FC<Props> = ({
  data,
  handleRemoveProduct,
  handleQuantityChange,
  amount,
  setAmount,
  cartDataLoading,
  handleCartDetails,
}) => {
  const screens = useBreakpoint();
  const router = useRouter();
  const [loading, setLoading] = useState({
    increase: false,
    decrease: false,
  });

  const [, setLocalCartCount] = useRecoilState(localCart);
  const userData: User | undefined = useRecoilValue(user);

  const handleCartSummary = async () => {
    try {
      const data = await getCartSummary();
      setAmount({
        subTotal: data?.sub_total,
        discount: data?.discount,
        total: data?.grand_total,
        tax: data?.tax,
        actualCartTotal: data?.product_regular_price,
        couponCode: data?.coupon_code || [],
      });

      // setProducts(formattedData);
    } catch (error: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  const handleUpdateCartCount = async (quantity: number) => {
    try {
      setLoading({
        increase: quantity > (data?.quantity || 0),
        decrease: quantity < (data?.quantity || 0),
      });
      await updateCartProductQuantity({
        id: data.id,
        quantity: quantity,
      });
      await handleCartSummary();
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setLoading({
        increase: false,
        decrease: false,
      });
    }
  };

  const handleDeleteCartItem = async () => {
    try {
      await deleteCartItem(data.id.toString());
      await handleCartSummary();
      await handleCartCount();
      handleCartDetails && (await handleCartDetails());
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    }
  };

  const handleCartCount = async () => {
    try {
      const data = await getCartCount({});
      setLocalCartCount(data.count);
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    }
  };

  return (
    <div className='bg-cartBgColor rounded-lg'>
      <div className='flex flex-col gap-y-6 pb-6 gap-x-2 justify-center p-2 xxxs:p-4 xl:p-4 cardShadow rounded-lg bg-cartModalFormBg text-fadedWhite'>
        <div className='flex flex-row gap-x-3 md:gap-5 w-full h-full relative'>
          <div
            className={clsx(
              data?.itemType === 'Book' || data?.itemType === 'E-Book'
                ? 'items-end  pt-5'
                : 'items-center',
              !(
                data?.type === 'hardcover' &&
                data?.itemType?.toUpperCase() === 'BOOK'
              )
                ? 'w-4/12 '
                : 'w-1/2',
              ' min-w-[144px] md:w-40 md:max-h-32 md:min-w-64 md:min-h-40 bg-black rounded-2xl flex justify-center md:gap-x-1.5 px-4',
            )}
          >
            <span>
              <Image
                src={data?.image || BookDummy}
                alt='Product'
                width={
                  data?.type === 'hardcover' &&
                  data?.itemType?.toUpperCase() === 'BOOK'
                    ? screens.md
                      ? 115
                      : 80
                    : screens.md
                      ? 100
                      : 70
                }
                height={screens.md ? 170 : 120}
                className={clsx(
                  !(
                    data?.type === 'hardcover' &&
                    data?.itemType?.toUpperCase() === 'BOOK'
                  )
                    ? 'h-[80px] md:h-[128px] '
                    : 'object-contain   md:h-[128px] md:translate-x-2',
                )}
              />
            </span>
            <ShouldRender
              check={
                data?.type === 'hardcover' &&
                data?.itemType?.toUpperCase() === 'BOOK'
              }
            >
              <span className='flex justify-end items-end pl-1 relative md:-translate-x-2'>
                <span className='xxxs:px-1 xxxs:pt-2 xxxs:bg-[#434343] rounded-md'>
                  <Image
                    src={data?.image || BookDummy}
                    alt='Product'
                    width={0}
                    height={120}
                    className='object-contain w-[60px] md:w-[60px]'
                  />
                </span>

                <div className="absolute inset-0 before:content-[''] before:absolute before:inset-0 before:bg-gradient-to-t from-bgColor " />
                <div
                  style={{ lineHeight: '20px' }}
                  className='absolute md:pl-2 pb-1 text-fadedWhite text-[8px] xxxs:text-[10px] md:text-xs leading-tight left-[5px] xxxs:left-[10px] md:left-1 bottom-1'
                >
                  <p className='flex justify-center pb-1 md:pb-2 pr-2 items-center'>
                    <PlusIcon className='w-3 h-3 md:w-5 md:h-5' />
                  </p>{' '}
                  DIGITAL COPY
                </div>
              </span>
            </ShouldRender>
          </div>
          <div className='flex flex-row w-1/2 h-full justify-between md:w-full'>
            <div className='flex flex-col gap-y-2 md:pt-2 w-full'>
              <div className='flex flex-col'>
                <span className='text-sm md:text-lg font-medium'>
                  {data.name}
                </span>
              </div>

              <div>
                <div className='flex font-medium xxxs:items-end gap-2'>
                  <span className='text-sm xxxs:text-base md:pt-0'>
                    {data.currency} {data.price}
                  </span>
                  <span className='text-sm xxxs:text-base line-through text-[#737373] '>
                    {data.currency} {data.actualPrice}
                  </span>
                </div>
                <ShouldRender check={amount.discount}>
                  <span className='text-green-600 text-[10px] md:text-sm'>
                    YAY! YOU SAVED {data.actualPrice - data.price}!
                  </span>
                </ShouldRender>
              </div>
              <div className='flex md:justify-between items-end w-full'>
                <div>
                  <ShouldRender
                    check={
                      (!(
                        data?.itemType?.toUpperCase() === 'BOOK' &&
                        data?.type === 'hardcover'
                      ) &&
                        screens.md) ||
                      data?.itemType === 'Bundle'
                    }
                  >
                    <div className='bg-[#2C2302] rounded-md flex justify-center'>
                      <span className='font-bold text-[9px] xxxs:text-xs px-1 xs:px-4 py-1 tracking-wider text-[#FFC700]  whitespace-nowrap'>
                        Limited Time Deal
                      </span>
                    </div>
                  </ShouldRender>
                  <ShouldRender
                    check={
                      data?.itemType?.toUpperCase() === 'BOOK' &&
                      data?.type === 'hardcover'
                    }
                  >
                    <div className='bg-[#430F11] rounded-md flex justify-center'>
                      <span className='font-bold text-[9px] xxxs:text-xs p-1 tracking-wider uppercase text-[#FF1D26] whitespace-nowrap'>
                        Only few left
                      </span>
                    </div>
                  </ShouldRender>
                </div>

                <ShouldRender check={data?.type === 'hardcover' && screens.md}>
                  <div className='flex w-full items-center justify-end'>
                    <InputNumber
                      min={data?.lowerLimit || 1}
                      max={data?.upperLimit || 10}
                      controls={false}
                      disabled={true}
                      formatter={(value) =>
                        value !== undefined
                          ? `${value < 10 ? '0' : ''}${value}`
                          : ''
                      }
                      value={data.quantity}
                      style={{ textAlign: 'center', border: 'none' }}
                      className='flex justify-center text-center inputTextCenter disabled:text-white inputTextColor w-20 '
                      addonBefore={
                        <Button
                          disabled={cartDataLoading}
                          loading={loading.decrease}
                          style={{ border: 'none' }}
                          className={clsx(
                            loading.decrease && 'w-8',
                            'cursor-pointer border-0 m-0 px-3 bg-lightGray text-white rounded-r-none font-semibold hover:text-primary',
                          )}
                          onClick={async () => {
                            if ((data?.quantity || 0) > 1) {
                              await handleQuantityChange(
                                data.id ? String(data.id) : '',
                                -1,
                              );
                              await handleUpdateCartCount(
                                (data.quantity || 0) - 1,
                              );
                              await trackRemoveToCartEvent({
                                price: data?.price,
                                id: data?.id as number,
                                heading: data?.name,
                                quantity: 1,
                              });
                            }
                          }}
                        >
                          <ShouldRender check={!loading.decrease}>
                            -
                          </ShouldRender>
                        </Button>
                      }
                      addonAfter={
                        <Button
                          disabled={cartDataLoading}
                          loading={loading.increase}
                          className={clsx(
                            loading.increase && 'w-8',
                            'cursor-pointer border-0 m-0 px-3 bg-lightGray text-white font-semibold rounded-l-none hover:text-primary',
                          )}
                          onClick={async () => {
                            await handleQuantityChange(
                              data.id ? String(data.id) : '',
                              1,
                            );
                            await handleUpdateCartCount(
                              (data.quantity || 0) + 1,
                            );

                            await trackAddToCartEvent({
                              productData: {
                                price: data?.price,
                                id: data?.id as number,
                                heading: data?.name,
                                quantity: 1,
                              },
                              userData,
                              pathname: router.pathname,
                              productType:
                                data?.type === 'hardcover'
                                  ? 'hardcopy'
                                  : 'digitalcopy',
                            });
                          }}
                        >
                          <ShouldRender check={!loading.increase}>
                            +
                          </ShouldRender>
                        </Button>
                      }
                    />
                  </div>
                </ShouldRender>
              </div>
            </div>
            <div className='flex items-end justify-between h-full pb-5'>
              <span className='flex justify-between h-full flex-col pt-4'>
                <div className='hidden md:flex justify-end w-7 p-1 rounded-md bg-lightGray'>
                  <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    whileHover={{
                      scale: 1.1, // Scale up on hover
                      cursor: 'pointer', // Change cursor to pointer
                    }}
                    transition={{ duration: 0.3 }}
                    className='flex items-center justify-center'
                    onClick={() => {
                      handleRemoveProduct(data.id ? String(data.id) : '');
                      handleDeleteCartItem();
                      trackRemoveToCartEvent({
                        price: data?.price,
                        id: data?.id as number,
                        heading: data?.name,
                        quantity: 1,
                      });
                    }}
                  >
                    <TrashIcon className='text-[#646464] w-5 ' />
                  </motion.div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <ShouldRender check={!screens.md}>
          <div className='flex justify-between  w-full'>
            <ShouldRender check={data?.type === 'hardcover'}>
              <span className='pl-5'>
                <InputNumber
                  min={data?.lowerLimit || 1}
                  max={data?.upperLimit || 10}
                  controls={false}
                  disabled={true}
                  formatter={(value) =>
                    value !== undefined
                      ? `${value < 10 ? '0' : ''}${value}`
                      : ''
                  }
                  value={data.quantity}
                  style={{ textAlign: 'center', border: 'none' }}
                  className='flex justify-center text-center inputTextCenter disabled:text-white inputTextColor w-20 '
                  addonBefore={
                    <Button
                      disabled={cartDataLoading}
                      loading={loading.decrease}
                      style={{ border: 'none' }}
                      className={clsx(
                        loading.decrease && 'w-8',
                        'cursor-pointer border-0 m-0 px-3 bg-lightGray text-white rounded-r-none font-semibold hover:text-primary',
                      )}
                      onClick={async () => {
                        if ((data?.quantity || 0) > 1) {
                          await handleQuantityChange(
                            data.id ? String(data.id) : '',
                            -1,
                          );
                          await handleUpdateCartCount((data.quantity || 0) - 1);
                          await trackRemoveToCartEvent({
                            price: data?.price,
                            id: data?.id as number,
                            heading: data?.name,
                            quantity: 1,
                          });
                        }
                      }}
                    >
                      <ShouldRender check={!loading.decrease}>-</ShouldRender>
                    </Button>
                  }
                  addonAfter={
                    <Button
                      disabled={cartDataLoading}
                      loading={loading.increase}
                      className={clsx(
                        loading.increase && 'w-8',
                        'cursor-pointer border-0 m-0 px-3 bg-lightGray text-white font-semibold rounded-l-none hover:text-primary',
                      )}
                      onClick={async () => {
                        await handleQuantityChange(
                          data.id ? String(data.id) : '',
                          1,
                        );
                        await handleUpdateCartCount((data.quantity || 0) + 1);

                        await trackAddToCartEvent({
                          productData: {
                            price: data?.price,
                            id: data?.id as number,
                            heading: data?.name,
                            quantity: 1,
                          },
                          userData,
                          pathname: router.pathname,
                          productType:
                            data?.type === 'hardcover'
                              ? 'hardcopy'
                              : 'digitalcopy',
                        });
                      }}
                    >
                      <ShouldRender check={!loading.increase}>+</ShouldRender>
                    </Button>
                  }
                />
              </span>
            </ShouldRender>
            <ShouldRender
              check={
                !(
                  data?.itemType?.toUpperCase() === 'BOOK' &&
                  data?.type === 'hardcover'
                ) && data?.itemType !== 'Bundle'
              }
            >
              <div className='bg-[#2C2302] rounded-md flex items-center justify-center'>
                <span className='font-bold text-xs px-1 xs:px-4 py-1 tracking-wider text-[#FFC700]  whitespace-nowrap'>
                  Limited Time Deal
                </span>
              </div>
            </ShouldRender>

            <div className='flex justify-end w-full'>
              <div className=' w-7 p-1 flex items-center rounded-md bg-lightGray'>
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  whileHover={{
                    scale: 1.1, // Scale up on hover
                    cursor: 'pointer', // Change cursor to pointer
                  }}
                  transition={{ duration: 0.3 }}
                  className='flex items-center justify-center'
                  onClick={() => {
                    handleRemoveProduct(data.id ? String(data.id) : '');
                    handleDeleteCartItem();
                    trackRemoveToCartEvent({
                      price: data?.price,
                      id: data?.id as number,
                      heading: data?.name,
                      quantity: 1,
                    });
                  }}
                >
                  <TrashIcon className='text-[#646464] w-5 ' />
                </motion.div>
              </div>
            </div>
          </div>
        </ShouldRender>
      </div>
      <ShouldRender
        check={
          data.current_stock === 0 &&
          data?.itemType?.toUpperCase() === 'BOOK' &&
          data?.type === 'hardcover'
        }
      >
        <div className='flex gap-x-2 px-3 md:px-5 py-2.5 items-start'>
          <ExclamationCircleOutlined className='text-base text-[#EAB308]' />
          <div className='text-[#EAB308] text-xs font-semibold  '>
            Due to high demand, delivery may take little longer than expected.
          </div>
        </div>
      </ShouldRender>
    </div>
  );
};

export default CartCard;
