import UAParser from 'ua-parser-js';

import { getScreenName } from './usePageTracking';
import { logPageView } from '../services/ActivityLogs';
import { User } from '../utils/interfaces/User';

const getDeviceInfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  return {
    os: `${result.os.name} ${result.os.version}`,
    browser: `${result.browser.name} ${result.browser.version}`,
    device: result.device.type || 'desktop',
    version: result?.browser?.version,
  };
};

export interface EventDetails {
  event_name: string | null;
  source: string | null;
  source_type: string | null;
  source_id?: string | null;
  sub_source?: string | null;
  source_name?: string | null;
  sub_source_name?: string | null;
  sub_source_id?: string | null;
  zl_uid?: string | undefined;
  activity_start?: string | undefined;
  activity_end?: string | undefined;
  unit?: string | undefined;
  value?: string | number | undefined;
  meta?: Record<string, any>;
}

const removeUndefinedValues = (obj: any) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};

export const logEvent = async ({
  eventDetails,
  userData,
  pathname,
}: {
  eventDetails: EventDetails;
  userData?: User | undefined;
  pathname: string;
}) => {
  if (!eventDetails?.zl_uid && !userData?.zl_uid) return;

  const deviceInfo = getDeviceInfo();
  const referrerUrl = pathname;

  // Update localStorage with current URL and move the current URL to previous
  const currentUrl = pathname as string;
  localStorage.setItem('prevUrl', localStorage.getItem('currentUrl') || '');
  localStorage.setItem('currentUrl', currentUrl);

  const eventData = {
    source: eventDetails.source,
    source_type: eventDetails.source_type,
    source_id: eventDetails.source_id,
    source_name: eventDetails.source_name,
    zl_uid: eventDetails?.zl_uid || userData?.zl_uid,
    event: eventDetails.event_name,
    sub_source_name: eventDetails.sub_source_name,
    unit: eventDetails.unit
      ? eventDetails.unit
      : eventDetails.activity_start
        ? 'seconds'
        : 'boolean',
    value: eventDetails.value
      ? eventDetails.value
      : eventDetails.activity_start
        ? Math.round(
            (new Date(eventDetails.activity_end as string).getTime() -
              new Date(eventDetails.activity_start as string).getTime()) /
              1000,
          )
        : 1,
    meta: {
      platform: 'website',
      os: deviceInfo.os,
      click_source: getScreenName(referrerUrl),
      version: deviceInfo?.version,
      ...eventDetails.meta,
    },
    sub_source_id: eventDetails.sub_source_id,
    sub_source: eventDetails.sub_source,
    activity_start: eventDetails.activity_start,
    activity_end: eventDetails.activity_end,
  };

  const filteredEventData = removeUndefinedValues(eventData);
  logPageView(filteredEventData);
};
