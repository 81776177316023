import { AppwriteException } from 'appwrite';
import { AxiosError, AxiosResponse } from 'axios';

export interface MyAxiosError<T = any> extends AxiosError<T> {
  response?: AxiosResponse<T> | undefined;
}

export const formateErrorObject = (error: MyAxiosError): string => {
  return error?.response?.data?.message;
};

export const formateErrorAppwriteObject = (error: AppwriteException) => {
  if (error?.type === 'user_already_exists') {
    return 'Your account already exists, Please login';
  }

  return error?.message;
};
