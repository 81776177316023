export { default as AppleLogo } from './appleLogo.webp';
export { default as Arrow2 } from './Arrow2.webp';
export { default as ArrowFaq } from './ArrowFaq.webp';
export { default as ArticleImage } from './ArticleImage.webp';
export { default as Author } from './author.webp';
export { default as AuthorLarge } from './AuthorLarge.webp';
export { default as Awards } from './Awards.webp';
export { default as BgImage } from './bgImage.webp';
export { default as Book } from './Book.webp';
export { default as Book_1 } from './Book_1.webp';
export { default as Book_2 } from './Book_2.webp';
export { default as Book_3 } from './Book_3.webp';
export { default as Book_4 } from './Book_4.webp';
export { default as Book_5 } from './Book_5.webp';
export { default as BookArrowBG } from './BookArrowBG.webp';
export { default as BookBundle } from './BookBundle.webp';
export { default as BookFront } from './BookFront.webp';
export { default as BookImage } from './BookImage.webp';
export { default as BookImage1 } from './BookImage/BookImage1.webp';
export { default as BookSideCover } from './BookSideCover.webp';
export { default as Bundle } from './Bundle.webp';
export { default as Candidate_1 } from './Candidate_1.webp';
export { default as Candidate_2 } from './Candidate_2.webp';
export { default as Candidate_3 } from './Candidate_3.webp';
export { default as Candidate_4 } from './Candidate_4.webp';
export { default as Company_1 } from './companies/Company_1.webp';
export { default as Company_2 } from './companies/Company_2.webp';
export { default as Company_3 } from './companies/Company_3.webp';
export { default as Company_4 } from './companies/Company_4.webp';
export { default as Company_5 } from './companies/Company_5.webp';
export { default as Company_6 } from './companies/Company_6.webp';
export { default as CoverImage } from './CoverImage.webp';
export { default as DetailsPageBG } from './DetailsPageBG.webp';
export { default as Divider } from './Divider.webp';
export { default as DoubleArrow } from './DoubleArrow.webp';
export { default as DoubleArrowBlank } from './DoubleArrowBlank.webp';
export { default as DoubleArrowContact } from './DoubleArrowContact.webp';
export { default as DoubleArrowJoin } from './DoubleArrowJoin.webp';
export { default as DoubleArrowLeft } from './DoubleArrowLeft.webp';
export { default as DoubleArrowRight } from './DoubleArrowRight.webp';
export { default as DoubleArrowRightFlip } from './DoubleArrowRightFlip.webp';
export { default as DoubleComma } from './DoubleComma.webp';
export { default as GoogleIcon } from './googleLogo.webp';
export { default as Books } from './Icons/Books.webp';
export { default as Envelope } from './Icons/Envelope.webp';
export { default as Icon } from './Icons/Icon.webp';
export { default as Icon1 } from './Icons/Icon1.webp';
export { default as Icon2 } from './Icons/Icon2.webp';
// export { default as LibrarySVG } from './Icons/LibrarySVG.svg';
export { default as EmailIcon } from './ContactUs/EmailIcon.webp';
export { default as LocationIcon } from './ContactUs/LocationIcon.webp';
export { default as Location } from './Icons/Location.webp';
export { default as Orders } from './Icons/Orders.webp';
export { default as Person } from './Icons/Person.webp';
export { default as PersonHelp } from './Icons/PersonHelp.webp';
export { default as ZCoin } from './Icons/ZCoin.webp';
export { default as InvestSM } from './InvestSM.webp';
export { default as JoinCard1 } from './JoinCard1.webp';
export { default as Person_1 } from './JoinImages/Person_1.webp';
export { default as Person_2 } from './JoinImages/Person_2.webp';
export { default as Profile_1 } from './JumpStart/Profile_1.webp';
export { default as Profile_2 } from './JumpStart/Profile_2.webp';
export { default as Profile_3 } from './JumpStart/Profile_3.webp';
export { default as Profile_4 } from './JumpStart/Profile_4.webp';
export { default as LandingPageBG } from './LandingPageBG.webp';
export { default as Line } from './line.webp';
export { default as LineLarge } from './LineLarge.webp';
export { default as LoginArrow } from './LoginArrow.webp';
export { default as LoginBG } from './LoginBG.webp';
export { default as Logo } from './Logo.webp';
export { default as Page_1 } from './Page_1.webp';
export { default as Page_2 } from './Page_2.webp';
export { default as Page_3 } from './Page_3.webp';
export { default as Page_4 } from './Page_4.webp';
export { default as Payment } from './Payment.webp';
export { default as PreviewSchool } from './PreviewSchool.webp';
export { default as BgLine } from './ProductBookWebP/BgLine.webp';
export { default as LargeBundleBooks } from './ProductBookWebP/LargeBundleBooks.webp';
export { default as SmallBundleBooks } from './ProductBookWebP/SmallBundleBooks.webp';
export { default as Review } from './Review.webp';
export { default as RightArrowJoin } from './RightArrowJoin.webp';
export { default as RotatingBook } from './RotatingBook.webp';
export { default as SchoolImage } from './SchoolImage.webp';
export { default as Facebook } from './socialMediaIcon/Facebook.webp';
export { default as Insta } from './socialMediaIcon/Insta.webp';
export { default as LinkedIn } from './socialMediaIcon/LinkedIn.webp';
export { default as Twitter } from './socialMediaIcon/Twitter.webp';
export { default as TabPlay } from './TabPlay.webp';
export { default as Ticket } from './Ticket.webp';
export { default as ZebraLearnFooterLogo } from './zebraLearnFooterLogo.webp';
export { default as ZCoinIcon } from './zIcon.webp';

// Book Library
export { default as BookBackgroundImage } from './BookBackgroundImage.webp';
export { default as Financial_Modeling_Flat } from './BookLibrary/Financial-Modeling(Flat).webp';
export { default as Financial_Modeling_Open } from './BookLibrary/Financial-Modeling(open).webp';
export { default as Financial_Modeling_Vertical } from './BookLibrary/Financial-Modeling(vertical).webp';
export { default as Financial_Statement_Analysis_Handbook_Flat } from './BookLibrary/Financial-Statement-Analysis-Handbook (flat).webp';
export { default as Financial_Statement_Analysis_Handbook_Open } from './BookLibrary/Financial-Statement-Analysis-Handbook (open).webp';
export { default as Financial_Statement_Analysis_Handbook_Vertical } from './BookLibrary/Financial-Statement-Analysis-Handbook (vertical).webp';
export { default as Fundraising_Decoded_Flat } from './BookLibrary/Fundraising-Decoded (flat).webp';
export { default as Fundraising_Decoded_Open } from './BookLibrary/Fundraising-Decoded (open).webp';
export { default as Fundraising_Decoded_Vertical } from './BookLibrary/Fundraising-Decoded(vertical).webp';
export { default as Futures_and_Options_Open } from './BookLibrary/Futures-&-Options (open).webp';
export { default as Futures_and_Options_Flat } from './BookLibrary/Futures-&-Options(flat).webp';
export { default as Futures_and_Options_Vertical } from './BookLibrary/Futures-&-Options(vertical).webp';
export { default as Master_Residential_Real_Estate_Flat } from './BookLibrary/Master-Residential-Real-Estate (flat).webp';
export { default as Master_Residential_Real_Estate_Open } from './BookLibrary/Master-Residential-Real-Estate (open).webp';
export { default as Master_Residential_Real_Estate_Vertical } from './BookLibrary/Master-Residential-Real-Estate (vertical).webp';
export { default as Money_Smart_Flat } from './BookLibrary/Money-Smart (flat).webp';
export { default as Money_Smart_Open } from './BookLibrary/Money-Smart (open).webp';
export { default as Money_Smart_Vertical } from './BookLibrary/Money-Smart (vertical).webp';
export { default as Stock_Investing_Mastermind_Flat } from './BookLibrary/Stock-Investing-Mastermind (flat).webp';
export { default as Stock_Investing_Mastermind_Open } from './BookLibrary/Stock-Investing-Mastermind (open).webp';
export { default as Stock_Investing_Mastermind_Vertical } from './BookLibrary/Stock-Investing-Mastermind (vertical).webp';
export { default as The_Industry_Handbook_Flat } from './BookLibrary/The-Industry-Handbook (flat).webp';
export { default as The_Industry_Handbook_Open } from './BookLibrary/The-Industry-Handbook (open).webp';
export { default as The_Industry_Handbook_Vertical } from './BookLibrary/The-Industry-Handbook (vertical).webp';
export { default as Trading_Mastermind_Flat } from './BookLibrary/Trading-Mastermind (flat).webp';
export { default as Trading_Mastermind_Open } from './BookLibrary/Trading-Mastermind (open).webp';
export { default as Trading_Mastermind_Vertical } from './BookLibrary/Trading-Mastermind (vertical).webp';
export { default as Career } from './ContactUs/Career.webp';
export { default as WriteWithUs } from './ContactUs/WriteWithUs.webp';
export { default as BookIcon } from './Icons/Book.webp';
export { default as BusinessIcon } from './Icons/Business.webp';
export { default as FAQIcon } from './Icons/FAQ.webp';
export { default as MessageQuestionIcon } from './Icons/message-question.webp';
export { default as NeedHelpIcon } from './Icons/Need help.webp';
export { default as Order } from './Icons/Order.webp';
export { default as ReturnIcon } from './Icons/return.webp';

// Author
export { default as Aseem } from './CEO/57 - Authors Zebralearn.webp';
export { default as Anand_Garg } from './CEO/Anand-Garg.webp';
export { default as Ashwinder_Sujata_Gaur } from './CEO/Ashwinder - Sujata Gaur.webp';
export { default as Asif_Masani } from './CEO/Asif-Masani.webp';
export { default as Bhuvana } from './CEO/Bhuvana.webp';
export { default as Deb_gooha } from './CEO/Deb gooha.webp';
export { default as Harjeet_Sujata_Gaur } from './CEO/Harjeet - Sujata Gaur.webp';
export { default as Rachit_Sujata_Gaur } from './CEO/Rachit - Sujata Gaur.webp';
export { default as Rahul_Sujata_Gaur } from './CEO/Rahul.webp';
export { default as Raj_Kapoor } from './CEO/Raj Kapoor.webp';
export { default as Rajesh_Sujata_Gaur } from './CEO/Rajesh - Sujata Gaur.webp';
export { default as Vineet_Agarwal } from './CEO/Vineet-Agarwal.webp';
export { default as Vinit_Rai } from './CEO/Vinit-Rai.webp';
export { default as Vivek_Chadha } from './CEO/Vivek-Chadha.webp';

// V2
export { default as Invest } from './HomeHeroSectionCarouselData/Invest.webp';
export { default as OnlinePayment } from './Icons/OnlinePayment.webp';
export { default as ZebraLearnLogo } from './ZebraLearnLogo.webp';

// Banner
export { default as Banner_2 } from './Banner/Banner_2.webp';
export { default as BookBanner } from './Banner/BookBanner.webp';
export { default as BookDummy } from './Banner/BookDummy.webp';
export { default as CourseDummy } from './Banner/CourseDummy.webp';
export { default as CureseBanner } from './Banner/CureseBanner.webp';
export { default as Home_LG } from './Banner/Home_LG.webp';
export { default as Home_SM } from './Banner/Home_SM.webp';
export { default as SchoolBanner } from './Banner/SchoolBanner.webp';

// Book Banner
export { default as FM } from './Banner/BookBanner/FM.webp';
export { default as FM_SM } from './Banner/BookBanner/FM_SM.webp';
export { default as IHB } from './Banner/BookBanner/IHB.webp';
export { default as IHB_SM } from './Banner/BookBanner/IHB_SM.webp';
export { default as SIM } from './Banner/BookBanner/SIM.webp';
export { default as SIM_SM } from './Banner/BookBanner/SIM_SM.webp';

// Course Banner
export { default as Courses_LG } from './Banner/Courses_LG.webp';
export { default as Courses_SM } from './Banner/Courses_SM.webp';

// Live Banner
export { default as Live_LG } from './Banner/Live_LG.webp';
export { default as Live_SM } from './Banner/Live_SM.webp';

// School Banner
export { default as Finance_LG } from './Banner/Finance_LG.webp';
export { default as Finance_SM } from './Banner/Finance_SM.webp';
export { default as Personal_LG } from './Banner/Personal_LG.webp';
export { default as Personal_SM } from './Banner/Personal_SM.webp';
export { default as Stock_LG } from './Banner/Stock_LG.webp';
export { default as Stock_SM } from './Banner/Stock_SM.webp';

// ServerError
export { default as CallIcon } from './Icons/CallIcon.webp';
export { default as ServerErrorImage } from './service/ServerErrorImage.webp';

// Logo
export { default as LogoWithoutSquare } from './logoWithoutSquare.webp';
export { default as LogoWithoutSquareLarge } from './LogoWithoutSquareLarge.webp';

//Empty Screen
export { default as EmptyScreen } from './EmptyScreen/EmptyScreen.webp';
export { default as Author_Empty } from './Icons/Author_Empty.webp';
export { default as LiveEmptyScreen } from './Icons/LiveEmptyScreen.webp';

// Banner
export { default as TMG_LG } from './Banner/TMG_LG.webp';
export { default as TMG_Mobile } from './Banner/TMG_Mobile.webp';

// QR-Code
export { default as QrCode } from './QR-Code/QrCode.webp';

//Hdfc Icon
export { default as checkoutCardBg } from './Background/checkoutCardBg.svg';
export { default as checkoutSM } from './Background/checkoutSM.svg';
export { default as AiChatBg } from './chatBackground/AiChatBg.webp';
export { default as coins } from './Icons/coins.svg';
export { default as FailedToLoadPdf } from './Icons/FailedToLoadPdf.svg';
export { default as HDFCIcon } from './Icons/HDFCIcon.webp';

// Refer Friend
export { default as CollectCoin } from './Icons/CollectCoin.svg';
export { default as GetFreeBook } from './Icons/getFreeBook.svg';
export { default as LearningGoals } from './Icons/LearningGoals.svg';
export { default as ReadingTime } from './Icons/ReadingTime.svg';
export { default as ReferAFriend } from './Icons/referAfriend.svg';
export { default as StartingPage } from './onboarding/startingPage.svg';
export { default as verifyEmailImage } from './verifyEmailImage.webp';

// Book
export { default as Book_3D } from './BookImage/3dBook.webp';
