export const setAuthToken = async (token: string) => {
  if (typeof localStorage !== 'undefined') {
    const encodedToken = btoa(token);
    localStorage.setItem('authIframe', encodedToken);
  }
};

export const setIFrameBookToken = async (token: string) => {
  if (typeof localStorage !== 'undefined') {
    const encodedToken = btoa(token);
    localStorage.setItem('bookIframe', encodedToken);
  }
};

export const removeIFrameBookToken = async () => {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem('bookIframe');
  }
};

export const removeAuthToken = async () => {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem('authIframe');
  }
};

export const getBookTokenIFrameLocalStorage = async () => {
  let token: string | undefined = undefined;

  if (typeof localStorage !== 'undefined') {
    const encodedToken = localStorage.getItem('bookIframe');
    if (encodedToken) {
      token = atob(encodedToken);
    }
  }
  return encodeURIComponent(token as string);
};

export const isLoggedInIframe = () => {
  let token: string | undefined = undefined;
  if (typeof localStorage !== 'undefined') {
    const encodedToken = localStorage.getItem('authIframe');
    if (encodedToken) {
      token = atob(encodedToken);
    }
    return !!token;
  }
  return true;
};
