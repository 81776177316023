import React from 'react';

interface Props {
  width?: string;
}

const CoinHeader: React.FC<Props> = ({ width }) => {
  return (
    <svg
      width={width ?? '28'}
      height={width ?? '28'}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Layer_1'>
        <g id='_x35_9'>
          <g id='Group'>
            <g id='Group_2'>
              <g id='Group_3'>
                <path
                  id='Vector'
                  d='M12.8571 25.6566C8.77767 25.6566 5.19555 23.5663 3.11373 20.3972C1.90002 18.5597 1.20044 16.36 1.20044 14C1.20044 7.56057 6.4177 2.34326 12.8571 2.34326H15.131V25.6566H12.8571Z'
                  fill='#FFFB12'
                />
                <path
                  id='Vector_2'
                  d='M3.47119 14.0001C3.47119 20.4413 8.68991 25.6599 15.1311 25.6599C21.5723 25.6599 26.7994 20.4413 26.7994 14.0001C26.7994 7.55886 21.5723 2.34009 15.1311 2.34009C8.68991 2.34009 3.47119 7.55886 3.47119 14.0001Z'
                  fill='#EAB308'
                />
                <g id='Group_4'>
                  <path
                    id='Vector_3'
                    d='M15.1317 22.9771C14.463 22.9771 13.8122 22.9017 13.1853 22.7629L12.7517 25.417C13.5202 25.5764 14.316 25.6608 15.1317 25.6608C15.9498 25.6608 16.7482 25.5757 17.5191 25.4153L17.0857 22.7615C16.4562 22.9011 15.803 22.9771 15.1317 22.9771Z'
                    fill='#EAB308'
                  />
                  <path
                    id='Vector_4'
                    d='M15.1317 5.02286C15.803 5.02286 16.4562 5.09885 17.0857 5.23851L17.5191 2.5846C16.7482 2.42423 15.9498 2.33911 15.1317 2.33911C14.316 2.33911 13.5202 2.42353 12.7517 2.58289L13.1853 5.23709C13.8122 5.09831 14.463 5.02286 15.1317 5.02286Z'
                    fill='#EAB308'
                  />
                  <path
                    id='Vector_5'
                    d='M23.7029 11.3063L26.2144 10.3561C25.7067 8.81347 24.8871 7.4129 23.8278 6.22974L21.7485 7.93025C22.6285 8.88765 23.3035 10.036 23.7029 11.3063Z'
                    fill='#EAB308'
                  />
                  <path
                    id='Vector_6'
                    d='M6.5686 16.6936L4.05664 17.644C4.56392 19.1874 5.38302 20.5884 6.44156 21.7718L8.52178 20.0705C7.64242 19.1131 6.96802 17.9645 6.5686 16.6936Z'
                    fill='#EAB308'
                  />
                  <path
                    id='Vector_7'
                    d='M8.52158 7.92923L6.44137 6.22803C5.38258 7.41146 4.56333 8.81256 4.05615 10.3558L6.56811 11.3062C6.96739 10.0354 7.64193 8.88678 8.52158 7.92923Z'
                    fill='#EAB308'
                  />
                  <path
                    id='Vector_8'
                    d='M23.7022 16.6934C23.3026 17.9639 22.6278 19.1121 21.748 20.0694L23.8274 21.7699C24.8864 20.5868 25.706 19.1864 26.2137 17.6436L23.7022 16.6934Z'
                    fill='#EAB308'
                  />
                </g>
                <path
                  id='Vector_9'
                  d='M6.00928 14C6.00928 19.0396 10.0924 23.1227 15.132 23.1227C20.1716 23.1227 24.2613 19.0396 24.2613 14C24.2613 8.96036 20.1716 4.8772 15.132 4.8772C10.0924 4.8772 6.00928 8.96037 6.00928 14Z'
                  fill='#FFFB12'
                />
                <path
                  id='Vector_10'
                  d='M19.3763 13.8424C17.339 13.0553 15.9674 11.2678 15.2616 8.4803C15.1434 8.01309 14.3494 8.01399 14.2309 8.48142C13.5243 11.2703 12.1522 13.0609 10.1148 13.8522C9.70435 14.0118 9.70422 14.4877 10.1146 14.6463C12.1518 15.4336 13.5235 17.2207 14.2293 20.0084C14.3476 20.4756 15.1415 20.4747 15.2598 20.0073C15.9666 17.2182 17.3387 15.428 19.376 14.6363C19.7866 14.4768 19.7866 14.0009 19.3763 13.8424Z'
                  fill='#EAB308'
                />
                <path
                  id='Vector_11'
                  d='M14.7581 4.89432C14.9553 4.88492 15.1618 4.87553 15.3683 4.87553C20.4093 4.87553 24.4927 8.95895 24.4927 13.9999C24.4927 19.0408 20.4092 23.1243 15.3683 23.1243C15.1618 23.1243 14.9553 23.1149 14.7581 23.1055C19.5081 22.7863 23.263 18.8343 23.263 13.9999C23.263 9.16556 19.5081 5.21345 14.7581 4.89432Z'
                  fill='#A16207'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CoinHeader;
