import { Avatar } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';

import { IReviewsData } from '.';
import StarRating from '../StarRating';
import { getUserInitials } from '../../ChatBox/AiChatMessage';

interface Props {
  data: IReviewsData;
}

const ReviewCard: React.FC<Props> = ({ data }) => {
  const { user, rating, text, images } = data;
  const screens = useBreakpoint();
  const imageSize = {
    width: screens?.lg ? 122 : 92,
    height: screens?.lg ? 132 : 100,
  };
  const avatarSrc = user?.avatar?.trim()
    ? `${process.env.NEXT_PUBLIC_IMAGE_BASE_URL_ECOM}/${user?.avatar}`
    : undefined;

  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleImages, setVisibleImages] = useState(images?.length || 0);

  useEffect(() => {
    const updateVisibleImages = () => {
      if (containerRef.current && images?.length) {
        const containerWidth = containerRef.current.offsetWidth;
        const maxImages = Math.floor(containerWidth / (imageSize.width + 10));
        setVisibleImages(Math.min(images.length, maxImages));
      }
    };

    updateVisibleImages();
    window.addEventListener('resize', updateVisibleImages);
    return () => window.removeEventListener('resize', updateVisibleImages);
  }, [images, imageSize]);

  return (
    <div className='bg-[#131313] p-5 rounded-lg shadow-md text-white'>
      <div className='flex justify-between'>
        <div className='flex'>
          <Avatar
            size={24}
            alt='profile-picture'
            style={{ fontSize: '14px' }}
            className={clsx(!avatarSrc && 'bg-primary', 'translate-y-1')}
            src={avatarSrc}
          >
            {user?.name && getUserInitials(user.name)}
          </Avatar>
          <div className='ml-2.5'>
            <h3 className='font-semibold text-xs translate-y-1'>{user.name}</h3>
            <span>
              <StarRating
                rating={rating || 0}
                color='#00A86B'
                starSize='12px'
              />
            </span>
          </div>
        </div>
        {/* <p className='mt-2 text-[#FFFFFFB2] font-light text-xs'>
          {moment(createdAt).format('DD, MMM YYYY')}
        </p> */}
      </div>

      <p className='mt-3 text-[#FFFFFFCC] text-sm'>{text}</p>
      <div ref={containerRef} className='flex gap-x-2.5 mt-5'>
        {images.slice(0, visibleImages).map((item, index) => (
          <div key={`Image-${index} ${item?.imageId}`} className='relative'>
            <Image
              src={item?.imageURL}
              alt={`Image-${index} ${item?.imageId}`}
              width={imageSize.width}
              height={imageSize.height}
              className={`rounded-md object-fill min-w-[${imageSize.width}px] min-h-[${imageSize.height}px] w-[${imageSize.width}px] h-[${imageSize.height}px]`}
            />
            {index === visibleImages - 1 && images.length > visibleImages && (
              <div
                className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 text-white rounded-md'
                style={{ width: imageSize.width, height: imageSize.height }}
              >
                +{images.length - visibleImages}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewCard;
