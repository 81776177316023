import { apiClient } from '../apiClients';
import { Endpoints } from '../../network';
import { AddressPayload } from '../../utils/interfaces/Manage-Addresses';

interface ICerateAddress {
  set_default: number;
  user_id: number;
  address: string;
  name: string;
  country_id: string;
  city_id: string;
  state_id: string;
  postal_code: string;
  phone: string;
}

export const getAddresses = async () => {
  const response = await apiClient.get(Endpoints.getAllAddresses);

  return response?.data?.data;
};
export const updateAddress = async (data: AddressPayload, cartId?: number) => {
  const params: {
    data: AddressPayload;
    card_id?: number;
  } = { data };

  if (cartId) params.card_id = cartId;

  const response = await apiClient.post(`${Endpoints.updateAddress}`, {
    ...params.data,
    card_id: params.card_id,
  });

  return response?.data?.data;
};

export const createAddressService = async (
  data: {
    set_default: number;
    user_id: number;
    address: string;
    name: string;
    country_id: string;
    city_id: string;
    state_id: string;
    postal_code: string;
    phone: string;
  },
  cartId?: number
) => {
  const params: {
    data: ICerateAddress;
    card_id?: number;
  } = { data };

  params.data.set_default = data.set_default ? 1 : 0;

  if (cartId) params.card_id = cartId;
  const response = await apiClient.post(`${Endpoints.createAddress}`, {
    ...params.data,
    card_id: params.card_id,
  });

  return response?.data;
};

export const updateAddressRadioButtonSelect = async (data: {
  id: number;
  set_default: number;
}) => {
  const response = await apiClient.post(`${Endpoints.updateAddress}`, data);

  return response?.data?.data;
};

export const deleteAddress = async (id: number) => {
  const response = await apiClient.delete(`${Endpoints.deleteAddress}/${id}`);

  return response?.data?.data;
};

// updateDefaultAddress
