import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

import {
  getAuthTokenFromCookie,
  setAuthToken,
} from '../components/shared/ManageTokenForClient/tokenManager';

export interface ApiClientOptions {
  baseURL: string;
  timeout: number;
  isSchool?: boolean;
  cookieName?: string;
  useBearer?: boolean;
  referer?: string;
  isAskAi?: boolean;
}

const createApiClient = ({
  baseURL,
  timeout,
  isSchool = false,
  cookieName,
  useBearer = true,
  referer,
  isAskAi = false,
}: ApiClientOptions): AxiosInstance => {
  const apiClient = axios.create({
    baseURL,
    timeout,
  });

  apiClient.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      if (cookieName) {
        const authToken = getAuthTokenFromCookie(cookieName);
        config.headers = config.headers || {};
        if (authToken && !isAskAi) {
          config.headers['Authorization'] = useBearer
            ? `Bearer ${authToken}`
            : authToken;
          return config;
        }
        if (referer) {
          config.headers['Referer'] = referer;
        }
      }

      return await setAuthToken(config, isSchool, useBearer, isAskAi);
    },
  );

  return apiClient;
};

export default createApiClient;
