import { Progress } from 'antd';
import Image from 'next/image';

import { ShouldRender } from '../shared/basic/ShouldRender';
import encryptToken from '../shared/crypto/encryptToken';
import { formatDuration } from '../shared/formateTime';
import { CourseDummy } from '../../../assets';

export interface Course {
  name: string;
  slug: string;
  duration?: number;
  course_image_url?: string;
  completionPercentage?: number;
  id?: string;
  seasonCount?: string;
}

interface Props {
  courses: Course[] | undefined;
  loading?: boolean;
  token: string | null;
  isMyCourse?: boolean;
}

const CoursesCard: React.FC<Props> = ({
  courses,
  token,
  isMyCourse = false,
}) => {
  return (
    <span className='flex '>
      {courses?.map((item, index) => (
        <span
          key={index}
          onClick={() => {
            const CRYPTO_KEY = process.env.NEXT_PUBLIC_CRYPTO_KEY || '';
            const encryptedToken = encryptToken(token as string, CRYPTO_KEY);

            const slug = item?.slug;
            let url: string;
            if (isMyCourse) {
              url = `${process.env.NEXT_PUBLIC_COURSE_LIVE}/#/course/${slug}`;
            } else {
              url = `${process.env.NEXT_PUBLIC_COURSE_LIVE}/#/course/info/${slug}`;
            }

            if (slug) {
              const finalUrl = token
                ? `${url}?redirect_url=${encryptedToken}`
                : url;
              window.open(finalUrl, '_blank');
            }
          }}
          className='flex flex-col gap-y-6 p-1 md:p-1.5 cursor-pointer'
        >
          <span className='w-[200px] h-[100px] lg:max-w-[316px] lg:min-w-[300px] lg:max-h-[176px] lg:min-h-[150px]'>
            <Image
              src={item?.course_image_url || CourseDummy}
              alt={item.name}
              className='rounded-md transition-transform transform hover:scale-105 duration-500'
              width={320}
              height={170}
              sizes='(max-width: 768px) 100vw, 320px'
            />
          </span>
          <div className='flex flex-col '>
            <ShouldRender
              check={
                item?.completionPercentage && item?.completionPercentage !== 0
              }
            >
              <span className='flex gap-x-2 text-sm'>
                <Progress
                  percent={item?.completionPercentage}
                  showInfo={false}
                  className='customProcessBar'
                />{' '}
                {item?.completionPercentage}%
              </span>
            </ShouldRender>
            <ShouldRender check={item.duration}>
              <span className='text-textGrayColor text-xs md:text-sm '>
                {formatDuration(item?.duration as number)}
              </span>
            </ShouldRender>
            <span className='text-white text-sm xl:text-base font-medium w-[200px] lg:max-w-[316px] lg:min-w-[300px] '>
              {item.name}
            </span>
          </div>
        </span>
      ))}
    </span>
  );
};

export default CoursesCard;
