import { apiClient, baseForPdf } from '../apiClients';
import { fetchAndCache } from '../../components/shared/CacheStroage';
import { Endpoints } from '../../network';

export const getAllProducts = async (
  page: number,
  category_id: number,
  book_type?: string | undefined,
  title?: string,
  limit?: number,
) => {
  const queryParams = new URLSearchParams({
    category_id: category_id.toString(),
    page: page.toString(),
    ...(book_type && { book_type }),
    ...(title?.trim() && { name: title }),
    ...(limit && { perPage: limit?.toString() }),
  });

  const url = `${Endpoints.getAllProducts}?${queryParams}`;

  const apiCall = async () => {
    const response = await apiClient.get(url);
    return response?.data;
  };

  return fetchAndCache(apiCall, url, page);
};

export const getAllProductsRewards = async (
  page: number,
  category_id: number,
  limit?: number,
) => {
  const queryParams = new URLSearchParams({
    sub_category_id: category_id.toString(),
    page: page.toString(),
    ...(limit && { perPage: limit?.toString() }),
  });

  const url = `${Endpoints.getAllProducts}/claimable?${queryParams}`;

  const response = await apiClient.get(url);
  return response?.data;
};

export const getProductsDetails = async (slug: string) => {
  const url = `${Endpoints.productDetails}/${slug}`;

  const response = await apiClient.get(url);

  return response?.data;
};

export const subscribeForUpcomingBooks = async (bookId: string) => {
  const url = `${Endpoints.comingSoonSubscribe}`;

  const response = await baseForPdf.post(url, { bookId });

  return response?.data;
};
