import Image from 'next/image';
import React from 'react';

import AuthLayout from '../AuthLayout';
import { ZebraLearnLogo } from '../../../../assets';

interface Props {
  isModal?: boolean;
  onCancel?: () => void;
  visible?: boolean;
}

const ForgotPassword: React.FC<Props> = ({ isModal = false }) => {
  // const handleModalClose = () => {
  //   onCancel && onCancel();
  //   setForgotPasswordStep(0);
  //   setEmail('');
  //   setOtp('');
  //   setPassword({
  //     password: '',
  //     reEnterPassword: '',
  //   });
  // };
  return (
    <AuthLayout isModal={isModal}>
      <div className='flex items-center gap-y-10 flex-col justify-center'>
        <div className={` z-30  flex flex-col gap-y-1 w-full`}>
          <div className='relative pt-2 md:pt-4'>
            <span className=' flex justify-center w-full'>
              <Image
                src={ZebraLearnLogo}
                alt='Logo'
                className='object-contain h-3.5 w-full'
              />
            </span>
          </div>
          <div className='border-b-2 border-backgroundGray py-1.5' />
          <div className='flex items-center gap-y-10 flex-col justify-center'>
            <div className='z-30  py-6 flex flex-col w-full gap-y-1'>
              {/* <ShouldRender check={forgotPasswordStep === 1}>
                <h1 className='text-xl text-fadedWhite font-medium lg:font-normal lg:text-3xl mt-1 leading-10  text-center w-full'>
                  Reset your{' '}
                  <b className='text-primary font-medium'>password</b>
                </h1>
                <span className='text-textGray text-center text-lg'>
                  Don't worry! We got your back.
                </span>
                <form
                  onSubmit={submitEmail}
                  className='flex flex-col  gap-2 mt-10 w-full'
                >
                  <div className='col-span-1 flex-col flex gap-y-.5'>
                    <Input
                      type='text'
                      name='email'
                      placeholder='Email'
                      style={{
                        backgroundColor: '#000000',
                      }}
                      value={email}
                      onChange={handleChange}
                      size='large'
                      prefix={
                        <span className='pr-2'>
                          <EmailIcon filled={true} />
                        </span>
                      }
                      className='border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack'
                    />
                    {(emailErrors.email || message) && (
                      <p className='text-red-500'>
                        {message ?? emailErrors.email}
                      </p>
                    )}
                  </div>

                  <span className='flex justify-center mt-2'>
                    <ButtonDefault
                      size={4}
                      variant={ButtonVariants.WHITE}
                      className='p-1 w-full'
                      disabled={loading}
                      loading={loading}
                      rounded={true}
                      // onClick={() => router.push('/auth/login')}
                    >
                      <span className='flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap'>
                        Send Reset Link
                      </span>
                    </ButtonDefault>
                  </span>

                  <span className='text-center text-sm text-fadedWhite mt-2'>
                    OTP will be send on your mail
                  </span>
                </form>
              </ShouldRender> */}
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
export default ForgotPassword;
