import { baseForPdf } from '../apiClients';
import { fetchAndCacheWithoutPage } from '../../components/shared/CacheStroage/CacheWithoutPage';
import { Endpoints } from '../../network';

export const getAllBannerPages = async () => {
  const apiUrl = Endpoints.allBannerPages;

  const apiCall = async () => {
    const response = await baseForPdf.get(apiUrl);
    return response?.data;
  };

  return fetchAndCacheWithoutPage(apiCall, apiUrl);
};

export const getAllBanners = async (pageId: string) => {
  const apiUrl = `${Endpoints.allBanners}/${pageId}`;
  const apiCall = async () => {
    const response = await baseForPdf.get(apiUrl);
    return response?.data;
  };

  return fetchAndCacheWithoutPage(apiCall, apiUrl);
};
