import { ChevronDownIcon } from '@heroicons/react/outline';
import {
  Button,
  ConfigProvider,
  Dropdown,
  MenuProps,
  theme,
  ThemeConfig,
} from 'antd';

import SortIcon from '../../../../assets/Icons/SortIcon';

const config: ThemeConfig = {
  token: { colorPrimary: '#FFFFFFB2', fontFamily: 'inherit' },
};

interface Props {
  setSortSelected: (value: string) => void;
  selectedSort: string;
}

const SortReview: React.FC<Props> = ({ setSortSelected, selectedSort }) => {
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setSortSelected(e.key);
  };

  const items: MenuProps['items'] = [
    {
      key: 'top_review',
      label: (
        <span
          style={{
            color: selectedSort === 'top_review' ? 'white' : 'inherit',
          }}
        >
          Top review
        </span>
      ),
      onClick: handleMenuClick,
    },
    {
      key: 'most_recent',
      label: (
        <span
          style={{
            color: selectedSort === 'most_recent' ? 'white' : 'inherit',
          }}
          className='pt-1'
        >
          Most recent
        </span>
      ),
      onClick: handleMenuClick,
    },
  ];

  return (
    <ConfigProvider theme={{ ...config, algorithm: [theme.darkAlgorithm] }}>
      <Dropdown
        menu={{
          items,
          selectedKeys: [selectedSort],
        }}
        trigger={['click']}
      >
        <Button className='flex items-center border-none bg-[#131313] text-white px-4 py-3 h-9 rounded-md'>
          <SortIcon />
          <span className='text-xs md:text-sm'>
            {selectedSort === 'most_recent' ? 'Most recent' : 'Top review'}
          </span>
          <ChevronDownIcon className='w-3 md:w-4 ml-1' />
        </Button>
      </Dropdown>
    </ConfigProvider>
  );
};

export default SortReview;
