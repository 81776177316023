import { InternalAxiosRequestConfig } from 'axios';

import {
  getSessionExistsFromCookie,
  setSessionExistsInCookie,
} from './manageCookies';
import {
  getLoginToken,
  isTokenExpired,
  storeTokenExpiration,
} from '../getToken';
import {
  checkSessionExists,
  setTokenInCookies,
} from '../../../config/appwrite';

let tokenPromise: Promise<string | undefined> | null = null;

export const setAuthToken = async (
  config: InternalAxiosRequestConfig,
  isSchool = false,
  useBearer = true,
  isAskAi = false,
): Promise<InternalAxiosRequestConfig> => {
  try {
    config.headers = config.headers || {};
    let authToken = await getLoginToken();

    if (authToken && !isTokenExpired()) {
      if (!isAskAi) {
        config.headers['Authorization'] = useBearer
          ? `Bearer ${authToken}`
          : authToken;
      }
      return config;
    }

    const sessionExists = getSessionExistsFromCookie();

    if (sessionExists !== undefined) {
      if (sessionExists) {
        if (!tokenPromise) {
          tokenPromise = setTokenInCookies();
        }
        authToken = await tokenPromise.finally(() => {
          tokenPromise = null;
        });

        if (authToken) {
          storeTokenExpiration();
          if (!isSchool && !isAskAi) {
            config.headers['Authorization'] = useBearer
              ? `Bearer ${authToken}`
              : authToken;
          }
          return config;
        }
      }
    } else {
      const session = await checkSessionExists();
      setSessionExistsInCookie(!!session);

      if (session) {
        if (!tokenPromise) {
          tokenPromise = setTokenInCookies();
        }
        authToken = await tokenPromise.finally(() => {
          tokenPromise = null;
        });
        if (authToken) {
          storeTokenExpiration();
          if (!isSchool && !isAskAi) {
            config.headers['Authorization'] = useBearer
              ? `Bearer ${authToken}`
              : authToken;
          }
          return config;
        }
      }
    }
  } catch (error) {
    console.error('Error setting auth token:', error);
  }

  return config;
};

export const getAuthTokenFromCookie = (
  cookieName: string,
): string | undefined => {
  try {
    if (typeof document === 'undefined') {
      return undefined;
    }
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');

    const tokenCookie = cookies.find((cookie) =>
      cookie.startsWith(`${cookieName}=`),
    );

    if (!tokenCookie) {
      return undefined;
    }

    const token = tokenCookie.split('=')[1];

    if (isTokenExpired()) {
      clearAuthToken(cookieName);
      return undefined;
    }

    return token;
  } catch {
    return undefined;
  }
};

export const clearAuthToken = (cookieName: string) => {
  document.cookie = `${cookieName}=; max-age=0; path=/`;
  localStorage.removeItem('auth_token_expiration');
};
