import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { Image as AntdImage, Skeleton } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';

import { ShouldRender } from '../../shared/basic/ShouldRender';

interface HomepageSliderProps {
  onPagination?: () => void;
  setIsPaginateShift?: (value: boolean) => void;
  isPaginateShift?: boolean;
  loading?: boolean;
  total?: number;
  imageWidths?: number[];
  data: {
    imageId: string;
    imageURL: string;
  }[];
}

type SlideProps = {
  totalRecords: number;
  containerWidth: number;
  imageWidths?: number[];
};

const calculateSlides = ({
  totalRecords,
  containerWidth,
  imageWidths = [120, 140, 200],
}: SlideProps): number => {
  if (
    totalRecords < 1 ||
    totalRecords === undefined ||
    !containerWidth ||
    !imageWidths.length
  ) {
    return 0;
  }

  let imageWidth = imageWidths[0];

  if (typeof window !== 'undefined') {
    if (window.innerWidth >= 1280) {
      imageWidth = imageWidths[2];
    } else if (window.innerWidth >= 1024) {
      imageWidth = imageWidths[1];
    }
  }

  const imagesPerSlide =
    imageWidth && containerWidth && Math.floor(containerWidth / imageWidth);

  const totalSlides =
    imagesPerSlide && totalRecords && Math.ceil(totalRecords / imagesPerSlide);

  return totalSlides || 0;
};

const ReviewImageSliderPaginated: FC<HomepageSliderProps> = ({
  data,
  onPagination,
  isPaginateShift = false,
  setIsPaginateShift,
  total,
  imageWidths,
  loading,
}) => {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const [isAtLeftmost, setIsAtLeftmost] = useState(true);
  const [isAtRightmost, setIsAtRightmost] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const totalSlides = calculateSlides({
    totalRecords: total || 1,
    containerWidth,
    imageWidths,
  });

  const screens = useBreakpoint();

  useEffect(() => {
    const handleResize = () => {
      if (sliderRef.current) {
        setContainerWidth(sliderRef.current.offsetWidth);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getCombinedCardWidth = () => {
    return data.length * (imageWidths?.[0] ?? 120);
  };

  const isOverflowing = getCombinedCardWidth() > containerWidth;

  useEffect(() => {
    if (getCombinedCardWidth() < containerWidth && currentPage < totalSlides) {
      onPagination && onPagination();
    }
  }, [containerWidth, data]);

  const scrollSlider = async (direction: 'left' | 'right') => {
    if (sliderRef.current) {
      const { scrollLeft, clientWidth } = sliderRef.current;
      const scrollTo =
        direction === 'left'
          ? scrollLeft - clientWidth
          : scrollLeft + clientWidth;

      sliderRef.current.scrollTo({ left: scrollTo, behavior: 'smooth' });

      const newPage =
        direction === 'right'
          ? Math.min(currentPage + 1, totalSlides - 1)
          : Math.max(currentPage - 1, 0);

      if (direction === 'right' && !isAtRightmost) {
        try {
          if (onPagination) onPagination();

          setCurrentPage(newPage);
        } catch (error) {
          console.error('Failed to fetch new page data:', error);
        }
      } else {
        setCurrentPage(newPage);
      }
    }
  };

  const handleScroll = () => {
    if (sliderRef.current) {
      const isLeftmost = sliderRef.current.scrollLeft === 0;
      const isRightmost =
        sliderRef.current.scrollLeft + sliderRef.current.clientWidth >=
        sliderRef.current.scrollWidth - 1;
      setIsAtLeftmost(isLeftmost);
      setIsAtRightmost(isRightmost);
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      setContainerWidth(sliderRef.current.offsetWidth);

      sliderRef.current.scrollLeft = 0;
      sliderRef.current.addEventListener('scroll', handleScroll);

      return () => {
        sliderRef.current?.removeEventListener('scroll', handleScroll);
      };
    }

    return undefined;
  }, [sliderRef.current]);

  useEffect(() => {
    if (isAtRightmost && onPagination) {
      onPagination();
      setIsPaginateShift && setIsPaginateShift(true);
    }
  }, [isAtRightmost]);

  useEffect(() => {
    if (isPaginateShift) {
      setIsPaginateShift && setIsPaginateShift(false);
    }
  }, [isPaginateShift]);

  return (
    <div className='text-fadedWhite'>
      <div className='flex items-center justify-center lg:px-4 transition-transform transform duration-500'>
        {/* Left Button */}
        <ShouldRender check={!isAtLeftmost && screens.lg}>
          <button
            disabled={isAtLeftmost}
            onClick={() => scrollSlider('left')}
            style={{ backdropFilter: 'blur(8px)' }}
            className={clsx(
              'z-10 w-7 h-7 flex items-center mx-2 p-1.5 justify-center rounded-full bg-[#BABABA33] text-fadedWhite transition-transform duration-500 ease-in-out hover:scale-110',
              isAtLeftmost && 'disabled:cursor-not-allowed',
            )}
          >
            <ChevronLeftIcon className='w-4 h-4' />
          </button>
        </ShouldRender>
        <ShouldRender check={data?.length}>
          <div className='w-full relative'>
            <div
              ref={sliderRef}
              className='flex hide-scrollbar min-h-[132px] h-[142px]'
            >
              {data
                ?.map((item: any) => ({
                  imageURL: item?.imageUrl,
                  imageId: item?.imageId,
                }))
                ?.map((item, index) => (
                  <span
                    key={index}
                    className='group cursor-pointer p-1.5 md:p-2'
                  >
                    <AntdImage
                      src={item?.imageURL}
                      alt={`${index}-${item?.imageId}`}
                      width={122}
                      height={132}
                      className='rounded-md object-fill min-w-[122px] min-h-[132px] w-[122px] h-[132px]'
                    />
                  </span>
                ))}
              <ShouldRender check={loading}>
                {Array.from({ length: 10 }).map((_, index) => (
                  <span
                    key={index}
                    className='group cursor-pointer flex p-1 md:p-1.5'
                  >
                    <Skeleton.Button
                      style={{
                        width: '122px',
                        height: screens.xl ? '132px' : '132px',
                      }}
                      active
                      className='rounded-sm md:rounded-md min-w-[122px] min-h-[132px] w-[122px] h-[142px]'
                    />
                  </span>
                ))}
              </ShouldRender>
            </div>
          </div>
        </ShouldRender>

        {/* Right Button */}
        <ShouldRender check={!isAtRightmost && isOverflowing && screens.lg}>
          <button
            disabled={isAtRightmost}
            onClick={() => scrollSlider('right')}
            style={{ backdropFilter: 'blur(8px)' }}
            className={clsx(
              'z-10 w-7 h-7 flex items-center mx-2 p-1.5 justify-center rounded-full bg-[#BABABA33] text-fadedWhite transition-transform duration-500 ease-in-out hover:scale-110',
              isAtRightmost && 'disabled:cursor-not-allowed',
            )}
          >
            <ChevronRightIcon className='w-4 h-4' />
          </button>
        </ShouldRender>
      </div>
    </div>
  );
};

export default ReviewImageSliderPaginated;
