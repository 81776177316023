/* eslint-disable no-useless-catch */
import { Account, Client, ID, OAuthProvider } from 'appwrite';

import {
  clearSession,
  isSessionChecked,
  isSessionCheckedIsFalse,
  setSessionValid,
} from '../../components/shared/AppWriteLocalStroage';
import {
  setToken,
  setUserId,
  setUserName,
  setZl_UID,
} from '../../components/shared/getToken';
import { resetSessionExistsCookie } from '../../components/shared/ManageTokenForClient/manageCookies';
import { notify } from '../../components/shared/notify';
import { getUserProfile } from '../../services/user';
import { ICreateAccount, ILogin } from '../../utils/interfaces/appwrite';

const appwriteClient = new Client();

const appwriteProjectId = process.env.NEXT_PUBLIC_APPWRITE_ID || '';
const appwriteUrl = process.env.NEXT_PUBLIC_APPWRITE_URL || '';

appwriteClient.setEndpoint(appwriteUrl).setProject(appwriteProjectId);

const account = new Account(appwriteClient);

const createUserAccount = async ({ email, password, name }: ICreateAccount) => {
  return await account.create(ID.unique(), email, password, name);
};

const loginWithGoogle = async (isRegister?: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set('googleLoginCompleted', 'true');
  if (isRegister) {
    currentUrl.searchParams.set('isRegister', isRegister);
  }

  await logoutIfSessionExists();

  return await account.createOAuth2Session(
    OAuthProvider.Google,
    currentUrl.toString(),
    `${process.env.NEXT_PUBLIC_API_URL_APP_URL}/auth/login/`,
    // [
    //   'https://www.googleapis.com/auth/youtube.readonly',
    //   'https://www.googleapis.com/auth/youtube.force-ssl',
    // ],
  );
};

const createTarget = async ({
  target_id,
  identifier,
  provider_id,
}: {
  target_id: string;
  identifier: string;
  provider_id: string;
}) => {
  try {
    return await account.createPushTarget(target_id, identifier, provider_id);
  } catch (e: any) {
    if (e.code === 409) {
      return await appwriteService.updateTarget({
        target_id,
        identifier,
      });
    } else {
      throw e;
    }
  }
};

const updateTarget = async ({
  target_id,
  identifier,
}: {
  target_id: string;
  identifier: string;
}) => {
  return await account.updatePushTarget(target_id, identifier);
};

const updateOnboardingPrefs = async (prefs: { [key: string]: any }) => {
  return await account.updatePrefs(prefs);
};

const deleteTarget = async ({ target_id }: { target_id: string }) => {
  return await account.deletePushTarget(target_id);
};

const updatePrefs = async ({ data }: { data: any }) => {
  return await account.updatePrefs(data);
};

const sendVerificationEmail = async () => {
  return await account.createVerification(
    `${process.env.NEXT_PUBLIC_API_URL_APP_URL}`,
  );
};

const verifyVerificationEmail = async (userId: string, secret: string) => {
  return await account.updateVerification(userId, secret);
};

const handleRedirection = () => {
  if (typeof window !== undefined) {
    notify('You are not authenticated', 'error');

    const redirectUrl = decodeURIComponent(window.location.pathname);
    window.location.href = `/auth/login?redirectUrl=${redirectUrl}`;
  }
};

const loginWithApple = async (isRegister?: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set('appleLoginCompleted', 'true');
  if (isRegister) {
    currentUrl.searchParams.set('isRegister', isRegister);
  }

  await logoutIfSessionExists();

  return await account.createOAuth2Session(
    OAuthProvider.Apple,
    currentUrl.toString(),
    `${process.env.NEXT_PUBLIC_API_URL_APP_URL}/auth/login/`,
  );
};
const setUpdatedProfile = async () => {
  try {
    const data = await getUserProfile();
    await setUserId(data?.data?.id);
    await setZl_UID(data?.data?.zl_uid);
    await setUserName(data?.data?.name);
  } catch (error: any) {
    if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
      console.error('Error initializing messaging:', error);
    }
  }
};

export const setTokenInCookies = async () => {
  try {
    const tokenResponse = await account.createJWT();
    const token = tokenResponse.jwt;
    await setToken(token);
    await setUpdatedProfile();
    return token;
  } catch (e: any) {
    if (e.code === 401 && e.type === 'general_unauthorized_scope') {
      handleRedirection();
      resetSessionExistsCookie();
      clearSession();
      return '';
    } else if (e.code !== 401 || e.type !== 'general_unauthorized_scope') {
      {
        throw e;
      }
    }
  }
  return '';
};

const logoutIfSessionExists = async () => {
  try {
    const user = await account.getSession('current');
    if (user) {
      return await logout();
    }
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('user_id_iframe');
    }
  } catch (e: any) {
    if (e.code === 401 && e.type === 'general_unauthorized_scope') {
      return null;
    } else if (e.code !== 401 || e.type !== 'general_unauthorized_scope') {
      {
        throw e;
      }
    }
  }
  return null;
};

export const checkSessionExists = async () => {
  try {
    const session = await account.getSession('current');
    setSessionValid(true);
    return session;
  } catch (e: any) {
    if (e.code === 401 && e.type === 'general_unauthorized_scope') {
      return null;
    } else if (e.code !== 401 || e.type !== 'general_unauthorized_scope') {
      {
        throw e;
      }
    }
    return null;
  }
};

const createSessionBySecretKey = async ({
  user_id,
  secret,
}: {
  user_id: string;
  secret: string;
}) => {
  await logoutIfSessionExists();
  return await account.createSession(user_id, secret);
};

const login = async ({ email, password }: ILogin) => {
  return await account.createEmailPasswordSession(email, password);
};

const createAnonymousSession = async () => {
  return await account.createAnonymousSession();
};

const isLoggedIn = async () => {
  if (isSessionChecked()) {
    return true;
  } else if (isSessionCheckedIsFalse()) {
    return false;
  } else {
    try {
      const session = await account.getSession('current');
      if (session) {
        setSessionValid(true);
        return true;
      }
    } catch {
      setSessionValid(false);
      return false;
    }
    return false;
  }
};

const getGoogleAccessToken = async (): Promise<string | null> => {
  try {
    // Fetch the current session
    const session = await account.getSession('current');

    // Ensure the session is for Google
    if (session.provider === 'google' && session.providerAccessToken) {
      return session.providerAccessToken;
    }

    console.error('Google session or access token not found');
    return null;
  } catch (error) {
    console.error('Error retrieving session:', error);
    return null;
  }
};

const getCurrentUser = async () => {
  try {
    const session = await account.getSession('current');
    setSessionValid(true);
    return session;
  } catch (e: any) {
    if (e.code === 401 && e.type === 'general_unauthorized_scope') {
      return null;
    } else if (e.code !== 401 || e.type !== 'general_unauthorized_scope') {
      {
        throw e;
      }
    }
    return null;
  }
};

export const logout = async () => {
  return await account.deleteSession('current');
};

export const getUserAccount = async () => {
  return await account.get();
};

const passwordRecovery = async (email: string) => {
  return await account.createRecovery(
    email,
    `${process.env.NEXT_PUBLIC_API_URL_APP_URL}/auth/reset-password/`,
  );
};

const updateName = async (name: string) => {
  return await account.updateName(name);
};

const updatePhone = async (phone: string, password: string) => {
  return await account.updatePhone(phone, password);
};

const updatePassword = async (password: string, oldPassword: string) => {
  return await account.updatePassword(password, oldPassword);
};

const passwordRecoveryConfirmation = async ({
  userId,
  secret_code,
  password,
}: {
  userId: string;
  secret_code: string;
  password: string;
}) => {
  return await account.updateRecovery(userId, secret_code, password);
};

const appwriteService = {
  createUserAccount,
  login,
  isLoggedIn,
  getCurrentUser,
  logout,
  createSessionBySecretKey,
  loginWithGoogle,
  setTokenInCookies,
  account,
  loginWithApple,
  passwordRecovery,
  passwordRecoveryConfirmation,
  logoutIfSessionExists,
  createAnonymousSession,
  updateName,
  updatePhone,
  getUserAccount,
  updatePassword,
  createTarget,
  updateTarget,
  deleteTarget,
  updatePrefs,
  getGoogleAccessToken,
  updateOnboardingPrefs,
  sendVerificationEmail,
  verifyVerificationEmail,
};

export default appwriteService;
