import { XIcon } from '@heroicons/react/outline';
import { Divider, Input, Modal } from 'antd';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';

import ButtonDefault, { ButtonVariants } from '../../shared/basic/button';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { AppleLogo, GoogleIcon, ZebraLearnLogo } from '../../../../assets';
import EmailIcon from '../../../../assets/Icons/Mail';
import PasswordIcon from '../../../../assets/Icons/password';
import { authModalControl } from '../../../atoms/authModalControl';
import appwriteService from '../../../config/appwrite';
import { useAuth } from '../../../hooks/useAuth';

interface ModalComponentProps {
  visible: boolean;
  onCancel: () => void;
  isClosable?: boolean;
  isIFrame?: boolean;
}

const LoginModal: React.FC<ModalComponentProps> = ({
  visible,
  onCancel,
  isClosable = true,
  isIFrame = false,
}) => {
  const router = useRouter();
  const [visibleControl, setVisible] = useRecoilState(authModalControl);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { redirectUrl } = router.query;
  const { onLogin, loading } = useAuth();
  const showAppleLogin =
    process.env.NEXT_PUBLIC_SHOW_APPLE_LOGIN === 'showLogin';

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setConfirmLoading(false);
      onCancel();
    }, 2000);
  };

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      email: '',
      password: '',
    };
    if (!formData.email) {
      validationErrors.email = 'Email or phone number is required';
    } else if (!isValidEmailOrPhone(formData.email)) {
      validationErrors.email = 'Invalid email or phone number';
    }

    if (!formData.password) {
      validationErrors.password = 'Password is required';
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      const login_by = isValidEmail(formData.email) ? 'email' : 'phone';
      onLogin({
        email: formData.email,
        password: formData.password,
        login_by,
        redirectUrl: redirectUrl as string,
        onCancel,
        noRedirection: true,
        bookId: visibleControl?.bookId,
        isIFrame,
      });
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const isValidEmailOrPhone = (value: string) => {
    // Your combined validation logic for email and phone number
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = /^\d{10}$/;

    return emailRegex.test(value) || phoneRegex.test(value);
  };

  const handleGoogleLogin = async () => {
    setGoogleLoading(true);
    try {
      await appwriteService.loginWithGoogle();
    } catch (error) {
      console.error('Error logging in with Google:', error);
    } finally {
      setGoogleLoading(false);
      setVisible((prevState) => ({
        ...prevState,
        login: false,
        signUp: false,
      }));
    }
  };

  const handleAppleLogin = async () => {
    setAppleLoading(true);
    try {
      await appwriteService.loginWithApple();
    } catch (error) {
      console.error('Error logging in with Google:', error);
    } finally {
      setAppleLoading(false);
      setVisible((prevState) => ({
        ...prevState,
        login: false,
        signUp: false,
      }));
    }
  };

  return (
    <Modal
      title=''
      open={visible}
      centered={true}
      onOk={handleOk}
      closable={false}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      footer={false}
      className='customLoginModal bg-bgColor bg-opacity-70 overflow-hidden  pt-5  relative rounded-2xl border-2 border-borderDarkGray backdrop-filter backdrop-blur-md '
    >
      <div className='flex items-center gap-y-10 flex-col justify-center px-5 md:px-10'>
        <div className='bg-[#311B01] text-sm z-40 absolute top-[45px] md:top-[65px] w-full text-[#DC9646] text-center  py-2  '>
          Can't log in? Use '
          <b
            onClick={() =>
              setVisible((prevState) => ({
                ...prevState,
                login: false,
                signUp: false,
                forgotPassword: true,
              }))
            }
            className='cursor-pointer'
          >
            Forgot Password
          </b>
          ' on the login page to reset.
        </div>
        <div className={` z-30  flex flex-col gap-y-1 w-full `}>
          <div className='relative pt-2 md:pt-4'>
            <span className=' flex justify-center w-full'>
              <Image
                src={ZebraLearnLogo}
                alt='Logo'
                priority
                className='object-contain h-3.5 w-full'
              />
            </span>
            <ShouldRender check={isClosable}>
              <span
                onClick={() => {
                  onCancel();
                }}
                className='absolute right-0 top-[40%]  md:top-[40%] cursor-pointer'
              >
                <XIcon className='w-4 h-4 md:w-6 md:h-6 text-fadedWhite' />
              </span>
            </ShouldRender>
          </div>
          <div className='border-b-2 border-backgroundGray py-1.5 md:py-3' />
          <div className='py-3 flex flex-col  mt-12 md:mt-10'>
            <h1 className='text-xl flex justify-center gap-x-2 font-medium  lg:font-semibold  leading-10 text-fadedWhite text-center w-full'>
              Welcome to Zebralearn
            </h1>
            <span className='text-sm text-textDarkGrayColor text-center flex justify-center'>
              Enter your credentials to login
            </span>
          </div>

          <form onSubmit={handleSubmit} className='flex flex-col gap-y-2 '>
            <div className='col-span-1 flex-col flex gap-y-.5'>
              <Input
                type='email'
                name='email'
                autoComplete='current-email'
                style={{
                  backgroundColor: '#000000',
                }}
                value={formData.email}
                placeholder='Email'
                prefix={
                  <span className='pr-2'>
                    <EmailIcon filled={true} />
                  </span>
                }
                onChange={handleChange}
                size='large'
                className='border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-2 text-white bg-bgColor customInputBlack makeBgBlack'
              />
              {errors.email && <p className='text-red-500'>{errors.email}</p>}
            </div>
            <div className='col-span-1 flex-col flex '>
              <Input.Password
                type='password'
                name='password'
                autoComplete='current-password'
                style={{
                  backgroundColor: '#000000',
                }}
                placeholder='Password'
                value={formData.password}
                prefix={
                  <span className='pr-2'>
                    <PasswordIcon />
                  </span>
                }
                onChange={handleChange}
                size='large'
                className='border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-2 text-white bg-bgColor customInputBlack makeBgBlack'
              />
              {errors.password && (
                <p className='text-red-500'>{errors.password}</p>
              )}
              <span
                onClick={() =>
                  setVisible((prevState) => ({
                    ...prevState,
                    login: false,
                    signUp: false,
                    forgotPassword: true,
                  }))
                }
                className='font-medium text-primary mt-1 cursor-pointer hover:text-darkPrimary'
              >
                Forgot password?
              </span>
            </div>

            <span className='flex justify-center mt-2'>
              <ButtonDefault
                size={4}
                variant={ButtonVariants.WHITE}
                className='p-1 w-full'
                disabled={loading}
                loading={loading}
                rounded={true}
                enableScaling={false}
                // onClick={() => router.push('/auth/login')}
              >
                <span className='flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap'>
                  Login
                </span>
              </ButtonDefault>
            </span>
            <span className='text-center font-medium flex text-fadedWhite tracking-wider justify-center text-sm pt-2'>
              Don’t have an account?
              <p
                onClick={() =>
                  setVisible((prevState) => ({
                    ...prevState,
                    login: false,
                    signUp: true,
                  }))
                }
                className='text-primary  px-1 hover:font-bold hover:underline cursor-pointer transition-all duration-300 ease-in-out'
              >
                Sign up
              </p>
            </span>
            <span className='flex justify-center'>
              <span className='text-center text-base text-fadedWhite w-1/3'>
                <Divider
                  style={{
                    margin: '8px 0',
                    // borderColor: '',
                    color: '#1C1C1C',
                  }}
                  className='text-fadedWhite text-sm'
                >
                  OR
                </Divider>
              </span>
            </span>
            <span className='flex justify-center '>
              <ButtonDefault
                size={4}
                variant={ButtonVariants.UNSTYLED}
                className='disabled:bg-bgDarkGray hover:bg-[#181818] disabled:text-gray-300 text-fadedWhite bg-black w-full  border border-bgDarkGray shadow-sm text-base'
                rounded={true}
                loading={googleLoading}
                spinColor='gray'
                disabled={googleLoading}
                onClick={(e) => {
                  e.preventDefault();
                  handleGoogleLogin();
                }}
              >
                <span className='flex relative w-full justify-center text-white   items-center font-medium gap-x-3 px-8  whitespace-nowrap'>
                  <span className='w-5 h-5 absolute left-0'>
                    <Image
                      src={GoogleIcon}
                      alt='GoogleLogo'
                      className='object-contain'
                    />
                  </span>{' '}
                  Continue with Google
                </span>
              </ButtonDefault>
            </span>
            <ShouldRender check={showAppleLogin}>
              <span className='flex justify-center '>
                <ButtonDefault
                  size={4}
                  variant={ButtonVariants.UNSTYLED}
                  loading={appleLoading}
                  spinColor='gray'
                  rounded={true}
                  disabled={appleLoading}
                  className='disabled:bg-bgDarkGray hover:bg-[#181818] disabled:text-gray-300 text-fadedWhite bg-black w-full  border border-bgDarkGray shadow-sm text-base'
                  onClick={(e) => {
                    e.preventDefault();
                    handleAppleLogin();
                  }}
                >
                  <span className='flex relative w-full items-end justify-center text-white   font-medium gap-x-3 px-8  whitespace-nowrap'>
                    <span className='w-5  absolute left-0'>
                      <Image
                        src={AppleLogo}
                        alt='GoogleLogo'
                        className='object-contain'
                      />
                    </span>{' '}
                    Continue with Apple
                  </span>
                </ButtonDefault>
              </span>
            </ShouldRender>

            <div className='flex justify-center pb-4'>
              <p className='text-center text-fadedWhite  text-sm  md:w-4/5'>
                By using our service, you are agreeing to our{' '}
                <a
                  href='/Privacy_Policy_Reader.html'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-primary font-medium cursor-pointer hover:text-darkPrimary'
                >
                  Terms
                </a>{' '}
                and{' '}
                <a
                  href='/Privacy_Policy_Reader.html'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-primary font-medium cursor-pointer hover:text-darkPrimary'
                >
                  conditions
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
