import React from 'react';

const LiveIcon: React.FC = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icons'>
        <path
          id='Combo shape'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.33165 1.67052C7.7997 1.72194 8.46077 1.84746 9.0625 2.05497V7.0483C8.46077 6.83435 7.7997 6.70006 7.33165 6.64864C6.96568 6.60844 6.66687 6.31121 6.66687 5.94463V2.29401C6.66687 1.92743 6.96568 1.63032 7.33165 1.67052ZM12.6688 6.64864C12.2006 6.70007 11.5393 6.83441 10.9375 7.04844V2.05512C11.5393 1.84752 12.2006 1.72196 12.6688 1.67052C13.0347 1.63032 13.3335 1.92743 13.3335 2.29401V5.94463C13.3335 6.31121 13.0347 6.60844 12.6688 6.64864ZM5.41687 4.16687H5C3.61929 4.16687 2.5 5.28616 2.5 6.66687V13.3335C2.5 14.7142 3.61929 15.8335 5 15.8335H9.375V17.7081H7.5C7.15482 17.7081 6.875 17.988 6.875 18.3331C6.875 18.6783 7.15482 18.9581 7.5 18.9581H12.5C12.8452 18.9581 13.125 18.6783 13.125 18.3331C13.125 17.988 12.8452 17.7081 12.5 17.7081H10.625V15.8335H15C16.3807 15.8335 17.5 14.7142 17.5 13.3335V6.66687C17.5 5.28616 16.3807 4.16687 15 4.16687H14.5835V5.9447C14.5835 7.02677 13.7243 7.79026 12.8053 7.89123C12.509 7.92378 12.0908 8.00161 11.6741 8.12368C11.2427 8.25007 10.9024 8.39812 10.704 8.53332C10.2794 8.82258 9.72103 8.82258 9.29643 8.53332C9.09797 8.39812 8.7577 8.25007 8.32631 8.12368C7.90966 8.00161 7.4914 7.92378 7.19514 7.89123C6.2761 7.79026 5.41687 7.02677 5.41687 5.9447V4.16687Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export default LiveIcon;
