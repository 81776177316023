import { Avatar } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { IBM_Plex_Sans } from 'next/font/google';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';

import { formatCreatedAt } from '../Message';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import Logo from '../../../../assets/Icons/Logo';

interface MessageProps {
  isSender: boolean;
  isTyping?: boolean;
  showSenderName: boolean;
  text?: any;
  user: string | undefined;
  createdAt?: string;
  loading?: boolean;
  bookName?: string;
}

const ibmPlexSans = IBM_Plex_Sans({
  subsets: ['latin'],
  weight: ['400', '500', '700'],
});

export function getUserInitials(fullName: string): string {
  if (!fullName) return '';
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
}

export const AiChatMessage: FC<MessageProps> = ({
  isSender,
  isTyping = false,
  text,
  user: userData,
  loading = false,
  bookName,
  createdAt,
}) => {
  const messageClassName = clsx(
    'relative px-4 py-2.5 rounded-xl cursor-default text-white text-sm break-words max-w-full md:max-w-lg lg:max-w-xl',
    isSender
      ? 'bg-[#1E1E1F] text-white self-end rounded-br-none'
      : 'bg-[#393939] text-black rounded-bl-none',
    'relative',
  );

  const tailClasses = clsx(
    'absolute w-0 h-0 border-[6px] border-transparent',
    isSender
      ? 'border-r-[#393939] right-[-12px] top-[8px]'
      : 'border-l-[#121212] left-[-12px] top-[8px]',
  );
  const screens = useBreakpoint();

  const formattedTime = createdAt ? formatCreatedAt(createdAt) : '';

  return (
    <div className={ibmPlexSans.className}>
      <div
        className={clsx(
          'flex w-full px-2',
          isSender ? 'justify-end' : 'justify-start',
        )}
      >
        <div className='flex items-end gap-2 overflow-hidden'>
          {!isSender && (
            <ShouldRender check={userData}>
              <Avatar
                size={screens.md ? 40 : 30}
                alt='profile-picture'
                className='flex-shrink-0 select-none bg-[#393939] flex justify-center items-center'
                draggable={false}
                shape='circle'
              >
                <Logo />
              </Avatar>
            </ShouldRender>
          )}
          <ShouldRender check={!loading}>
            <div
              className={clsx(
                'flex flex-col',
                isSender ? 'items-end' : 'items-start',
              )}
            >
              <div className={messageClassName}>
                <div className={tailClasses}></div>
                <ShouldRender check={text && text?.length > 0}>
                  <div className='flex flex-col gap-y-2 md:gap-y-2.5 custom-text-ai-message'>
                    <ShouldRender check={userData}>
                      <span className='text-[#FFBA00] font-semibold text-xs '>
                        {' '}
                        {bookName}
                      </span>
                    </ShouldRender>
                    <ReactMarkdown>{text}</ReactMarkdown>
                  </div>
                </ShouldRender>
                <ShouldRender check={isTyping}>Typing...</ShouldRender>
                <div className='flex justify-end mt-0.5 md:mt-1'>
                  <span className='text-[10px] text-[#737373]'>
                    {formattedTime}
                  </span>
                </div>
              </div>
            </div>
          </ShouldRender>

          {isSender && (
            <ShouldRender check={userData}>
              <Avatar
                size={screens.md ? 40 : 30}
                alt='profile-picture'
                className='flex-shrink-0 select-none bg-[#393939] flex justify-center items-center'
                draggable={false}
                shape='circle'
              >
                <Logo />
              </Avatar>
            </ShouldRender>
          )}
        </div>
      </div>
    </div>
  );
};
