import React from 'react';

const CourseIcon: React.FC = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icons'>
        <path
          id='Combo shape'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.6663 5.0002V10.0002C16.6663 12.1094 15.3603 13.9136 13.5128 14.6482C13.6661 14.239 13.7499 13.7958 13.7499 13.3331C13.7499 11.2621 12.0709 9.58313 9.99988 9.58313C7.92881 9.58313 6.24988 11.2621 6.24988 13.3331C6.24988 13.7959 6.33371 14.2391 6.48701 14.6484C4.63927 13.9139 3.33301 12.1096 3.33301 10.0002V5.0002C3.33301 3.15925 4.82539 1.66687 6.66634 1.66687H13.333C15.174 1.66687 16.6663 3.15925 16.6663 5.0002ZM5.72925 5C5.72925 4.48223 6.14898 4.0625 6.66675 4.0625H13.3334C13.8512 4.0625 14.2709 4.48223 14.2709 5C14.2709 5.51777 13.8512 5.9375 13.3334 5.9375H6.66675C6.14898 5.9375 5.72925 5.51777 5.72925 5ZM6.66675 6.5625C6.14898 6.5625 5.72925 6.98223 5.72925 7.5C5.72925 8.01777 6.14898 8.4375 6.66675 8.4375H10.0001C10.5178 8.4375 10.9376 8.01777 10.9376 7.5C10.9376 6.98223 10.5178 6.5625 10.0001 6.5625H6.66675ZM11.6663 15.1967C12.1779 14.739 12.4999 14.0736 12.4999 13.3331C12.4999 11.9524 11.3806 10.8331 9.99988 10.8331C8.61917 10.8331 7.49988 11.9524 7.49988 13.3331C7.49988 14.0734 7.82167 14.7386 8.33301 15.1964V18.3333L9.99967 17.5L11.6663 18.3333V15.1967Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export default CourseIcon;
