import React from 'react';

import { CustomIconProps } from '../../src/utils/interfaces/Icon/index';

const PlayStoreIcon: React.FC<CustomIconProps> = ({
  width,
  height,
  fill,
  ...rest
}) => {
  return (
    <svg
      width='27'
      height='33'
      viewBox='0 0 27 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M12.2477 15.8502L0.125 29.934C0.125455 29.937 0.126366 29.9394 0.126822 29.9423C0.498592 31.4715 1.77468 32.5977 3.2892 32.5977C3.89462 32.5977 4.46304 32.4185 4.95049 32.104L4.98921 32.0791L18.6348 23.4603L12.2477 15.8502Z'
        fill='#EA4335'
      />
      <path
        d='M24.5086 13.4579L24.497 13.4492L18.6058 9.71101L11.9688 16.1756L18.629 23.4648L24.489 19.7638C25.5164 19.1567 26.2139 17.9711 26.2139 16.604C26.2139 15.2467 25.5258 14.067 24.5086 13.4579Z'
        fill='#FBBC04'
      />
      <path
        d='M0.111178 3.22107C0.0383045 3.51522 0 3.82334 0 4.1433V29.0315C0 29.3509 0.0378488 29.66 0.111633 29.9531L12.6515 16.2288L0.111178 3.22107Z'
        fill='#4285F4'
      />
      <path
        d='M12.3375 16.625L18.6122 9.7588L4.98204 1.10881C4.48661 0.78401 3.9084 0.59656 3.28966 0.59656C1.77514 0.59656 0.497339 1.72464 0.125455 3.25579C0.125 3.25729 0.125 3.25829 0.125 3.25966L12.3375 16.625Z'
        fill='#34A853'
      />
    </svg>
  );
};

export default PlayStoreIcon;
