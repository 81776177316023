import { ClockIcon } from '@heroicons/react/outline';
import { Divider, Tag } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { extractSlugAndProductId } from '../SlidBookContainer';
import StarRating from '../StarRating';
import { formatFollowerCount } from '../../ChatBox';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { formateErrorObject } from '../../shared/formateErrorObject';
import KeyValueTag from '../../shared/keyPoints';
import { notify } from '../../shared/notify';
import BookIcon from '../../../../assets/Icons/BookIcon';
import CertificateIcon from '../../../../assets/Icons/CertificateIcon';
import FrameworkIcon from '../../../../assets/Icons/FrameworkIcon';
import GoogleTranslateIcon from '../../../../assets/Icons/google-translate';
import LevelIcon from '../../../../assets/Icons/LevelIcon';
import { isRatingRefreshAtom } from '../../../atoms/isRatingRefreshAtom';
import { totalReviews } from '../../../atoms/totalReviews';
import { getAverageRating } from '../../../services/ReviewLandingPage';
import { IHeroSectionLandingPage } from '../../../utils/interfaces/landingPage';

interface ProductProps {
  heroSectionData?: IHeroSectionLandingPage;
  authorsData:
    | {
        follower_count: string;
        user_name: string;
      }
    | undefined;
}

const iconMap = {
  finishIn: <ClockIcon className='w-5 h-5 text-[#737373]' />,
  getCertificate: <CertificateIcon />,
  noOfDesignFrameworks: <FrameworkIcon />,
  language: <GoogleTranslateIcon filled={true} color='#737373' />,
  pageNumber: <BookIcon />,
  level: <LevelIcon />,
};

const formatMap = {
  finishIn: (value: string) => `Finish in ${value}`,
  getCertificate: () => 'Get a Certificate',
  noOfDesignFrameworks: (value: string) => `No. of Design Frameworks: ${value}`,
  language: (value: string) => value,
  pageNumber: (value: string) => `${value} Pages`,
  level: (value: string) => `Level: ${value}`,
};

const HeroSection: React.FC<ProductProps> = ({
  heroSectionData,
  authorsData,
}) => {
  const [rating, setRating] = useState<number>();
  const [isRatingRefresh, setIsRatingRefresh] =
    useRecoilState(isRatingRefreshAtom);
  const totalReview = useRecoilValue(totalReviews);
  const screens = useBreakpoint();
  const router = useRouter();
  const productId = extractSlugAndProductId(router.asPath);

  const roundToNearestHalf = (num: number) => {
    return Math.round(num * 2) / 2;
  };

  const averageRating = async () => {
    try {
      const data = await getAverageRating(productId);
      setRating(roundToNearestHalf(data.averageRating));
    } catch (error: any) {
      notify(formateErrorObject(error), 'error');
    }
  };

  useEffect(() => {
    if (productId) {
      averageRating();
      setIsRatingRefresh(false);
    }
  }, [productId, isRatingRefresh]);

  const handleScrollToBottom = () => {
    if (typeof window !== 'undefined') {
      const target = document.getElementById('review-container');
      if (target) {
        const targetPosition =
          target.getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <React.Fragment>
      <div className='text-fadedWhite  flex flex-col gap-y-2  lg:w-1/2 xl:w-3/5'>
        <div className='flex gap-y-1 md:gap-y-5 flex-col'>
          <ShouldRender check={screens?.lg}>
            <div className='flex w-min pb-1'>
              <div className='flex w-min '></div>
              <motion.div
                style={{
                  position: 'relative',
                  display: 'inline-block',
                  borderRadius: '4px',
                  padding: '1px',
                  backgroundColor: '#001330',
                }}
              >
                <motion.div
                  initial={{ backgroundPosition: '0% 50%' }}
                  animate={{ backgroundPosition: '200% 50%' }}
                  transition={{
                    ease: 'linear',
                    duration: 3,
                    repeat: Infinity,
                  }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: '4px',
                    border: '2px solid transparent',
                    background:
                      'linear-gradient(90deg, rgba(35,122,255,0) 25%, rgba(35,122,255,1) 50%, rgba(35,122,255,0) 75%)', // Blue gradient
                    backgroundSize: '200% 100%',
                    pointerEvents: 'none',
                  }}
                />
                <div
                  className='text-[#237AFF] whitespace-nowrap bg-[#001330] font-semibold py-1.5 px-3 text-xs flex'
                  style={{
                    borderRadius: '4px',
                    zIndex: 2,
                    position: 'relative',
                  }}
                >
                  {heroSectionData?.productHighlightText}
                </div>
              </motion.div>
            </div>
          </ShouldRender>
          <span className='text-xs font-light lg:font-normal lg:text-sm text-white leading-5  mt-2 lg:mt-0'>
            BY{' '}
            <Link
              href={`/author/${authorsData?.user_name}`}
              target='_blank'
              rel='noopener noreferrer'
              className='inline-flex hover:underline text-white'
              style={{ textUnderlineOffset: '2px' }}
            >
              {heroSectionData?.authorName?.toUpperCase()}
            </Link>{' '}
            <ShouldRender check={Number(authorsData?.follower_count) > 200}>
              <p className='bg-dropDownMenuBg rounded-full px-3 inline-flex py-0.5 mx-1'>
                {formatFollowerCount(Number(authorsData?.follower_count))}{' '}
                Learners
              </p>
            </ShouldRender>
            <span className='inline-flex items-center'>
              <span className=''>
                <Divider type='vertical' />
              </span>

              <ShouldRender check={heroSectionData?.authorName}>
                <Image
                  alt={heroSectionData?.authorName || 'company_icon'}
                  src={heroSectionData?.companyIcon as string}
                  width={screens.lg ? 16 : 14}
                  height={screens.lg ? 16 : 14}
                  className='ml-1 mr-2'
                />
              </ShouldRender>
            </span>
            {heroSectionData?.authorDesignation}
          </span>
          <div className='flex flex-col mt-2'>
            <span className='text-lg lg:text-[26px] font-semibold'>
              {heroSectionData?.productTitle}
            </span>
            <span className='text-sm md:text-base font-medium text-[#E2E2E2] mt-2 md:mt-3'>
              {heroSectionData?.shortDescription}
            </span>
          </div>
        </div>

        <div className='flex flex-col lg:items-center lg:flex-row gap-3 lg:gap-5 mt-2 '>
          <ShouldRender check={rating}>
            <span className='flex items-center text-sm md:text-base lg:text-xl gap-x-2'>
              <span className=''>
                <StarRating rating={rating || 0} color='#2DD598' />
              </span>
              <span className='font-medium text-sm '>{rating || 0}</span>
              <span className=''>
                <Divider type='vertical' />
              </span>
              <span
                onClick={handleScrollToBottom}
                className='font-medium text-xs md:text-sm underline cursor-pointer'
              >
                {totalReview} Reviews
              </span>
            </span>
          </ShouldRender>

          {/* <ShouldRender check={screens.lg}>
            <span className=''>
              <Divider type='vertical' />
            </span>
          </ShouldRender> */}

          {/* <div className='flex flex-row gap-x-5'>
            <span className='flex items-center text-sm md:text-base lg:text-xl gap-x-2'>
              <span className=' text-gray-300'>
                <GoogleTranslateIcon filled={true} />
              </span>
              <span className='font-medium text-sm'>
                {heroSectionData?.language}
              </span>
            </span>

            <span className='flex items-center text-sm md:text-base lg:text-xl gap-x-2'>
              <span className='text-gray-300'>
                <OpenedBookIcon />
              </span>
              <span className='font-medium text-sm'>
                {' '}
                {heroSectionData?.numberOfPages} Pages
              </span>
            </span>
          </div> */}
        </div>
        <ShouldRender check={heroSectionData?.keyPoints}>
          <div className='mt-5 flex flex-wrap gap-2'>
            {heroSectionData?.keyPoints?.map((keyPoint, index) => {
              return (
                <Tag
                  className='border-none rounded-lg px-3 md:px-5 py-2 m-0 bg-[#202020] text-qureosSecondary'
                  key={index}
                >
                  <KeyValueTag
                    key={index}
                    name={keyPoint.name}
                    value={keyPoint.value ?? ''}
                    iconMap={iconMap}
                    formatMap={formatMap}
                  />
                </Tag>
              );
            })}
          </div>
        </ShouldRender>
      </div>
    </React.Fragment>
  );
};
export default HeroSection;
