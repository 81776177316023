import { EventDetails } from '../../hooks/usePageTracking';

export const pageTitleToEventDetails: {
  [key: string]: { details: EventDetails[]; params?: string[] };
} = {
  '/': {
    details: [
      {
        event_name: 'screen_view',
        source: 'global',
        source_type: 'home',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'screen_session',
        source: 'global',
        source_type: 'home',
        unit: 'seconds',
        value: 0,
      },
    ],
  },
  '/books/[bookId]': {
    details: [
      {
        event_name: 'details_view',
        source: 'books',
        source_type: 'long',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'details_session',
        source: 'books',
        source_type: '-',
        unit: 'seconds',
        value: 0,
      },
    ],
    params: ['productId'],
  },
  '/books': {
    details: [
      {
        event_name: 'screen_view',
        source: 'books',
        source_type: 'home',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'screen_session',
        source: 'books',
        source_type: 'home',
        unit: 'seconds',
        value: 0,
      },
    ],
  },
  '/pdf/[book_id]': {
    details: [
      {
        event_name: 'learn_view',
        source: 'books',
        source_type: 'long',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'learn_session',
        source: 'books',
        source_type: 'long',
        unit: 'seconds',
        value: 0,
      },
    ],
    params: ['book_id'],
  },
  '/schools': {
    details: [
      {
        event_name: 'screen_view',
        source: 'schools',
        source_type: 'home',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'screen_session',
        source: 'schools',
        source_type: 'home',
        unit: 'seconds',
        value: 0,
      },
    ],
  },
  '/schools/[schoolId]': {
    details: [
      {
        event_name: 'details_view',
        source: 'schools',
        source_type: 'schools',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'details_session',
        source: 'schools',
        source_type: 'schools',
        unit: 'seconds',
        value: 0,
      },
    ],
    params: ['school_id'],
  },
  '/schools/[schoolId]/[postId]': {
    details: [
      {
        event_name: 'learn_view',
        source: 'schools',
        source_type: 'schools',
        sub_source: 'chapter',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'learn_session',
        source: 'schools',
        source_type: 'schools',
        sub_source: 'chapter',
        unit: 'seconds',
        value: 0,
      },
    ],
    params: ['school_id'],
  },
  '/live': {
    details: [
      {
        event_name: 'screen_view',
        source: 'live',
        source_type: 'home',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'screen_session',
        source: 'live',
        source_type: 'home',
        unit: 'seconds',
        value: 0,
      },
    ],
  },
  '/live/[slug]': {
    details: [
      {
        event_name: 'details_view',
        source: 'live',
        source_type: 'workshop',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'details_session',
        source: 'live',
        source_type: 'workshop',
        unit: 'seconds',
        value: 0,
      },
    ],
    params: ['workshop_id'],
  },
  '/live/watch/[watchId]': {
    details: [
      {
        event_name: 'learn_view',
        source: 'live',
        source_type: 'workshop',
        sub_source: 'session',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'learn_session',
        source: 'live',
        source_type: 'workshop',
        sub_source: 'session',
        unit: 'seconds',
        value: 0,
      },
    ],
    params: ['workshop_id'],
  },
  '/broadcast-message': {
    details: [
      {
        event_name: 'screen_view',
        source: 'message',
        source_type: 'message',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'screen_session',
        source: 'message',
        source_type: 'message',
        unit: 'seconds',
        value: 0,
      },
    ],
  },
  '/product': {
    details: [
      {
        event_name: 'screen_view',
        source: 'shop',
        source_type: 'shop',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'screen_session',
        source: 'shop',
        source_type: 'shop',
        unit: 'seconds',
        value: 0,
      },
    ],
  },
  '/cart': {
    details: [
      {
        event_name: 'screen_view',
        source: 'global',
        source_type: 'cart',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'screen_session',
        source: 'global',
        source_type: 'cart',
        unit: 'seconds',
        value: 0,
      },
    ],
  },
  '/my-orders': {
    details: [
      {
        event_name: 'screen_view',
        source: 'global',
        source_type: 'my_orders',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'screen_session',
        source: 'global',
        source_type: 'my_orders',
        unit: 'seconds',
        value: 0,
      },
    ],
  },
  '/profile': {
    details: [
      {
        event_name: 'screen_view',
        source: 'global',
        source_type: 'profile',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'screen_session',
        source: 'global',
        source_type: 'profile',
        unit: 'seconds',
        value: 0,
      },
    ],
  },
  '/profile/?activeTab=2': {
    details: [
      {
        event_name: 'screen_view',
        source: 'global',
        source_type: 'password',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'screen_session',
        source: 'global',
        source_type: 'password',
        unit: 'seconds',
        value: 0,
      },
    ],
  },
  '/contact': {
    details: [
      {
        event_name: 'screen_view',
        source: 'global',
        source_type: 'support',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'screen_session',
        source: 'global',
        source_type: 'support',
        unit: 'seconds',
        value: 0,
      },
    ],
  },
  '/courses': {
    details: [
      {
        event_name: 'screen_view',
        source: 'courses',
        source_type: 'home',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'screen_session',
        source: 'courses',
        source_type: 'home',
        unit: 'seconds',
        value: 0,
      },
    ],
  },
  '/library': {
    details: [
      {
        event_name: 'screen_view',
        source: 'library',
        source_type: 'library',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'screen_session',
        source: 'library',
        source_type: 'library',
        unit: 'seconds',
        value: 0,
      },
    ],
  },
  '/get-our-app': {
    details: [
      {
        event_name: 'screen_view',
        source: 'global',
        source_type: 'app_download',
        unit: 'boolean',
        value: 1,
      },
      {
        event_name: 'screen_session',
        source: 'global',
        source_type: 'app_download',
        unit: 'seconds',
        value: 0,
      },
    ],
  },
};
