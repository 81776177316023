import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { Skeleton } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { ShouldRender } from '../../../shared/basic/ShouldRender';

export interface HomepageSliderProps {
  children: ReactNode;
  heading: string;
  isBook?: boolean;
  onPagination?: () => void;
  setIsPaginateShift?: (value: boolean) => void;
  isPaginateShift?: boolean;
  loading?: boolean;
  total?: number;
  imageWidths?: number[];
}

type SlideProps = {
  totalRecords: number;
  containerWidth: number;
  imageWidths?: number[];
};

const calculateSlides = ({
  totalRecords,
  containerWidth,
  imageWidths = [120, 140, 200],
}: SlideProps): number => {
  if (
    totalRecords < 1 ||
    totalRecords === undefined ||
    !containerWidth ||
    !imageWidths.length
  ) {
    return 0;
  }

  let imageWidth = imageWidths[0];

  // Adjust image width based on screen size (breakpoints)
  if (typeof window !== 'undefined') {
    if (window.innerWidth >= 1280) {
      imageWidth = imageWidths[2]; // Use largest width for xl breakpoint
    } else if (window.innerWidth >= 1024) {
      imageWidth = imageWidths[1];
    }
  }

  // Calculate how many images fit in one slide (per screen)
  const imagesPerSlide =
    imageWidth && containerWidth && Math.floor(containerWidth / imageWidth);

  // Calculate total slides needed
  const totalSlides =
    imagesPerSlide && totalRecords && Math.ceil(totalRecords / imagesPerSlide);

  return totalSlides || 0;
};

const HomepageSlider: FC<HomepageSliderProps> = ({
  children,
  heading,
  isBook = false,
  onPagination,
  isPaginateShift = false,
  setIsPaginateShift,
  loading = false,
  total,
  imageWidths,
}) => {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const [isAtLeftmost, setIsAtLeftmost] = useState(true);
  const [isAtRightmost, setIsAtRightmost] = useState(false);
  const [windowWidth, setWindowWidth] = useState<number | undefined>(undefined);
  const [leftPadding, setLeftPadding] = useState<number>(44);
  const [onHover, setOnHover] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const totalSlides = calculateSlides({
    totalRecords: total || 1,
    containerWidth,
    imageWidths: imageWidths,
  });

  // Initial left padding

  const screens = useBreakpoint();

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const scrollSlider = async (direction: 'left' | 'right') => {
    if (sliderRef.current) {
      const { scrollLeft, clientWidth } = sliderRef.current;
      const scrollTo =
        direction === 'left'
          ? scrollLeft - clientWidth - 20
          : scrollLeft + clientWidth - 20;

      // Scroll immediately
      sliderRef.current.scrollTo({ left: scrollTo, behavior: 'smooth' });

      // Update the left padding
      setLeftPadding(direction === 'left' ? 12 : 0);

      // Calculate the potential new page number
      const newPage =
        direction === 'right'
          ? Math.min(currentPage + 1, totalSlides - 1)
          : Math.max(currentPage - 1, 0);

      // If moving right and not at the last page, fetch data for the next page
      if (direction === 'right' && !isAtRightmost) {
        try {
          // Fetch next page's data if required
          if (onPagination) onPagination();

          // If fetch was successful, update the page
          setCurrentPage(newPage);
        } catch (error) {
          console.error('Failed to fetch new page data:', error);
          // Keep the current page unchanged if fetch fails
        }
      } else {
        // If moving left or no fetch is needed, update page normally
        setCurrentPage(newPage);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setWindowWidth(window.innerWidth);

      // Update scroll position to prevent initial auto-scrolling
      if (sliderRef.current) {
        sliderRef.current.scrollLeft = 0;
      }

      const handleScroll = () => {
        if (sliderRef.current) {
          const isLeftmost = sliderRef.current.scrollLeft === 0;
          const isRightmost =
            sliderRef.current.scrollLeft + sliderRef.current.clientWidth + 1 >=
            sliderRef.current.scrollWidth;

          setIsAtLeftmost(isLeftmost);
          setIsAtRightmost(isRightmost);
        }
      };

      sliderRef.current?.addEventListener('scroll', handleScroll);

      window.addEventListener('resize', updateWindowWidth);

      return () => {
        sliderRef.current?.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', updateWindowWidth);
      };
    }, 0);
  }, []);

  // Check if the total width of the row of cards exceeds the window width
  const isExceedingWindowWidth =
    windowWidth !== undefined &&
    sliderRef.current?.scrollWidth !== undefined &&
    windowWidth < sliderRef.current?.scrollWidth;

  useEffect(() => {
    if (isAtRightmost && onPagination) {
      onPagination();
      setIsPaginateShift && setIsPaginateShift(true);
    }
  }, [isAtRightmost]);

  useEffect(() => {
    if (isPaginateShift) {
      // scrollRight();
      setIsPaginateShift && setIsPaginateShift(false);
    }
  }, [isPaginateShift]);

  // Dynamically get the container width
  useEffect(() => {
    if (sliderRef.current) {
      setContainerWidth(sliderRef.current.offsetWidth);
    }
  }, [sliderRef.current?.offsetWidth]);

  return (
    <div
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      className='text-fadedWhite'
    >
      <div className='flex justify-between mb-2 '>
        <ShouldRender check={loading}>
          <h2 className='text-lg md:text-xl xl:text-2xl font-semibold capitalize pl-2 md:px-5 lg:px-12 z-20'>
            {heading}
          </h2>
        </ShouldRender>
        <ShouldRender check={!loading}>
          <Skeleton.Button
            style={{
              width: '150px',
              height: '28px',
              fontSize: '7px',
            }}
            active={true}
            className='rounded-sm   md:px-5 lg:px-12 transition-transform transform pl-2 duration-500 '
          />
        </ShouldRender>
        <ShouldRender
          check={
            totalSlides &&
            typeof totalSlides === 'number' &&
            totalSlides > 1 &&
            totalSlides !== Infinity &&
            !isNaN(totalSlides) &&
            Number.isInteger(totalSlides) &&
            totalSlides > 0 &&
            screens.lg
          }
        >
          <div className='flex justify-center items-center gap-[2px] mr-10'>
            {Array.from({ length: totalSlides }).map((_, index) => (
              <motion.div
                key={index}
                initial={{
                  width: currentPage === index ? 20 : 10,
                }}
                animate={{
                  width: currentPage === index ? 20 : 10,
                }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                style={{
                  background: currentPage === index ? '#AAAAAA' : '#4D4D4D',
                  height: '3px',
                }}
              />
            ))}
          </div>
        </ShouldRender>
      </div>
      <ShouldRender check={screens?.lg}>
        <div
          style={{ scrollBehavior: 'smooth', scrollbarWidth: 'none' }}
          className='flex relative'
        >
          <div className='absolute z-30  h-full transform top-0 left-0'>
            <ShouldRender check={onHover && !isAtLeftmost}>
              <div className='relative h-full z-10'>
                <div className='absolute h-full transform left-0 backdrop-blur-sm rounded-r-2xl'>
                  <div className='flex items-center h-full justify-center   px-2 md:px-8 opacity-40 rounded-r-2xl bg-bgColor '></div>
                </div>
                {/* Button */}
                <button
                  disabled={isAtLeftmost}
                  onClick={() => scrollSlider('left')}
                  className={clsx(
                    isBook ? 'top-[45%]' : 'top-[40%]',
                    'z-10 absolute left-0   w-8 h-8 xl:w-10 xl:h-10 rounded-full disabled:cursor-not-allowed flex justify-center items-center disabled:text-gray-500 text-fadedWhite transition-transform duration-300 ease-in-out cursor-pointer hover:scale-125',
                  )}
                >
                  <ChevronLeftIcon className='w-7 h-7 xl:w-8 xl:h-8' />
                </button>
              </div>
            </ShouldRender>
          </div>
          <div className='w-full relative'>
            <span
              ref={sliderRef}
              className='flex px-2 md:px-5 lg:px-12 hide-scrollbar'
              style={{
                scrollBehavior: 'smooth',
                scrollbarWidth: 'none',
                paddingLeft: `${isAtLeftmost ? 44 : leftPadding}px`,
                paddingRight: `${isAtRightmost && 44}px`,
              }}
            >
              {children}
            </span>

            <ShouldRender check={!isAtRightmost && isExceedingWindowWidth}>
              <div className='absolute  z-0 bg-gradient-to-l from-bgColor via-transparent to-transparent h-full w-10  right-0 top-0'></div>
            </ShouldRender>
          </div>
          <div className='absolute  h-full transform top-0 right-0'>
            <ShouldRender
              check={onHover && !isAtRightmost && isExceedingWindowWidth}
            >
              <div className='relative h-full '>
                <div className='absolute h-full transform right-0 backdrop-blur-sm rounded-l-2xl'>
                  <div className='flex items-center h-full justify-center px-2 md:px-8 opacity-40 rounded-l-2xl bg-bgColor'></div>
                </div>
                {/* Button */}
                <button
                  disabled={isAtRightmost}
                  onClick={() => scrollSlider('right')}
                  className={clsx(
                    isBook ? 'top-[45%]' : 'top-[40%]',
                    'z-10 absolute right-4  w-8 h-8 xl:w-10 xl:h-10 rounded-full disabled:cursor-not-allowed flex justify-center items-center disabled:text-gray-500 text-fadedWhite transition-transform duration-300 ease-in-out cursor-pointer hover:scale-125',
                  )}
                >
                  <ChevronRightIcon className='w-7 h-7 xl:w-8 xl:h-8' />
                </button>
              </div>
            </ShouldRender>
          </div>
        </div>
      </ShouldRender>
      <ShouldRender check={!screens?.lg}>
        <div
          ref={sliderRef}
          className='flex px-2 md:px-5  lg:px-12 hide-scrollbar '
          style={{ scrollBehavior: 'smooth', scrollbarWidth: 'none' }}
        >
          {children}
        </div>
      </ShouldRender>
    </div>
  );
};

export default HomepageSlider;
