import { schoolApiClient } from '../apiClients';
import { Endpoints } from '../../network';
import {
  ILiveRegistrationAlreadyRegistered,
  ReqCommentData,
} from '../../utils/interfaces/Live';

export const getCurrentLive = async (keyword?: string) => {
  const queryParams = new URLSearchParams({
    ...(keyword !== undefined && keyword !== '' && { title: keyword }),
  });
  const urlWithQuery = `${Endpoints.getCurrentLive}?${queryParams}`;

  const response = await schoolApiClient.get(urlWithQuery);
  return response?.data;
};

export const getUpcomingLive = async (keyword?: string) => {
  const queryParams = new URLSearchParams({
    ...(keyword !== undefined && keyword !== '' && { title: keyword }),
  });
  const urlWithQuery = `${Endpoints.getUpcomingLive}?${queryParams}`;
  const response = await schoolApiClient.get(urlWithQuery);
  return response?.data;
};

export const getLiveDetails = async (slug: string) => {
  const response = await schoolApiClient.get(Endpoints.getLiveDetails + slug);
  return response?.data;
};

export const getLiveSessionById = async (id: string) => {
  const response = await schoolApiClient.get(
    `${Endpoints.getLiveSessionById}/${id}`,
  );
  return response?.data;
};

export const getCommentsBySessionId = async (sessionId: number) => {
  const response = await schoolApiClient.get(
    Endpoints.getSessionCommentBySessionId + sessionId,
  );
  return response?.data;
};

export const postComment = async (payload: ReqCommentData) => {
  const response = await schoolApiClient.post(Endpoints.postComment, payload);
  return response?.data;
};

export const getLiveBanner = async (status: string) => {
  const response = await schoolApiClient.get(
    `${Endpoints.getLiveBanner}?status=${status}`,
  );
  return response?.data;
};

export const liveRegistrationAlreadyRegistered = async (
  body: ILiveRegistrationAlreadyRegistered,
) => {
  const response = await schoolApiClient.post(
    `${Endpoints.liveRegistrationAlreadyRegistered}`,
    body,
  );
  return response?.data;
};

export const getRegisterStatus = async (body: {
  ecom_user_id: number;
  live_workshop_id: number;
}) => {
  const response = await schoolApiClient.get(
    `${Endpoints.registerStatus}?ecom_user_id=${body?.ecom_user_id}&live_workshop_id=${body?.live_workshop_id}`,
  );
  return response?.data;
};
