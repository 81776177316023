import { atom } from 'recoil';

import { User } from '../utils/interfaces/User';

export const user = atom<User | undefined>({
  key: 'user',
  default: undefined,
});

export const localUserID = atom<string>({
  key: 'localUserID',
  default: undefined,
});

// export const updateProfile = atom({
//   default: false,
//   key: 'updateProfile',
// });
