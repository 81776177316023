import { Button, Card, Skeleton } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import CreateReviewModal from './CreateReviewModal';
import ProductReviewModal from './ProductReviewModal';
import ReviewCard from './ReviewCard';
import ReviewImageSliderPaginated from './ReviewImageSliderPaginated';
import SortReview from './SortReview';
import Headings from '../landingPage/heading';
import { extractSlugAndProductId } from '../SlidBookContainer';
import ButtonDefault, { ButtonVariants } from '../../shared/basic/button';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { formateErrorObject } from '../../shared/formateErrorObject';
import { notify } from '../../shared/notify';
import { tokenUpdate } from '../../../atoms/authModalControl';
import { totalReviews } from '../../../atoms/totalReviews';
import { user } from '../../../atoms/user';
import {
  checkIsPurchased,
  fetchUserProductReview,
  getAllReviews,
  getAllReviewsImages,
} from '../../../services/ReviewLandingPage';
import { PaginationProps } from '../../../utils/interfaces/Orders';
import { User } from '../../../utils/interfaces/User';

export interface IReviewsData {
  user: {
    zl_uid: string;
    name: string;
    avatar: string;
  };
  rating: number;
  title: string;
  text: string;
  createdAt: string;
  images: {
    imageId: string;
    imageURL: string;
  }[];
  _id: string;
}

interface Props {
  hardCoverId: string;
  digitalCoverId: string;
  isLoggedIn: boolean;
}

const ReviewsLandingPage: React.FC<Props> = ({
  hardCoverId,
  digitalCoverId,
  isLoggedIn,
}) => {
  const [loading, setLoading] = useState({ reviews: false, images: false });
  const [reviewDetailModal, setReviewDetailModal] = useState<{
    data: IReviewsData | undefined;
    isOpen: boolean;
  }>({ data: undefined, isOpen: false });
  const [, setTotalReview] = useRecoilState(totalReviews);
  const [isTokenChanged, setIsTokenChanged] = useRecoilState(tokenUpdate);

  const [createModal, setCreateModal] = useState<{
    value: boolean;
    type: 'create' | 'edit';
  }>({ type: 'create', value: false });
  const [data, setData] = useState({
    reviews: [] as IReviewsData[],
    images: [] as {
      imageId: string;
      imageURL: string;
    }[],
  });
  const [pagination, setPagination] = useState({
    reviews: {} as PaginationProps,
    images: {} as PaginationProps,
  });
  const [page, setPage] = useState({ reviews: 1, images: 1 });
  const [isPaginateShift, setIsPaginateShift] = useState(true);
  const [isPurchased, setIsPurchased] = useState(false);
  const [selectedSort, setSortSelected] = useState('top_review');

  const [isReviewed, setIsReviewed] = useState<IReviewsData>();

  const userData: User | undefined = useRecoilValue(user);
  const screens = useBreakpoint();

  const { query } = useRouter();
  const router = useRouter();
  const productId = extractSlugAndProductId(router.asPath);
  const reviewsLimit = 5;
  const reviewsImageLimit = 5;

  const fetchData = async (type: 'reviews' | 'images', pageNum: number) => {
    try {
      setLoading((prev) => ({ ...prev, [type]: true }));

      const service = type === 'reviews' ? getAllReviews : getAllReviewsImages;
      const limit = type === 'reviews' ? reviewsLimit : reviewsImageLimit;

      const sortParams =
        selectedSort === 'most_recent'
          ? { sortByDate: 'desc' }
          : { sortByRating: 'desc' };

      const response = await service({
        page: pageNum,
        limit,
        product_id: productId as string,
        ...sortParams,
        ...(userData?.zl_uid && { zl_uid: userData.zl_uid }),
      });
      let userReview;

      if (userData?.zl_uid && productId && type === 'reviews') {
        userReview = await fetchUserProductReview({
          productId: productId as string,
          zl_uid: userData?.zl_uid,
        });
      }

      const formattedData =
        type === 'reviews'
          ? response?.reviews.map((review: IReviewsData) => ({
              ...review,
              user: { ...review.user },
            }))
          : response?.images;
      if (userReview?.reviews?.length) {
        setIsReviewed(userReview?.reviews[0]);
      }
      const updatedData =
        type === 'reviews' && userReview && pageNum === 1
          ? [...(userReview?.reviews || []), ...formattedData]
          : formattedData;

      setPagination((prev) => ({
        ...prev,
        [type]: {
          current_page: response?.meta?.currentPage,
          per_page: response?.meta?.limit,
          total: response?.meta?.totalPages,
        },
      }));
      if (type === 'reviews') {
        setTotalReview(
          response?.meta?.totalRecords + (userReview?.reviews?.length || 0),
        );
      }

      setData((prev) => ({
        ...prev,
        [type]: pageNum === 1 ? updatedData : [...prev[type], ...updatedData],
      }));
    } catch (error: any) {
      notify(formateErrorObject(error), 'error');
    } finally {
      setLoading((prev) => ({ ...prev, [type]: false }));
    }
  };

  useEffect(() => {
    if (productId) fetchData('reviews', page.reviews);
  }, [productId, page.reviews, userData, selectedSort]);

  useEffect(() => {
    if (productId) fetchData('images', page.images);
  }, [productId, page.images]);

  const checkIfPurchased = async () => {
    try {
      const data = await checkIsPurchased(
        `${hardCoverId},${digitalCoverId}` as string,
      );
      setIsPurchased(data);
    } catch (error: any) {
      notify(formateErrorObject(error), 'error');
    }
  };

  useEffect(() => {
    if (userData && productId) {
      checkIfPurchased();
    }
  }, [userData, hardCoverId, digitalCoverId]);

  const handlePagination = (type: 'reviews' | 'images') => {
    if (pagination[type]?.total && page[type] < pagination[type].total) {
      setPage((prev) => ({ ...prev, [type]: prev[type] + 1 }));
    }
  };

  const handleScrollToBottom = () => {
    if (typeof window !== 'undefined') {
      const target = document.getElementById('review-container');
      if (target) {
        const targetPosition =
          target.getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    if (query.isReview) {
      setCreateModal({
        type: isReviewed ? 'edit' : 'create',
        value: true,
      });
      handleScrollToBottom();
    }
  }, [query.isReview, data]);

  useEffect(() => {
    if (isTokenChanged) {
      setIsTokenChanged(false);

      setPage({ reviews: 1, images: 1 });

      setData({ reviews: [], images: [] });

      if (productId) {
        fetchData('reviews', 1);
        fetchData('images', 1);
      }
    }
  }, [isTokenChanged]);

  return (
    <div id='review-container' className=''>
      <div className='flex justify-between  mb-5 md:mb-2 items-center'>
        <Headings heading='REVIEWS' isReview={true} />
        <SortReview
          setSortSelected={setSortSelected}
          selectedSort={selectedSort}
        />
      </div>
      <ReviewImageSliderPaginated
        loading={loading.images}
        data={data.images}
        onPagination={() => handlePagination('images')}
        setIsPaginateShift={setIsPaginateShift}
        isPaginateShift={isPaginateShift}
        total={pagination.images.total || 0}
        imageWidths={[122, 122, 122]}
      />
      <div className='mb-3 mt-6'>
        <ShouldRender check={userData && isPurchased}>
          <Button
            className='w-full bg-transparent text-white text-xs hover:border-[#3D3D3D] '
            size='large'
            onClick={() =>
              setCreateModal({
                type: isReviewed ? 'edit' : 'create',
                value: true,
              })
            }
          >
            {isReviewed ? 'Edit your review' : 'Write a Review'}
          </Button>
        </ShouldRender>
      </div>

      <div className='flex gap-y-3 flex-col'>
        {!loading.reviews
          ? data?.reviews?.map((review) => (
              <span
                key={review._id}
                onClick={() => {
                  review.images.length &&
                    setReviewDetailModal({
                      data: review,
                      isOpen: true,
                    });
                }}
                className={review.images.length ? `cursor-pointer` : ''}
              >
                <ReviewCard data={review} />
              </span>
            ))
          : Array.from({ length: 2 }).map((_, index) => (
              <Card
                key={`card-${index}`}
                style={{
                  backgroundColor: '#1a1a1a',
                  borderRadius: '8px',
                }}
                className='mb-2 md:mb-[16px]'
              >
                <div className='flex justify-between items-center w-full'>
                  <span className='flex items-center gap-x-4'>
                    {' '}
                    <Skeleton.Avatar active size='large' shape='circle' />
                    <Skeleton.Input
                      style={{ width: 150 }}
                      active
                      size='small'
                    />
                  </span>
                  <ShouldRender check={screens.md}>
                    <span>
                      <Skeleton.Input
                        style={{ width: 20 }}
                        active
                        size='small'
                      />{' '}
                    </span>
                  </ShouldRender>
                </div>

                <Skeleton.Input
                  style={{ width: '100%', marginTop: 12 }}
                  active
                  className='w-full h-12'
                  size='default'
                />
              </Card>
            ))}
      </div>
      <ShouldRender check={page.reviews < pagination.reviews.total}>
        <ButtonDefault
          size={4}
          variant={ButtonVariants.GRAY}
          onClick={() => handlePagination('reviews')}
          className='w-full  mt-2.5'
        >
          <span className='flex text-xs gap-x-2 px-4 py-1 w-full justify-center whitespace-nowrap'>
            SHOW MORE
          </span>
        </ButtonDefault>
      </ShouldRender>

      <CreateReviewModal
        handleClose={() =>
          setCreateModal({
            value: false,
            type: 'create',
          })
        }
        isPurchased={isPurchased}
        type={createModal.type}
        isLoggedIn={isLoggedIn}
        isModalOpen={createModal.value}
        review={isReviewed}
        fetchData={async () => {
          await fetchData('images', 1);
          await fetchData('reviews', 1);
        }}
      />

      <ProductReviewModal
        onClose={() =>
          setReviewDetailModal({
            data: undefined,
            isOpen: false,
          })
        }
        data={reviewDetailModal.data as IReviewsData}
        isModalOpen={reviewDetailModal.isOpen}
      />
    </div>
  );
};

export default ReviewsLandingPage;
