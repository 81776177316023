import React from 'react';

const ContactUs: React.FC = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icons'>
        <g id='Call'>
          <path
            id='Stroke 1'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.61043 10.3936C12.9347 13.7169 13.6888 9.87218 15.8054 11.9873C17.8459 14.0272 19.0187 14.4359 16.4333 17.0205C16.1095 17.2808 14.052 20.4118 6.82115 13.183C-0.410581 5.95325 2.71864 3.89362 2.97896 3.56988C5.57054 0.978124 5.9722 2.15774 8.01273 4.19769C10.1293 6.31366 6.2862 7.07026 9.61043 10.3936Z'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  );
};

export default ContactUs;
