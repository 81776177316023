import { atom } from 'recoil';

export const authModalControl = atom<{
  login: boolean;
  signUp: boolean;
  forgotPassword: boolean;
  bookId: string;
  comingSoon: string;
  noRedirect: boolean;
}>({
  key: 'authModalControl',
  default: {
    login: false,
    signUp: false,
    forgotPassword: false,
    bookId: '',
    comingSoon: '',
    noRedirect: false,
  },
});

export const tokenUpdate = atom<boolean>({
  key: 'tokenUpdate',
  default: false,
});
