import Cookies from 'js-cookie';

import { trackLogoutEvent } from '../Gtags';
import { removeAuthToken, removeIFrameBookToken } from '../IFrameHelper';
import { clearAuthToken } from '../ManageTokenForClient/tokenManager';

const TOKEN_EXPIRE_TIME = 13;

export const getLoginToken = async () => {
  if (typeof document === 'undefined') {
    return undefined;
  }
  const cookieString = document.cookie;
  const cookies = cookieString.split('; ');

  const tokenCookie = cookies.find((cookie) =>
    cookie.startsWith(`auth_token=`),
  );

  if (!tokenCookie) return undefined;

  const token = tokenCookie.split('=')[1];

  if (isTokenExpired()) {
    clearAuthToken('auth_token');
    return undefined;
  }

  return token;
};
export const getUserName = async () => {
  const token = Cookies.get('userName');
  return token;
};

export const getBookToken = async () => {
  const token = Cookies.get('book_token');
  return encodeURIComponent(token as string);
};

export const getBookTokenLocalStorage = async () => {
  const token = Cookies.get('book_token');
  return encodeURIComponent(token as string);
};

export const setBookToken = async (bookToken: string) => {
  Cookies.set('book_token', bookToken);
};

export const setBookTokenLocalStorage = async (bookToken: string) => {
  Cookies.set('book_token', bookToken);
};

export const setUserId = async (id: string) => {
  Cookies.set('user_id', id, { expires: 1 / 4 });
};

export const setZl_UID = async (id: string) => {
  Cookies.set('zl_uid', id, { expires: 1 / 4 });
};
export const getZl_UID = async () => {
  const id = Cookies.get('zl_uid');
  return id;
};

export const getUserId = async () => {
  const id = Cookies.get('user_id');
  return id;
};

export const removeZl_UID = () => {
  Cookies.remove('zl_uid');
};

export const removeUserId = () => {
  Cookies.remove('user_id');
};

export const getLocalUserId = async () => {
  if (typeof localStorage !== 'undefined') {
    const id = localStorage.getItem('userId');
    return id as string;
  } else {
    return undefined;
  }
};

export const setToken = async (token: string) => {
  if (typeof document === 'undefined') {
    return undefined;
  }
  const expirationTime = Date.now() + TOKEN_EXPIRE_TIME * 60 * 1000; // 13 minutes
  document.cookie = `auth_token=${token}; max-age=${13 * 60}; path=/`;
  localStorage.setItem('auth_token_expiration', expirationTime.toString());
  return token;
};

export const setUserName = async (userName?: string) => {
  if (userName) {
    Cookies.set('userName', userName, { expires: 1 / 4 });
  }
};

export const isTokenExpired = (): boolean => {
  const expirationTime = localStorage.getItem('auth_token_expiration');
  if (!expirationTime) return true;
  return Date.now() > parseInt(expirationTime, 10);
};

export const storeTokenExpiration = () => {
  const expirationTime = Date.now() + TOKEN_EXPIRE_TIME * 60 * 1000;
  localStorage.setItem('auth_token_expiration', expirationTime.toString());
};

export const logout = async () => {
  removeUserId();
  removeZl_UID();
  Cookies.remove('userName');
  Cookies.remove('user_id');
  Cookies.remove('auth_token');
  Cookies.remove('book_token');
  trackLogoutEvent();
  await removeIFrameBookToken();
  await removeAuthToken();
};

export const removeMarketingData = async () => {
  Cookies.remove('marketing_data');
};
