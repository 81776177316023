import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline';
import { Button, Empty, Steps } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import CartCard from './CartCard';
import CartContactForm from './CartContactForm';
import PaymentSuccessful from './PaymentSuccessful';
import Step_1_OrderCard from './Step_1_OrderCard';
import Step_2_OrderCard from './Step_2_OrderCard';
import Step_3_orderCard from './Step_3_orderCard';
import LoginCollapse from '../Checkout/LoginCollapse';
import { ShouldRender } from '../shared/basic/ShouldRender';
import { formateErrorObject } from '../shared/formateErrorObject';
import { getRandomNumber } from '../shared/getRandomNumber';
import { getLoginToken } from '../shared/getToken';
import { trackViewCartEvent } from '../shared/Gtags';
import { notify } from '../shared/notify';
import { tokenUpdate } from '../../atoms/authModalControl';
import { cartAtom } from '../../atoms/Cart';
import { loginCollapseAtom } from '../../atoms/loginCollapse';
import { localUserID, user } from '../../atoms/user';
import appwriteService from '../../config/appwrite';
import { useAuth } from '../../hooks/useAuth';
import { Endpoints } from '../../network';
import { generateUrlForImage } from '../../pages/books/[bookId]';
import {
  addCouponCode,
  getCartDetails,
  getCartSummary,
  handleRemoveCoupon,
} from '../../services/Cart';
import { getAllBookListApi } from '../../services/Library/Books';
import { CartItem } from '../../utils/interfaces/cart';
import { User } from '../../utils/interfaces/User';
import ShoppingCartCartIcon from '../../../assets/Icons/ShoppingCartCartIcon';

export const customExpandIcon = (props: any) => {
  if (props.isActive) {
    return (
      <div className='flex justify-center items-center h-full mt-2'>
        <span>
          <ChevronUpIcon className='w-4 md:w-5 text-white' />
        </span>
      </div>
    );
  } else {
    return (
      <div className='flex justify-center items-center h-full mt-3 md:mt-4'>
        <span>
          {' '}
          <ChevronDownIcon className='w-4 md:w-5 text-white' />
        </span>
      </div>
    );
  }
};

export const cardVariants = {
  hidden: {
    opacity: 0,
    x: 100,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'tween',
      duration: 0.5,
    },
  },
};

export const right = {
  hidden: {
    opacity: 0,
    x: -100,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'tween',
      duration: 0.5,
    },
  },
};

export const formatCartData = (rawData: any[], cartId?: number): CartItem[] => {
  return rawData.map((cart) => ({
    image: cart.product_thumbnail_image,
    name: cart.product_name,
    type: cart.book_type,
    price: cart.price,
    actualPrice: cart.stroked_price,
    id: cart.id,
    quantity: cart.quantity,
    upperLimit: cart.upper_limit,
    lowerLimit: cart.lower_limit,
    currency: cart.currency_symbol,
    itemType: cart?.category,
    cartId: cartId,
    product_id: cart?.product_id,
    category: cart?.category,
    digitalCopyName: cart?.digital_product?.name,
    current_stock: cart?.current_stock,
  }));
};

const CartPage: React.FC = () => {
  const [cartData, setCartData] = useRecoilState(cartAtom);
  const [token, setToken] = useState('');
  const hasRunOnce = useRef(false);
  // const [cartData, setCartData] = useRecoilState(cartAtom);

  const [amount, setAmount] = useState<{
    subTotal: number;
    discount: string;
    total: number;
    tax: number;
    couponCode: { coupon_code: string }[];
    actualCartTotal: number;
  }>({
    subTotal: 0,
    discount: '',
    total: 0,
    tax: 0,
    actualCartTotal: 0,
    couponCode: [{ coupon_code: '' }],
  });
  const [orderLoading, setOrderLoading] = useState(false);
  const router = useRouter();
  const [cartDataLoading, setCartDataLoading] = useState(false);
  const [loginCollapseTrigger, setLoginCollapseTrigger] =
    useRecoilState(loginCollapseAtom);

  const [products, setProducts] = useState<CartItem[]>([]);
  const [couponApply, setCouponApply] = useState<{
    value: boolean;
    isSuccess: boolean;
  }>({ value: false, isSuccess: false });
  const [removeLoading, setRemoveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTokenChanged, setIsTokenChanged] = useRecoilState(tokenUpdate);
  const [couponMessage, setCouponMessage] = useState({
    message: '',
    type: '',
  });
  const [synced, setSynced] = useState(false);
  const { cartStep, redirect_url, cp } = router.query;
  const [currentStep, setCurrentStep] = useState(0);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [randomNumber, setRandomNumber] = useState<number>(0);
  const [loadingBook, setLoadingBook] = useState(true);
  const [books, setBooks] = useState<
    {
      _id: string;
      coverImageUrl: string;
      title: string;
      externalLink: string;
      type: string;
      slug: string;
      totalPages: number;
      isExternalLink: boolean;
    }[]
  >([]);

  const userData: User | undefined = useRecoilValue(user);
  const screens = useBreakpoint();

  const { syncCart } = useAuth();

  const localUserId = useRecoilValue(localUserID);

  const handleQuantityChange = async (productId: string, change: number) => {
    const updatedProducts = products.map((product) => {
      if (String(product.id) === productId) {
        return { ...product, quantity: (product.quantity || 0) + change };
      }
      return product;
    });
    setProducts(updatedProducts);

    // localStorage.setItem('cart', JSON.stringify(updatedProducts));
  };

  const handleRemoveProduct = async (productId: string) => {
    setRandomNumber(0);
    if (products) {
      const updatedProducts = products.filter(
        (product) => String(product.id) !== productId,
      );
      // await handleCartSummary();
      setProducts(updatedProducts);
    }

    // localStorage.setItem('cart', JSON.stringify(updatedProducts));
  };

  // const amount = useMemo(() => {
  //   const totalActualPrice = products.reduce((total, product) => {
  //     return total + product.actualPrice * (product.quantity || 0);
  //   }, 0);
  //   const totalPrice = products.reduce((total, product) => {
  //     return total + product.price * (product.quantity || 0);
  //   }, 0);

  //   return {
  //     subTotal: totalActualPrice,
  //     discount: totalActualPrice - totalPrice,
  //     total: totalActualPrice - (totalActualPrice - totalPrice),
  //   };
  // }, [products]);
  const fetchData = async () => {
    try {
      const token = await getLoginToken();
      setToken(token as string);
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  useEffect(() => {
    fetchData();
    if (isTokenChanged) {
      setIsTokenChanged(false);
    }
  }, [isTokenChanged]);

  useEffect(() => {
    if (cartStep) {
      setCurrentStep(Number(cartStep));
      handleCartSummary();
    }
  }, [cartStep]);

  const handleCartSummary = async () => {
    try {
      setCartDataLoading(true);
      const data = await getCartSummary();
      setAmount({
        subTotal: data?.sub_total || 0,
        discount: data?.discount || '',
        total: data?.grand_total || 0,
        tax: data?.tax || 0,
        couponCode: data?.coupon_code || [],
        actualCartTotal: data?.product_regular_price || 0,
      });
    } catch (error: any) {
      // notify(message, 'error');
    } finally {
      setCartDataLoading(false);
    }
  };

  const removeCoupon = async (coupon_code: string) => {
    try {
      setRemoveLoading(true);
      await handleRemoveCoupon(coupon_code);
      await handleCartSummary();
    } catch (error: any) {
      const message = formateErrorObject(error);
      setCouponMessage({
        message: message,
        type: 'error',
      });
    } finally {
      setRemoveLoading(false);
    }
  };

  useEffect(() => {
    const fetchLoggedInStatus = async () => {
      const loggedInStatus = await appwriteService.isLoggedIn();
      setLoggedIn(loggedInStatus);
    };
    fetchLoggedInStatus();
    if (isTokenChanged) {
      setIsTokenChanged(false);
    }
  }, [appwriteService.account, isTokenChanged]);

  useEffect(() => {
    let timeoutId: any;

    if (couponMessage.message !== '') {
      timeoutId = setTimeout(() => {
        setCouponMessage({
          message: '',
          type: '',
        });
      }, 3000);
    }

    return () => clearTimeout(timeoutId);
  }, [couponMessage.message]);

  const handleAddCoupon = async (coupon_code: string) => {
    try {
      setCouponApply((prev) => ({
        ...prev,
        value: true,
      }));
      const message = await addCouponCode(coupon_code);
      setCouponApply((prev) => ({
        ...prev,
        isSuccess: true,
        value: false,
      }));
      setCouponMessage({
        message: message.message,
        type: 'success',
      });

      await handleCartSummary();
    } catch (error: any) {
      const message = formateErrorObject(error);
      setCouponMessage({
        message: message,
        type: 'error',
      });
    } finally {
      setCouponApply((prev) => ({
        ...prev,
        isSuccess: false,
        value: false,
      }));
    }
  };

  useEffect(() => {
    if (!hasRunOnce.current) {
      if (!(loggedIn && userData?.id && !synced)) {
        handleCartSummary();
      }
      hasRunOnce.current = true;
    }
  }, [products]);

  const items = [
    {
      title: 'Pick product',
    },
    {
      title: 'Your cart',
    },
    {
      title: 'Checkout',
    },
  ];

  const handleCartDetails = async () => {
    try {
      setLoading(true);
      const data = await getCartDetails(localUserId.toString());

      const formattedData = formatCartData(
        data?.data?.[0]?.cart_items,
        data?.cart_id,
      );
      setProducts(formattedData);
      setCartData(formattedData);
      if (
        router.isReady &&
        router.query.cartStep === '1' &&
        !formattedData.length
      ) {
        router.push('/my-orders');
      }

      // setCouponData()
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleCartDetailsWithoutLoading = async () => {
    try {
      const data = await getCartDetails(localUserId.toString());

      const formattedData = formatCartData(
        data?.data?.[0]?.cart_items,
        data?.cart_id,
      );
      setProducts(formattedData);
      setCartData(formattedData);
      if (
        router.isReady &&
        router.query.cartStep === '1' &&
        !formattedData.length
      ) {
        router.push('/my-orders');
      }

      // setCouponData()
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    }
  };

  useEffect(() => {
    if (!(loggedIn && userData?.id && !synced)) {
      handleCartDetails();
      // handleCartSummary();
    }
  }, [localUserId, cartStep, redirect_url]);

  // useEffect(() => {
  //   const existingCart = JSON.parse(localStorage.getItem('cart') || '[]');
  //   setProducts(existingCart);
  // }, []);

  useEffect(() => {
    if (cartData) {
      setProducts(cartData);
    }
  }, [cartData]);

  useEffect(() => {
    if (
      router.asPath === '/cart/' &&
      typeof router.query.cartStep === 'undefined'
    ) {
      setCurrentStep(0);
    }
  }, [cartStep]);

  useEffect(() => {
    if (currentStep === 0) {
      trackViewCartEvent({
        currency: 'INR',
        items: cartData.map((item) => {
          return {
            item_id: item.id.toString(),
            item_name: item.name,
            coupon: '',
            discount: item.actualPrice - item.price,
            price: item.price,
            quantity: item.quantity || 1,
          };
        }),
        value: amount?.total,
      });
    }
    setRandomNumber(getRandomNumber(4, 50));
  }, []);

  useEffect(() => {
    if (loginCollapseTrigger) {
      setLoginCollapseTrigger(false);
    }
  }, [loginCollapseTrigger]);

  const handleSyncUser = async () => {
    try {
      await syncCart((userData?.id || '')?.toString());
    } catch (error) {
      console.error('Error fetching ', error);
    }
  };

  useEffect(() => {
    if (loggedIn && userData?.id && !synced) {
      handleSyncUser();
      setSynced(true);
    }
  }, [loggedIn, userData, synced]);

  const handleApplyCoupon = async (coupon: string) => {
    try {
      await addCouponCode(coupon as string);
    } catch (error: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  const processCartItems = async (coupon: string) => {
    try {
      if (cp) {
        await handleApplyCoupon(coupon as string);
        await handleCartSummary();
        const { cp, ...otherQueryParams } = router.query;
        router.replace(
          {
            pathname: router.pathname,
            query: otherQueryParams,
          },
          undefined,
          { shallow: true },
        );
      }
    } catch (error: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  useEffect(() => {
    if (router.isReady) {
      processCartItems(cp as string);
    }
  }, [router.isReady, cp]);

  const handleGetAllBooks = async () => {
    try {
      setLoadingBook(true);
      const response = await getAllBookListApi({
        page: '1',
        ecomProductId: router?.query?.product_ids?.includes(',')
          ? (router?.query?.product_ids as string)?.split(',')[0]
          : (router?.query?.product_ids as string),
        limit: '10',
      });
      const { data } = response;

      const formattedBooks = data?.map((book: any) => ({
        _id: book?._id,
        coverImageUrl: generateUrlForImage(
          `${process.env.NEXT_PUBLIC_API_READER_URL}/`,
          `${Endpoints.getCoverImagesForBooks}/`,
          `${book?._id}`,
        ),
        title: book?.title,
        externalLink: book?.externalLink,
        slug: book?.slug,
        type: book?.type,
        isExternalLink: book?.isExternalLink,
        totalPages: book?.totalPages,
      }));

      setBooks(formattedBooks);
    } catch (error: any) {
      notify(error, 'error');
    } finally {
      setLoadingBook(false);
    }
  };

  useEffect(() => {
    if (currentStep === 2) {
      handleGetAllBooks();
    }
  }, [currentStep]);

  return (
    <div className=' '>
      <div className='relative overflow-hidden '>
        <div className='flex flex-col pt-2 md:pt-0 md:gap-y-2 lg:gap-y-5'>
          <ShouldRender check={currentStep !== 2}>
            <div
              onClick={() => {
                if (currentStep === 1) {
                  setCurrentStep(0);
                  router.push({
                    pathname: router.pathname,
                    query: { cartStep: 0 },
                  });
                }

                currentStep == 0 && router.push('/product');
              }}
              className='flex items-center px-2 cursor-pointer'
            >
              <ChevronLeftIcon className='w-5 h-5' />{' '}
              <p className='text-lg hover:underline'>Back</p>
            </div>
          </ShouldRender>
          <ShouldRender check={products.length}>
            <span className='lg:hidden flex flex-col '>
              <div
                className={clsx(
                  'bg-cartModalFormBg justify-center text-fadedWhite  mt-5 md:mt-7  py-3 md:py-5',
                )}
              >
                <Steps
                  current={currentStep + 1}
                  labelPlacement='vertical'
                  items={items}
                  direction='horizontal'
                  className='stepTheme'
                  responsive={false}
                />
              </div>
            </span>
          </ShouldRender>

          <ShouldRender check={currentStep === 1 && !token}>
            <ShouldRender check={currentStep === 1}>
              <div className='flex flex-col px-4 md:pl-10 md:pr-8 lg:pr-0   lg:mt-10'>
                <span className='pb-0'>
                  <LoginCollapse />
                </span>

                <div
                  onClick={() => router.back()}
                  className={clsx(
                    'text-base py-2  text-[#787878]  bg-transparent border-none pl-0  hover:text-white cursor-pointer font-medium  rounded-lg flex items-center gap-2',
                  )}
                >
                  <p>
                    <ChevronLeftIcon className='w-5 h-5' />
                  </p>{' '}
                  <p>Back</p>
                </div>
              </div>
            </ShouldRender>
          </ShouldRender>

          <div className={clsx('lg:grid-cols-3', 'grid w-full  relative')}>
            <div
              className={clsx(
                currentStep === 0 && 'lg:pt-5 lg:pr-5',
                currentStep !== 2 &&
                  (products.length ? 'col-span-2' : 'col-span-2'),
                currentStep === 2 && 'col-span-2',
                'lg:border-r-2 border-borderDarkGray ',
              )}
            >
              {/* <ShouldRender check={currentStep === 0}>
                <Link href='/'>
                  <div
                    className={clsx(
                      'text-base  text-[#787878] bg-transparent border-none   ml-4 pl-0 md:px-4 hover:text-white cursor-pointer font-medium  rounded-lg flex items-center gap-2'
                    )}
                  >
                    <p>
                      <ChevronLeftIcon className='w-5 h-5' />
                    </p>{' '}
                    <p>Back</p>
                  </div>
                </Link>
              </ShouldRender> */}

              <ShouldRender check={currentStep === 1 && token}>
                <div
                  onClick={() => router.push('/cart')}
                  className={clsx(
                    token ? 'pt-4 lg:mt-5' : 'pb-4 mt-4 md:mt-5 lg:mt-10 ',
                    'text-base  text-[#787878]     ml-4 bg-transparent border-none pl-0 md:px-4 hover:text-white cursor-pointer font-medium  rounded-lg flex items-center gap-2',
                  )}
                >
                  <p>
                    <ChevronLeftIcon className='w-5 h-5' />
                  </p>{' '}
                  <p>Back</p>
                </div>
              </ShouldRender>

              <ShouldRender check={currentStep === 0}>
                <motion.div
                  variants={right}
                  initial='hidden'
                  className=' p-4 flex flex-col gap-y-4 '
                  animate={currentStep === 0 ? 'visible' : 'hidden'}
                >
                  <div>
                    {/* <ShouldRender check={!loading}> */}
                    <span className='flex flex-col gap-y-4 lg:pl-5 lg:pt-4'>
                      <ShouldRender check={!screens.md && products.length}>
                        <ShouldRender
                          check={
                            !cartData?.every((item) => item.type === 'digital')
                          }
                        >
                          <div className='bg-[#2C2302] p-2 md:p-3  rounded-lg '>
                            <span className='font-bold text-xs md:text-sm p-1 md:tracking-wider text-white gap-x-1'>
                              Shipping cost:
                              <span className='inline-flex items-baseline '>
                                <b className='line-through px-1 font-normal'>
                                  ₹199
                                </b>
                                <b className='text-[#FFC700] font-normal'>
                                  Free Shipping
                                </b>
                              </span>
                            </span>
                          </div>
                        </ShouldRender>
                      </ShouldRender>
                      <ShouldRender check={!loading}>
                        <AnimatePresence>
                          {products.map((product) => (
                            <motion.div
                              key={product.id}
                              initial={{ opacity: 0, x: -50 }}
                              animate={{ opacity: 1, x: 0 }}
                              exit={{ opacity: 0, x: 50 }}
                            >
                              <CartCard
                                data={product}
                                amount={amount}
                                setAmount={setAmount}
                                handleRemoveProduct={handleRemoveProduct}
                                handleQuantityChange={handleQuantityChange}
                                cartDataLoading={cartDataLoading}
                                handleCartDetails={
                                  handleCartDetailsWithoutLoading
                                }
                              />
                            </motion.div>
                          ))}
                        </AnimatePresence>
                      </ShouldRender>
                    </span>
                    {/* </ShouldRender> */}

                    <ShouldRender check={!products?.length && !loading}>
                      <div className='flex justify-center py-20'>
                        <Empty description='No Orders Found!!' />
                      </div>
                    </ShouldRender>
                    <ShouldRender
                      check={!loading && products?.length === 1 && randomNumber}
                    >
                      <div className='lg:pr-5'>
                        <div className='text-xs sm:text-sm w-full lg:ml-5  inline-flex md:items-start mt-4 gap-x-2  p-2 md:p-4 cardShadow rounded-lg items-center bg-cartModalFormBg text-fadedWhite'>
                          <ShoppingCartCartIcon
                            width={20}
                            height={20}
                            fill=''
                          />
                          <span className=''>
                            In demand:{' '}
                            <b className='text-[#FF1D26] px-1'>
                              {randomNumber} shoppers
                            </b>
                            have it in their carts.
                          </span>
                        </div>
                      </div>
                    </ShouldRender>
                    <ShouldRender check={!loading}>
                      <div className='flex justify-center pt-6'>
                        <Link href='/product'>
                          <Button
                            onClick={() => setProducts([])}
                            className='cursor-pointer text-xs md:text-sm border leading-10 border-grayButtonBg rounded-xl text-fadedWhite  p-4 flex justify-center items-center'
                          >
                            ADD MORE PRODUCTS
                          </Button>
                        </Link>
                      </div>
                    </ShouldRender>
                    <ShouldRender check={token && !cartData.length && !loading}>
                      <div className='flex justify-center pt-4'>
                        <Link href='/my-orders/'>
                          <Button
                            type='link'
                            ghost
                            className='cursor-pointer text-sm border-none hover:underline leading-10 border-grayButtonBg rounded-xl text-fadedWhite  p-4 flex justify-center items-center'
                          >
                            My Orders
                          </Button>
                        </Link>
                      </div>
                    </ShouldRender>
                  </div>
                </motion.div>
              </ShouldRender>
              {/* <ShouldRender check={loading}>
                <div className='flex justify-center  py-20'>
                  <CustomLoader />
                </div>
              </ShouldRender> */}
              <ShouldRender check={currentStep === 1}>
                <motion.div
                  variants={right}
                  initial='hidden'
                  className=' px-4 flex flex-col gap-y-4'
                  animate={currentStep === 1 ? 'visible' : 'hidden'}
                >
                  <div>
                    <CartContactForm
                      setCurrentStep={setCurrentStep}
                      amount={amount}
                      token={token}
                      setProduct={() => setProducts([])}
                      setOrderLoading={setOrderLoading}
                      orderLoading={orderLoading}
                    />
                  </div>
                </motion.div>
              </ShouldRender>
              <ShouldRender check={currentStep === 1}>
                <span className='lg:hidden flex pb-5 md:pb-0'>
                  <Step_2_OrderCard
                    data={products}
                    amount={amount}
                    total={amount.total}
                    setCurrentStep={setCurrentStep}
                    handleAddCoupon={handleAddCoupon}
                    removeCoupon={removeCoupon}
                    removeLoading={removeLoading}
                    couponMessage={couponMessage}
                    orderLoading={orderLoading}
                    loading={cartDataLoading}
                    couponApplyLoading={couponApply}
                  />
                </span>
              </ShouldRender>
              <ShouldRender check={currentStep === 2}>
                <motion.div
                  variants={right}
                  initial='hidden'
                  className=' flex flex-col gap-y-4 h-full'
                  animate={currentStep === 2 ? 'visible' : 'hidden'}
                >
                  <div className='h-full'>
                    <PaymentSuccessful
                      data={products}
                      total={amount.total}
                      books={books}
                      loadingBook={loadingBook}
                    />
                  </div>
                  <div className='flex lg:hidden'>
                    <ShouldRender check={currentStep === 2}>
                      <Step_3_orderCard amount={amount} />
                    </ShouldRender>
                  </div>
                </motion.div>
              </ShouldRender>
            </div>

            <div
              className={`${
                currentStep !== 2 ? '  lg:min-h-[93vh] ' : 'lg:min-h-[100vh] '
              }  pt-5 lg:pt-10 col-span-1 border-borderDarkGray `}
            >
              <div
                className={clsx(
                  currentStep === 2 && 'pt-5 lg:pt-10',
                  'flex flex-col h-full',
                )}
              >
                <ShouldRender check={products.length || currentStep === 2}>
                  <span className='hidden lg:flex text-fadedWhite border-b-2 border-borderDarkGray pb-5 pr-8 '>
                    <Steps
                      current={currentStep + 1}
                      labelPlacement='vertical'
                      items={items}
                      className='stepTheme '
                    />
                  </span>
                </ShouldRender>

                <motion.div
                  variants={cardVariants}
                  initial='hidden'
                  className={
                    currentStep === 0
                      ? 'w-full px-5  hidden lg:flex flex-col h-full'
                      : ''
                  }
                  animate={currentStep === 0 ? 'visible' : 'hidden'}
                >
                  <ShouldRender check={currentStep === 0 && products.length}>
                    {/* <Affix offsetTop={40}> */}
                    <Step_1_OrderCard
                      amount={amount}
                      removeCoupon={removeCoupon}
                      removeLoading={removeLoading}
                      setCurrentStep={setCurrentStep}
                      couponMessage={couponMessage}
                      handleAddCoupon={handleAddCoupon}
                      loading={cartDataLoading}
                      couponApplyLoading={couponApply}
                    />
                    {/* </Affix> */}
                  </ShouldRender>
                </motion.div>
                <motion.div
                  variants={cardVariants}
                  initial='hidden'
                  className='hidden lg:flex px-5 lg:px-0  '
                  animate={currentStep === 1 ? 'visible' : 'hidden'}
                >
                  <ShouldRender check={currentStep === 1}>
                    <div className=' w-full'>
                      {/* <Affix offsetTop={40}> */}
                      <Step_2_OrderCard
                        data={products}
                        amount={amount}
                        total={amount.total}
                        setCurrentStep={setCurrentStep}
                        handleAddCoupon={handleAddCoupon}
                        removeCoupon={removeCoupon}
                        removeLoading={removeLoading}
                        couponMessage={couponMessage}
                        orderLoading={orderLoading}
                        loading={cartDataLoading}
                        couponApplyLoading={couponApply}
                      />
                      {/* </Affix> */}
                    </div>
                  </ShouldRender>
                </motion.div>
                <motion.div
                  variants={cardVariants}
                  initial='hidden'
                  className='hidden lg:flex justify-center w-full px-5 xl:px-0 '
                  animate={currentStep === 2 ? 'visible' : 'hidden'}
                >
                  <ShouldRender check={currentStep === 2}>
                    <Step_3_orderCard amount={amount} />
                  </ShouldRender>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        {/* <ShouldRender check={currentStep === 0}>
          <div className='flex flex-col gap-y-5 pl-5 md:pl-10 lg:pl-20 xl:pl-24 md:gap-y-10 lg:p-2'>
            <span className='text-3xl'>You may also like</span>
            <CardSliderType_2 lessPadding={true}>
              {CardListData.map((item, index) => (
                <CartScrollCard data={item} key={index} />
              ))}
            </CardSliderType_2>
          </div>
        </ShouldRender> */}
        <motion.div
          variants={screens.lg ? cardVariants : undefined}
          initial='hidden'
          className='w-full px-5 xl:px-0 lg:hidden flex flex-col'
          animate={currentStep === 0 ? 'visible' : 'hidden'}
        >
          <ShouldRender
            check={currentStep === 0 && !loading && products.length}
          >
            <Step_1_OrderCard
              amount={amount}
              setCurrentStep={setCurrentStep}
              handleAddCoupon={handleAddCoupon}
              removeCoupon={removeCoupon}
              removeLoading={removeLoading}
              couponMessage={couponMessage}
              loading={cartDataLoading}
              couponApplyLoading={couponApply}
            />
          </ShouldRender>
        </motion.div>
      </div>
    </div>
  );
};

export default CartPage;
