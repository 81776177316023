import { AxiosInstance } from 'axios';

import createApiClient, { ApiClientOptions } from './apiClientFactory';

class ApiClientManager {
  private static instance: ApiClientManager;
  private clients: Record<string, AxiosInstance>;

  private constructor() {
    this.clients = {};
  }

  public static getInstance(): ApiClientManager {
    if (!ApiClientManager.instance) {
      ApiClientManager.instance = new ApiClientManager();
    }
    return ApiClientManager.instance;
  }

  public getClient(name: string, options: ApiClientOptions): AxiosInstance {
    if (!this.clients[name]) {
      this.clients[name] = createApiClient(options);
    }
    return this.clients[name];
  }
}

const apiClientManager = ApiClientManager.getInstance();

export const apiClient = apiClientManager.getClient('apiClient', {
  baseURL: process.env.NEXT_PUBLIC_API_URL as string,
  timeout: 300000,
});

export const apiClientForCourses = apiClientManager.getClient(
  'apiClientForCourses',
  {
    baseURL: process.env.NEXT_PUBLIC_API_LMS_URL as string,
    timeout: 30000,
    isSchool: false,
    cookieName: 'auth_token',
    useBearer: false,
  },
);

export const baseForPdf = apiClientManager.getClient('baseForPdf', {
  baseURL: process.env.NEXT_PUBLIC_API_READER_URL as string,
  timeout: 30000,
});

export const baseForAskAi = apiClientManager.getClient('baseForAskAi', {
  baseURL: process.env.NEXT_PUBLIC_AI_CHAT as string,
  timeout: 30000,
});

export const courseRedirectToken = apiClientManager.getClient(
  'courseRedirectToken',
  {
    baseURL: process.env.NEXT_PUBLIC_API_READER_URL as string,
    timeout: 30000,
    isSchool: false,
    cookieName: 'courseRedirectCookieToken',
    useBearer: false,
  },
);

export const notificationBase = apiClientManager.getClient('notificationBase', {
  baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL as string,
  timeout: 30000,
});

export const schoolApiClient = apiClientManager.getClient('schoolApiClient', {
  baseURL: process.env.NEXT_PUBLIC_API_URL_SCHOOLS as string,
  timeout: 30000,
  isSchool: true,
});

export const activityLogsApiClient = apiClientManager.getClient(
  'activityLogsApiClient',
  {
    baseURL: process.env.NEXT_PUBLIC_BASE_URL_ACTIVITY_LOGS as string,
    timeout: 30000,
    referer: 'zebralearn.com',
  },
);
