import clsx from 'clsx';

import HeadingPointerIcon from '../../../../../assets/Icons/HeadingPointerIcon';

interface ContentProps {
  heading: string;
  isReview?: boolean;
}

const Headings: React.FC<ContentProps> = ({ heading, isReview = false }) => {
  return (
    <div className={clsx(isReview ? '' : 'mb-5', 'flex gap-x-3  items-center')}>
      <HeadingPointerIcon />
      <h2 className='font-bold text-lg uppercase'>{heading}</h2>
    </div>
  );
};

export default Headings;
