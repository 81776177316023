import React from 'react';

const AddImage: React.FC = () => {
  return (
    <svg
      width='52'
      height='52'
      viewBox='0 0 52 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.3333 5.41667C10.7519 5.41667 5.41667 10.7519 5.41667 17.3333V34.6667C5.41667 34.804 5.41899 34.9408 5.4236 35.077L13.942 30.8178C16.0769 29.7503 18.5898 29.7503 20.7247 30.8178L22.6822 31.7965C24.6071 32.7589 26.9191 32.4907 28.5723 31.1129L33.7465 26.8012C36.7595 24.2903 41.1901 24.4914 43.9634 27.2647L46.5833 29.8846V24.9167C46.5833 24.3185 47.0685 23.8333 47.6667 23.8333C48.2649 23.8333 48.75 24.3185 48.75 24.9167V34.6667C48.75 42.4448 42.4448 48.75 34.6667 48.75H17.3333C9.55533 48.75 3.25 42.4448 3.25 34.6667V17.3333C3.25 9.55533 9.55533 3.25 17.3333 3.25H31.4167C32.0149 3.25 32.5 3.73503 32.5 4.33333C32.5 4.93164 32.0149 5.41667 31.4167 5.41667H17.3333ZM17.3333 46.5833C11.675 46.5833 6.9378 42.6398 5.72024 37.3512L14.9109 32.7557C16.4359 31.9932 18.2308 31.9932 19.7557 32.7557L21.7132 33.7346C24.4079 35.0818 27.6447 34.7061 29.9594 32.7773L35.1334 28.4657C37.2857 26.6721 40.4504 26.8158 42.4314 28.7967L46.5833 32.9487V34.6667C46.5833 41.2481 41.2481 46.5833 34.6667 46.5833H17.3333Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.8333 16.2487C23.8333 19.2402 21.4082 21.6654 18.4167 21.6654C15.4251 21.6654 13 19.2402 13 16.2487C13 13.2572 15.4251 10.832 18.4167 10.832C21.4082 10.832 23.8333 13.2572 23.8333 16.2487ZM21.6667 16.2487C21.6667 18.0436 20.2116 19.4987 18.4167 19.4987C16.6217 19.4987 15.1667 18.0436 15.1667 16.2487C15.1667 14.4538 16.6217 12.9987 18.4167 12.9987C20.2116 12.9987 21.6667 14.4538 21.6667 16.2487Z'
        fill='currentColor'
      />
      <path
        d='M41.1667 5.41797C41.7649 5.41797 42.25 5.903 42.25 6.5013V9.78547L45.5067 9.80603C46.105 9.8098 46.587 10.2979 46.5831 10.8962C46.5795 11.4945 46.0913 11.9764 45.4931 11.9727L42.25 11.9522V15.168C42.25 15.7663 41.7649 16.2513 41.1667 16.2513C40.5685 16.2513 40.0834 15.7663 40.0834 15.168V11.9385L36.8264 11.9179C36.2282 11.9142 35.7463 11.4261 35.75 10.8278C35.7539 10.2295 36.2419 9.74753 36.8403 9.75132L40.0834 9.7718V6.5013C40.0834 5.903 40.5685 5.41797 41.1667 5.41797Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default AddImage;
