import { atom } from 'recoil';

import { CartItem } from '../../utils/interfaces/cart';

export const cartAtom = atom<CartItem[]>({
  key: 'cartAtom',
  default: [],
});

export const checkCouponAtom = atom<any>({
  key: 'checkCouponAtom',
  default: [],
});

export const orderDetails = atom<{ orderId: string; transactionId: string }>({
  key: 'orderDetails',
  default: { orderId: '', transactionId: '' },
});
