import { apiClient, notificationBase } from '../apiClients';
import { Endpoints } from '../../network';

interface IFirebaseTokenBody {
  user_id: string;
  token: string;
  device: string;
  device_id: string;
  zl_uid?: string;
  identifier?: string;
}

export const setFirebaseToken = async (body: IFirebaseTokenBody) => {
  try {
    const response = await apiClient.post(`${Endpoints.fireBaseTokens}`, {
      ...body,
    });

    if (
      ['staging', 'production'].includes(
        process.env.NEXT_PUBLIC_APP_ENV as string,
      )
    ) {
      await notificationBase.post(`${Endpoints.firebaseTokenNotification}`, {
        zl_uid: body?.zl_uid,
        name: 'readers',
        user_id: body?.user_id,
        provider_id: 2,
        provider_type: 'push',
        identifier: body?.token,
        original_id: body?.device_id,
      });
    }

    return response?.data?.data;
  } catch (error) {
    console.error('Error setting Firebase token:', error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
};

export const deleteFirebaseToken = async (id: string) => {
  const response = apiClient.delete(`${Endpoints.fireBaseTokens}/${id}`);

  return response;
};
const getFCMToken = async (): Promise<string | null> => {
  const dbName = 'firebase-installations-database';

  try {
    const dbRequest: IDBOpenDBRequest = indexedDB.open(dbName);

    return await new Promise<string | null>((resolve, reject) => {
      dbRequest.onsuccess = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        const storeNames = Array.from(db.objectStoreNames);

        if (storeNames.length === 0) {
          resolve(null);
          return;
        }

        const transaction = db.transaction(storeNames, 'readonly');
        const storeName = storeNames[0];
        const store = transaction.objectStore(storeName as string);
        const getAllRequest = store.getAll();

        getAllRequest.onsuccess = (event) => {
          const data = (event.target as IDBRequest).result;
          if (data.length > 0 && data[0].authToken && data[0].authToken.token) {
            resolve(data[0].authToken.token);
          } else {
            resolve(null);
          }
        };

        getAllRequest.onerror = (event) => {
          reject((event.target as IDBRequest).error);
        };

        transaction.onerror = (event) => {
          reject((event.target as IDBTransaction).error);
        };
      };

      dbRequest.onerror = (event) => {
        reject((event.target as IDBOpenDBRequest).error);
      };
    });
  } catch (error) {
    return null;
  }
};

export const deleteFirebaseTokenNotification = async () => {
  const token = await getFCMToken();
  if (token) {
    await notificationBase.post(`${Endpoints.deleteFireBaseToken}`, {
      identifier: token,
    });

    return;
  }
};
