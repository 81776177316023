import { apiClient } from './apiClients';
import { Endpoints } from '../network';

export const getUserProfile = async () => {
  const response = await apiClient.get(Endpoints.getUser);

  return response?.data;
};

export const updateProfile = async (data: {
  name?: string;
  email?: string;
  phone?: string;
  password?: string;
}) => {
  const response = await apiClient.post(`${Endpoints.updateProfile}`, data);

  return response?.data;
};

export const uploadProfileImage = async (image: File) => {
  const formData = new FormData();
  formData.append('image', image);

  try {
    const response = await apiClient.post(
      Endpoints.updateProfileImage,
      formData,
    );
    return response?.data;
  } catch (error) {
    console.error('Error uploading profile image:', error);
    throw error;
  }
};
