import { CheckIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { Button } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import Headings from '../landingPage/heading';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import CODIcon from '../../../../assets/Icons/CODIcon';
import TabIcon from '../../../../assets/Icons/TabIcon';

interface BookDescriptionProps {
  description: string[];
  whatYouWillLearn: string[];
}

const BookDescription: React.FC<BookDescriptionProps> = ({
  description,
  whatYouWillLearn,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState('10rem');
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const screens = useBreakpoint();

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      setMaxHeight(isExpanded ? `${contentHeight}px` : '10rem');

      // Check if the content height exceeds the threshold
      if (contentHeight > parseFloat('10rem') * 16) {
        setIsOverflowing(true); // Show button if content is taller than 10rem
      } else {
        setIsOverflowing(false); // Hide button otherwise
      }
    }
  }, [isExpanded]);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div>
      <div className='flex flex-col'>
        <ShouldRender check={whatYouWillLearn?.length}>
          <Headings heading='What You’ll Learn' />
          <div
            style={{
              border: '1px solid #FFFFFF24',
              background: screens.md
                ? 'radial-gradient(67.49% 179.15% at 50% 50.31%, #0F0F0F 0%, #2D2D2D 100%)'
                : '#181818',
            }}
            className='grid md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-x-10 gap-y-4 md:gap-y-6 px-4 md:px-8 py-6 rounded-lg'
          >
            {whatYouWillLearn?.map((topic, index) => (
              <div key={index} className='flex gap-x-3.5 items-center'>
                <span className='bg-[#545454] p-1.5 rounded-full'>
                  <CheckIcon className='md:h-5 h-4 w-4 md:w-5 text-white' />
                </span>
                <span className='text-xs md:text-sm text-[#DDDDDD]'>
                  {topic}
                </span>
              </div>
            ))}
          </div>
        </ShouldRender>
      </div>
      <div className='flex flex-col mt-[56px]'>
        <Headings heading='Description' />
        <div
          className='relative overflow-hidden transition-all duration-500'
          style={{ maxHeight }}
        >
          <div ref={contentRef} className='text-sm flex flex-col gap-y-4'>
            {description?.map((item, index) => <p key={index}>{item}</p>)}
          </div>
          {!isExpanded && isOverflowing && (
            <div className='absolute bottom-0 w-full h-12 bg-gradient-to-t from-black to-transparent'></div>
          )}
        </div>

        {/* Only show the toggle button if content is overflowing */}
        {isOverflowing && (
          <div
            onClick={toggleExpand}
            className='flex justify-start cursor-pointer'
          >
            <Button type='link' className='text-white text-sm font-semibold'>
              {isExpanded ? 'Show Less' : 'Show More'}
            </Button>
            <ChevronUpIcon
              className={clsx(!isExpanded && 'rotate-180', 'w-4 mt-1')}
            />
          </div>
        )}
      </div>
      <div className='flex gap-x-2 md:gap-x-5 lg:gap-x-10 mt-12'>
        <div className='flex gap-x-2 md:gap-x-4 items-center'>
          <span className='bg-cartBgColor p-2 rounded-full md:w-12 w-10 h-10 md:h-12 flex items-center justify-center'>
            <TabIcon />
          </span>
          <span className='flex flex-col '>
            <p className='text-xs font-medium uppercase'> Free Digital Copy</p>
            <p className='text-xs text-[#AEAEAE]'>ON HARD COPY</p>
          </span>
        </div>
        <div className='flex gap-x-2 md:gap-x-4 items-center'>
          <span className='bg-cartBgColor p-2 rounded-full md:w-12 w-10 h-10 md:h-12 flex items-center justify-center'>
            <CODIcon />
          </span>
          <span className='flex flex-col uppercase'>
            <p className='text-xs font-medium '>COD Available</p>
            <p className='text-xs text-[#AEAEAE]'>on hard copy</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default BookDescription;
