import {
  ArrowRightIcon,
  CheckCircleIcon,
  CheckIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import { Button, Divider, Input, Skeleton } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import confetti from 'canvas-confetti';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import ButtonDefault, { ButtonVariants, Spinner } from '../shared/basic/button';
import { ShouldRender } from '../shared/basic/ShouldRender';
import { trackBeginCheckoutCartEvent } from '../shared/Gtags';
import { parseFormattedNumber } from '../shared/parseFormattedNumber';
import { cartAtom } from '../../atoms/Cart';
import { user } from '../../atoms/user';
import { User } from '../../utils/interfaces/User';

interface Props {
  amount: {
    subTotal: number;
    discount: string;
    total: number;
    tax: number;
    actualCartTotal: number;
    couponCode: { coupon_code: string }[];
  };
  setCurrentStep: (value: number) => void;
  handleAddCoupon: (value: string) => void;
  removeCoupon: (value: string) => void;
  removeLoading: boolean;
  couponMessage: { message: string; type: string };
  loading: boolean;
  couponApplyLoading: { value: boolean; isSuccess: boolean };
}

export const showLoading = (value: any, loading: boolean) => {
  if (loading) {
    return (
      <>
        <Skeleton.Input
          active
          size='small'
          style={{ width: '60%' }}
          className='h-5'
        />
      </>
    );
  } else {
    return <>{value}</>;
  }
};

const Step_1_OrderCard: React.FC<Props> = ({
  amount,
  setCurrentStep,
  handleAddCoupon,
  couponMessage,
  removeCoupon,
  removeLoading,
  loading,
  couponApplyLoading,
}) => {
  const [couponCode, setCouponCode] = useState('');
  const [showCheckIcon, setShowCheckIcon] = useState(false);
  const cartData = useRecoilValue(cartAtom);
  const screens = useBreakpoint();
  const userData: User | undefined = useRecoilValue(user);

  const ShippingPrice = 199;
  const router = useRouter();

  // useEffect(() => {
  //   if (isVisible) {
  //     const timer = setTimeout(() => {
  //       setIsVisible(false);
  //     }, 3000);

  //     return () => clearTimeout(timer);
  //   } else {
  //     return () => null;
  //   }
  // }, [isVisible]);

  // const handleAddCoupon = () => {
  //   // Your logic for handling the coupon code goes here
  //   console.log('Coupon code applied:', couponCode);
  //   // You can call the function with the coupon code or perform any other desired action
  // };

  const handleScrollToBottom = () => {
    if (typeof window !== undefined) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const parseNumber = (str: string) => parseFloat(str?.replace(/,/g, ''));

  const totalNumber = parseNumber(amount.total?.toString());

  const savings = -(totalNumber - amount.actualCartTotal);
  const savingsPercentage = isFinite((savings / amount.actualCartTotal) * 100)
    ? Math.round((savings / amount.actualCartTotal) * 100)
    : 0;

  const handleClick = () => {
    if (handleAddCoupon) {
      handleAddCoupon(couponCode);
      setCouponCode('');
    }
  };

  useEffect(() => {
    if (couponMessage.type === 'success') {
      setShowCheckIcon(true);
      const confettiOrigin = screens.lg
        ? { x: 0.8, y: 0.5 }
        : { x: 0.5, y: 0.8 };

      confetti({
        particleCount: 30,
        spread: 70,
        origin: confettiOrigin,
        colors: ['#ffffff', '#FFD700', '#FF69B4', '#87CEEB', '#32CD32'],
      });

      const timer = setTimeout(() => {
        setShowCheckIcon(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
    return undefined;
  }, [couponMessage.type, screens.lg]);

  return (
    <>
      <div className='flex flex-col h-full lg:h-auto w-full'>
        <div className='w-full lg:pt-7 lg:px-0'>
          {/* <span className='hidden lg:flex flex-col '>
          <ButtonDefault
            onClick={() => {
              setCurrentStep(1);
              router.push({
                pathname: router.pathname,
                query: { cartStep: 1 },
              });
              trackBeginCheckoutCartEvent({
                coupon:
                  amount?.couponCode
                    .map((coupon) => coupon.coupon_code)
                    .join(', ') || '',
                discount: amount?.discount,
                heading: '',
                id: 0,
                price: amount?.total.toString(),
                quantity: cartData?.length,
              });
            }}
            size={3}
            variant={ButtonVariants.PRIMARY}
            className='w-full text-center text-xs p-1'
          >
            PLACE ORDER
          </ButtonDefault>
          <div className='flex justify-center items-center h-10 w-full'>
            <div className='w-full flex justify-center'>
              <Image src={Payment} alt='Payment' />
            </div>
          </div>
        </span> */}

          <div>
            <Input
              className='md:border-[2px] text-sm placeholder:text-xs placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray customInputBlack py-1 text-textDarkGrayColor bg-bgColor'
              suffix={
                <div
                  className={clsx(
                    couponMessage.type === 'error' && 'shaking-animation',
                  )}
                >
                  <div className='relative'>
                    <Button
                      onClick={handleClick}
                      className={clsx(
                        !couponApplyLoading.value && 'translate-x-[6px]',
                        'text-white tracking-wider font-medium border-none placeholder:text-[#6E6E6E] placeholder:font-medium hover:text-white bg-cartBgColor px-6 md:px-8 text-xs transition-all duration-300 ease-in-out',
                      )}
                    >
                      {couponApplyLoading.value ? (
                        <span className='transition-opacity duration-300 ease-in-out opacity-100 translate-x-1'>
                          <Spinner />
                        </span>
                      ) : (
                        <>
                          {showCheckIcon ? (
                            <CheckIcon className='text-white w-5 transition-transform duration-300 ease-in-out transform scale-100' />
                          ) : (
                            <span className='transition-opacity duration-300 ease-in-out opacity-100'>
                              APPLY
                            </span>
                          )}
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              }
              style={{
                background: '#000000',
              }}
              placeholder='Enter your coupon code'
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
          </div>
          <ShouldRender check={couponMessage.message}>
            <span
              className={`${
                couponMessage.type === 'error'
                  ? 'text-red-500'
                  : 'text-green-600 '
              } pt-2 text-sm w-[90%] flex items-center gap-x-1`}
            >
              <ShouldRender check={couponMessage.type === 'error'}>
                <ExclamationCircleIcon className='w-4 h-4 mt-1' />
              </ShouldRender>
              <ShouldRender check={couponMessage.type === 'success'}>
                <CheckCircleIcon className='w-4 h-4' />
              </ShouldRender>{' '}
              <span className={clsx(couponMessage.type === 'error' && 'pt-1')}>
                {couponMessage.message}
              </span>
            </span>
          </ShouldRender>
        </div>
        <ShouldRender check={screens.md}>
          <ShouldRender
            check={!cartData?.every((item) => item.type === 'digital')}
          >
            <div className='bg-[#2C2302] p-3 mt-4 xl:mt-6 rounded-lg '>
              <span className=' text-xs md:text-sm p-1 md:tracking-wider text-white gap-x-1'>
                Shipping cost:
                <span className='inline-flex items-baseline'>
                  <b className='line-through px-1 font-normal'>₹199</b>
                  <b className='text-[#FFC700] font-normal'>Free Shipping</b>
                </span>
              </span>
            </div>
          </ShouldRender>
        </ShouldRender>
        <div
          className={clsx(
            !screens.lg && 'mb-28',
            'bg-cartModalFormBg  rounded-lg mt-5 pt-5 md:pt-8 ',
          )}
        >
          {/* <span className='flex justify-between'>
          <p className='underline text-primary text-xs'>Use Z-coins</p>{' '}
          <p>
            <Checkbox />
          </p>
        </span> */}
          {/* <span className='flex justify-between'>
          <p className='underline text-primary text-xs'>
            Login to check/ collect Z-coins
          </p>{' '}
          <p>
            {' '}
            <InformationCircleIcon className='w-5 h-5 text-primary' />{' '}
          </p>
        </span> */}

          <div className='flex flex-col gap-y-3.5 md:gap-y-5   pb-5'>
            <div className='flex justify-between px-6 text-sm'>
              <span className='text-[#6E6E6E] font-semibold'>Cart Total</span>

              {showLoading(
                <>
                  <span className='font-semibold text-white text-base'>
                    ₹ {amount?.actualCartTotal}
                  </span>
                </>,
                loading,
              )}
            </div>

            <div className='mx-6  border-b border-dropDownMenuBg border-2 border-dashed'></div>
            <ShouldRender check={amount.discount !== '0.00' && !loading}>
              {amount?.couponCode
                ?.filter(
                  (coupon, index, self) =>
                    index ===
                    self.findIndex((c) => c.coupon_code === coupon.coupon_code),
                )
                .map((coupon) => (
                  <span
                    key={coupon.coupon_code}
                    className='flex justify-between px-6'
                  >
                    <span className='text-[#6E6E6E] text-sm font-semibold'>
                      {' '}
                      Coupon Applied ({coupon.coupon_code}){' '}
                    </span>

                    <ButtonDefault
                      size={1}
                      variant={ButtonVariants.UNSTYLED}
                      loading={removeLoading}
                      disabled={removeLoading}
                      onClick={() => {
                        if (removeCoupon) {
                          removeCoupon(coupon.coupon_code);
                        }
                      }}
                      className='font-medium text-red-500 hover:bg-bgColor hover:text-red-400'
                    >
                      Remove
                    </ButtonDefault>
                  </span>
                ))}
              <span className='text-[#6E6E6E] font-medium  px-6 flex justify-between'>
                Coupon Code Discount{' '}
                <p className='text-white font-semibold'>
                  - ₹ {amount.discount}
                </p>
              </span>
            </ShouldRender>
            {/* <span className='flex justify-between text-primary'>
          Z-coins discount <p className=''> 0</p>
        </span> */}
            {/* <ShouldRender check={ data?.type === 'hardcover' &&
                  data?.itemType?.toUpperCase() === 'BOOK'}> */}
            <ShouldRender
              check={!cartData?.every((item) => item.type === 'digital')}
            >
              <span className='text-[#6E6E6E] font-medium px-6 flex justify-between'>
                Shipping Charges{' '}
                {showLoading(
                  <>
                    <span className='text-white font-semibold xs:flex gap-x-2'>
                      <p className='line-through'>₹ {ShippingPrice}</p>{' '}
                      <p className='text-[#FFC700] uppercase'>Free</p>
                    </span>
                  </>,
                  loading,
                )}
              </span>
            </ShouldRender>

            {/* </ShouldRender> */}

            <div className='flex justify-between px-6 text-sm'>
              <span className='text-[#6E6E6E] font-semibold'>
                Total Discount
              </span>
              {showLoading(
                <>
                  <span className='font-semibold text-green-500 text-base'>
                    - ₹{' '}
                    {amount?.actualCartTotal -
                      parseFormattedNumber(amount?.total?.toString())}
                  </span>
                </>,
                loading,
              )}
            </div>
            {/* <div className='absolute bottom-0 w-full'> */}
            <Divider
              style={{
                margin: '4px 0',
                // borderColor: '',
                color: '#1C1C1C',
                border: '1px solid #1C1C1C',
              }}
              className='text-cartBgColor bg-cartBgColor'
            />
            <span className='flex justify-between font-medium px-6 text-white '>
              To Pay{' '}
              {showLoading(
                <>
                  <p className='text-lg font-semibold'>
                    ₹ {parseFormattedNumber(amount?.total?.toString())}
                  </p>
                </>,
                loading,
              )}
            </span>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          !screens.lg && 'fixed bottom-0 left-0',
          'w-full lg:pb-5',
        )}
      >
        {/* <div className='bg-bgColor bg-opacity-75 px-5 lg:px-0 py-4 backdrop-blur-sm flex'>
      
      </div> */}
        <div className='bg-bgColor border-t-[1px] lg:border-none border-[#2A2A2A] md:px-6 items-center translate-y-1 p-2 xs:p-4 lg:p-0  flex'>
          <ShouldRender check={!screens.lg}>
            <div className='w-full flex flex-col text-white no-select'>
              <ShouldRender check={savingsPercentage}>
                <span className='bg-[#002618] text-[#00A86B] flex items-end gap-x-1 font-semibold w-fit px-2 rounded-md py-1'>
                  <div className='flex items-center -translate-y-[4px]'>
                    <motion.div
                      animate={{
                        y: [0, -0.4, 0],
                      }}
                      transition={{
                        duration: 1.5,
                        repeat: Infinity,
                        repeatType: 'mirror',
                      }}
                    >
                      <ArrowRightIcon
                        className='w-2.5 rotate-90'
                        style={{ strokeWidth: '20px' }}
                      />
                    </motion.div>
                  </div>
                  <p className='text-[10px]'>{savingsPercentage}%</p>
                  <p className='uppercase text-[9px]'>saved</p>
                </span>
              </ShouldRender>

              <span className='flex items-end gap-x-2'>
                <p className='text-base font-semibold'>
                  ₹{Math.round(parseFormattedNumber(amount.total?.toString()))}
                </p>
                <ShouldRender check={amount.actualCartTotal}>
                  <p
                    className='line-through text-sm font-thin text-textGray'
                    style={{ textDecorationColor: '#7D7D7D' }}
                  >
                    ₹{amount.actualCartTotal}
                  </p>
                </ShouldRender>
              </span>
              <span
                onClick={handleScrollToBottom}
                className='text-[10px] xs:text-xs text-[#0055D6] font-medium hover:underline no-select'
              >
                View Price Details
              </span>
            </div>
          </ShouldRender>
          <div className='w-full  md:mt-2 py-1'>
            <ButtonDefault
              size={screens.md ? 4 : 1}
              rounded={false}
              id='PROCEED-TO-CHECKOUT'
              variant={ButtonVariants.WHITE}
              enableScaling={false}
              onClick={() => {
                setCurrentStep(1);
                router.push({
                  pathname: '/cart',
                  query: { cartStep: 1, cartId: cartData?.[0]?.cartId },
                });
                trackBeginCheckoutCartEvent({
                  checkoutData: {
                    currency: 'INR',
                    value: amount?.total || 0,
                    coupon:
                      amount?.couponCode
                        .map((coupon) => coupon.coupon_code)
                        .join(', ') || '',
                    items: cartData.map((item) => ({
                      item_id: item.id,
                      item_name: item.name,
                      item_brand: 'Zebralearn',
                      coupon: '',
                      discount: Number(amount?.discount) || 0,
                      price: item.price,
                      quantity: Number(item.quantity),
                    })),
                    cartId: cartData?.[0]?.cartId,
                  },
                  userData,
                  pathname: router.pathname,
                });
              }}
              shimmer={true}
              className='w-full text-center text-sm p-3 py-2 '
            >
              <span className='md:px-1  py-5 whitespace-nowrap'>
                PROCEED TO CHECKOUT
              </span>
            </ButtonDefault>
          </div>
        </div>
        {/* <div className='absolute bottom-28 right-0 w-max'> */}

        {/* </div> */}
      </div>
    </>
  );
};

export default Step_1_OrderCard;
