import { MenuOutlined } from '@ant-design/icons';
import { ChevronRightIcon, LogoutIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import {
  Badge,
  Button,
  ConfigProvider,
  Drawer,
  Input,
  InputRef,
  Space,
  ThemeConfig,
  Tooltip,
} from 'antd';
import { Avatar, Dropdown } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Cookies from 'js-cookie';
import mixpanel from 'mixpanel-browser';
import { Montserrat } from 'next/font/google';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import GetOurAppModal from '../GetAppComp';
import ButtonDefault, { ButtonVariants } from '../shared/basic/button';
import { ShouldRender } from '../shared/basic/ShouldRender';
import {
  formateErrorAppwriteObject,
  formateErrorObject,
} from '../shared/formateErrorObject';
import { getUserInitials } from '../shared/getInitials';
import { getUserName } from '../shared/getToken';
import { notify } from '../shared/notify';
import ForgotPasswordModal from '../V2/ForgotPasswordModal';
import LoginModal from '../V2/LoginModal';
import SignUpModal from '../V2/SignUpModal';
import VerifyEmailModal from '../VerifyEmailModal';
import { authModalControl, tokenUpdate } from '../../atoms/authModalControl';
import { chatCount } from '../../atoms/chatCount';
import { isBannerVisible } from '../../atoms/isBannerVisible';
import { localCart } from '../../atoms/LocalCart';
import { user } from '../../atoms/user';
import { verifyModal } from '../../atoms/verifyModal';
import appwriteService from '../../config/appwrite';
import { useAuth } from '../../hooks/useAuth';
import { EventDetails, logEvent } from '../../hooks/useClickEventTracking';
import { getUserProfile } from '../../services/user';
import { User } from '../../utils/interfaces/User';
import {
  LogoWithoutSquare,
  PreviewSchool,
  ZebraLearnLogo,
} from '../../../assets';
import AppleLoginSVG from '../../../assets/Icons/AppleLoginSVG';
import BusinessHeader from '../../../assets/Icons/BusinessHeader';
import ChatIcon from '../../../assets/Icons/ChatIcon';
import InfoCircleIcon from '../../../assets/Icons/InfoCircle';
import KeyIcon from '../../../assets/Icons/Key';
import LibraryIcon from '../../../assets/Icons/LibraryIcon';
import BookIcon from '../../../assets/Icons/NewHeaderIcons/BookIcon';
import CoinHeader from '../../../assets/Icons/NewHeaderIcons/CoinHeader';
import ContactUs from '../../../assets/Icons/NewHeaderIcons/ContactUs';
import CourseIcon from '../../../assets/Icons/NewHeaderIcons/CourseIcon';
import GooglePlay from '../../../assets/Icons/NewHeaderIcons/GooglePlay';
import HomeIcon from '../../../assets/Icons/NewHeaderIcons/HomeIcon';
import LiveIcon from '../../../assets/Icons/NewHeaderIcons/LiveIcon';
import SchoolIcon from '../../../assets/Icons/NewHeaderIcons/SchoolIcon';
import SearchIcon from '../../../assets/Icons/NewHeaderIcons/SearchIcon';
import PencilEdit from '../../../assets/Icons/PencilEdit';
import ProfileIcons from '../../../assets/Icons/ProfileIcons';
import ReceiptItem from '../../../assets/Icons/receipt-item';
import RewardIcon from '../../../assets/Icons/RewardIcon';
import SearchIconSVG from '../../../assets/Icons/SearchIconSVG';
import ShoppingCart from '../../../assets/Icons/shopping-cart';
import VerifiedIcon from '../../../assets/Icons/verifiedIcon';

interface HeaderLinkProps {
  text: string | ReactNode;
  route?: string;
  setOpen: (value: boolean) => void;
  isDrawer?: boolean;
  notRender?: boolean;
  index?: number;
  open?: boolean;
}

interface RenderAvatarProps {
  containerStyle?: CSSProperties;
  wrapperStyle?: string;
  width?: number;
  redirect?: boolean;
  avatarStyle?: {
    fontSize: string;
    border: string;
  };
  userName?: string;
  avatarSrc?: string;
  gradientBg?: string;
  setShowDropDown: (value: boolean) => void;
  onClose: () => void;
}

const RenderAvatar = ({
  containerStyle,
  wrapperStyle,
  width,
  redirect,
  avatarStyle,
  userName,
  avatarSrc,
  gradientBg,
  setShowDropDown,
  onClose,
}: RenderAvatarProps) => {
  const router = useRouter();
  const avatarBackground = useMemo(
    () => (!avatarSrc ? gradientBg : undefined),
    [avatarSrc, gradientBg],
  );

  const avatarClass = useMemo(
    () => clsx(!avatarSrc ? 'bg-primary' : 'bg-bgColor'),
    [avatarSrc],
  );

  const shouldRenderEditIcon = useMemo(() => !!redirect, [redirect]);

  const handleClick = () => {
    if (redirect) {
      router.push('/profile');
      React.startTransition(() => {
        setShowDropDown(false);
        onClose();
      });
    }
  };

  return (
    <div
      onClick={handleClick}
      style={{ width, height: width, ...containerStyle }}
      className={clsx(wrapperStyle, 'relative')}
    >
      <Avatar
        size={width}
        alt='profile-picture'
        style={{
          ...avatarStyle,
          background: avatarBackground,
        }}
        className={avatarClass}
        src={avatarSrc}
      >
        {!avatarSrc && !userName ? (
          <ProfileIcons width='8' />
        ) : (
          userName && getUserInitials(userName)
        )}
      </Avatar>
      {shouldRenderEditIcon && (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            borderRadius: '50%',
            cursor: 'pointer',
          }}
        >
          <div className='bg-[#121212] translate-y-1 rounded-full flex justify-center items-center'>
            <PencilEdit width='20' fillBgColor='#121212' />
          </div>
        </div>
      )}
    </div>
  );
};
export interface RenderMenuSectionProps {
  items: { text: string; customSVG?: ReactNode; onClick?: () => void }[];
  isCenter?: boolean;
}

interface ProductsHeaderProps {
  isShadow?: boolean;
  links: { text: string | ReactNode; route?: string; notRender?: boolean }[];
  isLoggedIn?: boolean;
}

const config: ThemeConfig = {
  token: {
    colorPrimary: '#FF5612',
  },
};

const montserrat = Montserrat({
  subsets: ['latin'],
  fallback: ['Arial', 'sans-serif'],
});

export enum HeaderIcons {
  HOME = 'Home',
  BOOKS = 'Books',
  COURSES = 'Courses',
  SCHOOLS = 'Schools',
  LIVE = 'Live',
  CONTACT_US = 'Contact Us',
  BUSINESS = 'For Business',
}

export const ICON_MAPPING = {
  [HeaderIcons.HOME]: <HomeIcon />,
  [HeaderIcons.BOOKS]: <BookIcon />,
  [HeaderIcons.COURSES]: <CourseIcon />,
  [HeaderIcons.SCHOOLS]: <SchoolIcon />,
  [HeaderIcons.LIVE]: <LiveIcon />,
  [HeaderIcons.CONTACT_US]: <ContactUs />,
  [HeaderIcons.BUSINESS]: <BusinessHeader />,
};
export const HeaderLink: React.FC<HeaderLinkProps> = ({
  text,
  route,
  setOpen,
  isDrawer = false,
  notRender = false,
  index = 0,
  open = false,
}) => {
  const router = useRouter();
  const isSelected =
    route &&
    (router.pathname === route ||
      (router.pathname.includes(route) && route !== '/'));
  const isHome = text !== 'Home';

  const linkRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSelected && linkRef.current) {
      linkRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });

      setTimeout(() => {
        const offset = 100;
        const rect = linkRef.current?.getBoundingClientRect();
        if (rect) {
          const scrollPosition = rect.left - offset;
          window.scrollBy({ left: scrollPosition, behavior: 'smooth' });
        }
      }, 100);
    }
  }, [isSelected]);

  const content = (
    <motion.div
      ref={linkRef}
      className={clsx(
        'flex items-center cursor-pointer border',
        !isSelected
          ? 'border-[#FFFFFF1A] text-[#C7C6CF] hover:text-white'
          : 'border-white text-white',
        !isHome
          ? 'p-1.5 md:p-2 rounded-full'
          : 'md:px-4 px-3.5 py-1.5 md:py-[9px] rounded-lg',
      )}
    >
      {!isHome ? (
        <motion.div
          key='icon'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {ICON_MAPPING[text as HeaderIcons]}
        </motion.div>
      ) : (
        <motion.div
          key='icon-text'
          className='flex items-center gap-2'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {ICON_MAPPING[text as HeaderIcons]}
          <ShouldRender check={isHome}>
            <span className='text-sm whitespace-nowrap'>{text}</span>
          </ShouldRender>
        </motion.div>
      )}
    </motion.div>
  );

  return (
    <ShouldRender check={!notRender}>
      <ShouldRender check={!isDrawer}>
        <AnimatePresence>
          <motion.div
            onClick={() => {
              if (!isDrawer) {
                route && router.push(route);
                setOpen && setOpen(false);
              }
            }}
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            className={clsx(
              'flex h-full items-center hover:cursor-pointer',
              isSelected ? 'text-primary' : 'text-gray-600',
            )}
          >
            {text === 'SCHOOLS' ? (
              <Tooltip
                title={
                  <div
                    onClick={() => {
                      route && router.push(route);
                    }}
                    className='flex justify-center cursor-pointer'
                  >
                    <Image src={PreviewSchool} alt='AuthorLarge' width={270} />
                  </div>
                }
              >
                {content}
              </Tooltip>
            ) : (
              content
            )}
          </motion.div>
        </AnimatePresence>
      </ShouldRender>
      <ShouldRender check={isDrawer && open}>
        <motion.div
          onClick={() => {
            if (!isDrawer) {
              route && router.push(route);
              setOpen && setOpen(false);
            }
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 260,
            damping: 20,
            delay: 0.1 + index / 10,
          }}
          className={clsx(
            'flex h-full items-center hover:cursor-pointer',
            isSelected ? 'text-primary' : 'text-gray-600',
          )}
        >
          {text === 'SCHOOLS' ? (
            <Tooltip
              title={
                <div
                  onClick={() => {
                    route && router.push(route);
                  }}
                  className='flex justify-center cursor-pointer'
                >
                  <Image src={PreviewSchool} alt='AuthorLarge' width={270} />
                </div>
              }
            >
              {content}
            </Tooltip>
          ) : (
            content
          )}
        </motion.div>
      </ShouldRender>
    </ShouldRender>
  );
};
const ProductsHeader: React.FC<ProductsHeaderProps> = ({
  isShadow = false,
  links,
}) => {
  const router = useRouter();
  const [showSearch, setShowSearch] = useState(false);
  const [userData, setUserData] = useRecoilState<User | undefined>(user);
  const [visible, setVisible] = useRecoilState(authModalControl);
  const [showDropDown, setShowDropDown] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>();
  const [visibleGetAppModal, setVisibleGetAppModal] = useState(false);
  const [visibleVerifyEmailModal, setVisibleVerifyEmailModal] =
    useRecoilState(verifyModal);

  const [isBannerVisibleValue, setIsBannerVisibleValue] = useRecoilState<
    boolean | null
  >(isBannerVisible);

  const bannerRoutes = [
    '/',
    '/books',
    '/courses',
    '/schools',
    '/live',
    '/contact',
  ];

  const isMatchingRoute = useMemo(() => {
    return bannerRoutes.some(
      (bannerRoute) =>
        router.pathname === bannerRoute ||
        router.pathname === `${bannerRoute}/`,
    );
  }, [router.pathname, bannerRoutes]);

  const chatCountValue = useRecoilValue(chatCount);

  const [open, setOpen] = useState<boolean>(false);
  const [isTokenChanged, setIsTokenChanged] = useRecoilState(tokenUpdate);
  const screens = useBreakpoint();
  const { onLogOut } = useAuth();
  const localCartCount = useRecoilValue(localCart);
  const inputRef = useRef<InputRef | null>(null);
  // const syncToken = getSynchronousLoginToken();
  const isSchoolsPage = router.pathname.startsWith(
    '/schools/[schoolId]/[postId]',
  );
  const isCheckoutPage = !router.asPath.includes('/checkout');
  const isCartPage = router.asPath.includes('/cart');
  const isVerified = useRef(false);

  const isPdf = router.asPath.startsWith('/pdf/');
  const validPaths = Object.freeze([
    '/',
    '/books/',
    '/books/[bookId]',
    '/schools/[schoolId]',
    '/schools/',
    '/live/',
    '/courses/',
    '/about_us/',
    '/contact/',
    '/library/',
    '/broadcast-message/',
  ]);

  const validPathSearch = [
    '/',
    '/books/',
    '/books',
    '/schools/',
    '/schools',
    '/live/',
    '/live',
    '/courses/',
    '/courses',
    '/library/',
    '/library',
  ];

  const validPathSearchNav = [
    '/',
    '/books/',
    '/books',
    '/schools/',
    '/schools',
    '/live/',
    '/live',
    '/courses/',
    '/courses',
    '/contact/',
    '/contact',
  ];

  const keyWord = router?.query?.search || '';
  const environment = process.env.NEXT_PUBLIC_APP_ENV;

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const avatarSrc =
    userData?.avatar &&
    `${process.env.NEXT_PUBLIC_IMAGE_BASE_URL_ECOM}/${userData?.avatar}`; // Use uploaded avatar first, fallback to userData.avatar

  // const handleDocumentClick = (e: MouseEvent) => {
  //   if (
  //     dropdownRef.current &&
  //     !dropdownRef.current.contains(e.target as Node)
  //   ) {
  //     setShonCloseowDropDown(false);
  //   }
  // };

  const handleRemoveBanner = () => {
    setIsBannerVisibleValue(false);

    setTimeout(() => {
      setIsBannerVisibleValue(null);
    }, 2000);
  };
  const cleanedPath = router.pathname.trim();

  const shouldRenderLinks = validPaths.some((path) =>
    cleanedPath.startsWith(path),
  );

  const onCancelCloseModal = () => {
    setVisible((prevState) => ({
      ...prevState,
      signUp: false,
    }));
  };

  const onCancelCloseLoginModal = () => {
    setVisible((prevState) => ({
      ...prevState,
      login: false,
    }));
  };

  const onCancelCloseForgotPasswordModal = () => {
    setVisible((prevState) => ({
      ...prevState,
      forgotPassword: false,
    }));
  };

  useEffect(() => {
    const fetchLoggedInStatus = async () => {
      const loggedInStatus = await appwriteService.isLoggedIn();
      const user_name = await getUserName();
      user_name && setUserName(user_name);
      setLoggedIn(loggedInStatus);
    };
    fetchLoggedInStatus();
    if (isTokenChanged) {
      setIsTokenChanged(false);
    }
  }, [appwriteService.account, isTokenChanged]);

  const sendVerificationLink = async () => {
    try {
      await appwriteService.sendVerificationEmail();
      setVisibleVerifyEmailModal(true);
    } catch (error: any) {
      notify(formateErrorAppwriteObject(error));
    }
  };

  const renderMenuSection = ({
    items,
    isCenter = false,
  }: RenderMenuSectionProps) => (
    <div className={montserrat.className}>
      <div className='flex flex-col  gap-y-2 font-medium rounded-lg '>
        {items.map((item, index) => (
          <button
            key={`${item.text}-${index}`}
            onClick={() => {
              item.onClick && item.onClick();
              setShowDropDown(false);
            }}
            className={` ${
              !isCenter ? 'justify-start gap-x-3.5' : 'justify-center gap-x-2'
            } border border-dividerColor rounded-lg p-3 md:py-2 px-4 text-textGrayColor hover:text-primary flex items-center`}
          >
            <div className='flex justify-between items-center w-full'>
              <span className='flex gap-x-4 items-center'>
                <div className='w-5 h-5 '>{item.customSVG}</div>
                <span>{item.text}</span>
              </span>
              <div className='flex justify-center items-center gap-x-3'>
                <ShouldRender check={chatCountValue && item.text === 'Message'}>
                  <span className='w-1.5 h-1.5 rounded-full bg-primary'></span>
                </ShouldRender>
                <span className='bg-dropDownMenuBg flex justify-center p-1 rounded-full items-center '>
                  <ChevronRightIcon className='h-4' />
                </span>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );

  const gradientBg = 'linear-gradient(180deg, #FF5612 0%, #C72707 100%)';

  const onClose = () => {
    setOpen(false);
  };
  const dropdownMenu = (
    <div
      ref={dropdownRef}
      className={clsx(
        montserrat.className,
        'custom-dropdown-menu bg-[#121212]  rounded-xl text-fadedWhite customScrollBar',
      )}
    >
      <div className='px-4 lg:px-5 pt-2 lg:pt-4 overflow-y-auto'>
        <ShouldRender check={screens.sm}>
          <div className='flex flex-col gap-y-1 text-lg '>
            <div className='flex flex-col items-center gap-x-1.5'>
              <div className='flex justify-start gap-x-2.5 items-center w-full '>
                <div
                  className={clsx(
                    montserrat.className,
                    'min-h-[48px] flex justify-start  w-min',
                  )}
                >
                  <RenderAvatar
                    wrapperStyle='rounded-full  min-w-[25px] min-h-[25px] flex justify-center items-center lg:min-w-[48px] lg:min-h-[48px]'
                    width={48}
                    redirect={true}
                    avatarStyle={{
                      fontSize: '14px',
                      border: '1px solid #FFFFFF0D',
                    }}
                    userName={userName}
                    avatarSrc={avatarSrc as string}
                    gradientBg={gradientBg}
                    setShowDropDown={setShowDropDown}
                    onClose={onClose}
                  />
                </div>

                <div className='flex gap-x-2 items-center'>
                  <span
                    onClick={() => {
                      setShowDropDown(false);
                      router.push('/profile');
                    }}
                    className='font-bold text-xl capitalize cursor-pointer truncate'
                  >
                    {userData?.name?.split(' ')?.[0] ??
                      userName?.split(' ')?.[0] ??
                      ''}
                  </span>
                  <ShouldRender check={!userData?.email_verified_at}>
                    <Button
                      size='small'
                      type='text'
                      onClick={() => {
                        sendVerificationLink();
                        setShowDropDown(false);
                        onClose();
                      }}
                      style={{
                        position: 'relative',
                        overflow: 'hidden',
                        background:
                          'linear-gradient(180deg, rgba(97, 153, 255, 0.1) 0%, rgba(4, 92, 251, 0.1) 100%)',
                        border: 'none',
                        borderRadius: '20px',
                        padding: '5px 15px',
                        zIndex: 1,
                      }}
                      className='h-[24px] hover:text-white text-[10px] font-semibold'
                    >
                      <span
                        style={{
                          position: 'absolute',
                          inset: 0,
                          zIndex: -1,
                          borderRadius: 'inherit',
                          padding: '1.3px',
                          background:
                            'linear-gradient(180deg, #6199FF 0%, #045CFB 100%)',
                          WebkitMask:
                            'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                          WebkitMaskComposite: 'xor',
                          maskComposite: 'exclude',
                        }}
                      />
                      Get Verified
                    </Button>
                  </ShouldRender>
                  <ShouldRender check={userData?.email_verified_at}>
                    <VerifiedIcon />
                  </ShouldRender>
                </div>
              </div>

              <div className='flex justify-between items-center gap-x-10 mt-2.5 w-full'>
                <span className='flex flex-col'>
                  <span
                    // onClick={() => router.push('/reader-personality-test')}
                    className='text-primary cursor-pointer  text-xs font-medium flex gap-x-2 items-center mt-1'
                  >
                    <ShouldRender
                      check={
                        !(
                          userData?.user_attributes?.find(
                            (attr) =>
                              attr.attribute_key === 'onboarding_completed',
                          )?.attribute_value === '1'
                        )
                      }
                    >
                      <Button
                        size='small'
                        type='text'
                        onClick={() => {
                          router.push('/onboarding');
                          onClose();
                          setShowDropDown(false);
                        }}
                        style={{
                          position: 'relative',
                          overflow: 'hidden',
                          background:
                            'linear-gradient(180deg, rgba(0, 255, 194, 0.1) 0%, rgba(1, 216, 61, 0.1) 100%)',

                          border: 'none',
                          borderRadius: '8px',
                          padding: '5px 15px',
                          zIndex: 1,
                        }}
                        className='h-[32px] hover:text-white text-xs font-semibold'
                      >
                        <span
                          style={{
                            position: 'absolute',
                            inset: 0,
                            zIndex: -1,
                            borderRadius: 'inherit',
                            padding: '1.3px',
                            background:
                              'linear-gradient(180deg, #00FFC2 0%, #01D83D 100%)',
                            WebkitMask:
                              'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',

                            WebkitMaskComposite: 'xor',
                            maskComposite: 'exclude',
                          }}
                        />
                        <span className='shimmer-effect' />
                        Complete Onboarding
                        <span
                          className='shimmer-effect'
                          style={{ '--shimmer-delay': '0s' } as any}
                        />
                      </Button>
                    </ShouldRender>
                    <ShouldRender
                      check={
                        !userData?.user_attributes?.find(
                          (attr) => attr.attribute_key === 'personality_test',
                        )
                      }
                    >
                      <Button
                        size='small'
                        onClick={() => {
                          router.push('/personality_test');
                          onClose();
                          setShowDropDown(false);
                        }}
                        style={{
                          position: 'relative',
                          overflow: 'hidden',
                          background:
                            'linear-gradient(180deg, rgba(249, 116, 44, 0.1) 0%, rgba(242, 4, 134, 0.1) 100%)',
                          border: 'none',
                          borderRadius: '8px',
                          padding: '5px 15px',
                          zIndex: 1,
                          textDecoration: 'none',
                        }}
                        className='h-[32px] hover:text-white text-xs decoration-none font-semibold'
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.textDecoration = 'none')
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.textDecoration = 'none')
                        }
                      >
                        <span
                          style={{
                            position: 'absolute',
                            inset: 0,
                            zIndex: -1,
                            borderRadius: 'inherit',
                            padding: '1.3px',
                            background:
                              'linear-gradient(180deg, #F9742C 0%, #F20486 100%)',
                            WebkitMask:
                              'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                            WebkitMaskComposite: 'xor',
                            maskComposite: 'exclude',
                            textDecoration: 'none',
                          }}
                        />
                        Check Personality Type
                        <span
                          className='shimmer-effect'
                          style={{ '--shimmer-delay': '.5s' } as any}
                        />
                      </Button>
                    </ShouldRender>
                    <ShouldRender
                      check={userData?.user_attributes?.find(
                        (attr) => attr.attribute_key === 'personality_test',
                      )}
                    >
                      <div
                        onClick={() => {
                          router.push(
                            `/personality_test/${
                              userData?.user_attributes?.find(
                                (attr) =>
                                  attr.attribute_key === 'personality_test',
                              )?.attribute_value
                            }`,
                          );
                          onClose();
                          setShowDropDown(false);
                        }}
                        className=' flex items-center justify-center gap-x-2'
                      >
                        <div
                          style={{
                            background:
                              'linear-gradient(180deg, #F9742C 0%, #F20486 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                          }}
                          className='text-xl font-bold'
                        >
                          {
                            userData?.user_attributes?.find(
                              (attr) =>
                                attr.attribute_key === 'personality_test',
                            )?.attribute_value
                          }
                        </div>
                        <div
                          style={{
                            background:
                              'linear-gradient(180deg, #F9742C 0%, #F20486 100%)',
                          }}
                          className='rounded-full p-1 translate-y-[1px]'
                        >
                          <ChevronRightIcon className='w-3 h-3 text-white' />{' '}
                        </div>
                      </div>
                    </ShouldRender>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </ShouldRender>
      </div>
      <ShouldRender check={screens.md}>
        <div className='border-b  border-dividerColor pt-2.5' />
      </ShouldRender>
      <div className='pt-3 flex px-4 gap-x-2 lg:px-5 w-full '>
        <ButtonDefault
          size={2}
          rounded={false}
          roundedSm={true}
          is3D={true}
          variant={ButtonVariants.WHITE}
          enableScaling={false}
          onClick={() => {
            if (window !== undefined) {
              window.open('https://studio.zebralearn.com/', '_blank');
            }
          }}
          className='flex justify-center  w-full py-3 font-medium text-xs border-none whitespace-nowrap focus:outline-none focus:right-0'
        >
          <span className='px-10 text-sm font-medium text-[#121212]'>
            Become Author
          </span>
        </ButtonDefault>
        <ButtonDefault
          size={2}
          rounded={false}
          roundedSm={true}
          enableScaling={false}
          variant={ButtonVariants.GRAY}
          onClick={() => {
            router.push('/refer-earn');
            onClose();
            setShowDropDown(false);
          }}
          className='flex justify-center w-full py-3 font-medium text-xs border-none whitespace-nowrap focus:outline-none focus:right-0'
        >
          <span className='px-1 text-sm font-medium text-white'>
            Refer & Earn
          </span>
        </ButtonDefault>
      </div>

      <div className='flex flex-col gap-y-3 px-2 lg:px-5 pt-2 md:pt-4 pb-4 '>
        {renderMenuSection({
          items: [
            {
              text: 'Message',
              customSVG: (
                <ChatIcon
                  width={!screens.md ? 20 : 22}
                  height={!screens.md ? 20 : 22}
                />
              ),
              onClick: () => {
                router.push('/broadcast-message');
                onClose();
              },
            },
            {
              text: 'Cart',
              customSVG: <ShoppingCart />,
              onClick: () => {
                router.push('/cart');
                onClose();
              },
            },

            {
              text: 'Library',
              customSVG: <LibraryIcon />,
              onClick: () => {
                router.push('/library/');
                onClose();
              },
            },
            {
              text: 'Rewards',
              customSVG: <RewardIcon />,
              onClick: () => {
                router.push('/reward-store');
                onClose();
              },
            },
            {
              text: 'My Orders',
              customSVG: <ReceiptItem />,
              onClick: () => {
                router.push('/my-orders/');
                onClose();
              },
            },

            {
              text: 'Security & Passwords',
              customSVG: <KeyIcon />,
              onClick: () => {
                router.push('/profile/?activeTab=2');
                onClose();
              },
            },

            {
              text: 'Help & Support',
              customSVG: <InfoCircleIcon />,
              onClick: () => {
                if (window !== undefined) {
                  window.open(
                    'https://support.zebralearn.com/support/home',
                    '_blank',
                  );
                }
                onClose();
              },
            },
            {
              text: 'Logout',
              customSVG: <LogoutIcon />,
              onClick: () => onLogOut(),
            },
          ],
        })}
      </div>
    </div>
  );

  const showDrawer = () => {
    setOpen(true);
  };

  useEffect(() => {
    setSearchKeyword(keyWord as string);
  }, [keyWord]);

  const verifyUserEmail = async () => {
    try {
      await appwriteService.verifyVerificationEmail(
        router.query.userId as string,
        router.query.secret as string,
      );
      const data = await getUserProfile();
      setUserData(data?.data);
      router.replace(router.pathname, undefined, { shallow: true });
    } catch (error: any) {
      notify(formateErrorObject(error), 'error');
    }
  };

  useEffect(() => {
    if (!isVerified.current && router.query.userId && router.query.secret) {
      verifyUserEmail();
      isVerified.current = true;
    }
  }, [router.query.userId, router.query.secret]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchKeyword = e.target.value;
    setSearchKeyword(newSearchKeyword);
    Cookies.set('search', newSearchKeyword);

    // Update the query parameter in the URL
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, search: newSearchKeyword },
      },
      undefined,
      { shallow: true },
    );
  };

  const useAppButton = (
    <div className={montserrat.className}>
      <Button
        onClick={() => {
          setVisibleGetAppModal(true);
          const eventParams: EventDetails = {
            event_name: 'download_app',
            source: 'global',
            source_type: 'global',
            source_id: null,
            sub_source: null,
            sub_source_id: null,
            unit: 'boolean',
            value: 1,
            meta: {
              click_source: 'app_popup',
            },
          };

          logEvent({
            eventDetails: eventParams,
            userData: userData,
            pathname: router.pathname,
          });
          if (typeof mixpanel !== 'undefined' && environment === 'production') {
            mixpanel.track(eventParams.event_name as string, eventParams);
          }
        }}
        className='border-primary border-1 bg-transparent border-opacity-50 md:border-[2px] text-[10px] xxxs:text-xs md:text-base px-2 md:px-4 hover:text-white hover:border-[#737373] text-primary h-7 xs:h-8 md:h-[38px] rounded-lg  font-semibold'
      >
        Use App
      </Button>
    </div>
  );

  return (
    <>
      <ConfigProvider theme={config}>
        <ShouldRender
          check={
            !screens.md &&
            validPathSearchNav.some((path) => path === router.pathname)
          }
        >
          <AnimatePresence>
            {isBannerVisibleValue === true && (
              <motion.div
                className='bg-cartBgColor flex justify-between items-center h-16 px-4 fixed -translate-y-2 w-full z-40'
                initial={{ x: 0 }}
                animate={{ x: 0 }}
                exit={{ x: '100%' }}
                transition={{ duration: 0.3 }}
              >
                <div className='flex justify-between w-full items-center '>
                  <div className='flex gap-x-4 items-center justify-start'>
                    <button
                      type='button'
                      className='px-0 flex items-center rounded-full'
                      onClick={handleRemoveBanner}
                      aria-label='Close banner'
                    >
                      <XIcon className='w-5 h-5' />
                    </button>
                    <div className='flex flex-col justify-center'>
                      <span className='font-semibold'>ZebraLearn App</span>
                      <span className='text-xs'>
                        Install for a better experience
                      </span>
                    </div>
                  </div>
                  <div className='mt-1'>{useAppButton}</div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </ShouldRender>
        <div className='flex flex-col'>
          <nav
            className={clsx(
              'flex py-2 z-50 px-4 md:px-5 lg:px-12 items-center bg-bgColor border-borderDarkGray shadow-md justify-between w-full h-14 lg:min-h-16 top-0 transition-all duration-300',
              !isMatchingRoute && 'bg-bgColor border-borderDarkGray shadow-md',
              // {
              //   'bg-transparent border-none': !isScrolled && isMatchingRoute,
              //   'bg-bgColor border-borderDarkGray shadow-md': isScrolled,
              // },

              !isSchoolsPage && !isPdf && 'fixed',
              isBannerVisibleValue &&
                !screens.md &&
                !isCartPage &&
                isCheckoutPage &&
                validPathSearchNav.some((path) => path === router.pathname)
                ? 'mt-14 -translate-y-2'
                : 'mt-0 -translate-y-0',
              !isShadow ? 'shadow-md' : '',
            )}
          >
            <div className={montserrat.className}></div>
            <ShouldRender check={!showSearch}>
              <div className='flex gap-x-1.5 w-full  justify-start items-center'>
                <div className='flex gap-x-2 items-center'>
                  <div className=' h-full flex justify-center items-center md:pr-8'>
                    <span
                      onClick={() => router.push('/')}
                      className={clsx(
                        'cursor-pointer flex h-3 md:h-3.5  md:pl-0 justify-start md:w-full w-32 md:min-w-36 xl:min-w-40 ',
                      )}
                    >
                      <span>
                        <Image
                          src={screens.lg ? ZebraLearnLogo : LogoWithoutSquare}
                          alt='Logo'
                          priority
                          className='object-contain w-full h-full'
                        />
                      </span>
                    </span>
                  </div>
                </div>
                {/* <ShouldRender
                check={
                  shouldRenderLinks &&
                  router.asPath !== '/contact/' &&
                  !router.asPath.includes('/broadcast-message/') &&
                  !router.pathname.includes('/books/[bookId]') &&
                  validPathSearch.includes(router.pathname) &&
                  router.isReady
                }
              >
                <ShouldRender
                  check={
                    !router.asPath.includes('/notification/') &&
                    windowSize > 1380
                  }
                >
                  <span className='hidden xl:flex justify-center  '>
                    <Input
                      size='large'
                      placeholder='Search'
                      className=' text-base min-w-[11rem] rounded-full border-none placeholder:text-white  py-2 px-4  text-white h-10  bg-cartBgColor  '
                      value={searchKeyword}
                      onChange={handleInputChange}
                      prefix={
                        <div className='text-[#C7C6CF] pr-2'>
                          <SearchIcon />
                        </div>
                      }
                      suffix={
                        searchKeyword?.length && (
                          <XIcon
                            onClick={() => {
                              Cookies.set('search', '');
                              setSearchKeyword(undefined);
                              const { search, ...restQuery } = router.query;
                              router.push(
                                {
                                  pathname: router.pathname,
                                  query: restQuery,
                                },
                                undefined,
                                { shallow: true },
                              );
                            }}
                            className='w-5 h-5 pr-1  cursor-pointer'
                          />
                        )
                      }
                    />
                  </span>
                </ShouldRender>
              </ShouldRender> */}

                <span className=' h-full lgXL:flex  hidden gap-x-1.5 items-center w-full '>
                  <ShouldRender
                    check={
                      shouldRenderLinks &&
                      screens.xl &&
                      isCheckoutPage &&
                      !isCartPage
                    }
                  >
                    {links.map((link, index) => (
                      <HeaderLink
                        key={`${link.route}-${index}`}
                        text={link.text}
                        route={link.route}
                        setOpen={setOpen}
                        notRender={link.notRender ?? false}
                      />
                    ))}
                  </ShouldRender>
                </span>
              </div>
            </ShouldRender>

            <span className='flex justify-end items-center w-full'>
              <ShouldRender
                check={shouldRenderLinks && !screens.md && isCheckoutPage}
              >
                <ShouldRender
                  check={
                    showSearch && validPathSearch.includes(router.pathname)
                  }
                >
                  <span className='flex justify-center customInput w-full '>
                    <Input
                      size='small'
                      ref={inputRef}
                      placeholder='Search for books courses and schools'
                      className=' text-sm rounded-2xl focus-within:bg-bgColor border border-bgDarkGray  placeholder:text-textDarkGrayColor  py-3  text-white h-10 lg:h-12 bg-bgColor customInputBlack makeBgBlack'
                      value={searchKeyword}
                      style={{
                        backgroundColor: '#000000',
                      }}
                      onChange={handleInputChange}
                      prefix={
                        <div className=''>
                          <SearchIconSVG isGray={true} />
                        </div>
                      }
                      suffix={
                        <XIcon
                          onClick={() => {
                            Cookies.set('search', '');
                            setSearchKeyword('');
                            const { search, ...restQuery } = router.query;
                            router.push(
                              { pathname: router.pathname, query: restQuery },
                              undefined,
                              { shallow: true },
                            );
                            setShowSearch(false);
                          }}
                          className='w-5 h-5 pr-1 text-[#A6A6A6] cursor-pointer'
                        />
                      }
                      // onChange={handleInputChange}
                    />
                  </span>
                </ShouldRender>
              </ShouldRender>
            </span>

            {/* <ShouldRender check={screens.md}> */}
            <div className='flex gap-x-3 w-full items-center justify-end'>
              <ShouldRender
                check={
                  shouldRenderLinks &&
                  router.asPath !== '/contact/' &&
                  !router.asPath.includes('/broadcast-message/') &&
                  !router.pathname.includes('/books/[bookId]') &&
                  validPathSearch.includes(router.pathname)
                }
              >
                <ShouldRender check={loggedIn}>
                  <div
                    onClick={() => router.push('/rewards')}
                    className={`rounded-full border  hover:bg-backgroundGray border-none  cursor-pointer flex justify-center items-center bg-cartBgColor `}
                  >
                    <span className='w-6 h-6 md:w-8 md:h-8 flex justify-center items-center m-1'>
                      <CoinHeader />
                    </span>
                    <p
                      className={clsx(
                        'font-semibold flex justify-center text-xs md:text-sm items-center my-1  min-w-6 text-[#F9F9FA]',
                        userData?.balance && userData.balance > 100
                          ? 'mr-2'
                          : 'mr-1',
                      )}
                    >
                      {userData?.balance || 0}
                    </p>
                  </div>
                  <ShouldRender check={screens.md}>{useAppButton}</ShouldRender>
                </ShouldRender>
              </ShouldRender>

              <ShouldRender check={loggedIn}>
                <ShouldRender
                  check={
                    !(
                      shouldRenderLinks &&
                      router.asPath !== '/contact/' &&
                      !router.asPath.includes('/broadcast-message/') &&
                      !router.pathname.includes('/books/[bookId]') &&
                      validPathSearch.includes(router.pathname)
                    )
                  }
                >
                  <div
                    onClick={() => router.push('/rewards')}
                    className={`rounded-full border  hover:bg-backgroundGray border-none  cursor-pointer flex justify-center items-center bg-cartBgColor `}
                  >
                    <span className='w-6 h-6 md:w-8 md:h-8 flex justify-center items-center m-1'>
                      <CoinHeader />
                    </span>
                    <p
                      className={clsx(
                        'font-semibold flex justify-center text-xs md:text-sm items-center my-1  min-w-6 text-[#F9F9FA]',
                        userData?.balance && userData.balance > 100
                          ? 'mr-2'
                          : 'mr-1',
                      )}
                    >
                      {userData?.balance || 0}
                    </p>
                  </div>
                </ShouldRender>
                <Badge
                  dot
                  color='#FF5612'
                  count={chatCountValue}
                  className='customDot'
                  style={{ color: 'white', fontSize: '11px' }}
                >
                  <Dropdown
                    overlay={dropdownMenu}
                    trigger={['click']}
                    open={screens.md ? showDropDown : false}
                    onOpenChange={(visible) =>
                      screens.md ? setShowDropDown(visible) : showDrawer()
                    }
                  >
                    <div
                      ref={dropdownRef}
                      onClick={(e) => {
                        e.preventDefault();

                        screens.md
                          ? setShowDropDown(!showDropDown)
                          : showDrawer();
                      }}
                    >
                      <Space>
                        <div
                          className={clsx(
                            showDropDown && 'bg-backgroundGray',
                            'rounded-full   border hover:bg-backgroundGray border-none  cursor-pointer  flex  justify-center items-center bg-cartBgColor',
                          )}
                        >
                          <div className='h-6 w-6  md:w-8 md:h-8 m-1 flex justify-center items-center'>
                            <RenderAvatar
                              wrapperStyle='rounded-full  '
                              width={screens.lg ? 30 : 24}
                              avatarStyle={{
                                fontSize: '10px',
                                border: '1px solid #FFFFFF0D',
                              }}
                              userName={userName}
                              avatarSrc={avatarSrc as string}
                              gradientBg={gradientBg}
                              setShowDropDown={setShowDropDown}
                              onClose={onClose}
                            />
                          </div>

                          {/* </Badge> */}
                          <div className='flex  h-6 w-6  md:w-8 md:h-8 ml-1 my-1 items-center'>
                            <MenuOutlined className='text-fadedWhite text-xs md:text-sm' />
                          </div>
                        </div>
                      </Space>
                    </div>
                  </Dropdown>
                </Badge>
              </ShouldRender>

              <ShouldRender check={!loggedIn && isCheckoutPage}>
                <ShouldRender check={screens.lg}>{useAppButton}</ShouldRender>
                <span className='flex h-full items-center'>
                  <ButtonDefault
                    size={screens.md ? 4 : 1}
                    rounded={false}
                    roundedSm={true}
                    variant={ButtonVariants.PRIMARY}
                    onClick={() =>
                      setVisible((prevState) => ({
                        ...prevState,
                        login: true,
                      }))
                    }
                    className='flex justify-center px-5  xl:py-2.5  font-medium text-xs  whitespace-nowrap'
                  >
                    <span className='px-2  text-sm font-medium'>SIGN IN</span>
                  </ButtonDefault>
                </span>
              </ShouldRender>
            </div>
            {/* </ShouldRender> */}
          </nav>
          <ShouldRender
            check={
              validPathSearchNav.some((path) => path === router.pathname) &&
              !screens.xl
            }
          >
            <motion.div
              className={clsx(
                isBannerVisibleValue
                  ? 'md:translate-y-8 lg:translate-y-10 mt-[90px]'
                  : 'md:translate-y-8 lg:translate-y-10 mt-10',
                ' flex flex-col lg:flex-row bg-bgColor justify-between md:gap-y-2 z-10 pb-2 md:pb-4 md:mt-4 pt-2 px-4 md:px-5 lg:px-12',
                '',
              )}
            >
              <div
                className='flex gap-x-2 overflow-auto no-scrollbar overflow-x-auto'
                style={{ minHeight: '50px' }} // Placeholder height
              >
                {router.isReady ? (
                  links.map((link, index) => (
                    <div
                      key={`${link.route}-${index}`}
                      onClick={() => {
                        link.route && router.push(link.route);
                        setOpen && setOpen(false);
                      }}
                      className='hover:cursor-pointer'
                    >
                      <HeaderLink
                        text={link.text}
                        index={index}
                        open={open}
                        route={link.route}
                        setOpen={setOpen}
                      />
                    </div>
                  ))
                ) : (
                  <div className='flex gap-x-2 overflow-auto no-scrollbar overflow-x-auto items-center'>
                    {Array.from({ length: 4 }).map((_, index) => (
                      <div
                        key={`skeleton-nav-${index}`}
                        className='bg-cartBgColor rounded-lg h-9 w-[90px] flex-shrink-0 animate-pulse'
                      />
                    ))}
                  </div>
                )}
              </div>
              <ShouldRender
                check={
                  router.asPath !== '/contact/' &&
                  !router.asPath.includes('/broadcast-message/') &&
                  !router.pathname.includes('/books/[bookId]') &&
                  validPathSearch.includes(router.pathname) &&
                  !screens.lg
                }
              >
                <div
                  className='flex justify-center items-center'
                  style={{ minHeight: '48px' }}
                >
                  {router.isReady ? (
                    <Input
                      size='large'
                      placeholder='Search'
                      className='text-base w-full lg:max-w-[195px] rounded-full border-none placeholder:text-white px-3 text-white h-10 bg-cartBgColor'
                      value={searchKeyword}
                      onChange={handleInputChange}
                      prefix={
                        <div className='text-[#C7C6CF] pr-2'>
                          <SearchIcon />
                        </div>
                      }
                      suffix={
                        searchKeyword?.length ? (
                          <XIcon
                            onClick={() => {
                              Cookies.set('search', ''); // Reset the search in cookies
                              setSearchKeyword(undefined); // Clear the keyword state
                              const { search, ...restQuery } = router.query;
                              router.push(
                                {
                                  pathname: router.pathname,
                                  query: restQuery, // Remove 'search' from query
                                },
                                undefined,
                                { shallow: true },
                              );
                            }}
                            className='w-5 h-5 pr-1 cursor-pointer'
                          />
                        ) : null
                      }
                    />
                  ) : (
                    // Placeholder or skeleton loader
                    <div className='bg-cartBgColor h-10 w-full lg:max-w-[195px] rounded-full animate-pulse' />
                  )}
                </div>
              </ShouldRender>
            </motion.div>
          </ShouldRender>
        </div>
      </ConfigProvider>
      {/* <div className='bg-[#FF5612] w-full text-center h-11 mt-16 text-fadedWhite font-semibold flex justify-center items-center'>
        Hey Reader, We have redesigned our website, in case of any query please
        contact us.{' '}
      </div> */}
      <Drawer
        className='drawerPaddingNone drawerPaddingNoScrollBar  h-full'
        width={550}
        height='100%'
        title={
          <div className='flex justify-between '>
            <span
              onClick={() => router.push('/')}
              className='cursor-pointer flex  md:pl-0 justify-start w-2/5 h-5'
            >
              <span>
                <Image
                  src={LogoWithoutSquare}
                  alt='Logo'
                  className='object-contain w-full h-full max-w-36'
                />
              </span>
            </span>
            <span
              className='flex justify-end mt-1'
              onClick={() => {
                onClose();
              }}
            >
              <XIcon className='text-fadedWhite w-5 h-5' />
            </span>
          </div>
        }
        placement='right'
        closeIcon={false}
        onClose={onClose}
        open={open}
      >
        <div className={montserrat.className}>
          <span className=' flex flex-col justify-between h-full w-full text-xs  '>
            <div className='flex flex-col gap-y-1 text-lg px-5 py-3 no-scrollbar '>
              <div className='flex flex-col items-center gap-x-1.5'>
                <div className='flex justify-start gap-x-2.5 items-center w-full '>
                  <div className='min-h-[48px] flex justify-start w-fit'>
                    <RenderAvatar
                      wrapperStyle='rounded-full  '
                      width={48}
                      redirect={true}
                      avatarStyle={{
                        fontSize: '14px',
                        border: '1px solid #FFFFFF0D',
                      }}
                      userName={userName}
                      avatarSrc={avatarSrc as string}
                      gradientBg={gradientBg}
                      setShowDropDown={setShowDropDown}
                      onClose={onClose}
                    />
                  </div>

                  <div className='flex gap-x-2 items-center'>
                    <span
                      onClick={() => {
                        setShowDropDown(false);
                        router.push('/profile');
                      }}
                      className='font-bold text-xl capitalize cursor-pointer truncate'
                    >
                      {userData?.name?.split(' ')?.[0] ??
                        userName?.split(' ')?.[0] ??
                        ''}
                    </span>
                    <ShouldRender check={!userData?.email_verified_at}>
                      <Button
                        size='small'
                        type='text'
                        onClick={() => {
                          sendVerificationLink();
                          setShowDropDown(false);
                          onClose();
                        }}
                        style={{
                          position: 'relative',
                          overflow: 'hidden',
                          background:
                            'linear-gradient(180deg, rgba(97, 153, 255, 0.1) 0%, rgba(4, 92, 251, 0.1) 100%)',
                          border: 'none',
                          borderRadius: '20px',
                          padding: '5px 15px',
                          zIndex: 1,
                        }}
                        className='h-[24px] hover:text-white text-[10px] font-semibold'
                      >
                        <span
                          style={{
                            position: 'absolute',
                            inset: 0,
                            zIndex: -1,
                            borderRadius: 'inherit',
                            padding: '1.3px',
                            background:
                              'linear-gradient(180deg, #6199FF 0%, #045CFB 100%)',
                            WebkitMask:
                              'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                            WebkitMaskComposite: 'xor',
                            maskComposite: 'exclude',
                          }}
                        />
                        Get Verified
                      </Button>
                    </ShouldRender>
                    <ShouldRender check={userData?.email_verified_at}>
                      <VerifiedIcon />
                    </ShouldRender>
                  </div>
                </div>

                <div className='flex justify-between items-center gap-x-10 mt-2.5 w-full'>
                  <span className='flex w-full flex-col'>
                    <span
                      // onClick={() => router.push('/reader-personality-test')}
                      className='text-primary cursor-pointer  text-xs font-medium flex gap-x-2 items-center mt-2'
                    >
                      <ShouldRender
                        check={
                          !(
                            userData?.user_attributes?.find(
                              (attr) =>
                                attr.attribute_key === 'onboarding_completed',
                            )?.attribute_value === '1'
                          )
                        }
                      >
                        <Button
                          size='small'
                          type='text'
                          onClick={() => {
                            router.push('/onboarding');
                            onClose();
                            setShowDropDown(false);
                          }}
                          style={{
                            position: 'relative',
                            overflow: 'hidden',
                            background:
                              'linear-gradient(180deg, rgba(0, 255, 194, 0.1) 0%, rgba(1, 216, 61, 0.1) 100%)',

                            border: 'none',
                            borderRadius: '8px',
                            padding: '5px 15px',
                            zIndex: 1,
                          }}
                          className='h-[32px] hover:text-white text-xs font-semibold'
                        >
                          <span
                            style={{
                              position: 'absolute',
                              inset: 0,
                              zIndex: -1,
                              borderRadius: 'inherit',
                              padding: '1.3px',
                              background:
                                'linear-gradient(180deg, #00FFC2 0%, #01D83D 100%)',
                              WebkitMask:
                                'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',

                              WebkitMaskComposite: 'xor',
                              maskComposite: 'exclude',
                            }}
                          />
                          <span className='shimmer-effect' />
                          Complete Onboarding
                        </Button>
                      </ShouldRender>
                      <ShouldRender
                        check={
                          !userData?.user_attributes?.find(
                            (attr) => attr.attribute_key === 'personality_test',
                          )
                        }
                      >
                        <Button
                          size='small'
                          onClick={() => {
                            router.push('/personality_test');
                            onClose();
                            setShowDropDown(false);
                          }}
                          style={{
                            position: 'relative',
                            overflow: 'hidden',
                            background:
                              'linear-gradient(180deg, rgba(249, 116, 44, 0.1) 0%, rgba(242, 4, 134, 0.1) 100%)',
                            border: 'none',
                            borderRadius: '8px',
                            padding: '5px 15px',
                            zIndex: 1,
                            textDecoration: 'none',
                          }}
                          className='h-[32px] hover:text-white text-xs decoration-none font-semibold'
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.textDecoration = 'none')
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.textDecoration = 'none')
                          }
                        >
                          <span
                            style={{
                              position: 'absolute',
                              inset: 0,
                              zIndex: -1,
                              borderRadius: 'inherit',
                              padding: '1.3px',
                              background:
                                'linear-gradient(180deg, #F9742C 0%, #F20486 100%)',
                              WebkitMask:
                                'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                              WebkitMaskComposite: 'xor',
                              maskComposite: 'exclude',
                              textDecoration: 'none',
                            }}
                          />
                          <span className='shimmer-effect' />
                          Check Personality Type
                        </Button>
                      </ShouldRender>
                      <ShouldRender
                        check={userData?.user_attributes?.find(
                          (attr) => attr.attribute_key === 'personality_test',
                        )}
                      >
                        <div
                          onClick={() => {
                            router.push(
                              `/personality_test/${
                                userData?.user_attributes?.find(
                                  (attr) =>
                                    attr.attribute_key === 'personality_test',
                                )?.attribute_value
                              }`,
                            );
                            onClose();
                            setShowDropDown(false);
                          }}
                          className=' flex items-center justify-center gap-x-2'
                        >
                          <div
                            style={{
                              background:
                                'linear-gradient(180deg, #F9742C 0%, #F20486 100%)',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                            }}
                            className='text-xl font-bold'
                          >
                            {
                              userData?.user_attributes?.find(
                                (attr) =>
                                  attr.attribute_key === 'personality_test',
                              )?.attribute_value
                            }
                          </div>
                          <div
                            style={{
                              background:
                                'linear-gradient(180deg, #F9742C 0%, #F20486 100%)',
                            }}
                            className='rounded-full p-1 translate-y-[1px]'
                          >
                            <ChevronRightIcon className='w-3 h-3 text-white' />{' '}
                          </div>
                        </div>
                      </ShouldRender>
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div className='border-b  border-dividerColor' />

            <div className='pt-3 flex px-4 gap-x-2 lg:px-5 w-full '>
              <ButtonDefault
                size={2}
                rounded={false}
                roundedSm={true}
                is3D={true}
                variant={ButtonVariants.WHITE}
                enableScaling={false}
                onClick={() => {
                  if (window !== undefined) {
                    window.open('https://studio.zebralearn.com/', '_blank');
                  }
                }}
                className='flex justify-center  w-full py-3 font-medium text-xs border-none whitespace-nowrap focus:outline-none focus:right-0'
              >
                <span className='px-10 text-sm font-medium text-[#121212]'>
                  Become Author
                </span>
              </ButtonDefault>
              <ButtonDefault
                size={2}
                rounded={false}
                roundedSm={true}
                enableScaling={false}
                variant={ButtonVariants.GRAY}
                onClick={() => {
                  router.push('/refer-earn');
                  setShowDropDown(false);
                  onClose();
                }}
                className='flex justify-center w-full py-3 font-medium text-xs border-none whitespace-nowrap focus:outline-none focus:right-0'
              >
                <span className='px-1 text-sm font-medium text-white'>
                  Refer & Earn
                </span>
              </ButtonDefault>
            </div>

            <div className='flex flex-col gap-y-3 px-4 lg:px-5 pt-2 md:pt-4 pb-4 '>
              {renderMenuSection({
                items: [
                  {
                    text: 'Message',
                    customSVG: (
                      <ChatIcon
                        width={!screens.md ? 20 : 22}
                        height={!screens.md ? 20 : 22}
                      />
                    ),
                    onClick: () => {
                      router.push('/broadcast-message');
                      onClose();
                    },
                  },
                  {
                    text: 'Cart',
                    customSVG: <ShoppingCart />,
                    onClick: () => {
                      router.push('/cart');
                      onClose();
                    },
                  },

                  {
                    text: 'Library',
                    customSVG: <LibraryIcon />,
                    onClick: () => {
                      router.push('/library/');
                      onClose();
                    },
                  },
                  {
                    text: 'Rewards',
                    customSVG: <RewardIcon />,
                    onClick: () => {
                      router.push('/reward-store');
                      onClose();
                    },
                  },
                  {
                    text: 'My Orders',
                    customSVG: <ReceiptItem />,
                    onClick: () => {
                      router.push('/my-orders/');
                      onClose();
                    },
                  },

                  {
                    text: 'Security & Passwords',
                    customSVG: <KeyIcon />,
                    onClick: () => {
                      router.push('/profile/?activeTab=2');
                      onClose();
                    },
                  },

                  {
                    text: 'Help & Support',
                    customSVG: <InfoCircleIcon />,
                    onClick: () => {
                      if (window !== undefined) {
                        window.open(
                          'https://support.zebralearn.com/support/home',
                          '_blank',
                        );
                      }
                      onClose();
                    },
                  },
                  {
                    text: 'Logout',
                    customSVG: <LogoutIcon />,
                    onClick: () => onLogOut(),
                  },
                ],
              })}
            </div>

            <div className='flex flex-col gap-y-3 px-4 pb-4 mt-8'>
              <span className='text-center mb-1'>DOWNLOAD THE APP NOW</span>
              <div className='flex flex-col xs:flex-row gap-3 w-full'>
                <Button
                  type='default'
                  onClick={() => {
                    if (typeof window !== undefined) {
                      window.open(
                        'https://play.google.com/store/apps/details?id=com.zebralearn.reader&utm_source=zebralearn_website',
                        '_blank',
                      );
                    }
                  }}
                  className=' w-full bg-transparent py-7 border-white'
                >
                  <div className='flex w-full items-center gap-x-3 '>
                    <span>
                      <GooglePlay
                        width={screens.md ? 27 : 20}
                        height={screens.md ? 29 : 22}
                      />
                    </span>

                    <span className='flex flex-col '>
                      <span className='text-[10px] text-start'>GET IT ON</span>
                      <span className='text-base md:text-lg -translate-y-1 font-semibold'>
                        Google Play
                      </span>
                    </span>
                  </div>
                </Button>
                <Button
                  type='default'
                  onClick={() => {
                    if (typeof window !== undefined) {
                      window.open(
                        'https://apps.apple.com/in/app/zebralearn/id6477746268?utm_source=zebralearn_website',
                        '_blank',
                      );
                    }
                  }}
                  className=' w-full bg-transparent py-7  border-white'
                >
                  <div className='flex w-full items-center gap-x-3 '>
                    <span>
                      <AppleLoginSVG
                        width={screens.md ? 25 : 18}
                        height={screens.md ? 32 : 25}
                      />
                    </span>

                    <span className='flex flex-col '>
                      <span className='text-[10px] text-start'>
                        Download on the
                      </span>
                      <span className='text-base md:text-lg -translate-y-1 font-semibold'>
                        App Store
                      </span>
                    </span>
                  </div>
                </Button>
              </div>
            </div>
          </span>
        </div>
      </Drawer>
      <ShouldRender
        check={
          localCartCount &&
          !isCartPage &&
          isCheckoutPage &&
          router.pathname !== '/broadcast-message' &&
          !router.pathname.includes('/books/[bookId]') &&
          !router.pathname.includes('/reader-personality-test') &&
          !isPdf
        }
      >
        <div
          style={{
            position: 'fixed',
            bottom: !screens.lg ? '22px' : '40px',
            right: !screens.lg ? '22px' : '40px',
            cursor: 'pointer',
            zIndex: 999,
          }}
          className='bg-white hover:bg-gray-50 rounded-full p-1.5 md:p-3 shadow-2xl flex items-center justify-center'
        >
          <Badge
            style={{ color: 'white', fontSize: '11px' }}
            count={localCartCount}
          >
            <div
              onClick={() => router.push('/cart')}
              className='h-8 w-8 md:h-10 md:w-10 cursor-pointer text-gray-700 hover:text-gray-500  '
            >
              <ShoppingCart width={24} />
            </div>
          </Badge>
        </div>
      </ShouldRender>
      <SignUpModal
        onCancel={onCancelCloseModal}
        visible={visible.signUp}
        noRedirect={visible?.noRedirect}
      />
      <LoginModal onCancel={onCancelCloseLoginModal} visible={visible.login} />
      <ForgotPasswordModal
        onCancel={onCancelCloseForgotPasswordModal}
        visible={visible.forgotPassword}
      />
      <GetOurAppModal
        visible={visibleGetAppModal}
        onCancel={() => setVisibleGetAppModal(false)}
        isClosable={true}
      />

      <VerifyEmailModal
        visible={visibleVerifyEmailModal}
        onCancel={() => setVisibleVerifyEmailModal(false)}
      />
    </>
  );
};

export default ProductsHeader;
