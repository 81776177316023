import React from 'react';

interface UpdatedCustomIconProps {
  filled?: boolean;
}

const LogoutIcon: React.FC<UpdatedCustomIconProps> = () => (
  <svg
    width='86'
    height='86'
    viewBox='0 0 86 86'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='43' cy='43' r='43' fill='#DC9646' />
    <path
      d='M37.75 64H48.25C51.4978 63.9965 54.6117 62.7048 56.9082 60.4082C59.2048 58.1117 60.4965 54.9978 60.5 51.75V34.25C60.4965 31.0022 59.2048 27.8883 56.9082 25.5918C54.6117 23.2952 51.4978 22.0035 48.25 22H37.75C34.5022 22.0035 31.3883 23.2952 29.0918 25.5918C26.7952 27.8883 25.5035 31.0022 25.5 34.25C25.5 34.7141 25.6844 35.1592 26.0126 35.4874C26.3408 35.8156 26.7859 36 27.25 36C27.7141 36 28.1592 35.8156 28.4874 35.4874C28.8156 35.1592 29 34.7141 29 34.25C29.0025 31.9301 29.9252 29.706 31.5656 28.0656C33.206 26.4252 35.4301 25.5025 37.75 25.5H48.25C50.5699 25.5025 52.794 26.4252 54.4344 28.0656C56.0748 29.706 56.9975 31.9301 57 34.25V51.75C56.9975 54.0699 56.0748 56.294 54.4344 57.9344C52.794 59.5748 50.5699 60.4975 48.25 60.5H37.75C35.4301 60.4975 33.206 59.5748 31.5656 57.9344C29.9252 56.294 29.0025 54.0699 29 51.75C29 51.2859 28.8156 50.8407 28.4874 50.5126C28.1592 50.1844 27.7141 50 27.25 50C26.7859 50 26.3408 50.1844 26.0126 50.5126C25.6844 50.8407 25.5 51.2859 25.5 51.75C25.5035 54.9978 26.7952 58.1117 29.0918 60.4082C31.3883 62.7048 34.5022 63.9965 37.75 64Z'
      fill='black'
    />
    <path
      d='M25.5 43C25.5 43.4641 25.6844 43.9092 26.0126 44.2374C26.3408 44.5656 26.7859 44.75 27.25 44.75H39.6505L37.3878 47.0127C37.2206 47.1742 37.0873 47.3673 36.9956 47.5808C36.9039 47.7943 36.8556 48.0239 36.8536 48.2563C36.8515 48.4886 36.8958 48.7191 36.9838 48.9341C37.0718 49.1492 37.2017 49.3446 37.3661 49.5089C37.5304 49.6732 37.7258 49.8032 37.9408 49.8912C38.1559 49.9792 38.3863 50.0234 38.6187 50.0214C38.8511 50.0194 39.0807 49.9711 39.2942 49.8794C39.5077 49.7877 39.7008 49.6544 39.8622 49.4872L45.1123 44.2372C45.4403 43.9091 45.6246 43.464 45.6246 43C45.6246 42.5359 45.4403 42.0909 45.1123 41.7627L39.8622 36.5127C39.5322 36.194 39.0901 36.0176 38.6313 36.0216C38.1725 36.0255 37.7335 36.2096 37.4091 36.534C37.0846 36.8585 36.9006 37.2974 36.8966 37.7563C36.8926 38.2151 37.069 38.6572 37.3878 38.9872L39.6505 41.25H27.25C26.7859 41.25 26.3408 41.4344 26.0126 41.7625C25.6844 42.0907 25.5 42.5359 25.5 43Z'
      fill='black'
    />
  </svg>
);

export default LogoutIcon;
