export const register = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(() => {
        // Service Worker registered with scope
      })
      .catch(() => {
        // Error registering service worker
      });
  }
};
