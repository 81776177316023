import React from 'react';

export interface CustomIconProps {
  filled?: boolean;
}

const BusinessHeader: React.FC<CustomIconProps> = () => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.575 6.31676C16.8667 5.53343 15.6833 5.14176 13.9667 5.14176H13.7667V5.10843C13.7667 3.70843 13.7667 1.9751 10.6333 1.9751H9.36666C6.23332 1.9751 6.23332 3.71676 6.23332 5.10843V5.1501H6.03332C4.30832 5.1501 3.13332 5.54176 2.42499 6.3251C1.59999 7.24176 1.62499 8.4751 1.70832 9.31676L1.71666 9.3751L1.77745 10.0134C1.79171 10.1632 1.87246 10.2985 1.99846 10.3808C2.20051 10.5126 2.51025 10.7114 2.69999 10.8168C2.81666 10.8918 2.94166 10.9584 3.06666 11.0251C4.49166 11.8084 6.05832 12.3334 7.64999 12.5918C7.72499 13.3751 8.06666 14.2918 9.89166 14.2918C11.7167 14.2918 12.075 13.3834 12.1333 12.5751C13.8333 12.3001 15.475 11.7084 16.9583 10.8418C17.0083 10.8168 17.0417 10.7918 17.0833 10.7668C17.4035 10.5858 17.7354 10.3641 18.04 10.1454C18.1532 10.0641 18.2254 9.93801 18.2408 9.79951L18.25 9.71676L18.2917 9.3251C18.3 9.2751 18.3 9.23343 18.3083 9.1751C18.375 8.33343 18.3583 7.18343 17.575 6.31676ZM10.9083 12.0251C10.9083 12.9084 10.9083 13.0418 9.88332 13.0418C8.85832 13.0418 8.85832 12.8834 8.85832 12.0334V10.9834H10.9083V12.0251ZM7.42499 5.14176V5.10843C7.42499 3.69176 7.42499 3.16676 9.36666 3.16676H10.6333C12.575 3.16676 12.575 3.7001 12.575 5.10843V5.1501H7.42499V5.14176Z'
        fill='currentColor'
      />
      <path
        d='M17.2597 12.009C17.6138 11.8417 18.021 12.1223 17.9856 12.5123L17.7 15.6583C17.525 17.3249 16.8417 19.0249 13.175 19.0249H6.82501C3.15834 19.0249 2.47501 17.3249 2.30001 15.6666L2.0293 12.6888C1.99426 12.3033 2.39258 12.0233 2.74561 12.1819C3.70482 12.613 5.31682 13.3064 6.3528 13.5854C6.51681 13.6295 6.64996 13.7476 6.72436 13.9003C7.23857 14.9555 8.32775 15.5166 9.89168 15.5166C11.4402 15.5166 12.5427 14.9339 13.0588 13.8755C13.1334 13.7227 13.2664 13.6047 13.4305 13.5603C14.5323 13.2618 16.2536 12.4845 17.2597 12.009Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default BusinessHeader;
