import { schoolApiClient } from './apiClients';
import { fetchAndCache } from '../components/shared/CacheStroage';
import { fetchAndCacheWithoutPage } from '../components/shared/CacheStroage/CacheWithoutPage';
import { getUserId } from '../components/shared/getToken';

export const getBlog = async ({
  categoryId,
  qry,
}: {
  categoryId?: number;
  qry?: string;
}) => {
  const params = new URLSearchParams();

  if (categoryId !== undefined) params.append('category', String(categoryId));
  if (qry !== undefined) {
    // Replace %2520 with %20 in qry
    const replacedQry = qry.replace(/%2520/g, '%20');
    params.append('qry', replacedQry);
  }

  const apiUrl = `public_api/schools${
    params.toString() ? `?${params.toString()}` : ''
  }`;

  const apiCall = async () => {
    const response = await schoolApiClient.get(apiUrl);
    return response?.data;
  };

  return fetchAndCacheWithoutPage(apiCall, apiUrl);
};
export const getBlogPagination = async ({
  page,
  categoriesId = null,
  keyword,
  category_name,
}: {
  page: string;
  categoriesId: string | null;
  keyword?: string;
  category_name?: string;
}) => {
  const limit = 10;
  const queryParams = new URLSearchParams({
    page,
    limit: limit.toString(),
    ...(categoriesId !== null && { category: categoriesId }),
    ...(keyword !== undefined && { qry: keyword }),
    ...(category_name !== undefined && { category_name }),
  });
  const apiCall = async () => {
    const response = await schoolApiClient.get(
      `public_api/schools/paginated?${queryParams}`,
    );

    return response?.data;
  };
  return fetchAndCache(
    apiCall,
    `public_api/schools/paginated?${queryParams}`,
    page,
  );
};

export const getBlogSingle = async (id: string) => {
  const userId = await getUserId();

  const response = await schoolApiClient.get(
    `public_api/school/school_slug:${id}`,
    { params: userId && { ecom_user_id: userId } },
  );

  return response?.data;
};
export const getPostSingle = async (id: string) => {
  const response = await schoolApiClient.get(`/public_api/schools/post/${id}`);

  return response?.data;
};

export const getCategories = async () => {
  const response = await schoolApiClient.get(`public_api/categories`);

  return response?.data;
};

export const getMetaTags = async (id: string) => {
  const response = await schoolApiClient.get(`public_api/pages/${id}`);

  return response?.data;
};

export const getMetaTagSchool = async (id: string) => {
  const response = await schoolApiClient.get(
    `public_api/school/meta_tags/school_slug:${id}`,
  );

  return response?.data;
};

export const getMetaTagBlog = async (id: string) => {
  const response = await schoolApiClient.get(
    `public_api/schools/post/meta_tags/${id}`,
  );

  return response?.data;
};
