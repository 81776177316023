import { Modal } from 'antd';
import React, { useState } from 'react';

import Login from '../../Auth/Login';

interface ModalComponentProps {
  visible: boolean;
  onCancel: () => void;
}

const LoginModal: React.FC<ModalComponentProps> = ({ visible, onCancel }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setConfirmLoading(false);
      onCancel();
    }, 2000);
  };

  return (
    <Modal
      title=''
      open={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      // footer={[
      //   <Button key='back' onClick={onCancel}>
      //     Cancel
      //   </Button>,
      //   <Button
      //     key='submit'
      //     type='primary'
      //     loading={confirmLoading}
      //     onClick={handleOk}
      //   >
      //     OK
      //   </Button>,
      // ]}
      footer={false}
    >
      <Login isModal={true} onCancel={onCancel} />
    </Modal>
  );
};

export default LoginModal;
