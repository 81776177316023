import { Avatar } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import { FC } from 'react';

import { IUserInfoInterface } from '..';
import MessageRender from '../../ResourcesPage/Editor/Reader/MessageRender';
import { ShouldRender } from '../../shared/basic/ShouldRender';

interface MessageProps {
  isSender: boolean;
  isTyping?: boolean;
  showSenderName: boolean;
  text?: any;
  user: IUserInfoInterface | undefined;
  createdAt?: string;
}

export function getUserInitials(fullName: string): string {
  if (!fullName) return '';
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
}

export const formatCreatedAt = (createdAt: string): string => {
  let time;
  if (createdAt.startsWith('Today')) {
    time = createdAt.replace('Today, ', '');
  } else if (createdAt.startsWith('Yesterday')) {
    time = createdAt.replace('Yesterday, ', '');
  } else {
    time = moment.utc(createdAt).format('hh:mm A');
  }
  return time;
};

export const Message: FC<MessageProps> = ({
  isSender,
  isTyping = false,
  text,
  user: userData,
  createdAt,
}) => {
  const messageClassName = clsx(
    'relative px-4 py-2.5 rounded-xl text-sm break-words max-w-full md:max-w-lg lg:max-w-xl',
    isSender
      ? 'bg-[#121212] text-white self-end rounded-br-none'
      : 'bg-[#121212] text-black rounded-bl-none',
    'relative',
  );

  const tailClasses = clsx(
    'absolute w-0 h-0 border-[6px] border-transparent',
    isSender
      ? 'border-r-[#121212] right-[-12px] top-[8px]'
      : 'border-l-[#121212] left-[-12px] top-[8px]',
  );

  const formattedTime = createdAt ? formatCreatedAt(createdAt) : '';

  return (
    <div
      className={clsx(
        'flex w-full px-2',
        isSender ? 'justify-end' : 'justify-start',
      )}
    >
      <div className='flex items-end gap-2'>
        {!isSender && (
          <Avatar
            size={40}
            alt='profile-picture'
            style={{ fontSize: '16px' }}
            className='flex-shrink-0 select-none bg-gray-900'
            draggable={false}
            shape='circle'
          >
            {getUserInitials(`${userData?.name}`)}
          </Avatar>
        )}
        <div
          className={clsx(
            'flex flex-col',
            isSender ? 'items-end' : 'items-start',
          )}
        >
          <div className={messageClassName}>
            <div className={tailClasses}></div>
            <ShouldRender check={text && text?.length > 0}>
              <MessageRender data={text} />
            </ShouldRender>
            <ShouldRender check={isTyping}>Typing...</ShouldRender>
            <div className='flex justify-end mt-1'>
              <span className='text-[10px] text-gray-400'>{formattedTime}</span>
            </div>
          </div>
        </div>
        {isSender && (
          <Avatar
            size={40}
            alt='profile-picture'
            className='flex-shrink-0 select-none bg-gray-900'
            draggable={false}
            shape='circle'
          >
            {getUserInitials(`${userData?.name}`)}
          </Avatar>
        )}
      </div>
    </div>
  );
};
