import React from 'react';

interface Props {
  isGray?: boolean;
}

const SearchIconSVG: React.FC<Props> = ({ isGray = false }) => (
  <svg
    width='15'
    height='15'
    viewBox='0 0 18 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='8.16667'
      cy='8.16667'
      r='6.66667'
      stroke={isGray ? '#A6A6A6' : '#FFFFFF'}
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.75 13.1308L16.9167 17.2975'
      stroke={isGray ? '#A6A6A6' : '#FFFFFF'}
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default SearchIconSVG;
