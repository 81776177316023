export const Endpoints = {
  login: `/api/v3/auth/login`,
  signUp: `/api/v3/auth/signup`,
  shortSignUp: `/api/v3/auth/signup/temp`,
  forgotPasswordSendEmail: `/api/v3/auth/password/forget_request`,
  forgotPasswordCheckOtp: `/api/v3/auth/password/confirm_otp`,
  forgotPasswordResetPassword: `/api/v3/auth/password/confirm_reset`,
  logOut: `/api/v3/auth/logout`,
  getAllAddresses: `/api/v3/user/shipping/address`,
  getAllCountries: `/api/v3/countries`,
  getStates: `/api/v3/states-by-country`,
  getCities: `/api/v3/cities-by-state`,
  createAddress: `api/v3/user/shipping/create`,
  updateAddress: `api/v3/user/shipping/update`,
  deleteAddress: `api/v3/user/shipping/delete`,
  getUser: `api/v3/auth/user`,
  updateProfileImage: `api/v3/profile/update-image`,
  updateDefaultAddress: `api/v3/user/shipping/make_default`,
  updateProfile: `api/v3/profile/update`,
  getAllOrderHistory: `api/v3/purchase-history`,
  getAllInAppOrderHistory: `api/v3/digital/in-app-purchased-list`,
  getInAppOrderDetails: `api/v3/digital/in-app-purchased-details`,
  orderCancel: `api/v3/order-cancel-requests`,

  getOrderDetails: `/api/v3/purchase-history-details`,
  getCartDetails: `/api/v3/carts`,
  mergeAllCarts: `/api/v3/carts/merge-all`,
  getAllProducts: `/api/v3/products`,
  addToCart: `/api/v3/carts/add`,
  getCartCount: `/api/v3/cart-count`,
  updateQuantity: `/api/v3/carts/change-quantity`,
  emailExist: `/api/v3/auth/email-exist`,
  syncCart: `/api/v3/carts/user-assign`,
  cartSummary: `/api/v3/cart-summary`,
  applyCoupon: `/api/v3/coupon-apply`,
  updateCartAddress: `/api/v3/update-address-in-cart`,
  placeOrder: `/api/v3/order/store`,
  razorpayPayment: `/api/v3/razorpay/payment`,
  orderDetails: `/api/v3/order`,
  productDetails: `/api/v3/products/slug`,
  removeCoupon: `/api/v3/coupon-remove`,
  checkCoupon: `/api/v3/coupon-check`,
  bookMetaData: `/api/v3/products/metadata/slug`,
  contactUs: `/api/v3/contact-us`,
  paymentFailed: `/api/v3/razorpay/payment/failure`,
  convertToCod: `/api/v3/order/complete-with-cod`,
  recentlyViewed: `/api/v2/recently-viewed`,

  invoice: `/api/v3/invoice/download`,
  subscribe: `/api/v3/subscribe`,

  syncOrder: `/api/v3/order/sync`,
  syncOrderInApp: `/api/v3/order/in-app-sync`,

  updateAddressOnBlur: `api/v3/temp-cart-users`,

  // krishna's changes
  resetPassword: `/api/v3/password/change_password`,

  // library pdf
  getAllBooks: `api/v2/books`,
  getAllShortBooks: `api/v2/books/shorts`,
  getMyBooks: `api/v2/my-book`,
  getAuthorBooks: `api/v2/books`,
  getCoverImagesForBooks: `api/v2/books/cover-file`,
  getSampleFileForBooks: `api/v2/books/sample-file`,
  getSampleFileForBooksByProductId: `api/v2/books/sample-file/productId`,
  getAllChapters: `api/v2/chapters`,
  getChapterFile: `api/v2/chapters/file-stream`,
  getBookmarkslist: `api/v2/bookmarks`,
  myBookSyncList: `api/v2/my-book/sync`,
  getSampleBook: `api/v2/books/sample-file-stream`,
  getBookDetailsBySlug: `api/v2/books/details`,
  getBookToken: `api/v2/auth/file-token`,
  postReadingLogs: `api/v2/reading-logs`,
  createSecret: `api/v2/webhook/appwrite/create-secret`,

  // courses
  getAllCourses: '/api/v2/v2/get/all-courses',
  getMyCourses: '/api/v2/v2/get/my-courses',
  getAuthorCourses: '/api/v2/v2/get/all-courses',
  coursesAllCategories: `/api/v2/v2/get/categories?unique=1`,
  syncCourses: `/api/v2/v2/my-courses/sync`,
  //schools
  readingHistory: `/public_api/schools/reading-history`,
  readingHistoryUpdate: `/public_api/schools/reading-history`,

  // Author
  authorProfile: `public_api/author`,
  featuredProfile: `/public_api/author-featured`,
  authorSchools: `/public_api/school`,
  authorLive: `/public_api/live-work-shops`,

  //  Live
  getCurrentLive: '/public_api/get-current-live',
  getUpcomingLive: '/public_api/get-upcoming-live',
  getLiveDetails: '/public_api/live-work-shop/slug:',
  getLiveSessionById: '/public_api/live-work-shop-sessions',
  getSessionCommentBySessionId:
    '/public_api/session-comments?live_workshop_session_id=',
  postComment: '/public_api/session-comments',
  register: '/public_api/live-registration',
  getLiveBanner: '/public_api/live-work-shops',
  registerStatus: `/public_api/already-registered`,
  liveRegistrationAlreadyRegistered: `/public_api/live-registration-new`,

  //Chat
  getThreads: `/api/v3/chat-threads`,
  getChatCount: `/api/v3/chat-count`,

  //Affiliate
  linkClick: `/api/v2/affiliate/link-clicked`,
  affiliateCode: `/api/v3/affiliate-customers`,

  // Book Categories
  getAllBookCategories: `/api/v2/categories`,
  getAllOnboardingCategories: `/api/v2/personality-test/category`,

  // AppWrite Api
  appWriteUser: `/api/v3/auth/appwrite-user`,

  // Temp-user Iframe
  tempUser: `/api/v3/temp-users`,
  checkAffiliateCode: `/api/v3/affiliate/code-check`,

  // firebase
  fireBaseTokens: `/api/v3/fire-base-tokens`,

  // Notifications
  notifications: `/api/v2/user-notification`,
  firebaseTokenNotification: `/api/v2/firebase-token`,
  deleteFireBaseToken: `/api/v2/firebase-token-delete`,
  userSyncNotification: `/api/v2/appwrite-user`,

  // Banner
  allBannerPages: `/api/v2/banner-page`,
  allBanners: `/api/v2/banner`,

  // Landing Page
  getLandingPageData: `/api/v2/landing-page/slug-heal`,

  // Avatar Image
  getAuthorAvatar: `/api/v2/authors/avatar`,

  // Subscribe Coming soon books
  comingSoonSubscribe: `/api/v2/subscribe`,

  //School
  likesSchool: `api/v2/likes`,

  followAuthor: `api/v2/author-followers`,

  // Ai Chat
  askQuestion: `/api/ask_question`,

  // Reviews
  getAllProductReviews: `api/v2/review`,
  getUserProductReview: `api/v2/review/user`,
  checkIsPurchased: `api/v3/is-purchased`,

  // Personality Test
  getAllCategory: `api/v2/personality-test/category`,
  questions: 'api/v2/personality-test/question',
  options: 'api/v2/personality-test/option',
  deleteOption: 'api/v2/personality-test/option',
  getUserSubmission: 'api/v2/personality-test/user-submission/users',
  submitResponse: `api/v2/personality-test/user-submission`,
  getResultData: `api/v2/personality-test/results`,

  // Reward
  claimReward: `api/v3/order/claim_product`,
  walletTransactions: `api/v3/wallet/transactions`,
  inviteFriend: `api/v3/invite-users`,
  getRewardRule: `api/v3/get-reward-value`,
  invitedUser: `api/v3/user/referrals`,
  getAllRewards: `api/v3/user-rewards`,
  claimRewardDynamic: `api/v3/claim-reward`,

  //onboarding-response
  submitOnboardingResponse: `api/v2/onboarding-user-submission`,
  resultOnboardingResponse: `api/v2/onboarding-user-submission/result`,

  // Get Author
  getAuthorByZl_uid: `public_api/author_by_zl_uid`,
};
