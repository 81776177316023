export const fetchAndCacheWithoutPage = async <T>(
  apiCall: () => Promise<T>,
  apiUrl: string
): Promise<T> => {
  const cachedData = localStorage.getItem(apiUrl);
  if (cachedData) {
    const parsedData = JSON.parse(cachedData);
    const { timestamp, data } = parsedData;
    const now = new Date().getTime();
    const expiryDays = process.env.NEXT_PUBLIC_CASH_STORAGE_EXPIRY || 3;
    if (now - timestamp < Number(expiryDays) * 60 * 60 * 1000) {
      // Check if data is not older than 10 hours
      return data;
    }
  }

  const response = await apiCall();
  const timestamp = new Date().getTime();
  localStorage.setItem(apiUrl, JSON.stringify({ timestamp, data: response }));
  return response;
};
