import { Layout, Statistic, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import clsx from 'clsx';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import { ZebraLearnLogo } from '../../assets';

const { Countdown } = Statistic;
const { Content } = Layout;
const { Title } = Typography;

const Custom503: React.FC = () => {
  const targetTime = process.env.NEXT_PUBLIC_MAINTENANCE_WINDOW || '20:00';
  const [deadline, setDeadline] = useState<number | null>(null);

  useEffect(() => {
    const now = new Date();
    const [hours, minutes] = targetTime.split(':').map(Number);

    const targetDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours,
      minutes,
      0,
      0,
    );

    if (targetDate.getTime() <= now.getTime()) {
      targetDate.setDate(targetDate.getDate() + 1);
    }

    setDeadline(targetDate.getTime());
  }, [targetTime]);

  const handleFinish = () => {
    setDeadline(Date.now() + 4 * 60 * 60 * 1000);
  };

  return (
    <Layout
      style={{ minHeight: '100vh', background: '#131313', color: '#fff' }}
    >
      <Header
        style={{
          background: '#000E5',
          color: '#fff',
          padding: '30px 50px 30px 50px',
        }}
      >
        <nav
          className={clsx(
            'flex  z-50   items-center  w-full h-full top-0  bg-opacity-75 backdrop-filter backdrop-blur-md',
          )}
        >
          <div className='flex  w-full  justify-start items-center'>
            <span>
              <Image
                src={ZebraLearnLogo}
                alt='Logo'
                priority
                className='object-contain w-[125px] h-[14px]'
              />
            </span>
          </div>
        </nav>
      </Header>

      <Content
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          gap: '12px',
        }}
      >
        <Title style={{ color: '#fff' }} level={1}>
          {deadline ? (
            <Countdown
              value={deadline}
              format='HH:mm:ss'
              onFinish={handleFinish}
              className='text-white'
            />
          ) : (
            'Loading...'
          )}
        </Title>
        <div className='flex gap-y-4 flex-col -translate-y-5'>
          <div className='text-xl font-semibold text-white'>
            Site Under Maintenance
          </div>
          <div className='flex justify-center'>
            <div className='text-[#A7A7A7] w-2/3 text-base text-center'>
              We're tidying up behind the scenes! 🛠️ Check soon for a smoother
              experience. See you soon! 😊
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default Custom503;
