import { Skeleton } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { useEffect, useState } from 'react';

import SchoolFeaturedCard from '../SchoolCard';
import { getBlogPagination } from '../../../../services/blog';
import { PaginationProps } from '../../../../utils/interfaces/Orders';
import { SchoolsDashBoardData } from '../../../../utils/interfaces/Schools';
import { ShouldRender } from '../../../shared/basic/ShouldRender';
import { notify } from '../../../shared/notify';
import HomepageSlider from '../../../V2/HomePage/HomepageSlider';

interface Props {
  categoryName?: string;
  categoryId?: string | null;
  search?: string;
  category_name?: string;
}

const PaginatedSchoolSlider: React.FC<Props> = ({
  categoryId,
  categoryName,
  search,
  category_name,
}) => {
  const [schoolCardDataPagination, setSchoolDataPagination] = useState<
    SchoolsDashBoardData[]
  >([]);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [isPaginateShift, setIsPaginateShift] = useState(true);
  const [paginationData, setPaginationData] = useState<PaginationProps>();
  const screens = useBreakpoint();

  const handleFetchCategoryWise = async () => {
    try {
      setLoading(true);

      const response = await getBlogPagination({
        page: page.toString(),
        categoriesId: categoryId ?? null,
        keyword: search,
        ...(category_name && { category_name }),
      });

      const { data } = response;

      const transformedData = data?.data?.map((school: any) => ({
        id: school.id,
        desc: school.name,
        chapterNum: school.posts_count,
        image: school.documents.file_path,
        chaptersRead: school?.post_read_count,
        slug: school?.slug,
      }));
      setPaginationData({
        current_page: data?.current_page,
        per_page: data?.per_page,
        total: data?.total,
      });
      if (page === 1) {
        setSchoolDataPagination(transformedData);
      } else {
        setSchoolDataPagination((prevData) => [
          ...prevData,
          ...transformedData,
        ]);
      }
    } catch (error: any) {
      notify('Something went wrong', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchCategoryWise();
  }, [page, categoryId, search]);

  const handlePagination = () => {
    if (
      paginationData &&
      schoolCardDataPagination.length < paginationData?.total
    )
      setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <ShouldRender check={schoolCardDataPagination.length || loading}>
        <HomepageSlider
          heading={categoryName as string}
          onPagination={handlePagination}
          setIsPaginateShift={setIsPaginateShift}
          isPaginateShift={isPaginateShift}
          loading={!!schoolCardDataPagination?.length}
          total={paginationData?.total || 0}
          imageWidths={[120, 170, 200]}
        >
          {schoolCardDataPagination?.map((item, index) => (
            <span
              className='group cursor-pointer p-1 lg:p-1.5  no-select'
              key={index}
            >
              <SchoolFeaturedCard item={item} isGrid={true} />
            </span>
          ))}
          <ShouldRender check={loading || isPaginateShift}>
            {Array.from({ length: 10 }).map((_, index) => (
              <span
                key={index}
                className='group cursor-pointer p-1 md:p-1.5 flex flex-col gap-y-1 bg-transparent no-select '
              >
                <Skeleton.Button
                  style={{
                    width: screens.xl
                      ? '200px'
                      : screens.lg
                        ? '150px'
                        : '120px',
                    height: screens.xl
                      ? '200px'
                      : screens.lg
                        ? '150px'
                        : '120px',
                  }}
                  active={true}
                  className='rounded-sm md:rounded-md transition-transform transform  duration-500 max-w-[120px] lg:max-w-[140px] xl:max-w-[220px]'
                />
                <Skeleton.Button
                  style={{
                    width: screens.xl ? '100px' : screens.lg ? '50px' : '20px',
                    height: '8px',
                    fontSize: '7px',
                  }}
                  active={true}
                  className='rounded-sm  transition-transform transform  duration-500 '
                />
                <Skeleton.Button
                  style={{
                    // width: screens.xl ? '100px' : screens.lg ? '50px' : '20px',
                    height: '12px',
                    fontSize: '7px',
                  }}
                  active={true}
                  className='rounded-sm w-full  -translate-y-1 transition-transform transform  duration-500 max-w-[120px] lg:max-w-[140px] xl:max-w-[220px]'
                />
              </span>
            ))}
          </ShouldRender>
        </HomepageSlider>
      </ShouldRender>
    </>
  );
};

export default PaginatedSchoolSlider;
