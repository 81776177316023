import { ChevronUpIcon } from '@heroicons/react/outline';
import { Button } from 'antd';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import React from 'react';

interface ExpandableTextProps {
  text: string;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState('10rem');
  const [showToggle, setShowToggle] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      if (contentHeight > 160) {
        // 160px is equivalent to 10rem
        setShowToggle(true);
      } else {
        setShowToggle(false);
        setMaxHeight('none'); // Allow full text to be shown without expansion
      }
    }
  }, [text]);

  useEffect(() => {
    if (contentRef.current && showToggle) {
      setMaxHeight(
        isExpanded ? `${contentRef.current.scrollHeight}px` : '10rem',
      );
    }
  }, [isExpanded, showToggle]);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div>
      <div className='flex flex-col gap-y-2'>
        <div
          className='relative overflow-hidden transition-all duration-500'
          style={{ maxHeight }}
        >
          <div
            ref={contentRef}
            className='text-sm text-white flex flex-col gap-y-4'
          >
            <p>{text}</p>
          </div>
          {showToggle && !isExpanded && (
            <div className='absolute bottom-0 w-full h-12 bg-gradient-to-t from-black to-transparent'></div>
          )}
        </div>
        {showToggle && (
          <div
            onClick={toggleExpand}
            className='flex justify-start cursor-pointer'
          >
            <Button type='link' className='text-white text-sm font-semibold'>
              {isExpanded ? 'Show Less' : 'Show More'}
            </Button>

            <ChevronUpIcon
              className={clsx(!isExpanded && 'rotate-180', 'w-4 mt-1')}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpandableText;
