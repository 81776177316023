import { apiClient } from '../apiClients';
import { Endpoints } from '../../network';

export const getChatThreads = async () => {
  const response = await apiClient.get(`${Endpoints.getThreads}`);

  return response?.data;
};

export const getSingleChatThreads = async (id: string) => {
  const response = await apiClient.get(`${Endpoints.getThreads}/${id}`);

  return response?.data;
};

export const getChatCount = async () => {
  const response = await apiClient.get(`${Endpoints.getChatCount}`);

  return response?.data?.data;
};
