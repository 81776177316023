import { XIcon } from '@heroicons/react/outline';
import {
  Form,
  Input,
  message,
  Modal,
  Rate,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import ImgCrop from 'antd-img-crop';
import BadWordsNext from 'bad-words-next';
import en from 'bad-words-next/data/en.json';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IReviewsData } from '.';
import { extractSlugAndProductId } from '../SlidBookContainer';
import ButtonDefault, { ButtonVariants } from '../../shared/basic/button';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { formateErrorObject } from '../../shared/formateErrorObject';
import { notify } from '../../shared/notify';
import AddImage from '../../../../assets/Icons/AddImage';
import LogoutIcon from '../../../../assets/Icons/LogoutIcon';
import { authModalControl } from '../../../atoms/authModalControl';
import { isRatingRefreshAtom } from '../../../atoms/isRatingRefreshAtom';
import { user } from '../../../atoms/user';
import {
  createReview,
  updateReview,
} from '../../../services/ReviewLandingPage';
import { User } from '../../../utils/interfaces/User';

const filterWords = new BadWordsNext({ data: en });

interface CreateReviewModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  type: 'create' | 'edit';
  review?: IReviewsData | undefined;
  fetchData: () => Promise<void>;
  isLoggedIn: boolean;
  isPurchased: boolean;
}

const TIMEOUT_DURATION = 3 * 60 * 1000; // 3 minutes in milliseconds

interface FormValues {
  rating: number;
  review: string;
}

const CreateReviewModal: React.FC<CreateReviewModalProps> = ({
  isModalOpen,
  handleClose,
  review,
  fetchData,
  isLoggedIn,
  isPurchased,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<FormValues>();
  const userData: User | undefined = useRecoilValue(user);
  const [deletedImage, setDeletedImage] = useState<string[]>([]);
  const [, setIsRatingRefresh] = useRecoilState(isRatingRefreshAtom);
  const [showLoginOverlay, setShowLoginOverlay] = useState<boolean>(false);
  const [, setVisible] = useRecoilState(authModalControl);
  const router = useRouter();
  const screens = useBreakpoint();

  const [formData, setFormData] = useState({
    rating: 0,
    review: '',
    images: [] as UploadFile[],
  });

  const saveFormDataToLocalStorage = (data: typeof formData) => {
    if (typeof localStorage === 'undefined') {
      return;
    }
    localStorage.setItem('reviewFormData', JSON.stringify(data));
    localStorage.setItem('reviewFormTimestamp', Date.now().toString());
  };

  // Load form data from localStorage if available and not in edit mode
  useEffect(() => {
    if (typeof localStorage === 'undefined') {
      return;
    }
    const savedData = localStorage.getItem('reviewFormData');
    const savedTimestamp = localStorage.getItem('reviewFormTimestamp');
    const isWithinTimeout =
      savedTimestamp &&
      Date.now() - parseInt(savedTimestamp) < TIMEOUT_DURATION;

    if (savedData && isWithinTimeout && !review && isModalOpen) {
      setFormData(JSON.parse(savedData));
      setFormData((prev) => ({
        ...prev,
        rating: JSON.parse(savedData).rating,
        review: JSON.parse(savedData).review,
      }));

      form.setFieldsValue({
        rating: JSON.parse(savedData).rating,
        review: JSON.parse(savedData).review,
      });
    }
  }, [review, isModalOpen]);

  useEffect(() => {
    if (
      formData.review ||
      formData.rating ||
      (formData.images && formData.images.length > 0)
    ) {
      saveFormDataToLocalStorage(formData);
    }
  }, [formData]);

  const handleInputChange = (changedValues: any) => {
    const { review: reviewText } = changedValues;
    const sanitizedReview = reviewText
      ? filterWords.filter(reviewText)
      : reviewText;

    setFormData((prev) => ({
      ...prev,
      ...changedValues,
      review: sanitizedReview,
    }));
  };

  const handleUpload: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (newFileList.length <= 5) {
      setFormData((prev) => ({
        ...prev,
        images: newFileList,
      }));
    } else {
      message.error('You can upload up to 5 images only.');
    }
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as File);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleSubmit = async () => {
    const { rating, review: reviewText, images } = formData;
    const { name, zl_uid, avatar } = userData || {};
    const productId = extractSlugAndProductId(router.asPath);

    if (!productId || (!review && !reviewText) || !rating) {
      message.error('Review and rating are required.');
      return;
    }

    setLoading(true);
    if (!isLoggedIn) {
      setShowLoginOverlay(true);
      setLoading(false);
      return;
    }
    try {
      const mappedImages = images.map((file) => file.url || file.originFileObj);
      const reviewData = {
        rating,
        title: 'to-do',
        text: reviewText,
        images: mappedImages,
        userName: name as string,
        userId: zl_uid as string,
        ...(avatar && { userImage: avatar as string }),
      };

      if (!isPurchased) {
        notify('You need to purchase this product to submit a review!', 'info');
        handleCancel();
        setLoading(false);
        return;
      }
      if (review) {
        const updatedFields = {
          ...(rating !== review.rating && { rating }),
          ...(reviewText !== review.text && { text: reviewText }),
          ...(JSON.stringify(mappedImages) !==
            JSON.stringify(review.images) && {
            images: mappedImages,
          }),
        };

        if (Object.keys(updatedFields).length > 0) {
          await updateReview(updatedFields, review._id as string, deletedImage);
        }
        setIsRatingRefresh(true);
        setDeletedImage([]);
      } else {
        await createReview(reviewData, productId as string);
      }
      handleCancel();
      await fetchData();
    } catch (error: any) {
      notify(formateErrorObject(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    handleClose();
    setFormData({
      rating: 0,
      review: '',
      images: [] as UploadFile[],
    });
    setDeletedImage([]);
    form.setFieldsValue({
      rating: 0,
      review: '',
    });
    handleCleanLocalStorage();
    const { isReview, ...restQueries } = router.query;

    router.push(
      {
        pathname: router.pathname,
        query: {
          ...restQueries,
        },
      },
      undefined,
      { shallow: true },
    );
  };

  const handleCleanLocalStorage = () => {
    if (typeof localStorage === 'undefined') {
      return;
    }
    localStorage.removeItem('reviewFormData');
    localStorage.removeItem('reviewFormTimestamp');
  };

  useEffect(() => {
    if (review) {
      if (review.images.length) {
        const initialFileList: UploadFile[] = review.images.map(
          (url, index) => ({
            uid: url?.imageId,
            name: `image-${index}.jpg`,
            status: 'done' as const,
            url: url.imageURL,
          }),
        );
        setFormData((prev) => ({
          ...prev,
          images: initialFileList,
        }));
      }

      setFormData((prev) => ({
        ...prev,
        rating: review.rating,
        review: review.text,
      }));

      form.setFieldsValue({
        rating: review.rating,
        review: review.text,
      });
    }
  }, [review, isModalOpen]);

  useEffect(() => {
    if (isLoggedIn) {
      setShowLoginOverlay(!isLoggedIn);
    }
  }, [isLoggedIn]);

  return (
    <Modal
      closable={false}
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      footer={
        !showLoginOverlay ? (
          <div className='flex gap-x-5 pb-6 pt-4 w-full px-4 md:px-8 bg-opacity-70 backdrop-filter  md:backdrop-blur-none'>
            <ButtonDefault
              size={4}
              rounded
              className='w-1/3'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleCancel();
              }}
              variant={ButtonVariants.GRAY}
            >
              <span className='flex text-sm gap-x-2 text-[#4B4B4B] px-4 whitespace-nowrap'>
                Cancel
              </span>
            </ButtonDefault>
            <ButtonDefault
              size={4}
              loading={loading}
              rounded
              disabled={loading}
              className='w-2/3'
              onClick={handleSubmit}
              variant={ButtonVariants.WHITE}
            >
              <span className='flex text-sm gap-x-2 px-4 whitespace-nowrap'>
                Submit
              </span>
            </ButtonDefault>
          </div>
        ) : (
          false
        )
      }
      className='customLoginModal  bg-[#131313] backdrop-filter backdrop-blur-sm m-0 relative bg-opacity-70 min-h-full md:min-h-min md:h-min md:rounded-2xl border-2 border-borderDarkGray'
      width={screens.md ? 450 : '100%'}
      destroyOnClose
    >
      {/* Modal Content */}
      <div className='flex flex-col min-h-full  justify-between h-full md:min-h-fit '>
        {/* Header Section */}
        <div className='flex justify-between  h-full items-center bg-backGroundReaderTabBg md:rounded-t-2xl px-4 md:px-8 py-4 xl:py-6'>
          <div className='flex flex-col gap-1'>
            <span>Write a Review</span>
            <span className='text-textGray text-sm'>
              Help someone find their next best read
            </span>
          </div>
          <span className='border rounded-full p-1.5 border-[#363636] flex justify-center items-center'>
            <XIcon
              onClick={handleCancel}
              className='w-5 h-5 text-[#A6A6A6] cursor-pointer'
            />
          </span>
        </div>

        {/* Body Section */}
        <div className='flex-grow px-7 pt-7 pb-2 relative overflow-y-auto h-full'>
          <Form
            form={form}
            layout='vertical'
            onFinish={handleSubmit}
            initialValues={{ rating: 0, review: '', images: [] }}
            onValuesChange={handleInputChange}
            className='space-y-4'
          >
            {/* Rating Input */}
            <Form.Item
              name='rating'
              label={
                <span className='text-sm font-semibold'>
                  How would you rate it?
                </span>
              }
              rules={[
                { required: true, message: 'Please provide a rating!' },
                {
                  validator: (_, value) =>
                    value && value > 0
                      ? Promise.resolve()
                      : Promise.reject('Please provide a rating!'),
                },
              ]}
            >
              <Rate
                style={{ fontSize: '22px' }}
                className='text-[#00A86B] ml-1'
                allowClear
                value={formData.rating}
                // allowHalf
              />
            </Form.Item>

            {/* Image Upload */}
            <Form.Item
              name='images'
              label={
                <span className='text-sm font-semibold'>Share a photo</span>
              }
            >
              <ImgCrop rotationSlider>
                <Upload
                  listType='picture-card'
                  fileList={formData.images}
                  onChange={handleUpload}
                  onPreview={onPreview}
                  onRemove={(value) => {
                    setDeletedImage((prev) => [...prev, value?.uid]);
                  }}
                  multiple
                  maxCount={5}
                  className='w-fit group group-hover:border-white'
                  headers={{ 'Content-Type': 'multipart/form-data' }}
                >
                  {formData.images.length < 5 && (
                    <div className='text-[#363636] group-hover:text-primary'>
                      <AddImage />
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            </Form.Item>

            {/* Review Text Input */}
            <Form.Item
              name='review'
              rules={[{ required: true, message: 'Please write a review!' }]}
            >
              <Input.TextArea
                autoSize={{ minRows: screens.md ? 5 : 8, maxRows: 10 }}
                placeholder='Write your review, what did you like or dislike?'
                className='text-white bg-transparent text-[16px]'
                value={formData.review}
              />
            </Form.Item>
          </Form>
          <ShouldRender check={showLoginOverlay}>
            <div className='absolute inset-0 bg-backGroundReaderTabBg  backdrop-filter backdrop-blur-[1.5px]   bg-opacity-60 flex flex-col items-center justify-center z-10 text-white p-4 md:p-8'>
              <LogoutIcon />
              <h2 className='text-2xl mb-4 mt-14'>You’re not logged in</h2>
              <p className='text-center text-sm text-textGrayLight mb-12'>
                Please login to submit your review and help others find their
                next best read.
              </p>

              <div className='w-2/3'>
                <ButtonDefault
                  size={1}
                  variant={ButtonVariants.WHITE}
                  className='p-1 w-full'
                  disabled={loading}
                  loading={loading}
                  roundedSm={true}
                  rounded={false}
                  enableScaling={false}
                  onClick={() =>
                    setVisible((prevState) => ({
                      ...prevState,
                      login: true,
                    }))
                  }
                >
                  <span className='flex text-sm font-bold gap-x-2  py-1 whitespace-nowrap'>
                    Login
                  </span>
                </ButtonDefault>
              </div>
            </div>
          </ShouldRender>
        </div>
      </div>
    </Modal>
  );
};

export default CreateReviewModal;
