import { LockClosedIcon } from '@heroicons/react/solid';
import { Radio, RadioChangeEvent } from 'antd';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { ShouldRender } from '../shared/basic/ShouldRender';
import { parseFormattedNumber } from '../shared/parseFormattedNumber';
import { tokenUpdate } from '../../atoms/authModalControl';
import appwriteService from '../../config/appwrite';
import { HDFCIcon, OnlinePayment } from '../../../assets';
import CardIcon from '../../../assets/Icons/CardIcon';

interface PaymentOptionsProps {
  selectedOption: string;
  onSelectOption: (e: RadioChangeEvent) => void;
  isCOD_Disabled: boolean;
  handlePaymentOptionClick: (
    value: 'razorpay' | 'cash_on_delivery' | 'wallet',
  ) => void;
  totalAmount: number;
  isIndia: boolean;
  orderLoading?: boolean;
  walletBalance?: number;
  walletDisable?: boolean;
  notRenderWallet?: boolean;
}

const PaymentInfo: React.FC<PaymentOptionsProps> = ({
  selectedOption,
  onSelectOption,
  isCOD_Disabled,
  handlePaymentOptionClick,
  totalAmount,
  isIndia,
  orderLoading,
  walletBalance,
  notRenderWallet = false,
  walletDisable,
}) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [isTokenChanged, setIsTokenChanged] = useRecoilState(tokenUpdate);
  const totalCartAmount = parseFormattedNumber(totalAmount?.toString());
  const allowedAmount = process.env.NEXT_PUBLIC_ALLOWABLE_COD_AMOUNT || 12000;
  const isCODUnavailable =
    isCOD_Disabled || totalCartAmount > Number(allowedAmount) || isIndia;
  const isWalletDisabled =
    walletDisable || (walletBalance || 0) < totalCartAmount;

  useEffect(() => {
    const fetchLoggedInStatus = async () => {
      const loggedInStatus = await appwriteService.isLoggedIn();
      setLoggedIn(loggedInStatus);
    };
    fetchLoggedInStatus();
    if (isTokenChanged) {
      setIsTokenChanged(false);
    }
  }, [appwriteService.account, isTokenChanged]);

  const offers = [
    {
      text: 'Instant ₹30 Cashback Offer',
      image: (
        <div className='flex h-[20px]'>
          <Image
            src={OnlinePayment}
            alt='OnlinePayment'
            className='object-contain w-full'
          />
        </div>
      ),
    },
    {
      text: '₹30 Instant Cashback Offer',
      image: (
        <div className='flex gap-x-2 md:gap-x-3 items-center bg-[#00281A] px-2 py-[2px]   text-[10px] whitespace-nowrap xxxs:text-xs text-[#009C63] font-semibold rounded-[6px]'>
          <span>All Cards</span>
          <CardIcon />
        </div>
      ),
      type: 'icon',
    },
    {
      text: 'No Cost EMI Offer',
      image: (
        <div className='flex gap-x-2 md:gap-x-3 items-center bg-[#002748] px-2 py-[2px]  text-xs text-white font-semibold rounded-[6px]'>
          <span className='text-[10px] xxxs:text-xs '>HDFC Bank</span>
          <div className='flex w-3 h-3'>
            <Image
              src={HDFCIcon}
              alt='HDFCIcon'
              className='object-contain w-full'
            />
          </div>
        </div>
      ),
    },
  ];
  return (
    <div className='shadow-md space-y-4 w-full text-white'>
      <div className='space-y-2 w-full'>
        <motion.div
          className={clsx(
            selectedOption === 'razorpay' ? 'pb-6 md:pb-7 ' : 'pb-5',
            `pt-6 px-2 xxxs:px-4 md:px-6 rounded-lg cursor-pointer hover:bg-[#101010] w-full ${
              selectedOption === 'razorpay' ? 'bg-[#101010]' : ''
            }`,
          )}
          onClick={() => !orderLoading && handlePaymentOptionClick('razorpay')}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          whileHover={{ scale: 1 }}
          whileTap={{ scale: 0.99 }}
        >
          <div className='flex items-start w-full '>
            <Radio
              value='razorpay'
              checked={selectedOption === 'razorpay'}
              onChange={onSelectOption}
              className='custom-radio-wrapper translate-y-[4px]'
            />
            <div className='  md:ml-2.5 w-full '>
              <div
                className={clsx(
                  ' flex translate-y-[2px] md:translate-y-0 w-full',
                )}
              >
                <div
                  className={clsx(
                    ' w-fit relative cursor-pointer overflow-hidden',
                  )}
                >
                  {/* Shimmer Effect */}
                  <motion.div
                    className='absolute top-0 left-0 z-30 cursor-pointer h-full rounded-[5px]  w-full '
                    style={{
                      background:
                        'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%)',
                      transform: 'translateX(-100%)',
                    }}
                    animate={{ translateX: ['-100%', '100%'] }}
                    transition={{
                      duration: 2,
                      ease: 'linear',
                      repeat: Infinity,
                    }}
                  />

                  {/* Button */}
                  <div className='text-white text-[9px] z-20  bg-[#0380F3]f rounded-[5px]  px-[8px]  py-[4px] font-semibold  bg-[#0380F3]'>
                    Most Used
                  </div>
                </div>
              </div>
              <h3
                className={clsx(
                  'text-xs xs:text-sm font-medium leading-6 mt-3',
                )}
              >
                Pre-Paid (UPI, Credit card, Debit card)
              </h3>

              <span className='text-[10px] md:text-xs flex  gap-x-1.5 items-center text-[#646464]'>
                <span className=''>
                  <LockClosedIcon className='w-3 h-3 -translate-y-[1px] text-[#626262]' />
                </span>
                All transactions are secure and encrypted
              </span>
              <div>
                <span className='bg-[#372800] mt-2.5 w-fit rounded-md flex justify-center'>
                  <span className='font-semibold uppercase text-[10px] xxxs:text-xs px-2 xxxs:px-2.5 py-0.5 xs:py-1.5 tracking-wider whitespace-nowrap text-[#FFBA00] '>
                    GET Instant Digital Access
                  </span>
                </span>
              </div>

              <ul className='list-disc mt-5'>
                {offers.map((offer, index) => (
                  <li
                    key={index}
                    className='flex list-disc items-center mt-2 md:mt-4 space-x-2 md:space-x-3'
                  >
                    <div className='flex gap-x-1 md:gap-x-2 items-center text-[#A7A7A7]'>
                      <p className=' text-[9px] xs:text-[11px]  md:text-sm font-medium'>
                        {' '}
                        •
                      </p>
                      <span className=' text-[9px] xs:text-[11px]  md:text-sm font-medium'>
                        {offer.text}
                      </span>
                    </div>

                    {offer.image}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </motion.div>

        <motion.div
          className={clsx(
            selectedOption === 'cash_on_delivery' ? 'pb-5' : 'pb-5',
            isCODUnavailable && 'cursor-not-allowed text-gray-200',
            `pt-6 px-4 md:px-6 rounded-lg cursor-pointer hover:bg-[#101010] ${
              selectedOption === 'cash_on_delivery' ? 'bg-[#101010]' : ''
            }`,
          )}
          onClick={() =>
            !isCODUnavailable &&
            !orderLoading &&
            handlePaymentOptionClick('cash_on_delivery')
          }
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          whileHover={{ scale: 1 }}
          whileTap={{ scale: 0.99 }}
        >
          <div
            className={clsx(
              isCODUnavailable && 'text-gray-300 cursor-not-allowed',
              'flex items-start',
            )}
          >
            <Radio
              value='cash_on_delivery'
              checked={selectedOption === 'cash_on_delivery'}
              onChange={onSelectOption}
              disabled={isCODUnavailable}
              className=' custom-radio-wrapper custom-radio-wrapperCod '
            />
            <div
              className={clsx(
                isCODUnavailable && 'text-gray-300 cursor-not-allowed',
                ' md:ml-2.5',
              )}
            >
              <h3
                className={clsx(
                  selectedOption === 'cash_on_delivery' ? '' : '',
                  'text-sm pl-1 md:pl-0 md:text-base font-medium leading-6',
                )}
              >
                Cash on delivery (COD)
              </h3>
              {/* <ShouldRender check={selectedOption === 'cash_on_delivery'}>
                <div>
                  <span className='bg-[#022342] w-fit rounded-md flex justify-center mt-3.5'>
                    <span className='font-semibold uppercase text-[10px] xxxs:text-xs px-2 xxxs:px-2.5 py-1.5 tracking-wider whitespace-nowrap text-[#0380F3] '>
                      Digital Access AFTER DELIVERY
                    </span>
                  </span>
                </div>
              </ShouldRender> */}

              <ShouldRender check={isCOD_Disabled}>
                <span>
                  <p className='text-xs md:text-sm text-[#626262]  pl-3 mt-2'>
                    <b>•&#160;</b> Cash on Delivery is unavailable as digital
                    items are present in your cart.
                  </p>
                </span>
              </ShouldRender>
              <ShouldRender check={isIndia}>
                <span>
                  <p className='text-xs md:text-sm text-[#626262] pl-3 mt-2'>
                    <b>•&#160;</b> COD not available for locations outside
                    India.
                  </p>
                </span>
              </ShouldRender>
              <ShouldRender check={totalCartAmount > Number(allowedAmount)}>
                <span>
                  <p className='text-xs md:text-sm text-[#626262]  pl-3 mt-2'>
                    <b>•&#160;</b> COD unavailable for cart amount above{' '}
                    {Number(allowedAmount)}
                  </p>
                </span>
              </ShouldRender>
            </div>
          </div>
        </motion.div>
        <ShouldRender check={loggedIn && !notRenderWallet}>
          <motion.div
            className={clsx(
              'pt-6 px-4 md:px-6 rounded-lg cursor-pointer transition-all duration-200',
              selectedOption === 'wallet'
                ? 'bg-[#101010]'
                : 'hover:bg-[#101010]',
              isWalletDisabled ? 'cursor-not-allowed text-gray-300' : '',
              'pb-5',
            )}
            onClick={() => {
              if (!isWalletDisabled && !orderLoading)
                handlePaymentOptionClick('wallet');
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            whileHover={{ scale: isWalletDisabled ? 1 : 1.02 }}
            whileTap={{ scale: isWalletDisabled ? 1 : 0.98 }}
            role='button'
            aria-disabled={isWalletDisabled}
          >
            <div className='flex items-start'>
              <Radio
                value='wallet'
                checked={selectedOption === 'wallet'}
                onChange={onSelectOption}
                disabled={isWalletDisabled}
                className='custom-radio-wrapper'
                aria-label='Select Wallet Transfer'
              />
              <div className='md:ml-2.5 flex-1'>
                <div className='flex justify-between w-full gap-x-2'>
                  <h3 className='text-sm pl-1 md:pl-0 md:text-base font-medium leading-6'>
                    Wallet Transfer
                  </h3>
                  <ShouldRender check={walletBalance}>
                    <div className='rounded-md  flex gap-x-3 items-center text-right'>
                      <p className='text-xs md:text-sm font-medium text-[#A7A7A7]'>
                        Balance:
                      </p>
                      <p className='text-base md:text-lg font-semibold text-[#009C63]'>
                        ₹{walletBalance?.toFixed(2)}
                      </p>
                    </div>
                  </ShouldRender>
                </div>
                {isWalletDisabled && (
                  <ul className='text-xs md:text-sm text-[#626262] pl-3 mt-2 list-disc'>
                    <li>
                      {walletBalance
                        ? `Insufficient wallet balance. Your wallet balance is ₹${walletBalance}, which is less than the total amount of ₹${totalCartAmount}.`
                        : 'Wallet balance unavailable.'}
                    </li>
                  </ul>
                )}
                {!isWalletDisabled && (
                  <div className='mt-3'>
                    <span className='bg-[#00281A] rounded-md inline-flex items-center justify-center px-2.5 py-1.5'>
                      <span className='text-[10px] xxxs:text-xs font-semibold uppercase tracking-wide text-[#009C63]'>
                        No Additional Charges
                      </span>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        </ShouldRender>
      </div>
    </div>
  );
};

export default PaymentInfo;
