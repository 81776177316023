import React from 'react';

import { CustomIconProps } from '../../src/utils/interfaces/Icon';

const ChatIcon: React.FC<CustomIconProps> = ({
  width,
  height,
  fill,
  ...rest
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : '24'}
      height={height ? height : '24'}
      viewBox='0 0 20 20'
      fill={fill ? fill : 'currentColor'}
      {...rest}
    >
      <path
        d='M8.05717 6.30469H5.52876C5.26694 6.30469 5.05469 6.02486 5.05469 5.67969C5.05469 5.33451 5.26694 5.05469 5.52876 5.05469H8.05717C8.31899 5.05469 8.53125 5.33451 8.53125 5.67969C8.53125 6.02486 8.31899 6.30469 8.05717 6.30469Z'
        fill='currentColor'
      />
      <path
        d='M5.05469 8.88281C5.05469 8.53765 5.26732 8.25781 5.52961 8.25781H10.5954C10.8577 8.25781 11.0703 8.53765 11.0703 8.88281C11.0703 9.22798 10.8577 9.50781 10.5954 9.50781H5.52961C5.26732 9.50781 5.05469 9.22798 5.05469 8.88281Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.13867 0.041626H9.86133C11.0044 0.041626 11.9006 0.0416177 12.6209 0.100468C13.3543 0.160393 13.9602 0.284443 14.5087 0.563884C15.4103 1.02328 16.1433 1.75631 16.6027 2.65792C16.8822 3.20637 17.0062 3.81228 17.0662 4.54574C17.125 5.26603 17.125 6.16222 17.125 7.30531V9.60263C17.125 9.81638 17.125 9.94646 17.1197 10.0599C17.0028 12.5281 15.0282 14.5027 12.5599 14.6196C12.4465 14.625 12.3032 14.625 12.0657 14.625H12.042L11.9792 14.625C11.2566 14.6296 10.5527 14.8553 9.96192 15.2715L9.92092 15.3007L7.74533 16.8547C6.49158 17.7502 4.84407 16.457 5.41628 15.0264C5.49319 14.8341 5.35159 14.625 5.14451 14.625H4.64305C2.56201 14.625 0.875 12.938 0.875 10.8569V7.30533C0.875 6.16223 0.874992 5.26603 0.933842 4.54574C0.993767 3.81228 1.11782 3.20637 1.39726 2.65792C1.85665 1.75631 2.58968 1.02328 3.49129 0.563884C4.03974 0.284443 4.64566 0.160393 5.37912 0.100468C6.09941 0.0416177 6.9956 0.041626 8.13867 0.041626ZM5.4809 1.34632C4.82475 1.39993 4.40164 1.50295 4.05878 1.67764C3.39237 2.01719 2.85057 2.559 2.51102 3.22541C2.33633 3.56827 2.2333 3.99138 2.17969 4.64753C2.12548 5.31098 2.125 6.1562 2.125 7.33329V10.8569C2.125 12.2476 3.25237 13.375 4.64305 13.375H5.14451C6.23593 13.375 6.98222 14.4773 6.57688 15.4906C6.46831 15.762 6.78091 16.0075 7.01879 15.8375L9.24183 14.2497C10.0411 13.6865 10.9935 13.3813 11.9712 13.375L12.042 13.375C12.3101 13.375 12.4211 13.3748 12.5008 13.371C14.3252 13.2846 15.7847 11.8251 15.871 10.0008C15.8748 9.92079 15.875 9.82254 15.875 9.58229V7.33329C15.875 6.1562 15.8745 5.31098 15.8203 4.64753C15.7667 3.99138 15.6637 3.56827 15.489 3.22541C15.1494 2.559 14.6076 2.01719 13.9412 1.67764C13.5983 1.50295 13.1753 1.39993 12.5191 1.34632C11.8557 1.29211 11.0104 1.29163 9.83333 1.29163H8.16667C6.98957 1.29163 6.14435 1.29211 5.4809 1.34632Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ChatIcon;
