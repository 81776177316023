import { Divider, Input } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';

import AuthLayout from '../AuthLayout';
import ButtonDefault, { ButtonVariants } from '../../shared/basic/button';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { AppleLogo, GoogleIcon, ZebraLearnLogo } from '../../../../assets';
import EmailIcon from '../../../../assets/Icons/Mail';
import PasswordIcon from '../../../../assets/Icons/password';
import { authModalControl } from '../../../atoms/authModalControl';
import appwriteService from '../../../config/appwrite';
import { useAuth } from '../../../hooks/useAuth';

interface Props {
  isModal?: boolean;
  onCancel?: () => void;
}

const Login: React.FC<Props> = ({ isModal = false, onCancel }) => {
  const [googleLoading, setGoogleLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const showAppleLogin =
    process.env.NEXT_PUBLIC_SHOW_APPLE_LOGIN === 'showLogin';

  const router = useRouter();
  const { redirectUrl } = router.query;
  const { onLogin, loading } = useAuth();
  const [, setVisible] = useRecoilState(authModalControl);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      email: '',
      password: '',
    };
    if (!formData.email) {
      validationErrors.email = 'Email or phone number is required';
    } else if (!isValidEmailOrPhone(formData.email)) {
      validationErrors.email = 'Invalid email or phone number';
    }

    if (!formData.password) {
      validationErrors.password = 'Password is required';
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      const login_by = isValidEmail(formData.email) ? 'email' : 'phone';
      onLogin({
        email: formData.email,
        password: formData.password,
        login_by,
        redirectUrl: redirectUrl as string,
        isModal,
        onCancel,
      });
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const isValidEmailOrPhone = (value: string) => {
    // Your combined validation logic for email and phone number
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = /^\d{10}$/;

    return emailRegex.test(value) || phoneRegex.test(value);
  };

  const handleGoogleLogin = async () => {
    setGoogleLoading(true);
    try {
      await appwriteService.loginWithGoogle();
    } catch (error) {
      console.error('Error logging in with Google:', error);
    } finally {
      setGoogleLoading(false);
    }
  };

  const handleAppleLogin = async () => {
    setAppleLoading(true);
    try {
      await appwriteService.loginWithApple();
      router.push('/');
    } catch (error) {
      console.error('Error logging in with Google:', error);
    } finally {
      setAppleLoading(false);
      setVisible((prevState) => ({
        ...prevState,
        login: false,
        signUp: false,
      }));
    }
  };

  return (
    <AuthLayout isModal={isModal}>
      <div className='z-30 flex justify-center items-center py-10 min-h-screen'>
        <div className='bg-bgColor bg-opacity-45 max-w-[550px]   relative rounded-2xl md:border-2 border-borderDarkGray backdrop-filter backdrop-blur-sm'>
          <div className='flex items-center gap-y-10 flex-col justify-center px-5 md:px-10 h-min '>
            <div className='bg-[#311B01] text-sm z-40 absolute top-[50px] md:top-[65px] w-full text-[#DC9646] text-center  py-2  '>
              Can't log in? Use '
              <b
                onClick={() =>
                  setVisible((prevState) => ({
                    ...prevState,
                    login: false,
                    signUp: false,
                    forgotPassword: true,
                  }))
                }
                className='cursor-pointer'
              >
                Forgot Password
              </b>
              ' on the login page to reset.
            </div>

            <div className={` z-30  flex flex-col gap-y-1 w-full `}>
              <div className='relative pt-6'>
                <Link href='/'>
                  <span className=' flex justify-center w-full'>
                    <Image
                      src={ZebraLearnLogo}
                      alt='Logo'
                      priority
                      className='object-contain h-4 w-full'
                    />
                  </span>
                </Link>
              </div>
              <div className='border-b-2 border-backgroundGray py-1.5 md:py-3' />
              <div className='py-3 flex flex-col  mt-12 md:mt-10'>
                <h1 className='text-xl flex justify-center gap-x-2 font-medium  lg:font-semibold  leading-10 text-fadedWhite text-center w-full'>
                  Welcome to Zebralearn
                </h1>
                <span className='text-sm text-textDarkGrayColor text-center flex justify-center'>
                  Enter your credentials to login
                </span>
              </div>

              <form onSubmit={handleSubmit} className='flex flex-col gap-y-2 '>
                <div className='col-span-1 flex-col flex gap-y-.5'>
                  <Input
                    type='email'
                    name='email'
                    style={{
                      backgroundColor: '#000000',
                    }}
                    value={formData.email}
                    autoComplete='current-email'
                    placeholder='Email'
                    prefix={
                      <span className='pr-2'>
                        <EmailIcon filled={true} />
                      </span>
                    }
                    onChange={handleChange}
                    size='large'
                    className='border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-2 text-white bg-bgColor customInputBlack makeBgBlack'
                  />
                  {errors.email && (
                    <p className='text-red-500'>{errors.email}</p>
                  )}
                </div>
                <div className='col-span-1 flex-col flex'>
                  <Input.Password
                    type='password'
                    name='password'
                    autoFocus={false}
                    style={{
                      backgroundColor: '#000000',
                    }}
                    placeholder='Password'
                    value={formData.password}
                    prefix={
                      <span className='pr-2'>
                        <PasswordIcon />
                      </span>
                    }
                    onChange={handleChange}
                    size='large'
                    className='border text-sm focus-within:bg-bgColor placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray py-2 text-white bg-bgColor customInputBlack makeBgBlack'
                    autoComplete='current-password'
                  />
                  {errors.password && (
                    <p className='text-red-500'>{errors.password}</p>
                  )}
                  <span
                    onClick={() =>
                      setVisible((prevState) => ({
                        ...prevState,
                        login: false,
                        signUp: false,
                        forgotPassword: true,
                      }))
                    }
                    className='font-medium text-primary mt-1 cursor-pointer hover:text-darkPrimary'
                  >
                    Forgot password?
                  </span>
                </div>
                <span className='flex justify-center mt-2'>
                  <ButtonDefault
                    size={4}
                    variant={ButtonVariants.WHITE}
                    className='p-1 w-full'
                    disabled={loading}
                    loading={loading}
                    rounded={true}
                    enableScaling={false}
                    // onClick={() => router.push('/auth/login')}
                  >
                    <span className='flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap'>
                      Login
                    </span>
                  </ButtonDefault>
                </span>
                <span className='text-center font-medium flex text-fadedWhite tracking-wider justify-center text-sm pt-2'>
                  Don’t have an account?
                  <Link href='/auth/sign_up'>
                    <p className='text-primary  px-1 hover:font-bold hover:underline cursor-pointer transition-all duration-300 ease-in-out'>
                      Sign up
                    </p>
                  </Link>
                </span>
                <span className='flex justify-center'>
                  <span className='text-center text-base text-fadedWhite w-1/3'>
                    <Divider
                      style={{
                        margin: '8px 0',
                        // borderColor: '',
                        color: '#1C1C1C',
                      }}
                      className='text-fadedWhite text-sm'
                    >
                      OR
                    </Divider>
                  </span>
                </span>
                <span className='flex justify-center '>
                  <ButtonDefault
                    size={4}
                    variant={ButtonVariants.UNSTYLED}
                    className='disabled:bg-bgDarkGray hover:bg-[#181818] disabled:text-gray-300 text-fadedWhite bg-black w-full  border border-bgDarkGray shadow-sm text-base'
                    rounded={true}
                    loading={googleLoading}
                    spinColor='gray'
                    disabled={googleLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      handleGoogleLogin();
                    }}
                  >
                    <span className='flex relative w-full justify-center text-white   items-center font-medium gap-x-3 px-8  whitespace-nowrap'>
                      <span className='w-5 h-5 absolute left-0'>
                        <Image
                          src={GoogleIcon}
                          alt='GoogleLogo'
                          className='object-contain'
                        />
                      </span>{' '}
                      Continue with Google
                    </span>
                  </ButtonDefault>
                </span>
                <ShouldRender check={showAppleLogin}>
                  <span className='flex justify-center '>
                    <ButtonDefault
                      size={4}
                      variant={ButtonVariants.UNSTYLED}
                      loading={appleLoading}
                      spinColor='gray'
                      rounded={true}
                      disabled={appleLoading}
                      className='disabled:bg-bgDarkGray hover:bg-[#181818] disabled:text-gray-300 text-fadedWhite bg-black w-full  border border-bgDarkGray shadow-sm text-base'
                      onClick={(e) => {
                        e.preventDefault();
                        handleAppleLogin();
                      }}
                    >
                      <span className='flex relative w-full justify-center text-white   items-center font-medium gap-x-3 px-8  whitespace-nowrap'>
                        <span className='w-4 h-4 absolute left-0'>
                          <Image
                            src={AppleLogo}
                            alt='GoogleLogo'
                            className='object-contain'
                          />
                        </span>{' '}
                        Continue with Apple
                      </span>
                    </ButtonDefault>
                  </span>
                </ShouldRender>

                <div className='flex justify-center pb-4'>
                  <p className='text-center text-fadedWhite  text-sm  md:w-4/5'>
                    By using our service, you are agreeing to our{' '}
                    <a
                      href='/Privacy_Policy_Reader.html'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-primary font-medium cursor-pointer hover:text-darkPrimary'
                    >
                      Terms
                    </a>{' '}
                    and{' '}
                    <a
                      href='/Privacy_Policy_Reader.html'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-primary font-medium cursor-pointer hover:text-darkPrimary'
                    >
                      conditions
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
export default Login;
