import React from 'react';

import { CustomIconProps } from '../../src/utils/interfaces/Icon';

const ExclamationMarkCart: React.FC<CustomIconProps> = ({
  width,
  height,
  fill,
  ...rest
}) => {
  return (
    <svg
      width='64'
      height='64'
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipRule='url(#clip0_4272_27798)'>
        <path
          d='M32 0C14.3275 0 0 14.3275 0 32C0 49.6725 14.3275 64 32 64C49.6725 64 64 49.6725 64 32C64 14.3275 49.6725 0 32 0ZM36.4787 45.0213C36.4787 46.2091 36.0069 47.3483 35.167 48.1882C34.327 49.0281 33.1878 49.5 32 49.5C30.8122 49.5 29.673 49.0281 28.833 48.1882C27.9931 47.3483 27.5212 46.2091 27.5212 45.0213C27.5212 43.8334 27.9931 42.6942 28.833 41.8543C29.673 41.0144 30.8122 40.5425 32 40.5425C33.1878 40.5425 34.327 41.0144 35.167 41.8543C36.0069 42.6942 36.4787 43.8334 36.4787 45.0213ZM37.0737 19.1625L36.4825 34.0925C36.4363 35.2504 35.9439 36.3456 35.1083 37.1485C34.2727 37.9515 33.1588 38.4 32 38.4C30.8412 38.4 29.7273 37.9515 28.8917 37.1485C28.0561 36.3456 27.5637 35.2504 27.5175 34.0925L26.9263 19.1625C26.9025 18.559 27.0009 17.9569 27.2155 17.3924C27.43 16.8278 27.7564 16.3124 28.175 15.877C28.5935 15.4417 29.0957 15.0953 29.6514 14.8586C30.2071 14.622 30.8048 14.5 31.4088 14.5H32.5913C33.1952 14.5 33.7929 14.622 34.3486 14.8586C34.9043 15.0953 35.4065 15.4417 35.825 15.877C36.2436 16.3124 36.57 16.8278 36.7845 17.3924C36.9991 17.9569 37.0975 18.559 37.0737 19.1625Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_4272_27798'>
          <rect width='64' height='64' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExclamationMarkCart;
