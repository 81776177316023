import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  YoutubeFilled,
} from '@ant-design/icons';

import {
  Author,
  AuthorLarge,
  Book,
  Book_1,
  Book_2,
  Book_3,
  Book_4,
  Book_5,
  BookBundle,
  BookFront,
  BookImage1,
  Bundle,
  Candidate_1,
  Candidate_2,
  Candidate_3,
  Candidate_4,
  Company_1,
  Company_2,
  Company_3,
  Company_4,
  Company_5,
  Company_6,
  Courses_LG,
  Courses_SM,
  Finance_LG,
  Finance_SM,
  Financial_Modeling_Flat,
  Financial_Modeling_Open,
  Financial_Modeling_Vertical,
  Financial_Statement_Analysis_Handbook_Flat,
  Financial_Statement_Analysis_Handbook_Open,
  Financial_Statement_Analysis_Handbook_Vertical,
  FM,
  FM_SM,
  Fundraising_Decoded_Flat,
  Fundraising_Decoded_Open,
  Fundraising_Decoded_Vertical,
  Futures_and_Options_Flat,
  Futures_and_Options_Open,
  Futures_and_Options_Vertical,
  Icon,
  Icon1,
  Icon2,
  IHB,
  IHB_SM,
  Live_LG,
  Live_SM,
  Master_Residential_Real_Estate_Flat,
  Master_Residential_Real_Estate_Open,
  Master_Residential_Real_Estate_Vertical,
  Money_Smart_Flat,
  Money_Smart_Open,
  Money_Smart_Vertical,
  Page_1,
  Page_2,
  Page_3,
  Page_4,
  Personal_LG,
  Personal_SM,
  Profile_1,
  Profile_2,
  Profile_3,
  Profile_4,
  SIM,
  SIM_SM,
  Stock_Investing_Mastermind_Flat,
  Stock_Investing_Mastermind_Open,
  Stock_Investing_Mastermind_Vertical,
  Stock_LG,
  Stock_SM,
  TabPlay,
  The_Industry_Handbook_Flat,
  The_Industry_Handbook_Open,
  The_Industry_Handbook_Vertical,
  Trading_Mastermind_Flat,
  Trading_Mastermind_Open,
  Trading_Mastermind_Vertical,
} from '../../assets';

export const WhatsInsideData = {
  heading:
    'Take a look at the key chapters from the book to know why this book is a Game-Changer for you',
  bookData: [
    {
      image: Book_1,
      heading: 'Guide to home buying',
      subHeading: 'A complete roadmap to help you make an informed purchase',
      feature: [
        'What, when, where, how to buy',
        'Do’s & Don’ts while buying a house',
        'Rent vs Buy - what to choose',
        'Understanding overall real estate market',
        'Finding the right location',
      ],
    },
    {
      image: Book_2,
      heading: 'Guide to home buying',
      subHeading:
        'Practical insights and strategies so you don’t find yourself stuck in the process',
      feature: [
        'How to clean up your credit score',
        'How to find the right real estate agent',
        'How to identify read flags in a property',
        'How to present your offer & negotiate a more',
      ],
    },
    {
      image: Book_3,
      heading: 'Manage Savings & Loans',
      subHeading:
        'Buying a home means managing both - savings & loans. The book has a detailed section of these',
      feature: [
        'Create a realistic property budget',
        'Choose the right mortgage options',
        'Avoid common financing mistakes',
        'Tips to save for down payment & closing costs and more',
      ],
    },
    {
      image: Book_4,
      heading: 'Stay on Top of Documentations',
      feature: [
        '90% of home buyers face legal problem because they lack the paperwork muscle.',
        'This section has an in-depth list of documents you need to have before buying a home.',
      ],
    },
    {
      image: Book_5,
      heading: 'Stay on Top of Documentations',
      subHeading:
        'Author has beautifully explained the roles, duties, and perspectives of different stake-holders in the industry',
      feature: [
        'Brokers, Channel Partners & Developers',
        'Banks & Financial Institutes',
        'Government schemes, initiatives, policies',
        'Micro & Macro factors impacting the industry',
        'Understanding REITs',
      ],
    },
  ],
  pages: [Page_1, Page_2, Page_3, Page_4],
};

export const OverViewData = {
  desc: 'The Author, a Real Estate Expert goes way beyond to give tactics, strategies, frameworks and tools to take an informed buying decision.  /n Provided a complete roadmap to help you make an informed purchase, practical insights and strategies so you dont find yourself stuck in the process. /n Buying a home means managing both, savings & loans. The book has detailed sections of choosing the right mortgage options, creating a realistic property budget, tips to save for down payment & closing costs and more.',
  heading: 'Navigate the Residential Real Estate Industry with an expert',
  youtubeId: 'dQw4w9WgXcQ',
  DifficultyLevel: 'Beginner to intermediate',
  NoOfPages: 348,
  Author: 'Ashwinder R. Singh, CEO ',
  KeyTakeaways: [
    'Create a realistic property budget',
    'Learn how to find the right real estate agent',
    'Identify read flags in a property',
    'Learn to present your offer & negotiate a more',
  ],
};

export const WhosThisForData = {
  heading:
    'If you are thinking of entering the Real Estate industry, then this book is tailored for YOU!',
  subHeading:
    'To help you navigate the home buying process, avoid pitfalls, to build the understanding of when and how to buy & build a strong career in real estate. ',
  candidates: [
    {
      image: Candidate_1,
      profession: 'Home Buyers',
    },
    {
      image: Candidate_2,
      profession: 'Students',
    },
    {
      image: Candidate_3,
      profession: 'Residential Investors',
    },
    {
      image: Candidate_4,
      profession: 'Real-estate professionals',
    },
  ],
};

export const CurriculumData = {
  heading:
    'Don’t know a thing about residential real estate? We got you covered!',
  data: [
    {
      heading:
        'Overview - Explore the intricacies of RERA and other real estate policies',
      content: ['Rent vs Purchase', 'Guide for buyers', 'Homebuyers How To’s'],
      toDoList: [
        'What are the basics to follow while buying your first property?',
        'How to save money for a house?',
        'How to clean up your credit score?',
        'Do you have the right real estate agent?',
        'What questions to ask a real estate agent?',
        'How to identify red flags when inspecting a property?',
        'How to present your offer and negotiate?',
        'How to land a great home loan?',
        'What is a good return on investment (ROI) for rental properties?',
        'Why do many rental properties fail?',
        'Tips for NRI Investors',
      ],
    },
    {
      heading: 'Buyers - Empower yourself as buyers with essential insight',
      content: ['Rent vs Purchase', 'Guide for buyers', 'Homebuyers How To’s'],
      toDoList: [
        'What are the basics to follow while buying your first property?',
        'How to save money for a house?',
        'How to clean up your credit score?',
        'Do you have the right real estate agent?',
        'What questions to ask a real estate agent?',
        'How to identify red flags when inspecting a property?',
        'How to present your offer and negotiate?',
        'How to land a great home loan?',
        'What is a good return on investment (ROI) for rental properties?',
        'Why do many rental properties fail?',
        'Tips for NRI Investors',
      ],
    },
    {
      heading:
        'Real Estate Finance - Navigate the world of real estate finance',
      content: ['Rent vs Purchase', 'Guide for buyers', 'Homebuyers How To’s'],
      toDoList: [
        'What are the basics to follow while buying your first property?',
        'How to save money for a house?',
        'How to clean up your credit score?',
        'Do you have the right real estate agent?',
        'What questions to ask a real estate agent?',
        'How to identify red flags when inspecting a property?',
        'How to present your offer and negotiate?',
        'How to land a great home loan?',
        'What is a good return on investment (ROI) for rental properties?',
        'Why do many rental properties fail?',
        'Tips for NRI Investors',
      ],
    },
    {
      heading: 'Developers - Developer’s roadmap to understanding the market',
      content: ['Rent vs Purchase', 'Guide for buyers', 'Homebuyers How To’s'],
      toDoList: [
        'What are the basics to follow while buying your first property?',
        'How to save money for a house?',
        'How to clean up your credit score?',
        'Do you have the right real estate agent?',
        'What questions to ask a real estate agent?',
        'How to identify red flags when inspecting a property?',
        'How to present your offer and negotiate?',
        'How to land a great home loan?',
        'What is a good return on investment (ROI) for rental properties?',
        'Why do many rental properties fail?',
        'Tips for NRI Investors',
      ],
    },
    {
      heading:
        'Channel Partners - Unveil successful channel partner essentials to approach',
      content: ['Rent vs Purchase', 'Guide for buyers', 'Homebuyers How To’s'],
      toDoList: [
        'What are the basics to follow while buying your first property?',
        'How to save money for a house?',
        'How to clean up your credit score?',
        'Do you have the right real estate agent?',
        'What questions to ask a real estate agent?',
        'How to identify red flags when inspecting a property?',
        'How to present your offer and negotiate?',
        'How to land a great home loan?',
        'What is a good return on investment (ROI) for rental properties?',
        'Why do many rental properties fail?',
        'Tips for NRI Investors',
      ],
    },
  ],
};

export const AuthorDetails = {
  image: AuthorLarge,
  name: 'Ashwinder R. Singh',
  position: 'CEO, Anarock',
  experience: [
    'Real Estate Person of the Year, South - Construction Week 2022',
    'Most Enterprising CEO of the Year 2016',
    'Top 15 Real Estate Influencers by Realty + and Thought Leader of the Year by International Brand Equity in 2023.',
    'Co-Chair of the Confederation of Indian Industry Regional Committee on Real Estate for 2023-24',
  ],
  bio: 'With over two decades of experience, Ashwinder R Singh is a prominent figure in the Indian Real Estate Industry. Having helped over 200K+ home buyersMake Informed Real Estate Decisions with Master Residential Real Estate. In a nutshell, your ‘How-to’ Guide to Home Buying from the Author of Amazon #1 Bestseller.',
  experienceYear: 25,
  KeynoteSpeeches: '200+',
  homeBuyersHelped: '200k',
  amazonBestsellingAuthor: '#1',
  companiesData: [
    {
      name: 'Best CEO Award',
      image: Company_1,
    },
    {
      name: 'CEO-Residential',
      image: Company_2,
    },
    {
      name: 'Founder CEO',
      image: Company_3,
    },
    {
      name: 'Vice President',
      image: Company_4,
    },
    {
      name: 'Group Business Head',
      image: Company_5,
    },
    {
      name: 'CEO Residential',
      image: Company_6,
    },
  ],
};

export const footerData = {
  navigation: {
    social: [
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/Zebralearn?mibextid=LQQJ4d',
        icon: FacebookFilled,
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/zebra_learn/',
        icon: InstagramFilled,
      },
      {
        name: 'Youtube',
        href: 'https://www.youtube.com/@zebralearn9279',
        icon: YoutubeFilled,
      },
      {
        name: 'LinkedIn',
        href: 'https://www.linkedin.com/company/zebra-learn/?viewAsMember=true',
        icon: LinkedinFilled,
      },
    ],
    for_Traders: [
      {
        name: 'Trading Mastermind',
        href: 'https://zebralearn.com/books/trading-mastermind-hard-copy-65a28e0ef2089eb795218609/',
      },
      {
        name: 'Futures & Options Blueprint',
        href: 'https://zebralearn.com/books/futures-options-blueprint-hardcopy-65a29624f2089eb795218683/',
      },
      {
        name: '51 Trading Strategies',
        href: 'https://zebralearn.com/books/51-trading-strategies-hardcopy-65be19a9f99ff072edcb42eb/',
      },
    ],

    for_Investors: [
      {
        name: 'Money Smart in your 20s & 30s',
        href: 'https://zebralearn.com/books/money-smart-hard-copy-65a287dbf2089eb7952185be/',
      },
      {
        name: 'Stock Investing Mastermind',
        href: 'https://zebralearn.com/books/stock-investing-mastermind-hard-copy-65a29df7f2089eb795218725/',
      },
    ],
    for_Finance_Students: [
      {
        name: 'Financial Modeling Handbook',
        href: 'https://zebralearn.com/books/financial-modeling-handbook-hardcopy-65a299dbf2089eb7952186e2/',
      },
      {
        name: 'Financial Statement Analysis Handbook',
        href: 'https://zebralearn.com/books/financial-statement-analysis-handbook-hardcopy-65a2a091f2089eb79521875e/',
      },
    ],
    for_Entrepreneurs: [
      {
        name: 'The Industry Handbook',
        href: 'https://zebralearn.com/books/the-industry-handbook-hardcopy-65a290e1f2089eb79521863a/',
      },
      {
        name: 'Fundraising Decoded',
        href: 'https://zebralearn.com/books/fundraising-decoded-hard-copy-65a2a48bf2089eb7952187a4/',
      },
      {
        name: 'Startup Finance 360',
        href: 'https://zebralearn.com/books/startup-finance-360-hard-copy-65be184c18ca9b29ed36eddc/',
      },
    ],
    for_Authors: [
      {
        name: 'The Industry Handbook',
        href: 'https://zebralearn.com/books/the-industry-handbook-hardcopy-65a290e1f2089eb79521863a/',
      },
      {
        name: 'Fundraising Decoded',
        href: 'https://zebralearn.com/books/fundraising-decoded-hard-copy-65a2a48bf2089eb7952187a4/',
      },
      {
        name: 'Startup Finance 360',
        href: 'https://zebralearn.com/books/startup-finance-360-hard-copy-65be184c18ca9b29ed36eddc/',
      },
    ],
    business_Books: [
      {
        name: 'Mindful Marketing',
        href: 'https://zebralearn.com/books/mindful-marketing-hardcopy-65a2acf85501c521ba7e6882/',
      },
      {
        name: 'Hiring Mastermind',
        href: 'https://zebralearn.com/books/hiring-mastermind-3-65be2c0ebc878989fb9756ac/',
      },
    ],
    solutions: [
      {
        name: 'Product 1',
        href: '/product1',
      },
      {
        name: 'Product 2',
        href: '/product2',
      },
    ],
    support: [
      {
        name: 'Books & Courses',
        href: '/product',
      },
      {
        name: 'Schools',
        href: '/schools',
      },
      {
        name: 'Authors',
        href: 'https://studio.zebralearn.com/',
      },
      {
        name: 'Partnerships & Collaborations',
        href: 'https://studio.zebralearn.com/',
      },
      {
        name: 'Login',
        href: '/auth/login',
      },
      {
        name: 'Know your personality',
        href: '/reader-personality-test',
      },
      {
        name: 'Sitemap',
        href: '/api/sitemap.xml',
      },
    ],
    company: [
      {
        name: 'About Us',
        href: '/about_us/',
      },
      {
        name: 'Contact Us',
        href: '/contact',
      },
      {
        name: 'Careers',
        href: 'https://www.linkedin.com/jobs/search/?currentJobId=3879790265&geoId=102713980&keywords=zebralearn&location=India&originalSubdomain=in',
      },
      {
        name: 'For Designer',
        href: 'https://studio.zebralearn.com/',
      },
      {
        name: 'For Writers',
        href: 'https://studio.zebralearn.com/',
      },
      {
        name: 'Terms & Conditions',
        href: '/Privacy_Policy_Reader.html',
      },
      {
        name: 'Refund Policy',
        href: 'https://zebralearn519766423.wpcomstaging.com/refunds-and-cancellation-policy/',
      },
      {
        name: 'Help & Support',
        href: 'https://support.zebralearn.com/support/home',
      },
    ],
    links: [
      {
        name: 'JOIN ZEBRALEARN',
        href: '/contact/join/',
      },
      {
        name: 'WRITE WITH US',
        href: 'https://studio.zebralearn.com/',
      },
    ],
    legal: [
      {
        name: 'Privacy Policy',
        href: '/privacy',
      },
      {
        name: 'Terms of Service',
        href: '/terms',
      },
    ],
  },
};

export const FAQData = [
  {
    heading: 'What happens after I purchase?',
    desc: 'Digital copy: Once you click the link, you will be taken to a secure payment page. After you’ve completed your payment, you can simply go to the course dashboard and get access to the chapters of the book. These are downloadable chapter wise. /n Hard copy: Once you click the link, you can order by opting for the prepaid or COD option. We will deliver the book in the next 6-7 working days. Along with the book you will also receive the instructions to activate the complementary digital copy.',
  },
  {
    heading: 'What if I have doubts while going through the book?',
    desc: 'Digital copy: Once you click the link, you will be taken to a secure payment page. After you’ve completed your payment, you can simply go to the course dashboard and get access to the chapters of the book. These are downloadable chapter wise. /n Hard copy: Once you click the link, you can order by opting for the prepaid or COD option. We will deliver the book in the next 6-7 working days. Along with the book you will also receive the instructions to activate the complementary digital copy.',
  },
  {
    heading: 'What is the refund policy?',
    desc: 'Digital copy: Once you click the link, you will be taken to a secure payment page. After you’ve completed your payment, you can simply go to the course dashboard and get access to the chapters of the book. These are downloadable chapter wise. /n Hard copy: Once you click the link, you can order by opting for the prepaid or COD option. We will deliver the book in the next 6-7 working days. Along with the book you will also receive the instructions to activate the complementary digital copy.',
  },
];

export const NumberSectionData = [
  {
    value: '1,00,000+',
    label: 'Books Sold',
  },
  {
    value: '75,000+',
    label: 'Readers',
  },
  {
    value: '5x',
    label: 'Higher Retention',
  },
  {
    value: '4.6/5',
    label: 'Avg Rating',
  },
];

export const NumberSectionData2 = [
  {
    value: '40k+',
    label: 'Learners',
  },
  {
    value: '10k+',
    label: '5-star ratings',
  },
  {
    value: '5x',
    label: 'Rate of retention',
  },
  {
    value: '25+',
    label: 'Entrepreneurs backing us',
  },
];

export const BooksLibData = {
  bookImagesArr: [BookImage1],
};

export const JumpStartData = [
  {
    image: Profile_1,
    title: 'Student',
    disc: 'Expand your horizons beyond textbooks, explore subjects that ignite your curiosity and pave the way for future success',
  },
  {
    image: Profile_2,
    title: 'Working Professional',
    disc: 'Enhance your skills, stay updated on industry trends, and secure your place at the top of your field',
  },
  {
    image: Profile_3,
    title: 'Entrepreneur',
    disc: 'Discover the tools, knowledge, and strategies you need to take your business to new heights.',
  },
  {
    image: Profile_4,
    title: 'Learning Enthusiasts',
    disc: 'Feed your curiosity, explore diverse topics, and embark on a journey of endless discovery with our Bestsellers',
  },
];

export const ReadersCardsData = [
  {
    name: 'Arun Kumar Sundaram',
    position: 'Financial Analysis of Companies - Course (digital version)',
    image: Author,
    comment:
      'I liked the books very much , colourful and eye pleasing. Content is very good looking forward for combination of books and courses.',
  },
  {
    name: 'Abhishek Doshi',
    position: 'Ultimate Finance Bundle',
    image: Author,
    comment:
      'I bought Ultimate finance bundle out of which the industry hand book and stock investing mastermind were the best in bundle. Everything was on spot really loved books.',
  },
  {
    name: 'Sudeep Bapat',
    position: 'Trading Mastermind',
    image: Author,
    comment:
      'Easy to understand, graphs and illustrations are very good, best for someone with zero knowledge of trading.',
  },
  {
    name: 'Uday',
    position: 'All-in-One Finance Bundle - Hardcopy + Digital Access  ',
    image: Author,
    comment:
      'I liked the books and they having good graphics, courses are very helpful, really liked your courses',
  },
  {
    name: 'Govind Nalamwar',
    position: 'Fundraising Decoded - Digital Access',
    image: Author,
    comment: 'really good and informative, innovative and clear',
  },
];

export const books = [
  {
    id: 1,
    title: 'Financial Modeling Handbook',
    webFlowLink: `https://zebralearn.com/books/financial-statement-analysis-handbook-hardcopy-65a2a091f2089eb79521875e/`,
    desc: 'Learn to build real financial models from scratch, including discounted cash flow (DCF) modeling and valuation techniques. Gain a competitive edge in the finance industry with actionable skills and knowledge gained from this book.',
    sideImageSrc: Financial_Modeling_Vertical,
    frontImageSrc: Financial_Modeling_Flat,
    openImageSrc: Financial_Modeling_Open,
  },
  {
    id: 2,
    title: 'Financial Statement Analysis',
    webFlowLink: `https://zebralearn.com/books/financial-statement-analysis-handbook-hardcopy-65a2a091f2089eb79521875e/`,
    desc: 'Learn to extract valuable insights from financial statements and make informed decisions that drive success in the finance and Investment industry.',
    sideImageSrc: Financial_Statement_Analysis_Handbook_Vertical,
    frontImageSrc: Financial_Statement_Analysis_Handbook_Flat,
    openImageSrc: Financial_Statement_Analysis_Handbook_Open,
  },
  {
    id: 3,
    title: 'Fundraising Decoded',
    webFlowLink: `https://zebralearn.com/books/financial-statement-analysis-handbook-hardcopy-65a2a091f2089eb79521875e/`,
    desc: 'Learn about fundraising for your early-stage startup with our comprehensive guide, from understanding investor expectations to crafting an impactful pitch deck.',
    sideImageSrc: Fundraising_Decoded_Vertical,
    frontImageSrc: Fundraising_Decoded_Flat,
    openImageSrc: Fundraising_Decoded_Open,
  },
  {
    id: 4,
    title: 'Futures and Options Blueprint',
    webFlowLink: `https://zebralearn.com/books/financial-statement-analysis-handbook-hardcopy-65a2a091f2089eb79521875e/`,
    desc: 'An essential guide to understanding and trading derivatives, covering basic and advanced strategies with risk management techniques. Ideal for traders looking to enter the lucrative derivatives market.',
    sideImageSrc: Futures_and_Options_Vertical,
    frontImageSrc: Futures_and_Options_Flat,
    openImageSrc: Futures_and_Options_Open,
  },
  {
    id: 5,
    title: 'Master Residential Real Estate',
    webFlowLink: `https://zebralearn.com/books/financial-statement-analysis-handbook-hardcopy-65a2a091f2089eb79521875e/`,
    desc: 'Your essential guide to succeed in India’s residential real estate. Learn due diligence, negotiation, legal awareness, and much more.',
    sideImageSrc: Master_Residential_Real_Estate_Vertical,
    frontImageSrc: Master_Residential_Real_Estate_Flat,
    openImageSrc: Master_Residential_Real_Estate_Open,
  },
  {
    id: 6,
    title: `Money Smart in your 20's and 30's`,
    webFlowLink: `https://zebralearn.com/books/financial-statement-analysis-handbook-hardcopy-65a2a091f2089eb79521875e/`,
    desc: 'One stop solution to lay down a solid financial plan. Includes mutual funds, investments, insurances, taxes and more.',
    sideImageSrc: Money_Smart_Vertical,
    frontImageSrc: Money_Smart_Flat,
    openImageSrc: Money_Smart_Open,
  },
  {
    id: 7,
    title: 'Stock Investing Mastermind',
    webFlowLink:
      'https://zebralearn.com/books/financial-statement-analysis-handbook-hardcopy-65a2a091f2089eb79521875e/',
    desc: 'Suited for those who want to pick and invest in the right stocks, at the right price, at the right time | Beginner Friendly | 100% Practical',
    sideImageSrc: Stock_Investing_Mastermind_Vertical,
    frontImageSrc: Stock_Investing_Mastermind_Flat,
    openImageSrc: Stock_Investing_Mastermind_Open,
  },
  {
    id: 8,
    desc: '95% entrepreneurs fail because they are in the wrong industry. This book helps you identify most attractive industries and enables decision making as an entrepreneur.',
    title: 'The Industry Handbook',
    webFlowLink:
      'https://zebralearn.com/books/the-industry-handbook-hardcopy-65a290e1f2089eb79521863a/',
    sideImageSrc: The_Industry_Handbook_Vertical,
    frontImageSrc: The_Industry_Handbook_Flat,
    openImageSrc: The_Industry_Handbook_Open,
  },
  {
    id: 9,
    desc: 'Gets you ready to become a professional trader from absolute scratch. Suited for traders with 0-2 yrs experience.',
    webFlowLink:
      'https://zebralearn.com/books/trading-mastermind-hard-copy-65a28e0ef2089eb795218609/',
    title: 'Trading Mastermind',
    sideImageSrc: Trading_Mastermind_Vertical,
    frontImageSrc: Trading_Mastermind_Flat,
    openImageSrc: Trading_Mastermind_Open,
  },
];

export const BigPictureSectionData = [
  {
    icon: Icon,
    label: 'What we do',
    desc: 'We collaborate with industry experts & build pioneering books and courses to get you industry-ready!',
  },
  {
    icon: Icon1,
    label: 'Why we do',
    desc: 'In a world full of chaos, we want to be the calm, creative & educative corner for people who are seeking one.',
  },
  {
    icon: Icon2,
    label: 'How we do',
    desc: 'We promote art and create visually understanding books, making them immersive and actionable.',
  },
];

export const OpenPositions = [
  'Publishing Department',
  'Marketing Department',
  'Content Acquisition Department',
  'Content Acquisition Department',
  'Submissions Department',
  'Tech Department',
  'HR Department',
];

export const OpenPositionCardData = [
  {
    title: 'Finance Research Analyst',
    location: 'Surat',
    heading: 'Whom are we looking for',
    desc: ' We are looking for a passionate and skilled Research Analyst to join our team at ZebraLearn. As a Research Analyst, you will play a vital role in understanding market trends, and customer preferences within the e-learning industry. By gathering and analyzing data, you',
  },
  {
    title: 'Finance Research Analyst',
    location: 'Surat',
    heading: 'Whom are we looking for',
    desc: ' We are looking for a passionate and skilled Research Analyst to join our team at ZebraLearn. As a Research Analyst, you will play a vital role in understanding market trends, and customer preferences within the e-learning industry. By gathering and analyzing data, you  We are looking for a passionate and skilled Research Analyst to join our team at ZebraLearn. As a Research Analyst, you will play a vital role in understanding market trends, and customer preferences within the e-learning industry. By gathering and analyzing data, you',
  },
  {
    title: 'Finance Research Analyst',
    location: 'Surat',
    heading: 'Whom are we looking for',
    desc: ' We are looking for a passionate and skilled Research Analyst to join our team at ZebraLearn. As a Research Analyst, you will play a vital role in understanding market trends, and customer preferences within the e-learning industry. By gathering and analyzing data, you',
  },
];

export const JoinCardData = [
  {
    desc: '“It’s always fun to ideate and come up with innovative ways to visualise tough concepts”',
    reviewer: 'Ziya Shah, Design Intern',
  },
];

export const JoinCardData_2 = [
  {
    title: 'We strive for excellence ',
    desc: 'We are driven by a relentless pursuit of excellence, always pushing the boundaries to deliver products that exceed expectations.',
  },
  {
    title: 'Consistency is the key',
    desc: 'Consistency is the cornerstone of Zebra Learn. We are committed to providing reliable and high-quality experiences to our customers day in and day out.',
  },
  {
    title: 'We are not just dreamers; we are doers.',
    desc: 'Zebra Learn embraces an action-oriented approach, turning ideas into reality and making a tangible impact in the industry.',
  },
];

export const BooksData = {
  image: BookFront,
  heading: 'Master Residential Real Estate',
  authorImage: Author,
  authorName: 'Ashwinder R. Singh',
  desc: 'Your essential guide to succeed in India’s residential real estate. Learn due diligence, negotiation, legal awareness, and much more.',
  hardCopy: 1690,
  softCopy: 990,
};

export const BundlePageData = {
  bookImage: BookBundle,
  heading: 'Ultimate Finance Bundle',
  about:
    'Explore our Ultimate Finance Bundle for simplified learning of complex finance concepts from our bestselling books : personal finance, stock market investing, financial modeling, startup fundraising – all delivered to your doorstep',
  feature: [
    '8 Books worth 13,520 ',
    'Comprehensive Finance Curriculums to make you self-sufficient',
    'Hyper-focused on building Practical Finance Skills',
    'Real-World Case Studies to make you Industry Ready',
    'Clear, Engaging Books to simplify concepts & maximize learning',
  ],
  hardCopyPrice: '1690',
  softCopy: '990',
};

export const WhatsInsideBundlesData = {
  image: Book,
  name: 'Trading Mastermind',
  problem:
    'Problem: Trading based on your guesses, tips from internet, friends will soon lead you to heavy losses.',
  goal: 'Learn to build Trading Strategies that are data backed & eliminate luck from trading',
  list: [
    'Become a Confident trader',
    'Understand the market and its affecting factors.',
    'Learn tools, techniques & strategies',
  ],
};

export const CartData = [
  {
    image: Book,
    name: 'The Industry Handbook',
    type: 'Hardcopy',
    price: 1699,
    actualPrice: 2999,
    id: 1,
    quantity: 2,
    currency: 'USD',
    upperLimit: 100,
    lowerLimit: 0,
  },
  {
    image: Bundle,
    name: 'Ultimate Finance Bundle',
    type: 'Hardcopy',
    price: 8999,
    actualPrice: 14999,
    id: 2,
    quantity: 1,
    currency: 'USD',
    upperLimit: 100,
    lowerLimit: 0,
  },
];

export const CardListData = [
  {
    image: Book,
    name: 'Master Residential Real Estate',
    desc: 'Your essential guide to succeed in India’s residential real estate. Learn due diligence, negotiation, legal awareness, and much more.',
    price: 1699,
    actualPrice: 2999,
  },
  {
    image: Bundle,
    name: 'Ultimate Finance Bundle',
    desc: 'Your essential guide to succeed in India’s residential real estate. Learn due diligence, negotiation, legal awareness, and much more.',
    price: 1699,
    actualPrice: 2999,
  },
  {
    image: Book,
    name: 'Master Residential Real Estate',
    desc: 'Your essential guide to succeed in India’s residential real estate. Learn due diligence, negotiation, legal awareness, and much more.',
    price: 1699,
    actualPrice: 2999,
  },
  {
    image: Bundle,
    name: 'Ultimate Finance Bundle',
    desc: 'Your essential guide to succeed in India’s residential real estate. Learn due diligence, negotiation, legal awareness, and much more.',
    price: 1699,
    actualPrice: 2999,
  },
];

export const LibraryBooksPercentages = [
  { id: 1, percentage: 60, image: BookFront },
  { id: 2, percentage: 0, image: BookFront },
  { id: 3, percentage: 89, image: TabPlay },
  { id: 4, percentage: 89, image: TabPlay },
  { id: 5, percentage: 89, image: TabPlay },
  { id: 6, percentage: 89, image: TabPlay },
  { id: 7, percentage: 89, image: TabPlay },
  { id: 8, percentage: 89, image: TabPlay },
  { id: 9, percentage: 89, image: TabPlay },
];

export const LibraryCoursesPercentages = [
  { id: 1, percentage: 60, image: TabPlay },
  { id: 2, percentage: 0, image: TabPlay },
];

export const OrderDetailsData = {
  orderId: '567499994W7',
  orderDate: '16 Oct, 2023 ',
  list: [
    {
      image: BookFront,
      name: 'The Industry Handbook',
      price: 1699,
      actualPrice: 2399,
      type: 'Hardcopy',
      quantity: 1,
      statusDate: 'Fri, 20 Oct',
      status: 'In transit',
    },
    {
      image: BookFront,
      name: 'Master Residential Real Estate',
      price: 1699,
      actualPrice: 2399,
      type: 'Hardcopy',
      quantity: 1,
      statusDate: '18 Oct, 2023',
      status: 'Delivered',
    },
  ],
  cardNum: '5681',
  deliveryAddress:
    'Anurag App, street, locality landmark city, state mobile no.',
  subTotal: 2698,
  shippingCharges: 'FREE',
  discount: 1700,
  promoCode: 500,
  total: 2198,
};

export const Chapters = [
  { chapter: 1, page: 1 },
  { chapter: 2, page: 5 },
  { chapter: 3, page: 10 },
  { chapter: 4, page: 15 },
  { chapter: 5, page: 20 },
  { chapter: 6, page: 25 },
];

export const OrderData = [
  {
    orderId: '567499994W7',
    orderDate: '16 Oct, 2023 ',
    list: [
      {
        image: BookFront,
        name: 'The Industry Handbook',
        price: 1699,
        actualPrice: 2399,
        type: 'Hardcopy',
        quantity: 1,
        statusDate: 'Fri, 20 Oct',
        status: 'In transit',
      },
      {
        image: BookFront,
        name: 'Master Residential Real Estate',
        price: 1699,
        actualPrice: 2399,
        type: 'Hardcopy',
        quantity: 1,
        statusDate: '18 Oct, 2023',
        status: 'Delivered',
      },
    ],
  },
  {
    orderId: '5674999927',
    orderDate: '16 Oct, 2023 ',
    list: [
      {
        image: BookFront,
        name: 'The Industry Handbook',
        price: 1699,
        actualPrice: 2399,
        type: 'Hardcopy',
        quantity: 1,
        statusDate: 'Fri, 20 Oct',
        status: 'In transit',
      },
    ],
  },
  {
    orderId: '567499993',
    orderDate: '16 Oct, 2023 ',
    list: [
      {
        image: BookFront,
        name: 'The Industry Handbook',
        price: 1699,
        actualPrice: 2399,
        type: 'Hardcopy',
        quantity: 1,
        statusDate: 'Fri, 20 Oct',
        status: 'In transit',
      },
      {
        image: Bundle,
        name: 'Master Residential Real Estate',
        price: 1699,
        actualPrice: 2399,
        type: 'Hardcopy',
        quantity: 1,
        statusDate: '18 Oct, 2023',
        status: 'Delivered',
      },
      {
        image: BookFront,
        name: 'The Industry Handbook',
        price: 1699,
        actualPrice: 2399,
        type: 'Hardcopy',
        quantity: 1,
        statusDate: 'Fri, 20 Oct',
        status: 'In transit',
      },
    ],
  },
];

export const AdminHomeTableData = [
  {
    id: 1,
    title: 'Product Management',
    route: '/super-admin/product-management',
    desc: 'A page to add and edit books to the website, including book details, images, prices, and description.',
    card: [
      {
        title: 'Products Published',
        count: 12,
      },
      {
        title: 'Products Unpublished',
        count: 4,
      },
      {
        title: 'Products in draft',
        count: 6,
      },
    ],
  },
  {
    id: 2,
    title: 'Order Management',
    route: '/super-admin/order-management',
    desc: 'A page to see all customer orders with details like order date, shipping information, and payment status.',
    card: [
      {
        title: 'Orders Delivered',
        count: 12,
      },
      {
        title: 'Orders Refunded',
        count: 4,
      },
      {
        title: 'Orders Cancelled',
        count: 6,
      },
      {
        title: 'Orders In Transit',
        count: 40,
      },
    ],
  },
  {
    id: 3,
    title: 'Customer Management',
    desc: 'Access to all the customers who have signed up. Know their order history, activity or even block their access.',
    card: [
      {
        title: 'Products Published',
        count: 12,
      },
      {
        title: 'Products Unpublished',
        count: 4,
      },
      {
        title: 'Products in draft',
        count: 6,
      },
    ],
  },
  {
    id: 4,
    title: 'Settings',
    desc: 'Security and User Roles: Manage admin accounts and their permissions.',
    card: [
      {
        title: 'Products Published',
        count: 12,
      },
      {
        title: 'Products Unpublished',
        count: 4,
      },
      {
        title: 'Products in draft',
        count: 6,
      },
    ],
  },
];

export const OrderManagementData = [
  {
    key: '1',
    date: '2023-11-22',
    order: 'Sample Order 1',
    orderId: '12345',
    status: 'Cancelled',
    customer: 'John Doe',
    payment: 'Credit Card',
  },
  {
    key: '2',
    date: '2023-11-23',
    order: 'Sample Order 2',
    orderId: '67890',
    status: 'Delivered',
    customer: 'Jane Doe',
    payment: 'PayPal',
  },
  {
    key: '3',
    date: '2023-11-23',
    order: 'Sample Order 3',
    orderId: '67890',
    status: 'Processing',
    customer: 'Jane Doe',
    payment: 'PayPal',
  },
  {
    key: '4',
    date: '2023-11-23',
    order: 'Sample Order 4',
    orderId: '67890',
    status: 'Refund',
    customer: 'Jane Doe',
    payment: 'PayPal',
  },
  {
    key: '5',
    date: '2023-11-23',
    order: 'Sample Order 5',
    orderId: '67890',
    status: 'Cancelled',
    customer: 'Jane Doe',
    payment: 'PayPal',
  },
];

export const ProductManagementData = [
  {
    key: '1',
    title: 'Product title',
    bestsellersStatus: false,
    updatedDate: '2023-11-22',
    modifiedDate: '2023-11-22',
    published: '2023-11-22',
    status: 'Cancelled',
  },
  {
    key: '2',
    title: 'Product title 2',
    bestsellersStatus: false,
    updatedDate: '2023-11-22',
    modifiedDate: '2023-11-22',
    published: '2023-11-22',
    status: 'Published',
  },
  {
    key: '3',
    title: 'Product title 3',
    bestsellersStatus: true,
    updatedDate: '2023-11-22',
    modifiedDate: '2023-11-22',
    published: '2023-11-22',
    status: 'Refund',
  },
  {
    key: '4',
    title: 'Product title 4',
    bestsellersStatus: false,
    updatedDate: '2023-11-22',
    modifiedDate: '2023-11-22',
    published: '2023-11-22',
    status: 'Cancelled',
  },
];

/// V2

export const homeHeroSectionCarouselData = [
  // {
  //   id: 1,
  //   name: '100K+ Readers Trust Zebralearn to level up!',
  //   webFlowLink: 'https://go.zebralearn.com/the-money-guide',
  //   desc: 'Welcome to India’s First Books Platform ',
  //   imageLG: TMG_LG,
  //   imageSM: TMG_Mobile,
  // },
  {
    id: 2,
    name: '100K+ Readers Trust Zebralearn to level up!',
    link: '/schools',
    desc: 'Welcome to India’s First Books Platform ',
    imageLG: Personal_LG,
    imageSM: Personal_SM,
  },
  // {
  //   id: 2,
  //   name: 'Easy, Actionable, & Exhaustive Books',
  //   webFlowLink: '/books',
  //   desc: 'Illustrative Books for Upskilling & business',
  //   imageLG: Stock_LG,
  //   imageSM: Stock_SM,
  // },
  // {
  //   id: 3,
  //   name: 'Learn directly from Industry Leaders',
  //   webFlowLink: '/courses',
  //   desc: 'Actionable Courses to Upskill ',
  //   imageLG: Finance_LG,
  //   imageSM: Finance_SM,
  // },
  {
    id: 3,
    name: 'Master a new subject Everyday',
    link: '/courses',
    desc: 'Most structured & detailed blogs ',
    imageLG: Courses_LG,
    imageSM: Courses_SM,
  },

  {
    id: 4,
    name: 'Learn directly from Industry Leaders',
    link: '/books',
    desc: 'Actionable Courses to Upskill ',
    imageLG: SIM,
    imageSM: SIM_SM,
  },
  // {
  //   id: 5,
  //   name: 'DEVA PATEL 5',
  //   webFlowLink: `https://go.zebralearn.com/financial-modelling-handbook?_gl=1*lckulm*_ga*MTMzMDgyNTg2OS4xNjgyNDkyNzg0*_ga_21G196HESG*MTcwNTA2MzgyOC43MS4xLjE3MDUwNjU3NDUuMTUuMC4w`,
  //   desc: 'The fundamental of business and marketing for babies',
  //   image: BookBanner,
  // },
];

export const bookHomeHeroSectionCarouselData = [
  {
    id: 1,
    name: '100K+ Readers Trust Zebralearn to level up!',
    webFlowLink:
      'https://zebralearn.com/books/financial-modeling-handbook-hardcopy-65a299dbf2089eb7952186e2/',
    desc: 'Welcome to India’s First Books Platform ',
    imageLG: FM,
    imageSM: FM_SM,
    slug: false,
  },
  {
    id: 2,
    name: 'Easy, Actionable, & Exhaustive Books',
    webFlowLink:
      'https://zebralearn.com/books/the-industry-handbook-hardcopy-65a290e1f2089eb79521863a/',
    desc: 'Illustrative Books for Upskilling & business',
    imageLG: IHB,
    imageSM: IHB_SM,
    slug: false,
  },
  {
    id: 3,
    name: 'Learn directly from Industry Leaders',
    webFlowLink:
      'https://zebralearn.com/books/stock-investing-mastermind-hard-copy-65a29df7f2089eb795218725/',
    desc: 'Actionable Courses to Upskill ',
    imageLG: SIM,
    imageSM: SIM_SM,
    slug: false,
  },
];

export const CourseHomeHeroSectionCarouselData = [
  {
    id: 1,
    name: '100K+ Readers Trust Zebralearn to level up!',
    webFlowLink: 'https://courses.zebralearn.com',
    desc: 'Welcome to India’s First Books Platform ',
    imageLG: Courses_LG,
    imageSM: Courses_SM,
    slug: false,
  },
];

export const LiveHomeHeroSectionCarouselData = [
  {
    id: 1,
    name: '100K+ Readers Trust Zebralearn to level up!',
    link: '/schools/',
    desc: 'Welcome to India’s First Books Platform ',
    imageLG: Live_LG,
    imageSM: Live_SM,
    slug: false,
  },
];

export const SchoolHomeHeroSectionCarouselData = [
  {
    id: 1,
    name: '100K+ Readers Trust Zebralearn to level up!',
    link: '/schools/mutual-funds/',
    desc: 'Welcome to India’s First Books Platform ',
    imageLG: Finance_LG,
    imageSM: Finance_SM,
    slug: false,
  },
  {
    id: 2,
    name: '100K+ Readers Trust Zebralearn to level up!',
    link: '/schools/budgeting-and-savings/',
    desc: 'Welcome to India’s First Books Platform ',
    imageLG: Personal_LG,
    imageSM: Personal_SM,
    slug: false,
  },
  {
    id: 3,
    name: '100K+ Readers Trust Zebralearn to level up!',
    link: '/schools/life-insurance/',
    desc: 'Welcome to India’s First Books Platform ',
    imageLG: Stock_LG,
    imageSM: Stock_SM,
    slug: false,
  },
];
export const filterDate = [
  {
    id: 1,
    name: 'All',
  },
  {
    id: 2,
    name: 'Marketing',
  },
  {
    id: 3,
    name: 'Ads creation',
  },
  {
    id: 4,
    name: 'Email marketing',
  },
  {
    id: 5,
    name: 'Personal finance',
  },
  {
    id: 6,
    name: 'Personal finance',
  },
  {
    id: 7,
    name: 'Personal finance',
  },
];

export const flatBookImageArray = [
  {
    id: 1,
    image: Financial_Modeling_Flat,
  },
  {
    id: 2,
    image: Financial_Statement_Analysis_Handbook_Flat,
  },
  {
    id: 3,
    image: Fundraising_Decoded_Flat,
  },
  {
    id: 4,
    image: Futures_and_Options_Flat,
  },
  {
    id: 5,
    image: Master_Residential_Real_Estate_Flat,
  },
  {
    id: 6,
    image: Money_Smart_Flat,
  },
  {
    id: 7,
    image: Stock_Investing_Mastermind_Flat,
  },
  {
    id: 8,
    image: The_Industry_Handbook_Flat,
  },
  {
    id: 9,
    image: Trading_Mastermind_Flat,
  },
];

// BookDetails
export const BookDetails = {
  authorName: 'Ashwinder R. Singh',
  bookName: 'FUNDRAISING DECODED',
  desc: 'Make Informed Real Estate Decisions with Master Residential Real Estate. Your How-to Guide to Home Buying from the Author of Amazon #1 Bestseller',
  lang: 'English',
  type: 'Book',
  totalPages: 250,
  hardCopyPrice: 1690,
  digitalCopyPrice: 990,
  bookLSample: '',
};

// live

export const zebraAuthoList = [
  'Join our thriving community of over 10,000 learners and 25+ seasoned entrepreneurs. ',
  '1 million active users, ZEBRALEARN offers an unparalleled learning experience.',
  'Our innovative approach leads to 5 times higher retention rates compared to traditional methods',
  'Join us and explore our vast array of courses, workshops, and live sessions.',
  'Rated an impressive 4.6 on average by our satisfied users.',
];
