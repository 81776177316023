import React from 'react';

export interface CustomIconProps {
  filled?: boolean;
}

const CardIcon: React.FC<CustomIconProps> = () => {
  return (
    <svg
      width='16'
      height='13'
      viewBox='0 0 16 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.6768 1.04492H4.61685C3.90186 1.04492 3.31186 1.62494 3.29688 2.33994H11.3869C12.6669 2.33994 13.7068 3.38492 13.7068 4.66492V9.45993C14.4219 9.44492 15.0019 8.85492 15.0019 8.13492V2.36993C15.0019 1.63992 14.4069 1.04492 13.6768 1.04492Z'
        fill='#009C63'
      />
      <path
        d='M11.385 3.33984H2.32501C1.595 3.33984 1 3.93484 1 4.66483V5.60483H12.705V4.66483C12.705 3.93484 12.11 3.33984 11.385 3.33984Z'
        fill='#009C63'
      />
      <path
        d='M1 10.4305C1 11.1605 1.595 11.7555 2.32501 11.7555H11.385C12.11 11.7555 12.705 11.1605 12.705 10.4305V6.60547H1V10.4305ZM8.95499 8.75546H10.455C10.73 8.75546 10.955 8.97546 10.955 9.25546C10.955 9.53049 10.73 9.75546 10.455 9.75546H8.95499C8.67999 9.75546 8.45499 9.53049 8.45499 9.25546C8.45499 8.97546 8.67999 8.75546 8.95499 8.75546Z'
        fill='#009C63'
      />
    </svg>
  );
};

export default CardIcon;
