import React from 'react';

interface Props {
  width?: string;
  fillBgColor?: string;
}

const PencilEdit: React.FC<Props> = ({ width, fillBgColor }) => (
  <svg
    width={width ?? '40'}
    height={width ?? '40'}
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Icons'>
      <rect width='40' height='40' rx='20' fill={fillBgColor ?? 'black'} />
      <g id='Edit Square'>
        <path
          id='Path'
          d='M29.5032 16.8358L18.2981 28.0742C17.7153 28.6402 16.9494 28.9566 16.1503 28.9566H12.2376C12.0212 28.9566 11.8214 28.8733 11.6715 28.7235C11.5217 28.5736 11.4551 28.3738 11.4551 28.1574L11.555 24.2115C11.5716 23.429 11.888 22.6964 12.4374 22.147L20.3793 14.2052C20.5124 14.072 20.7455 14.072 20.8787 14.2052L23.6592 16.969C23.8424 17.1521 24.1088 17.2687 24.3918 17.2687C25.0078 17.2687 25.4907 16.7692 25.4907 16.1698C25.4907 15.8701 25.3741 15.6037 25.191 15.4039C25.141 15.3373 22.4937 12.7067 22.4937 12.7067C22.3273 12.5402 22.3273 12.2572 22.4937 12.0907L23.6093 10.9585C24.6415 9.92626 26.3065 9.92626 27.3388 10.9585L29.5032 13.123C30.5188 14.1386 30.5188 15.8035 29.5032 16.8358Z'
          fill='white'
        />
      </g>
    </g>
  </svg>
);

export default PencilEdit;
