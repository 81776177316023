import React from 'react';

interface UpdatedCustomIconProps {
  filled?: boolean;
}

const Logo: React.FC<UpdatedCustomIconProps> = () => (
  <svg
    width='10'
    height='10'
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_5307_44985)'>
      <path
        d='M0.015625 0.0368416L1.18013 1.09501L2.71055 1.1012L3.49668 0.015625L0.015625 0.0368416Z'
        fill='#979797'
      />
      <path
        d='M0.015625 0.03125V4.64936L1.18013 1.08942L0.015625 0.03125Z'
        fill='#EFEFEF'
      />
      <path
        d='M1.18013 1.09375L2.71054 1.09993L0.015625 4.65369L1.18013 1.09375Z'
        fill='white'
      />
      <path
        d='M4.51562 1.09267L5.44776 0.046875H8.14891L5.87511 1.09886L4.51562 1.09267Z'
        fill='#979797'
      />
      <path
        d='M4.51458 1.09375L5.87405 1.09993L1.42971 6.45622L0.0390625 6.473L4.51458 1.09375Z'
        fill='#B2B2B2'
      />
      <path
        d='M0.0385726 6.4699L1.42922 6.45312L0.0234375 9.94501L0.0385726 6.4699Z'
        fill='#EFEFEF'
      />
      <path
        d='M1.42922 6.45513L5.87357 1.09886L8.14737 0.046875L0.0234375 9.94696L1.42922 6.45513Z'
        fill='white'
      />
      <path
        d='M1.86719 9.92716L4.20065 8.76475L8.68149 3.43854L10.0134 0.015625L1.86719 9.92716Z'
        fill='#979797'
      />
      <path
        d='M4.20065 8.76562L5.55212 8.85229L4.5995 9.92371L1.86719 9.92811L4.20065 8.76562Z'
        fill='#EFEFEF'
      />
      <path
        d='M10.0116 0.015625L8.67969 3.43853H10.032L10.0116 0.015625Z'
        fill='#EFEFEF'
      />
      <path
        d='M8.67617 3.4375H10.0285L5.54678 8.85034L4.19531 8.76375L8.67617 3.4375Z'
        fill='white'
      />
      <path
        d='M7.36719 8.82082H8.8336L9.99539 5.32812L7.36719 8.82082Z'
        fill='#979797'
      />
      <path
        d='M7.363 8.82031H8.82927L10.0125 9.94655L6.52344 9.94209L7.363 8.82031Z'
        fill='#EFEFEF'
      />
      <path
        d='M9.98999 5.32812L10.0114 9.94713L8.82812 8.82082L9.98999 5.32812Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_5307_44985'>
        <rect width='10' height='10' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
