import { XIcon } from '@heroicons/react/outline';
import { Modal } from 'antd';
import Image from 'next/image';
import React from 'react';
import { useRecoilValue } from 'recoil';

import ButtonDefault, { ButtonVariants } from '../shared/basic/button';
import { user } from '../../atoms/user';
import { User } from '../../utils/interfaces/User';
import { verifyEmailImage } from '../../../assets';

interface Props {
  visible?: boolean;
  onCancel?: () => void;
}

const VerifyEmailModal: React.FC<Props> = ({ visible, onCancel }) => {
  const userData: User | undefined = useRecoilValue(user);
  return (
    <Modal
      open={visible}
      centered={true}
      closable={false}
      onCancel={onCancel}
      footer={false}
      className='customModalNoPadding customLoginModal bg-none'
      styles={{
        body: {
          background: 'transparent',
        },
      }}
    >
      <div className='z-30 flex justify-center items-center py-10 h-screen bg-none'>
        <div className='bg-cartBgColor  relative flex py-10 flex-col px-5 md:px-20 max-w-[550px] text-white  rounded-2xl md:border-2 border-borderDarkGray '>
          <span
            onClick={() => {
              onCancel && onCancel();
            }}
            className='absolute right-[6%] top-[7%] xs:top-[3%] md:top-[5%]  cursor-pointer  group'
          >
            <div className='w-6 h-6 xs:w-9 xs:h-9 rounded-full flex justify-center items-center bg-[#212121] p-1'>
              <XIcon className='w-4 h-4 md:w-6 md:h-6 group-hover:text-[#A8A8A8] text-[#787878]' />
            </div>
          </span>

          <div className='px-6'>
            <Image
              src={verifyEmailImage}
              width={458}
              height={180}
              alt='verifyEmailImage'
              className='rounded-xl'
            />
          </div>
          <div className='flex justify-center mt-10'>
            <div className='flex flex-col relative'>
              <span className='text-xl md:text-3xl font-semibold'>
                Kindly check your inbox
              </span>
              <div className='py-6 flex flex-col justify-center items-center gap-y-1'>
                <span className='text-[#C7C6CF]  text-sm text-center font-medium'>
                  We have sent an verification link to:
                </span>
                <span className='text-white text-lg text-center font-semibold'>
                  {userData?.email}
                </span>
              </div>
            </div>
          </div>
          <div className='justify-center flex items-center w-full'>
            <ButtonDefault
              size={4}
              variant={ButtonVariants.WHITE}
              className='w-3/5 p-1'
              onClick={() => {
                onCancel && onCancel();
              }}
              is3D={true}
            >
              <span className='flex text-sm font-semibold gap-x-2 px-4  whitespace-nowrap'>
                Done
              </span>
            </ButtonDefault>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyEmailModal;
