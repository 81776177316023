import { XIcon } from '@heroicons/react/outline';
import { Input, Modal } from 'antd';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import ButtonDefault, { ButtonVariants } from '../shared/basic/button';
import { authModalControl } from '../../atoms/authModalControl';
import { useAuth } from '../../hooks/useAuth';
import EmailIcon from '../../../assets/Icons/Mail';
import PasswordIcon from '../../../assets/Icons/password';

interface ModalComponentProps {
  visible: boolean;
  onCancel: () => void;
  isClosable?: boolean;
  isIFrame?: boolean;
  email?: string;
  isCheckoutFlow?: boolean;
}

const LoginModalCheckout: React.FC<ModalComponentProps> = ({
  visible,
  onCancel,
  email,
}) => {
  const router = useRouter();
  const [visibleControl, setVisible] = useRecoilState(authModalControl);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const { redirectUrl } = router.query;
  const { onLogin, loading } = useAuth();

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setConfirmLoading(false);
      onCancel();
    }, 2000);
  };

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      email: '',
      password: '',
    };
    if (!formData.email) {
      validationErrors.email = 'Email or phone number is required';
    } else if (!isValidEmailOrPhone(formData.email)) {
      validationErrors.email = 'Invalid email or phone number';
    }

    if (!formData.password) {
      validationErrors.password = 'Password is required';
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      const login_by = isValidEmail(formData.email) ? 'email' : 'phone';
      await onLogin({
        email: formData.email,
        password: formData.password,
        login_by,
        redirectUrl: redirectUrl as string,
        onCancel,
        noRedirection: true,
        bookId: visibleControl?.bookId,
      });
      onCancel();
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const isValidEmailOrPhone = (value: string) => {
    // Your combined validation logic for email and phone number
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = /^\d{10}$/;

    return emailRegex.test(value) || phoneRegex.test(value);
  };

  useEffect(() => {
    if (email) {
      setFormData({
        ...formData,
        email,
      });
    }
  }, [email]);

  return (
    <Modal
      title=''
      open={visible}
      centered={true}
      onOk={handleOk}
      closable={false}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      footer={false}
      className='customLoginModal bg-bgColor bg-opacity-70  py-5  relative rounded-2xl border-2 border-borderDarkGray backdrop-filter backdrop-blur-md '
    >
      <div className='flex items-center gap-y-10 flex-col justify-center px-5 md:px-10'>
        <div className={` z-30  flex flex-col gap-y-1 w-full `}>
          <div className='top-2 right-6 absolute pt-2 md:pt-4'>
            <span
              onClick={() => {
                onCancel();
              }}
              className='absolute right-0 top-[40%]  md:top-[40%] cursor-pointer'
            >
              <XIcon className='w-4 h-4 md:w-6 md:h-6 text-fadedWhite' />
            </span>
          </div>

          <div className=''>
            <h1 className='text-xl flex justify-center gap-x-2 font-medium  lg:font-semibold mt-5 md:mt-2 leading-10 text-fadedWhite text-center w-full'>
              Welcome to Zebralearn
            </h1>
            <span className='text-sm text-textDarkGrayColor text-center flex justify-center'>
              Enter your credentials to login
            </span>
          </div>

          <form onSubmit={handleSubmit} className='flex flex-col mt-4 gap-y-2 '>
            <div className='col-span-1 flex-col flex gap-y-.5'>
              <Input
                type='email'
                name='email'
                autoComplete='current-email'
                style={{
                  backgroundColor: '#000000',
                }}
                value={formData.email}
                placeholder='Email'
                prefix={
                  <span className='pr-2'>
                    <EmailIcon filled={true} />
                  </span>
                }
                onChange={handleChange}
                size='large'
                className='border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-2 text-white bg-bgColor customInputBlack makeBgBlack'
              />
              {errors.email && <p className='text-red-500'>{errors.email}</p>}
            </div>
            <div className='col-span-1 flex-col flex '>
              <Input.Password
                type='password'
                name='password'
                autoComplete='current-password'
                style={{
                  backgroundColor: '#000000',
                }}
                placeholder='Password'
                value={formData.password}
                prefix={
                  <span className='pr-2'>
                    <PasswordIcon />
                  </span>
                }
                onChange={handleChange}
                size='large'
                className='border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-2 text-white bg-bgColor customInputBlack makeBgBlack'
              />
              {errors.password && (
                <p className='text-red-500'>{errors.password}</p>
              )}
              <span
                onClick={() => {
                  setVisible((prevState) => ({
                    ...prevState,
                    login: false,
                    signUp: false,
                    forgotPassword: true,
                  }));
                  onCancel();
                }}
                className='font-medium text-primary mt-1 cursor-pointer hover:text-darkPrimary'
              >
                Forgot password?
              </span>
            </div>

            <span className='flex justify-center mt-2'>
              <ButtonDefault
                size={4}
                variant={ButtonVariants.WHITE}
                className='p-1 w-full'
                disabled={loading}
                loading={loading}
                rounded={true}
                enableScaling={false}
                // onClick={() => router.push('/auth/login')}
              >
                <span className='flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap'>
                  Login
                </span>
              </ButtonDefault>
            </span>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModalCheckout;
