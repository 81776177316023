import React from 'react';

import { CustomIconProps } from '../../src/utils/interfaces/Icon/index';

const AppleLoginSVG: React.FC<CustomIconProps> = ({
  width,
  height,
  fill,
  ...rest
}) => {
  return (
    <svg
      width={width ?? '25'}
      height={height ?? '32'}
      viewBox='0 0 25 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M20.2227 16.8919C20.2523 14.4989 21.4861 12.2345 23.4433 10.9811C22.2086 9.14422 20.1404 7.97958 17.9888 7.90946C15.6939 7.65853 13.469 9.33992 12.2996 9.33992C11.1075 9.33992 9.30692 7.93437 7.36802 7.97592C4.84074 8.06098 2.48469 9.55772 1.25512 11.8593C-1.38798 16.6261 0.583534 23.6316 3.1154 27.4847C4.38214 29.3715 5.8626 31.4791 7.79976 31.4043C9.69538 31.3224 10.4034 30.1452 12.6915 30.1452C14.9585 30.1452 15.6227 31.4043 17.5992 31.3568C19.6333 31.3224 20.9148 29.4617 22.1371 27.557C23.0473 26.2127 23.7476 24.7268 24.2123 23.1546C21.8218 22.1014 20.2255 19.5955 20.2227 16.8919Z'
        fill='white'
      />
      <path
        d='M16.4895 5.37547C17.5986 3.98859 18.1449 2.206 18.0126 0.40625C16.3182 0.591631 14.7531 1.43519 13.629 2.76886C12.5298 4.07191 11.9578 5.82314 12.066 7.5541C13.761 7.57228 15.4273 6.75159 16.4895 5.37547Z'
        fill='white'
      />
    </svg>
  );
};

export default AppleLoginSVG;
