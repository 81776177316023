import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { formateErrorObject } from '../shared/formateErrorObject';
import { notify } from '../shared/notify';
import { EventDetails, logEvent } from '../../hooks/useClickEventTracking';
import {
  createRazorpayPayment,
  handlePaymentFailed,
} from '../../services/Cart';
import { User } from '../../utils/interfaces/User';
import { Logo } from '../../../assets';

interface RazorpayData {
  amount?: {
    subTotal: number;
    discount: string;
    total: number;
    tax: number;
    couponCode: { coupon_code: string }[];
  };
  razorpayOrderId: string;
  productIds?: string;
  orderId: string;
  userData: User;
  setFetchOrders?: (value: boolean) => void;
  fetchOrders?: boolean;
  setVisible?: (value: boolean) => void;
  paymentType: string;
  totalAmount?: number;
}

export const usePayment = () => {
  const router = useRouter();
  const { cartId } = router.query;

  // Define the beforeunload event listener
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      const isCartStep1 =
        router.pathname === '/cart' && router.query.cartStep === '1';
      if (isCartStep1) {
        event.preventDefault();
        event.returnValue = ''; // Required for Chrome to show the confirmation dialog
      }
    },
    [router.pathname, router.query.cartStep],
  );

  // Manage event listener based on the current route
  const manageBeforeUnloadListener = useCallback(() => {
    const isCartStep1 =
      router.pathname === '/cart' && router.query.cartStep === '1';
    if (isCartStep1) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [router.pathname, router.query.cartStep, handleBeforeUnload]);

  useEffect(() => {
    // Call the listener management function on route change
    manageBeforeUnloadListener();

    // Listen to route changes to manage the event listener accordingly
    router.events.on('routeChangeComplete', manageBeforeUnloadListener);

    // Cleanup event listeners when component unmounts or route changes
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      router.events.off('routeChangeComplete', manageBeforeUnloadListener);
    };
  }, [manageBeforeUnloadListener, handleBeforeUnload, router.events]);

  const initializeRazorpay = async ({
    amount,
    razorpayOrderId,
    orderId,
    userData,
    setFetchOrders,
    fetchOrders,
    setVisible,
    productIds,
    totalAmount,
  }: RazorpayData): Promise<void> => {
    const handleCreateRazorpayPayment = async (response: any) => {
      try {
        await createRazorpayPayment(
          {
            order_id: orderId,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          },
          Number(cartId),
        );
        router.push({
          pathname: '/order-successful',
          query: {
            orderId: orderId,
            product_ids: productIds,
            callPurchased: 'purchased',
          },
        });

        if (setFetchOrders) {
          setFetchOrders(!fetchOrders);
        }
      } catch (error: any) {
        const message = formateErrorObject(error);
        notify(message, 'error');
      }
    };

    const paymentFailed = async () => {
      try {
        await handlePaymentFailed(
          {
            order_id: orderId,
            razorpay_order_id: razorpayOrderId,
          },
          Number(cartId),
        );
        const eventParams: EventDetails = {
          event_name: 'order_failed',
          source: 'global',
          source_type: 'orders',
          source_id: orderId,
          sub_source: null,
          sub_source_id: null,
          unit: 'INR',
          value: totalAmount,
          meta: {
            payment_mode: 'prepaid',
          },
        };

        logEvent({
          eventDetails: eventParams,
          userData: userData,
          pathname: router.pathname as string,
        });
      } catch (error: any) {
        const message = formateErrorObject(error);
        notify(message, 'error');
      }
    };

    if (typeof window !== 'undefined') {
      const options = {
        key: process.env.NEXT_PUBLIC_RAZORPAY_KEY!,
        amount: amount?.total || totalAmount!,
        currency: 'INR',
        name: 'ZebraLearn',
        image: Logo,
        order_id: razorpayOrderId,
        handler: (response: any) => {
          handleCreateRazorpayPayment(response);
        },
        modal: {
          escape: false,
          ondismiss: async () => {
            try {
              if (setVisible) {
                setVisible(true);
              }
              await paymentFailed();
            } catch (error) {
              console.error('Error in ondismiss:', error);
            }
          },
        },
        prefill: { name: userData.name, email: userData.email },
      };

      if ((window as any).Razorpay) {
        const rzp = new (window as any).Razorpay(options);

        rzp.open();

        // Cleanup listeners when the Razorpay modal is closed
        rzp.on('close', () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
          router.events.off('routeChangeComplete', manageBeforeUnloadListener);
        });
      } else {
        console.error('Razorpay SDK not loaded');
      }
    } else {
      console.error('Window object is undefined. Cannot initialize Razorpay.');
    }
  };

  return {
    initializeRazorpay,
  };
};
