import React from 'react';

const SchoolIcon: React.FC = () => {
  return (
    <svg
      width='18'
      height='17'
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='school'>
        <path
          id='Combo shape'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.666016 5.49993C0.666016 4.11922 1.7853 2.99994 3.16602 2.99994H3.95573C4.52341 2.99994 5.07418 2.80673 5.51747 2.45211L7.43761 0.915992C8.35066 0.185555 9.64804 0.185555 10.5611 0.915992L12.4812 2.45211C12.9245 2.80673 13.4753 2.99994 14.043 2.99994H14.8327C16.2134 2.99994 17.3327 4.11922 17.3327 5.49994V13.8333C17.3327 15.214 16.2134 16.3333 14.8327 16.3333H3.16602C1.7853 16.3333 0.666016 15.214 0.666016 13.8333V5.49993ZM10.6668 6.33322C10.6668 5.41274 9.92064 4.66655 9.00016 4.66655C8.07969 4.66655 7.3335 5.41274 7.3335 6.33322C7.3335 7.25369 8.07969 7.99988 9.00016 7.99988C9.92064 7.99988 10.6668 7.25369 10.6668 6.33322ZM8.99976 10.4999C7.61904 10.4999 6.49976 11.6192 6.49976 12.9999V16.3332H11.4998V12.9999C11.4998 11.6192 10.3805 10.4999 8.99976 10.4999Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export default SchoolIcon;
