import { ArrowLeftIcon, ArrowRightIcon, XIcon } from '@heroicons/react/outline';
import { Affix, Avatar, Carousel, Modal } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';

import { IReviewsData } from '.';
import StarRating from '../StarRating';
import { getUserInitials } from '../../ChatBox/Message';
import ParserV2 from '../../shared/basic/Parser/ParserV2';

interface Props {
  data: IReviewsData;
  isModalOpen: boolean;
  onClose: () => void;
}

const ProductReviewModal: React.FC<Props> = ({
  data,
  isModalOpen,
  onClose,
}) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const carouselRef = useRef<any>(null);
  const screens = useBreakpoint();
  const imageSize = {
    width: screens?.lg ? 400 : 200,
    height: screens?.lg ? 250 : 200,
  };
  const imageSizeSm = { width: 92, height: 100 };

  const handleImageChange = (index: number) => {
    setSelectedImage(index);
    carouselRef.current.goTo(index);
  };

  const handleSlideChange = (currentIndex: number) => {
    setSelectedImage(currentIndex);
  };
  const goToPreviousImage = () => {
    const prevIndex =
      selectedImage === 0 ? data.images.length - 1 : selectedImage - 1;
    setSelectedImage(prevIndex);
    carouselRef.current.goTo(prevIndex);
  };

  const goToNextImage = () => {
    const nextIndex = (selectedImage + 1) % data.images.length;
    setSelectedImage(nextIndex);
    carouselRef.current.goTo(nextIndex);
  };

  const avatarSrc = data?.user?.avatar?.trim()
    ? `${process.env.NEXT_PUBLIC_IMAGE_BASE_URL_ECOM}/${data?.user?.avatar}`
    : undefined;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft' && data) {
        goToPreviousImage();
      } else if (event.key === 'ArrowRight' && data) {
        goToNextImage();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedImage]);

  return (
    <Modal
      open={isModalOpen}
      onCancel={onClose}
      footer={null}
      centered
      height='100%'
      closable={false}
      width={1000}
      maskClosable={screens.md}
      className='customLoginModal bg-[#131313] h-full md:h-fit md:rounded-2xl border-2 border-borderDarkGray'
    >
      <div className='text-fadedWhite text-xl h-full relative'>
        <Affix offsetTop={0}>
          <div className='flex md:hidden justify-between gap-x-1'>
            {data?.images?.map((item, index) => {
              return (
                <motion.div
                  key={item?.imageURL}
                  onClick={() => handleImageChange(index)}
                  className='w-full'
                  animate={{
                    width: index === selectedImage ? 30 : 10,
                  }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  style={{
                    background: index === selectedImage ? '#166FF6' : 'white',
                    borderRadius: '5px',
                    height: '5px',
                    cursor: 'pointer',
                  }}
                />
              );
            })}
          </div>
        </Affix>

        <div className='flex justify-end md:justify-between items-center md:bg-backGroundReaderTabBg md:rounded-t-2xl px-4 md:px-8 py-4 md:py-6'>
          <div className='hidden md:flex flex-col gap-1'>
            <span>Product Review</span>
            <span className='text-textGray text-sm'>
              Write a review to help someone find their next best read
            </span>
          </div>
          <span className='border rounded-full p-1.5 border-[#363636] flex justify-center items-center'>
            <XIcon
              onClick={onClose}
              className='w-5 h-5 text-[#A6A6A6] cursor-pointer'
            />
          </span>
        </div>

        <div className='md:px-7 pt-2 md:pb-5'>
          <div className='flex flex-col md:flex-row justify-center gap-x-7 w-full md:p-4'>
            <div className='w-full md:w-2/3 md:bg-bgColor relative md:rounded-2xl md:p-4 flex justify-center flex-col items-center'>
              <Carousel
                ref={carouselRef}
                dots={false}
                arrows={!screens.md}
                initialSlide={selectedImage}
                className={`relative border-bgDarkGray md:min-w-[${imageSize.width}px] md:max-h-[${imageSize.height}px] w-[95vw]  md:w-[40vw] xl:min-w-[30vw] 2xl:max-w-[20vw] md:w-[${imageSize.width}px] lg:rounded-2xl overflow-hidden`}
                effect='scrollx'
                afterChange={handleSlideChange}
              >
                {data?.images?.map((src, index) => (
                  <Image
                    src={src?.imageURL}
                    alt={`Image ${src?.imageId}`}
                    key={index}
                    width={imageSize.width}
                    height={imageSize.height}
                    className='md:rounded-md object-fill h-[50vh] md:h-[60vh]'
                  />
                ))}
              </Carousel>

              <div className='hidden md:flex gap-x-3 pt-4 '>
                <span className='flex justify-center rounded-full border-[#737373] border-[1.6px] items-center w-7 h-7'>
                  <ArrowLeftIcon
                    className='transform w-3.5 h-3.5 cursor-pointer text-[#737373]'
                    onClick={goToPreviousImage}
                  />
                </span>
                <span className='flex justify-center rounded-full border-[#737373] border-[1.6px] items-center w-7 h-7'>
                  <ArrowRightIcon
                    className='transform w-3.5 h-3.5 cursor-pointer text-[#737373]'
                    onClick={goToNextImage}
                  />
                </span>
              </div>
            </div>

            <div className='md:w-1/3 flex flex-col items-start pl-6 space-y-4 p-4 md:p-0 bg-[#131313] '>
              <div className='mt-7 w-full'>
                <div className='flex justify-between w-full items-center'>
                  <div className='flex items-center no-select'>
                    <Avatar
                      size={24}
                      alt='profile-picture'
                      style={{ fontSize: '14px' }}
                      className={clsx(!avatarSrc && 'bg-primary')}
                      src={avatarSrc}
                    >
                      {data?.user?.name && getUserInitials(data?.user.name)}
                    </Avatar>
                    <div className='ml-2.5'>
                      <h3 className='font-semibold text-xs translate-y-2 md:translate-y-1'>
                        {data?.user.name}
                      </h3>
                      <span>
                        <StarRating
                          rating={data?.rating || 0}
                          color='#00A86B'
                          starSize='12px'
                        />
                      </span>
                    </div>
                  </div>
                  {/* <p className='md:mt-2 text-[#FFFFFFB2] font-light text-xs'>
                    {moment(data?.createdAt).format('DD, MMM YYYY')}
                  </p> */}
                </div>
                <p className='mt-4 md:mt-0 text-sm text-[#FFFFFFCC]'>
                  <ParserV2 isPopOver={false} text={data?.text} maxWords={50} />
                </p>
              </div>
              <div className='grid-cols-3 gap-2 hidden md:grid '>
                {data?.images?.map((src, index) => (
                  <Image
                    src={src?.imageURL}
                    alt={`Image-${index} ${src?.imageId}`}
                    key={`Image-${index} ${src?.imageId}`}
                    onClick={() => handleImageChange(index)}
                    width={imageSizeSm.width}
                    height={imageSizeSm.height}
                    className={`cursor-pointer object-fill flex flex-wrap min-w-[${imageSizeSm.width}px] h-[${imageSizeSm.height}px] p-1 rounded-md ${
                      index === selectedImage ? 'ring-[1px] ring-white' : ''
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductReviewModal;
