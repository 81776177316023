import { apiClient } from '../apiClients';
import { Endpoints } from '../../network';

export const affiliateLinkClicks = async (afc: string) => {
  const response = await apiClient.post(`${Endpoints.linkClick}`, {
    code: afc,
  });

  return response?.data?.data;
};

export const affiliateCodeSend = async (afc: string) => {
  const response = await apiClient.post(`${Endpoints.affiliateCode}`, {
    code: afc,
  });

  return response?.data?.data;
};
