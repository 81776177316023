import React from 'react';
import {CustomIconProps} from "../../src/utils/interfaces/Icon";

const BackIcon: React.FC<CustomIconProps> = ({width, height, fill, ...rest}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             width={width ? width : "24"}
             height={height ? height : "24"}
             viewBox="0 0 24 24"
             fill={fill ? fill : "black"}
             {...rest}
        >
            <path
                d="M14.5866 8.61C14.649 8.53351 14.7259 8.47009 14.8129 8.4234C14.8999 8.3767 14.9953 8.34764 15.0935 8.33789C15.1918 8.32813 15.291 8.33787 15.3855 8.36655C15.48 8.39523 15.5678 8.44228 15.6441 8.505L19.2366 11.46C19.3888 11.6006 19.4825 11.7934 19.4991 12C19.5047 12.1146 19.484 12.2289 19.4385 12.3343C19.393 12.4396 19.3239 12.5331 19.2366 12.6075L15.6441 15.5625C15.4896 15.6745 15.2982 15.7234 15.1089 15.6993C14.9196 15.6751 14.7467 15.5797 14.6252 15.4325C14.5038 15.2852 14.4431 15.0973 14.4554 14.9068C14.4678 14.7164 14.5522 14.5378 14.6916 14.4075L16.6641 12.795H12.1641C11.9652 12.795 11.7744 12.716 11.6338 12.5753C11.4931 12.4347 11.4141 12.2439 11.4141 12.045C11.4141 11.8461 11.4931 11.6553 11.6338 11.5147C11.7744 11.374 11.9652 11.295 12.1641 11.295H16.6641L14.6916 9.66C14.5393 9.5341 14.4429 9.3532 14.4232 9.15659C14.4035 8.95998 14.4623 8.76356 14.5866 8.61ZM6.0966 20.25L6.0966 3.75C6.0966 3.55109 6.01758 3.36032 5.87693 3.21967C5.73627 3.07902 5.54551 3 5.3466 3C5.14769 3 4.95692 3.07902 4.81627 3.21967C4.67562 3.36032 4.5966 3.55109 4.5966 3.75L4.5966 20.25C4.5966 20.4489 4.67562 20.6397 4.81627 20.7803C4.95692 20.921 5.14769 21 5.3466 21C5.54551 21 5.73627 20.921 5.87693 20.7803C6.01758 20.6397 6.0966 20.4489 6.0966 20.25Z"
                fill="white"/>
        </svg>
    );
};

export default BackIcon;
