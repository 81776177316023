import React from 'react';

const BookIcon: React.FC = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icons'>
        <path
          id='Combo shape'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.9163 1.66687H7.08301C5.01194 1.66687 3.33301 3.34581 3.33301 5.41687V13.038C3.99645 12.4442 4.87256 12.0831 5.83301 12.0831H16.6663V5.41687C16.6663 3.3458 14.9874 1.66687 12.9163 1.66687ZM6.66675 4.0625C6.14898 4.0625 5.72925 4.48223 5.72925 5C5.72925 5.51777 6.14898 5.9375 6.66675 5.9375H13.3334C13.8512 5.9375 14.2709 5.51777 14.2709 5C14.2709 4.48223 13.8512 4.0625 13.3334 4.0625H6.66675ZM5.72925 8.33313C5.72925 7.81536 6.14898 7.39563 6.66675 7.39563H10.0001C10.5178 7.39563 10.9376 7.81536 10.9376 8.33313C10.9376 8.8509 10.5178 9.27063 10.0001 9.27063H6.66675C6.14898 9.27063 5.72925 8.8509 5.72925 8.33313ZM5.83301 13.3331H16.6663V14.5831C16.6663 16.6542 14.9874 18.3331 12.9163 18.3331H5.83301C4.45229 18.3331 3.33301 17.2138 3.33301 15.8331C3.33301 14.4524 4.4523 13.3331 5.83301 13.3331Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export default BookIcon;
