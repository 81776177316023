import { logEvent } from 'firebase/analytics';

import { activityLogsApiClient } from '../apiClients';
import { initializeAnalytics } from '../../utils/firebase/firebaseInit';

export const logPageView = async (eventData: any) => {
  try {
    activityLogsApiClient.post('/', eventData).catch((error) => {
      console.error('Error logging to API:', error);
    });
    const analyticsInstance = await initializeAnalytics();

    if (analyticsInstance) {
      logEvent(analyticsInstance, eventData.event, eventData);
    } else {
      console.warn(
        'Firebase Analytics is not initialized or supported on this device.',
      );
    }
  } catch (error) {
    console.error('Error logging event to Firebase Analytics:', error);
  }
};
