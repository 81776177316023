import axios, { InternalAxiosRequestConfig } from 'axios';

const iFrameApiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  timeout: 300000,
});

let token: string | undefined = undefined;

// Check if localStorage is available
if (typeof localStorage !== 'undefined') {
  const encodedToken = localStorage.getItem('encodedToken');
  if (encodedToken) {
    token = atob(encodedToken);
  }
}

iFrameApiClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
);

iFrameApiClient.interceptors.response.use((response) => {
  return response;
});

export default iFrameApiClient;
