import { apiClientForCourses } from '../apiClients';
import { fetchAndCache } from '../../components/shared/CacheStroage';
import { Endpoints } from '../../network';

export const getAllCourse = async ({
  page,
  categoriesId = null,
  keyword,
  limit,
}: {
  page: string;
  categoriesId: string | null;
  keyword?: string;
  limit?: string;
}) => {
  const queryParams = new URLSearchParams({
    page,
    ...(categoriesId !== 'undefined' &&
      categoriesId !== null && { category: categoriesId }),
    ...(keyword !== undefined && { search: keyword }),
    ...(limit !== undefined && { limit }),
  });
  const apiCall = async () => {
    const response = await apiClientForCourses.get(
      `${Endpoints.getAllCourses}?${queryParams}`,
    );
    return response?.data;
  };
  return fetchAndCache(
    apiCall,
    `${Endpoints.getAllCourses}?${queryParams}`,
    page,
  );
};

export const getMyCourses = async (searchQuery?: string) => {
  let url = Endpoints.getMyCourses;
  if (searchQuery) {
    url += `?search=${encodeURIComponent(searchQuery)}`;
  }
  const response = await apiClientForCourses.get(url);

  return response?.data;
};

export const getCoursesAllCategories = async () => {
  const response = await apiClientForCourses.get(
    `${Endpoints.coursesAllCategories}`,
  );
  return response?.data;
};

export const syncCourses = async () => {
  const response = await apiClientForCourses.get(`${Endpoints.syncCourses}`);

  return response?.data;
};
