import React from 'react';

const HomeIcon: React.FC = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Iconly/Bold/Home'>
        <g id='Home'>
          <path
            id='Home_2'
            d='M7.61199 17.3111V14.763C7.61199 14.1125 8.14315 13.5852 8.79837 13.5852H11.1935C11.5082 13.5852 11.8099 13.7093 12.0324 13.9302C12.2549 14.151 12.3799 14.4506 12.3799 14.763V17.3111C12.3779 17.5815 12.4847 17.8415 12.6767 18.0334C12.8686 18.2254 13.1297 18.3333 13.4021 18.3333H15.0362C15.7994 18.3353 16.532 18.0357 17.0723 17.5006C17.6127 16.9656 17.9163 16.2391 17.9163 15.4815V8.22234C17.9163 7.61034 17.6431 7.02983 17.1702 6.63718L11.6114 2.22985C10.6444 1.45709 9.25893 1.48204 8.32083 2.28911L2.88885 6.63718C2.39362 7.01825 2.09763 7.60049 2.08301 8.22234V15.4741C2.08301 17.0532 3.37249 18.3333 4.96315 18.3333H6.55992C7.1257 18.3333 7.58551 17.8801 7.5896 17.3185L7.61199 17.3111Z'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  );
};

export default HomeIcon;
