import { Badge, Skeleton, Tag } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import ParserV2 from '../shared/basic/Parser/ParserV2';
import { ShouldRender } from '../shared/basic/ShouldRender';
import { getLocalUserId, getZl_UID } from '../shared/getToken';
import { notify } from '../shared/notify';
import HomepageSlider from '../V2/HomePage/HomepageSlider';
import { user } from '../../atoms/user';
import { Endpoints } from '../../network';
import { generateUrlForImage } from '../../pages/books/[bookId]';
import { addToRecentlyViewed } from '../../services/Cart';
import {
  getAllBookListApi,
  getAllRecentlyViewedBooksApi,
} from '../../services/Library/Books';
import { PaginationProps } from '../../utils/interfaces/Orders';
import { User } from '../../utils/interfaces/User';
import { BookDummy } from '../../../assets';

interface Props {
  categoryName: string;
  categoryId: string | null;
  search?: string;
  bookType?: string;
  isHomePage?: boolean;
  categoryIdFilter?: string | null;
  order?: string;
  featured?: boolean;
  isRecentlyViewed?: boolean;
  includeCategories?: string;
}

const PaginatedBookSlider: React.FC<Props> = ({
  categoryId,
  categoryName,
  search,
  isHomePage = false,
  bookType,
  categoryIdFilter,
  order,
  featured,
  isRecentlyViewed = false,
  includeCategories,
}) => {
  const [books, setBooks] = useState<
    {
      _id: string;
      coverImageUrl: string;
      title: string;
      externalLink: string;
      type: string;
      slug: string;
      totalPages: number;
      isExternalLink: boolean;
    }[]
  >([]);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(true);
  const [isPaginateShift, setIsPaginateShift] = useState(true);
  const [paginationData, setPaginationData] = useState<PaginationProps>();
  const userData: User | undefined = useRecoilValue(user);

  const screens = useBreakpoint();
  const router = useRouter();

  const handleGetAllBooks = async () => {
    try {
      setLoading(true);
      const response = await getAllBookListApi({
        page: page.toString(),
        categoriesId: categoryId,
        keyword: search,
        ...(isHomePage && (bookType ? { type: bookType } : { type: 'long' })),
        ...(categoryIdFilter && { categoryId: categoryIdFilter as string }),
        ...(order && { order }),
        ...(featured && { featured }),
        ...(includeCategories && { includeCategories }),
      });
      const { data } = response;

      const formattedBooks = data?.map((book: any) => ({
        _id: book?._id,
        coverImageUrl: generateUrlForImage(
          `${process.env.NEXT_PUBLIC_API_READER_URL}/`,
          `${Endpoints.getCoverImagesForBooks}/`,
          `${book?._id}`,
        ),
        title: book?.title,
        externalLink: book?.externalLink,
        slug: book?.slug,
        type: book?.type,
        isExternalLink: book?.isExternalLink,
        totalPages: book?.totalPages,
      }));

      setPaginationData({
        current_page: response?.metadata?.page,
        per_page: response?.metadata?.limit,
        total: response?.metadata?.total,
      });

      if (page === 1) {
        setBooks(formattedBooks);
      } else {
        setBooks((prevData) => [...prevData, ...formattedBooks]);
      }
    } catch (error: any) {
      notify(error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleGetRecentlyViewedBooks = async () => {
    try {
      setLoading(true);
      const [zl_uid, localUserId] = await Promise.all([
        getZl_UID(),
        getLocalUserId(),
      ]);
      const response = await getAllRecentlyViewedBooksApi({
        page: page.toString(),
        keyword: search,
        zl_uid: zl_uid,
        tempUserId: localUserId as string,
      });
      const { data } = response;

      const formattedBooks = data?.map((book: any) => ({
        _id: book?.product?._id,
        coverImageUrl: generateUrlForImage(
          `${process.env.NEXT_PUBLIC_API_READER_URL}/`,
          `${Endpoints.getCoverImagesForBooks}/`,
          `${book?.product?._id}`,
        ),
        title: book?.product?.title,
        externalLink: book?.product?.externalLink,
        slug: book?.product?.slug,
        type: book?.product?.type,
        isExternalLink: book?.product?.isExternalLink,
        totalPages: book?.product?.totalPages,
      }));

      setPaginationData({
        current_page: response?.metadata?.page,
        per_page: response?.metadata?.limit,
        total: response?.metadata?.total,
      });

      if (page === 1) {
        setBooks(formattedBooks);
      } else {
        setBooks((prevData) => [...prevData, ...formattedBooks]);
      }
    } catch (error: any) {
      notify(error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleAddtwoRecentlyReviewed = async (bookId: string) => {
    try {
      addToRecentlyViewed(bookId, userData?.zl_uid);
    } catch (error: any) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (isRecentlyViewed) {
      handleGetRecentlyViewedBooks();
    } else {
      handleGetAllBooks();
    }
  }, [page, categoryId, search, categoryIdFilter, isRecentlyViewed]);

  const handlePagination = () => {
    if (paginationData && books?.length < paginationData?.total)
      setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      {loading || books?.length > 0 ? (
        <HomepageSlider
          heading={categoryName}
          onPagination={handlePagination}
          setIsPaginateShift={setIsPaginateShift}
          isPaginateShift={isPaginateShift}
          loading={!loading && !!books?.length}
          total={paginationData?.total || 0}
          imageWidths={[120, 140, 200]}
        >
          {loading
            ? Array.from({ length: 10 }).map((_, index) => (
                <span
                  key={index}
                  style={{
                    minHeight: screens.xl
                      ? '356px'
                      : screens.lg
                        ? '267px'
                        : screens.md
                          ? '237px'
                          : '233px',
                  }}
                  className='group cursor-pointer flex flex-col gap-y-1 px-1 md:px-1.5 bg-transparent no-select'
                >
                  <Skeleton.Button
                    style={{
                      width: '200px',
                      height: screens.xl ? '250px' : '176px',
                    }}
                    active
                    className='rounded-sm md:rounded-md transition-transform transform duration-500 max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'
                  />
                  <Skeleton.Button
                    style={{
                      width: screens.xl
                        ? '100px'
                        : screens.lg
                          ? '50px'
                          : '20px',
                      height: '8px',
                    }}
                    active
                    className='rounded-sm mt-[5px]'
                  />
                  <Skeleton.Button
                    style={{
                      height: '8px',
                    }}
                    active
                    className='rounded-sm w-full max-w-[200px]'
                  />
                </span>
              ))
            : books?.map((item, index) => (
                <span
                  key={index}
                  className='group cursor-pointer p-1 md:p-1.5 no-select'
                >
                  <ShouldRender check={item.type === 'short'}>
                    <span
                      onClick={() =>
                        router.push(
                          `/books/${item.slug}?productId=${item?._id}&swp=${item?.isExternalLink}`,
                        )
                      }
                    >
                      <Badge.Ribbon
                        placement='start'
                        className='customRibbon text-white'
                        text={<div className='text-xs font-medium'>Free</div>}
                        style={{ color: 'white' }}
                      >
                        <Image
                          src={item.coverImageUrl || BookDummy}
                          alt={item.title}
                          width={screens.md ? 200 : 120}
                          height={screens.md ? 300 : 180}
                          className='rounded-sm md:rounded-md max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'
                          style={{ objectFit: 'cover' }}
                        />
                      </Badge.Ribbon>
                      <div className='mt-2'>
                        <span className='text-textGrayColor text-xs md:text-sm'>
                          {item?.totalPages} Pages
                        </span>
                        <div className='text-white text-sm xl:text-base font-medium w-full max-w-[120px] lg:max-w-[140px] xl:max-w-[200px] max-h-[40px] truncate'>
                          <ParserV2
                            isPopOver={false}
                            text={item?.title}
                            maxWords={10}
                          />
                        </div>
                      </div>
                    </span>
                  </ShouldRender>

                  <ShouldRender check={item.type === 'comingSoon'}>
                    <span
                      onClick={() =>
                        router.push(
                          `/books/${item.slug}?productId=${item?._id}&swp=${item?.isExternalLink}&cS`,
                        )
                      }
                    >
                      <span className='max-w-[120px] lg:max-w-[140px] xl:max-w-[200px] relative'>
                        <Image
                          src={item.coverImageUrl || BookDummy}
                          alt={item.title}
                          width={screens.md ? 200 : 120}
                          height={screens.md ? 300 : 180}
                          className='rounded-sm md:rounded-md max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'
                          style={{ objectFit: 'cover' }}
                        />
                        <Tag
                          color='#3E0A6E'
                          className='text-[#EAD3FF] absolute bottom-1 xl:bottom-2 left-2 font-semibold md:py-0.5 text-[10px] md:text-xs rounded-[4px]'
                        >
                          Coming Soon
                        </Tag>
                      </span>
                      <div className='mt-2'>
                        <span className='text-textGrayColor text-xs md:text-sm'>
                          {item?.totalPages} Pages
                        </span>
                        <div className='text-white max-h-[40px] text-sm xl:text-base truncate font-medium w-full max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'>
                          <ParserV2
                            isPopOver={false}
                            text={item?.title}
                            maxWords={10}
                          />
                        </div>
                      </div>
                    </span>
                  </ShouldRender>

                  <ShouldRender check={item.type === 'long'}>
                    <span
                      onClick={() => {
                        if (item?.isExternalLink) {
                          router.push(`/books/${item.slug}-${item?._id}`);
                        }
                        handleAddtwoRecentlyReviewed(item?._id);
                      }}
                    >
                      <Image
                        src={item.coverImageUrl || BookDummy}
                        alt={item.title}
                        width={screens.md ? 200 : 120}
                        height={screens.md ? 300 : 180}
                        className='rounded-sm md:rounded-md max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'
                        style={{ objectFit: 'cover' }}
                      />
                      <div className='mt-2'>
                        <span className='text-textGrayColor text-xs md:text-sm'>
                          {item?.totalPages} Pages
                        </span>
                        <div className='text-white text-sm xl:text-base truncate font-medium w-full max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'>
                          {item?.title}
                        </div>
                      </div>
                    </span>
                  </ShouldRender>
                </span>
              ))}
          <ShouldRender check={loading || isPaginateShift}>
            {Array.from({ length: 10 }).map((_, index) => (
              <span
                key={index}
                className='group cursor-pointer flex flex-col p-1 md:p-1.5 bg-transparent no-select'
              >
                <Skeleton.Button
                  style={{
                    width: '200px',
                    height: screens.xl ? '290px' : '175px',
                  }}
                  active
                  className='rounded-md transition-all duration-300'
                />
                <Skeleton.Button
                  style={{
                    width: screens.xl ? '100px' : screens.lg ? '50px' : '20px',
                    height: '12px',
                  }}
                  active
                  className='rounded-sm mt-2'
                />
                <Skeleton.Button
                  style={{
                    height: '20px',
                  }}
                  active
                  className='rounded-sm w-full max-w-[200px]'
                />
              </span>
            ))}
          </ShouldRender>
        </HomepageSlider>
      ) : null}
    </>
  );
};

export default PaginatedBookSlider;
