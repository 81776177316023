import React from 'react';

import { CustomIconProps } from '../../../src/utils/interfaces/Icon';

const GooglePlay: React.FC<CustomIconProps> = ({ width, height }) => {
  return (
    <svg
      width={width ?? '27'}
      height={height ?? '29'}
      viewBox='0 0 27 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.9427 13.84L0.850586 26.5807C0.851041 26.5833 0.851949 26.5855 0.852375 26.5882C1.22323 27.9717 2.49623 28.9904 4.00702 28.9904C4.61096 28.9904 5.17798 28.8283 5.66425 28.5438L5.70287 28.5213L19.3148 20.7243L12.9427 13.84Z'
        fill='#EB3131'
      />
      <path
        d='M25.1771 11.6714L25.1655 11.6635L19.2888 8.28174L12.668 14.13L19.3118 20.7241L25.1575 17.376C26.1823 16.8268 26.8781 15.7543 26.8781 14.5175C26.8781 13.2896 26.1918 12.2224 25.1771 11.6714Z'
        fill='#F6B60B'
      />
      <path
        d='M0.848695 2.39941C0.776001 2.66551 0.737793 2.94423 0.737793 3.23366V25.7476C0.737793 26.0365 0.77555 26.3162 0.849156 26.5813L13.3587 14.1662L0.848695 2.39941Z'
        fill='#5778C5'
      />
      <path
        d='M13.0316 14.4905L19.2908 8.2791L5.69426 0.45389C5.20004 0.160058 4.62323 -0.00952148 4.00601 -0.00952148C2.49522 -0.00952148 1.2205 1.01102 0.849576 2.39612C0.849121 2.39747 0.849121 2.3984 0.849121 2.39959L13.0316 14.4905Z'
        fill='#3BAD49'
      />
    </svg>
  );
};

export default GooglePlay;
