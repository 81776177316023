import React from 'react';

interface UpdatedCustomIconProps {
  filled?: boolean;
}

const VerifiedIcon: React.FC<UpdatedCustomIconProps> = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_718_47433)'>
      <path
        d='M23.25 12C23.25 12.96 22.0706 13.7512 21.8344 14.6362C21.5906 15.5512 22.2075 16.8263 21.7444 17.6269C21.2738 18.4406 19.8581 18.5381 19.1981 19.1981C18.5381 19.8581 18.4406 21.2738 17.6269 21.7444C16.8263 22.2075 15.5512 21.5906 14.6362 21.8344C13.7512 22.0706 12.96 23.25 12 23.25C11.04 23.25 10.2487 22.0706 9.36375 21.8344C8.44875 21.5906 7.17375 22.2075 6.37313 21.7444C5.55938 21.2738 5.46187 19.8581 4.80188 19.1981C4.14188 18.5381 2.72625 18.4406 2.25563 17.6269C1.7925 16.8263 2.40938 15.5512 2.16563 14.6362C1.92938 13.7512 0.75 12.96 0.75 12C0.75 11.04 1.92938 10.2487 2.16563 9.36375C2.40938 8.44875 1.7925 7.17375 2.25563 6.37313C2.72625 5.55938 4.14188 5.46187 4.80188 4.80188C5.46187 4.14188 5.55938 2.72625 6.37313 2.25563C7.17375 1.7925 8.44875 2.40938 9.36375 2.16563C10.2487 1.92938 11.04 0.75 12 0.75C12.96 0.75 13.7512 1.92938 14.6362 2.16563C15.5512 2.40938 16.8263 1.7925 17.6269 2.25563C18.4406 2.72625 18.5381 4.14188 19.1981 4.80188C19.8581 5.46187 21.2738 5.55938 21.7444 6.37313C22.2075 7.17375 21.5906 8.44875 21.8344 9.36375C22.0706 10.2487 23.25 11.04 23.25 12Z'
        fill='url(#paint0_linear_718_47433)'
      />
      <g filter='url(#filter0_d_718_47433)'>
        <path
          d='M15.2511 8.64754L10.9686 12.93L8.74863 10.7119C8.26676 10.23 7.48488 10.23 7.00301 10.7119C6.52113 11.1938 6.52113 11.9757 7.00301 12.4575L10.1174 15.5719C10.5861 16.0407 11.3474 16.0407 11.8161 15.5719L16.9949 10.3932C17.4768 9.91129 17.4768 9.12941 16.9949 8.64754C16.513 8.16566 15.733 8.16566 15.2511 8.64754Z'
          fill='white'
        />
      </g>
    </g>
    <defs>
      <filter
        id='filter0_d_718_47433'
        x='2.6416'
        y='8.28613'
        width='18.7148'
        height='15.6375'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_718_47433'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_718_47433'
          result='shape'
        />
      </filter>
      <linearGradient
        id='paint0_linear_718_47433'
        x1='12'
        y1='0.75'
        x2='12'
        y2='23.25'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#6199FF' />
        <stop offset='1' stopColor='#045CFB' />
      </linearGradient>
      <clipPath id='clip0_718_47433'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default VerifiedIcon;
