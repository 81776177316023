import { Modal } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { useEffect } from 'react';
import { UAParser } from 'ua-parser-js';

import GetAppModalContent from './GetAppModalContent';

interface Props {
  visible?: boolean;
  onCancel?: () => void;
  isClosable?: boolean;
}

const GetOurAppModal: React.FC<Props> = ({ visible, onCancel, isClosable }) => {
  const screens = useBreakpoint();

  useEffect(() => {
    if (!screens.md && visible) {
      const parser = new UAParser();
      const result = parser.getResult();
      const deviceType = result.device.type;
      const osName = result.os.name;

      if (deviceType === 'mobile' || deviceType === 'tablet') {
        if (osName === 'Android') {
          window.location.href =
            'https://play.google.com/store/apps/details?id=com.zebralearn.reader&utm_source=zebralearn_website';
        } else if (osName === 'iOS') {
          window.location.href =
            'https://apps.apple.com/in/app/zebralearn/id6477746268?utm_source=zebralearn_website';
        }
      }
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      centered={true}
      closable={false}
      onCancel={onCancel}
      footer={false}
      className='customModalNoPadding customLoginModal bg-none'
      styles={{
        body: {
          background: 'transparent',
        },
      }}
    >
      <GetAppModalContent isClosable={isClosable} onCancel={onCancel} />
    </Modal>
  );
};

export default GetOurAppModal;
